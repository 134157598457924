import React from 'react';
import { useHistory} from 'react-router-dom';
 import NoDataIcon from '../../assets/imgs/Person.png';

// const Img = document.getElementById('no-data-img');
// Img.src = NoDataIcon;

const NoDataFound = (props) =>{
    const history = useHistory();

    const goToAddPage = () => {
        history.push(props.redirectUrl);
    }

    return (
        <div className="no-data-found">
            <div className="row align-items-center">
                <div className="col-3"></div>
                <div className="col-6 content-wrapper align-self-center">
                    <img className="img-container" src={NoDataIcon}></img>
                    <p className="text-msg">You don't have any {props.name} to show! Create Now</p>
                    <div className="text-center">
                        {props.hideButton ? '': <button type="button" className="btn btn-primary btn-sm btn-padding" onClick={goToAddPage}>Add {props.name}</button>}
                    </div>
                    
                </div>
                <div className="col3"></div>
            </div>  
        </div>
    )
}

export default NoDataFound;