import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import StaffService from '../../service/StaffService';
import Loader from '../../component/Loader';
import ConfirmModal from '../../component/modal/ConfirmModal';
import ErrorModal from '../../component/modal/ErrorModal';
import SuccessModal from '../../component/modal/SuccessModal';
import DefaultMaleProfilePic from '../../../assets/imgs/matthew@2x.png';
import DefaultFemaleProfilePic from '../../../assets/imgs/molly@2x.png';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import EventIcon from '@material-ui/icons/Event';
import ClassIcon from '@material-ui/icons/Class';
import Moment from 'react-moment';
import WarningIcon from '@material-ui/icons/Warning';

const ActivityList = (props) => {
    return (
        <div className="activity-date-section">
            <p><Moment date={new Date(props.data.date)} format={" MMMM DD YYYY"}></Moment></p>
            {props.data.messages && props.data.messages.map((item, index) => {
                return (
                    <div className="card activity-list-card ml-4" key={index}>
                        <div className="card-body p-2">
                            <div className="card-title row no-gutters mb-2">
                                <p className="col m-0">{item.message}</p>
                                <div className="col-auto"><Moment date={new Date(item.createdOn)} format={"hh:mm A"}></Moment></div>
                            </div>

                            <p className="card-text">{item.detailedMessage}</p>
                        </div>
                    </div>
                )
            })}
        </div>

    )
}

const StaffView = () => {
    const { id, userType } = useParams();
    const [staffData, setStaffData] = useState({});
    const [activityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [confirmationObj, setConfirmationObj] = useState({ show: false, msg: '' });
    const [errModel, setErrorModal] = useState({ show: false, error: {} })
    const [successModel, setSuccessModal] = useState({ show: false, success: {} })
    const [currentAction, setCurrentAction] = useState('');
    const history = useHistory();

    useEffect(() => {
        getStaffData();
        getActivityData();
    }, []);

    const getStaffData = async () => {
        try {
            const response = await StaffService.getStaffDetails(id);
            if (response.status === 200) {
                setStaffData(response.data.result);
                setLoading(false);
            }
        }
        catch (err) {
            setLoading(false);
            if (err && err.data) {
                setErrorModal({ show: true, error: err.data })
            }
        }

    }
    const getActivityData = async () => {
        try {
            const response = await StaffService.getUserActivity(id);
            if (response.status === 200) {
                setActivityData(response.data.result);
                setLoading(false);
            }
        }
        catch (err) {
            setLoading(false);
            if (err && err.data) {
                setErrorModal({ show: true, error: err.data })
            }
        }
    }
    const closePopup = () => {
        setConfirmationObj({ show: false, msg: '' });
        setCurrentAction("");
    }

    const performAction = (e) => {
        e.persist()
        e.preventDefault();
        const action = e.target.dataset.value;
        setCurrentAction({ action: action, data: [id] });
        setConfirmationObj({ show: true, msg: `You want to ${action} this user?` });
    }

    const doAction = async () => {
        setLoading(true);
        setConfirmationObj({ show: false, msg: '' });

        try {
            const res = await StaffService.updateStaffStatus(currentAction.action, { userIds: currentAction.data });
            setCurrentAction({});
            setLoading(false);
            if (res.status === 200) {
                setSuccessModal({ show: true, success: { msg: res.data.result } });
                getStaffData();
            }
        } catch (err) {
            setLoading(false);
            if (err && err.data) {
                setErrorModal({ show: true, error: err.data })
            }
        }
    }


    if (loading) return <Loader />;

    return (
        <div id="staff-view">
            <div className="row cs-breadcrumb no-gutters">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/staff`}>Staff</Link>
                    </li>
                    <li className="breadcrumb-item">{staffData.firstName ? `${staffData.firstName} ${staffData.lastName} ` : ''}</li>
                </ol>
            </div>
            <div className="content-wrapper" >
                <div className="content-container">
                    <div className="row staff-detail-container">
                        <div className="col-auto">
                            {staffData?.profilePic && staffData?.profilePic?.href ?
                                <img className="profile-staff-img" src={staffData?.profilePic?.href} /> :
                                <div className="profile-staff-no-img">No Image</div>
                            }

                        </div>
                        <div className="col">
                            <div className="name-section row no-gutters">
                                <p className="col-auto name m-0">{staffData.firstName + " " + staffData.lastName}</p>
                                <p className="col-auto mb-0 ml-4 mt-2">
                                    {staffData.isArchived ? <span className="badge badge-danger">Archived</span> : staffData.isAccessBlocked ? <span className="badge badge-warning">Inactive</span> : <span className="badge badge-success">Active</span>} {!staffData.hasEmailVerified ? <span className="badge badge-danger" title="Email Not Verified">NOT VERIFIED</span> : <span className="badge badge-primary" title="Email Verified">VERIFIED</span>}
                                </p>
                                <div className="col d-flex flex-row-reverse staff-detail-action ">
                                    {<Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/staff/edit/${id}`} className="btn btn-primary">Edit Profile</Link>}
                                    {
                                        staffData.isAccessBlocked ?
                                            <button type="button" className="btn btn-warning" data-value="activate" onClick={performAction} >Activate Profile</button> :
                                            <button type="button" className="btn btn-warning" data-value="deactivate" onClick={performAction}>Deactivate Profile</button>
                                    }
                                    {
                                        <button type="button" className="btn btn-danger" data-value="delete" onClick={performAction}>Delete Permanently</button>
                                    }

                                </div>
                            </div>
                            <div className="detail-section row no-gutters">
                                <div className="col-6 item-detail" title='Primary Email Address'> <MailIcon /> {staffData.email}</div>
                                <div className="col-6 item-detail" title='Primary Contact Number'> <PhoneIcon /> {staffData.contactNumber}</div>
                                <div className="col-6 item-detail" title='Joined On'> <EventIcon /> {staffData.createdOn ? <Moment date={staffData.createdOn} format={"DD MMM YYYY"}></Moment>: 'NA'}</div>
                                <div className="col-6 item-detail" title='Staff Role'> <ClassIcon /> {staffData.role && staffData.role.name ? staffData.role.name : 'NA'}</div>

                            </div>
                            <div className="staff-activity-details">
                                <div className="header mb-4">
                                    Activity Details
                                </div>
                                <div className="body scrollable">
                                    {
                                        activityData ? activityData.map((item, index) => { return <ActivityList data={item} key={index}></ActivityList> }) : ''
                                    }
                                    {
                                        activityData.length == 0 ?
                                            <div className="row no-gutters">
                                                <div className="ls-none col-auto py-1 m-auto align-items-center ">
                                                    <p className="no-data-icon-container"><WarningIcon style={{ fontSize: 32 }} className='warning-color no-items-found'></WarningIcon>No activity found</p>
                                                </div></div> : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmModal data={confirmationObj} onConfirmAction={doAction} onCancel={closePopup}></ConfirmModal>
            <ErrorModal data={errModel} onConfirmAction={() => setErrorModal({ show: false, error: {} })} ></ErrorModal>
            <SuccessModal data={successModel} onConfirmAction={() => setSuccessModal({ show: false, success: {} })}></SuccessModal>
        </div>
    )
}

export default StaffView
