import Api from './Interceptor';
import LocalStorageService from './LocalstorageService';

const localStorage = LocalStorageService.getService();

const ClientService = (function () {
    const ajax = function (param) {
        return Api(param)
    }

    const generateParams = function (param) {
        if (!param) return "";
        let params = "";

        Object.keys(param).forEach(key => {
            params = `${params}${(params ? '&' : "?")}${key}=${param[key]}`;
        })
        return params;
    }

    return {
        getRole: function () {
            let requestObj = { method: 'GET', url: 'own/role' }
            return ajax(requestObj);
        },
        isLoggedIn: function () {
            return localStorage.getAccessToken() ? true : false;
        },
        addJob: function (data) {
            let requestObj = { method: 'POST', url: '/requirement/post', data: data };
            return ajax(requestObj);
        },
        getJobList: function (search, offset, page, sortby, jobType) {
            const requestObj = {
                method: "GET",
                url: "/requirement/post",
            };
            let paramSting = "";
            if (search) {
                paramSting = paramSting + `search=${search}`
            }
            if (offset) {
                paramSting = (paramSting ? paramSting + '&' : paramSting) + `limit=${offset}`
            }
            if (page) {
                paramSting = (paramSting ? paramSting + '&' : paramSting) + `page=${page}`
            }
            if (sortby) {
                paramSting = (paramSting ? paramSting + '&' : paramSting) + `sort=${sortby.type}&sortby=${sortby.name}`
            }
            if (jobType) {
                paramSting = (paramSting ? paramSting + '&' : paramSting) + 'jobType=hot'
            }

            if (paramSting) {
                requestObj.url = requestObj.url + "?" + paramSting;
            }
            return ajax(requestObj);
        },
        getJobDetails: function (id) {
            let requestObj = { method: 'GET', url: `/requirement/post/${id}` };
            return ajax(requestObj);
        },
        updateJob: function (id, data) {
            let requestObj = { method: 'PUT', url: `/requirement/post/${id}`, data: data };
            return ajax(requestObj);
        },
        updateJobStatus: function (action, data) {
            let requestObj = { method: 'PUT', url: `/requirement/post/update/bulk?action=${action}`, data: data }
            return ajax(requestObj);
        },
        getCategoryByClient: function (clientId, param, url) {
            const requestObj = { method: 'GET', url: `/own/category${clientId ? `?clientId=${clientId}&items=all&search=active` : '?items=all&search=active'}` };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        getRoles: function (id, param, url) {
            const requestObj = { method: 'GET', url: `/own/category/role/${id}${param ? `?search=active,${param}?&items=all` : '?items=all&search=active'}` };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        getClientList: function (param, url) {
            const requestObj = { method: 'GET', url: `/client/detail${param ? `?search=active,${param}` : '?search=active'}` };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        getCourse: function (id, param, url) {
            const requestObj = { method: 'GET', url: `/own/course/${id}?items=all${param ? `&search=active,${param}` : '&search=active'}` };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        getQualification: function (param, url) {
            const requestObj = { method: 'GET', url: `/own/qualification?items=all${param ? `&search=active,${param}` : '&search=active'}` };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        getSpecialization: function (id, param) {
            const requestObj = { method: 'GET', url: `/own/specialization/${id}?items=all${param ? `&search=active,${param}` : '&search=active'}` };
            return ajax(requestObj);
        },
        getAllStateList: function () {
            let requestObj = { method: 'GET', url: `/own/state?items=all&search=active` };
            return ajax(requestObj);
        },
        getCitiesList: function (stateId) {
            let requestObj = { method: 'GET', url: `own/state/city/${stateId}?items=all&search=active` };
            return ajax(requestObj);
        },
        addCandidate: function (data) {
            let requestObj = { method: 'POST', url: '/candidate/user', data: data };
            return ajax(requestObj);
        },
        getCandidateList: function (id, param, filter, url) {
            // let api_url = `/candidate/user?jobPost=${id}${param ?`&search=${param}`:''}`
            // if(filter !== 'bot'){
            let api_url = `/requirement/post/candidate?jobPost=${id}${param ? `&search=${param}` : ''}${filter ? `&filterBy=${filter}` : ''}`
            // }
            const requestObj = { method: 'GET', url: api_url };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        getCandidateDetails: function (id, type) {
            let api_url = `/candidate/user/${id}`
            if (type !== 'bot') {
                api_url = `/requirement/post/candidate/${id}`
            }
            let requestObj = { method: 'GET', url: api_url };
            return ajax(requestObj);
        },
        updateCandidate: function (id, data) {
            let requestObj = { method: 'PUT', url: `/candidate/user/${id}`, data: data };
            return ajax(requestObj);
        },
        updateCandidateStatus: function (action, data) {
            let requestObj = { method: 'PUT', url: `/candidate/update/bulk?action=${action}`, data: data }
            return ajax(requestObj);
        },
        // Qualification Request
        addQualificationDetails: function (id, data) {
            let requestObj = { method: 'POST', url: `/candidate/study?user=${id}`, data: data }
            return ajax(requestObj);
        },
        getQualificationDetails: function (id) {
            let requestObj = { method: 'GET', url: `/candidate/study/${id}` };
            return ajax(requestObj)
        },
        updateQualificationDetails: function (id, data) {
            let requestObj = { method: 'PUT', url: `/candidate/study/${id}`, data: data }
            return ajax(requestObj);
        },

        // carrer request
        addCarrerDetails: function (id, data) {
            let requestObj = { method: 'POST', url: `/candidate/career?user=${id}`, data: data }
            return ajax(requestObj);
        },
        getCarrerDetails: function (id, data) {
            let requestObj = { method: 'GET', url: `/candidate/career/${id}`, data: data }
            return ajax(requestObj);
        },
        updateCarrerDetails: function (id, data) {
            let requestObj = { method: 'PUT', url: `/candidate/career/${id}`, data: data }
            return ajax(requestObj);
        },
        //Address Request
        updatePersonalDetails: function (id, data) {
            let requestObj = { method: 'PUT', url: `/candidate/user/${id}`, data: data }
            return ajax(requestObj);
        },
        addAddressDetails: function (id, data) {
            let requestObj = { method: 'POST', url: `/candidate/address?user=${id}`, data: data }
            return ajax(requestObj);
        },
        getAddressDetails: function (id) {
            let requestObj = { method: 'GET', url: `/candidate/address/${id}` };
            return ajax(requestObj);
        },
        updateAddressDetails: function (id, data) {
            let requestObj = { method: 'PUT', url: `/candidate/address/${id}`, data: data };
            return ajax(requestObj);
        },
        //additional info
        addAdditionalDetails: function (id, data) {
            let requestObj = { method: 'POST', url: `/candidate/additional?user=${id}`, data: data }
            return ajax(requestObj);
        },
        getadditionalDetails: function (id) {
            let requestObj = { method: 'GET', url: `/candidate/additional/${id}` };
            return ajax(requestObj);
        },
        updateAdditionalDetails: function (id, data) {
            let requestObj = { method: 'PUT', url: `/candidate/additional/${id}`, data: data }
            return ajax(requestObj);
        },
        getQualificationList: function (param, url) {
            const requestObj = { method: 'GET', url: `/own/qualification?items=all&search=active${param ? `,${param}` : ''}` };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        getCourseList: function (id, param, url) {
            const requestObj = { method: 'GET', url: `/own/course/${id}?items=all&search=active${param ? `,${param}` : ''}` };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        getSpecificationList: function (id, param) {
            const requestObj = { method: 'GET', url: `/own/specialization/${id}?items=all&search=active${param ? `,${param}` : ''}` };
            return ajax(requestObj);
        },
        getCategoryList: function (param, url) {
            let requestObj = { method: 'GET', url: `/own/category?items=all&search=active${param ? `,${param}` : ''}` };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        getRoleList: function (id, param, url) {
            let requestObj = { method: 'GET', url: `/own/category/role/${id}?items=all&search=active${param ? `,${param}` : ''}` };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        getStateList: function () {
            let requestObj = { method: 'GET', url: `/own/state?items=all&search=active` };
            return ajax(requestObj);
        },
        getCityList: function (stateId) {
            let requestObj = { method: 'GET', url: `own/state/city/${stateId}?items=all&search=active` };
            return ajax(requestObj);
        },
        upload: function (data) {
            let requestObj = { method: 'POST', url: `file/upload`, data: data, headers: { 'content-type': 'multipart/form-data' } };
            return ajax(requestObj);
        },
        getCityListByPagination: function (param, url) {
            let requestObj = { method: 'GET', url: `/own/state/city?search=active${param ? `,${param}` : ''}` };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        selectCandidate: function (data) {
            let requestObj = { method: 'POST', url: `/requirement/post/candidate`, data: data }
            return ajax(requestObj)
        },
        rejectCandidate: function (id) {
            let requestObj = { method: 'DELETE', url: `/requirement/post/candidate/${id}` }
            return ajax(requestObj)
        },
        applyJob: function (id) {
            let requestObj = { method: 'GET', url: `/requirement/post/apply?jobPost=${id}` };
            return ajax(requestObj);
        },
        filterJobs: function (filterVal) {
            const requestObj = { method: 'GET', url: `/requirement/post?filterBy=${filterVal}` };
            return ajax(requestObj);
        },
        getCustomCv: function (userId) {
            let requestObj = { method: 'GET', url: `/candidate/user/custom-cv/` + userId };
            return ajax(requestObj);
        },
    }
})()

export default ClientService;