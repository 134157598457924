import Api from "./Interceptor";
import LocalStorageService from "./LocalstorageService";

const localStorage = LocalStorageService.getService();

const CandidateService = (function () {
  const ajax = function (param) {
    return Api(param);
  };

  return {
    getRole: function () {
      let requestObj = { method: "GET", url: "own/role" };
      return ajax(requestObj);
    },
    isLoggedIn: function () {
      return localStorage.getAccessToken() ? true : false;
    },
    addCandidate: function (data) {
      let requestObj = { method: "POST", url: "/candidate/user", data: data };
      return ajax(requestObj);
    },
    getCandidateList: function (search, offset, page, sortby) {
      const requestObj = {
        method: "GET",
        url: '/candidate/user',
      };
      let paramSting = "";
      if (search) {
        paramSting = paramSting + `search=${search}`
      }
      if (offset) {
        paramSting = (paramSting ? paramSting + '&' : paramSting) + `limit=${offset}`
      }
      if (page) {
        paramSting = (paramSting ? paramSting + '&' : paramSting) + `page=${page}`
      }
      if (sortby) {
        paramSting = (paramSting ? paramSting + '&' : paramSting) + `sort=${sortby.type}&sortby=${sortby.name}`
      }

      if (paramSting) {
        requestObj.url = requestObj.url + "?" + paramSting;
      }
      return ajax(requestObj);
    },
    getCandidateDetails: function (id) {
      let requestObj = { method: "GET", url: `/candidate/user/${id}` };
      return ajax(requestObj);
    },
    updateCandidate: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `/candidate/user/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateCandidateStatus: function (action, data) {
      let requestObj = {
        method: "PUT",
        url: `/candidate/update/bulk?action=${action}`,
        data: data,
      };
      return ajax(requestObj);
    },
    // Qualification Request
    addQualificationDetails: function (id, data) {
      let requestObj = {
        method: "POST",
        url: `/candidate/study?user=${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getQualificationDetails: function (id) {
      let requestObj = { method: "GET", url: `/candidate/study/${id}` };
      return ajax(requestObj);
    },
    updateQualificationDetails: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `/candidate/study/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },

    // carrer request
    addCarrerDetails: function (id, data) {
      let requestObj = {
        method: "POST",
        url: `/candidate/career?user=${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getCarrerDetails: function (id, data) {
      let requestObj = {
        method: "GET",
        url: `/candidate/career/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateCarrerDetails: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `/candidate/career/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    //Address Request
    updatePersonalDetails: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `/candidate/user/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    addAddressDetails: function (id, data) {
      let requestObj = {
        method: "POST",
        url: `/candidate/address?user=${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getAddressDetails: function (id) {
      let requestObj = { method: "GET", url: `/candidate/address/${id}` };
      return ajax(requestObj);
    },
    updateAddressDetails: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `/candidate/address/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    //additional info
    addAdditionalDetails: function (id, data) {
      let requestObj = {
        method: "POST",
        url: `/candidate/additional?user=${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getadditionalDetails: function (id) {
      let requestObj = { method: "GET", url: `/candidate/additional/${id}` };
      return ajax(requestObj);
    },
    updateAdditionalDetails: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `/candidate/additional/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getQualificationList: function (param, url) {
      const requestObj = {
        method: "GET",
        url: `/own/qualification?items=all&search=active${param ? `,${param}` : ""
          }`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getCourseList: function (id, param, url) {
      const requestObj = {
        method: "GET",
        url: `/own/course/${id}?items=all&search=active${param ? `,${param}` : ""
          }`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getSpecificationList: function (id, param) {
      const requestObj = {
        method: "GET",
        url: `/own/specialization/${id}?items=all&search=active${param ? `,${param}` : ""
          }`,
      };
      return ajax(requestObj);
    },
    getCategoryList: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `/own/category?items=all&search=active${param ? `,${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getRoleList: function (id, param, url) {
      let requestObj = {
        method: "GET",
        url: `/own/category/role/${id}?items=all&search=active${param ? `,${param}` : ""
          }`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getStateList: function () {
      let requestObj = {
        method: "GET",
        url: `/own/state?items=all&search=active`,
      };
      return ajax(requestObj);
    },
    getCityList: function (stateId) {
      let requestObj = {
        method: "GET",
        url: `own/state/city/${stateId}?items=all&search=active`,
      };
      return ajax(requestObj);
    },
    upload: function (data) {
      let requestObj = {
        method: "POST",
        url: `file/upload`,
        data: data,
        headers: { "content-type": "multipart/form-data" },
      };
      return ajax(requestObj);
    },
    getCityListByPagination: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `/own/state/city?search=active${param ? `,${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getAllLocationListByPagination: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `/location/search?search=active${param ? `,${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    searchCandidatePublic: function (data) {
      let requestObj = {
        method: "POST",
        url: `webcontent/search?searchFor=candidate`,
        data: data,
      };
      return ajax(requestObj);
    },
    checkEmailExist: function (param) {
      let requestObj = {
        method: "GET",
        url: `/auth/validate/email?email=${param ? `${param}` : ""}`,
      };
      return ajax(requestObj);
    },
    checkContactNumberExist: function (param) {
      let requestObj = {
        method: "GET",
        url: `/auth/validate/contact?number=${param ? `${param}` : ""}`,
      };
      return ajax(requestObj);
    }
  };
})();

export default CandidateService;
