
const ValidationService = (function(){
    const errormsg = {
        requiedError : 'is required field.',
        minError : 'contains minimum ',
    }
    let _service;
    function _getService() {
        if(!_service) {
          _service = this;
          return _service
      }
      return _service
    }
    function isValidateEmail(value){
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(value)
    }

    function _checkValidField(name,value,fieldSchema) {
        const schema = fieldSchema[name];
        let errorObj = {valid : false, msg : ''};

        if(schema.required){
            !value && value.length === 0 ? errorObj.msg =  `${schema.dname} ${errormsg['requiedError']}`:'';
            if(errorObj.msg){
                return errorObj;
            }
        }
        if(schema.min){
            value.length < schema.min ? errorObj.msg =  `${schema.dname} ${errormsg['minError']} ${schema.min} characters.`:'';
            if(errorObj.msg){
                return errorObj;
            }
        }

        if(schema.type){
            switch(schema.type){
                case 'email' : !isValidateEmail(value) ? errorObj.msg =  `Invalid ${schema.dname} format.`:'';
                               break;
                case 'string' : break;               
                default      : console.log('invalid type',schema.type);
            }
            if(errorObj.msg){
                return errorObj;
            }
        }
        errorObj.valid = true;
        return errorObj;
    }
    function _checkValidForm (stateObj){
        let error = false;
        Object.keys(stateObj).forEach(function(key) {
            if(key !== 'formError' && key !== 'validationSchema'){
                const validationObj = _checkValidField(key,stateObj[key],stateObj.validationSchema);
                error = error || !validationObj.valid;
            }
        });
        return error;
    }

    

   return {
      getService : _getService,
      checkValidField : _checkValidField,
      checkValidForm : _checkValidForm
    }
   })();
   export default ValidationService;