import React,{useState,useCallback} from 'react';
import UnChecked from '@material-ui/icons/CheckBoxOutlineBlank';
import Checked from '@material-ui/icons/CheckBox';
import Intermediate from '@material-ui/icons/IndeterminateCheckBox';

const Checkbox = (props) => {
    const {label,value, classes = '',hasError, change  } = props;
    //const [checked, setChecked] = useState(value);

    const updateCheckBox= useCallback((e) => {
        e.preventDefault();
        //props.value === 'intermediate' ? props.value = false : props.value =! props.value;
        change( props.value === 'intermediate' ? false : ! props.value,props,e);
    })

    return (
        <div className={`checkbox-container ${classes}`}>
            <label className="checkbox-label">
                <input className="custom-checkbox-input d-none" type="checkbox" defaultChecked={props.value}/>
                <span className="checkbox-custom recangular" onClick={updateCheckBox}>
                    {props.value === true ? <Checked className="icon"></Checked> : ''}
                    {props.value === false ? <UnChecked className="icon"></UnChecked> : ''}
                    {props.value === 'intermediate' ? <Intermediate className="icon"></Intermediate> : ''} 
                </span>
            </label>
            { label ? <div className="input-title">{label}</div> :''}
        </div>
    );    
} 

export default Checkbox;

  