import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ClientService from '../../service/ClientService';
import Loader from '../../component/Loader';
import LocalService from '../../service/LocalstorageService';
import Moment from 'react-moment';
import { useDebouncedCallback } from 'use-debounce';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";



export const Subscription = (props) => {
    const { id, userType } = useParams();
    const [loading, setLoading] = useState(true);
    const [subscription, setSubscription] = useState([]);
    const [defaultSearch, setDefaultSearch] = useState("");
    const [perPageItem, setPerPageItem] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getSubsciptionList();
    }, []);


    const getSubsciptionList = async () => {
        try {
            const params = { search: defaultSearch, offset: perPageItem, page: currentPage };
            const response = await ClientService.getSubsciptionList(id, params);
            if (response.status === 200) {
                setSubscription(response.data.result.data);
                setLoading(false);
            }
        } catch (e) {
            setSubscription([]);
            setLoading(false);
        }
    }
    const debounce = useDebouncedCallback((value) => {
        setDefaultSearch(value)
        getSubsciptionList();
    }, 1000)

    const getTableData = (search, offset, page, sortby) => {
        setTableLoader(true);
        tableConfig = { ...tableConfig, search, "perPageItem": offset, page, sortby }
        getProfileList(search, offset, page, sortby, id);
    }

    if (loading) return <Loader />;

    return (
        <div className="p-0" id="subscription-container">
            <div className="container-fluid">

                <div className="row py-2 no-gutters filter-row align-items-center">
                    {/* <div className="col-auto px-2">
                        <h5 className="m-0">Subscriptions List</h5>
                    </div> */}
                </div>
                <div className="row p-2 no-gutters filter-row align-items-center">
                    <div className="col d-flex align-items-center">
                        <input type="text" className='table-search-input' placeholder='search' defaultValue={defaultSearch} onChange={(e) => debounce(e.currentTarget.value)}></input>
                    </div>
                </div>
                <div className="list-view p-0" id="subscription-list">
                    <div className='list-container'>
                        {subscription.length > 0 && subscription.map((list) => {
                            return (
                                <Accordion className="mb-2 cs-accordion-v1" key={list.id}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="acc-card" aria-controls="panel1a-content" id={`acc-${list.id}`}>
                                        <div className="acc-card-header">
                                            <div className="row no-gutters">
                                                <div className="col-4 align-items-center">
                                                    <div className="small-label">Subscription Name</div>
                                                    <div className="main-title text">{list.plan && list.plan.name}</div>
                                                </div>
                                                <div className="col-2 align-items-center">
                                                    <div className="small-label">Posting Balance</div>
                                                    <div className=" text" title={`Balace Left = ${list.jobPostBalance.left} out of ${list.jobPostBalance.total}`}>{`${list.jobPostBalance.left}/${list.jobPostBalance.total}`}</div>
                                                </div>
                                                <div className="col-2 align-items-center">
                                                    <div className="small-label">Current Status</div>
                                                    <div className=" text">{list.isValid ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Expired</span>}</div>
                                                </div>
                                                <div className="col-2 align-items-center">
                                                    <div className="small-label">Valid From</div>
                                                    <div className=" text">{list.activateOn ? <Moment format="DD MMM, YYYY">{list.activateOn}</Moment> : "-"}</div>
                                                </div>
                                                <div className="col-2 align-items-center">
                                                    <div className="small-label">Valid Upto</div>
                                                    <div className="text">{list.expireOn ? <Moment format="DD MMM, YYYY">{list.expireOn}</Moment> : "-"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="acc-card-body">
                                            <div className="row no-gutters">
                                                <div className="col-6 align-items-center">
                                                    <div className="small-label">Category Name</div>
                                                </div>
                                                <div className="col-3 align-items-center">
                                                    <div className="small-label">Balance Left</div>
                                                </div>
                                                <div className="col-3 align-items-center">
                                                    <div className="small-label">Total Balance</div>
                                                </div>
                                            </div>
                                            {list.categories.map((cat) => {
                                                return (
                                                    <div className="p-2 row no-gutters">
                                                        <div className="col-6 align-items-center">
                                                            <div className="main-title text">{cat.category && cat.category.name}</div>
                                                        </div>
                                                        <div className="col-3 align-items-center">
                                                            <div className=" text">{cat.profileAccessBalanceLeft}</div>
                                                        </div>
                                                        <div className="col-3 align-items-center">
                                                            <div className=" text">{cat.profileAccessBalance}</div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}

                        {subscription.length === 0 && !loading && <div className='table-loader-wrapper  position-absolute w-100 h-100 top-0 left-0'>
                            <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                                <div className='font-weight-bold loader-text'>No Data Available!!</div>
                            </div>
                        </div>}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscription;

