import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Validation from "../../../service/ValidationService";
import AuthService from "../../../service/AuthService";
import LocalStorageService from "../../../service/LocalstorageService";
import { SuccessToaster } from "../../../component/modal/SuccessModal";
import { ErrorToaster } from "../../../component/modal/ErrorModal";
import LoginImage from "../../../../assets/imgs/candidate-new-signup-WB.png";
import MobileBackgroundImage from "../../../../assets/imgs/login3.png";
import fogotPassword from "../../../../assets/imgs/working_women.png";
import whiteLogo from "../../../../assets/imgs/logo-white-transparent.png";
import DefaultLogo from "../../../../assets/imgs/new_logo-transparent.png";
import HomeIcon from '@material-ui/icons/Home';

const LocalService = LocalStorageService.getService();
const ValidationService = Validation.getService();

const validationSchema = {
  email: { dname: "email", type: "email", required: true },
};

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [successObj, setSuccessObj] = useState({ show: false, success: {} });
  const [emailError, setEmailError] = useState("");
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });
  const history = useHistory();

  useEffect(() => {
    const isUserLogin = LocalService.getAccessToken();
    isUserLogin
      ? history.push(`/${LocalService.getRoutePrefix()}/dashboard`)
      : "";
  }, []);

  const onBlur = (e) => {
    e.persist();
    e.preventDefault();
    const { name, value } = e.target;
    const validation = ValidationService.checkValidField(
      name,
      value,
      validationSchema
    );

    if (name === "email") {
      setEmail(validation.valid ? value : "");
      setEmailError(validation.msg || "");
    }
  };
  const closeToast = (e) => {
    e.preventDefault();
    setErrorObj({ show: false, msg: "" });
  };

  const onSubmit = async (e) => {
    e.persist();
    e.preventDefault();
    const error = emailError ? true : false;
    if (!error) {
      try {
        const response = await AuthService.forgotPassword(email);
        if (response.status === 200) {
          setSuccessObj({ show: true, msg: response.data.result });
        }
      } catch (err) {
        console.log("Error", err);
        setErrorObj({ show: true, msg: err.data.message });
      }
    }
  };

  return (
    <div className="container-fluid p-0 m-0">
      <Link to="/home" className="goToHome l-10 d-lg-block d-xl-block d-md-none d-sm-none d-xs-none">
        <HomeIcon />
        <span>Home</span>
      </Link>
      <div className="form-wrapper login-page-container">
        <div className="row vh-100 w-100 m-0">
          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 login-form-container">
            <div className="container vh-100 w-100">
              <div className="row align-items-center vh-100 w-100 login-form-section">
                <Link to="/home" className="goToHome d-md-block d-sm-block d-xs-block d-lg-none d-xl-none">
                  <HomeIcon />
                  <span>Home</span>
                </Link>
                <div className="col-md-2 col-lg-2 col-xl-2 d-sm-none d-xs-none d-md-block d-lg-block d-xl-block"></div>
                <div className="col-md-8 col-lg-8 col-xl-8 col-sm-12 col-xs-12">
                  <div className="logo-section text-center d-flex justify-content-center mb-4">
                    <img src={whiteLogo} height={"80px"} className="d-md-flex d-sm-flex d-xs-flex d-lg-none d-xl-none"/>
                    <img src={DefaultLogo} height={"100px"} className="d-md-none d-sm-none d-xs-none d-lg-block d-xl-block"/>
                  </div>
                  <div className="form-header text-center">
                    <span className="text-msg ">Forgot Password</span>
                  </div>
                  <form>
                    <div className="row"></div>
                    <div className="form-group">
                      <label className="label-sm">User name/Email ID</label>
                      <input
                        type="text"
                        className="form-control "
                        id="username"
                        placeholder="name@email.com"
                        name="email"
                        defaultValue={email}
                        onBlur={onBlur}
                      />
                      {emailError ? (
                        <small className="form-text  error-text">
                          {emailError}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="action-btn text-center">
                      <button
                        type="button"
                        className="btn  btn-primary"
                        onClick={onSubmit}
                      >
                        Confirm
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2 d-sm-none d-xs-none d-md-block d-lg-block d-xl-block"></div>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 p-0 parent_div">
            <img src={MobileBackgroundImage} className="login-bg-img d-lg-none d-xl-none d-md-block d-sm-block d-xs-block"></img>
            <img src={LoginImage} className="login-bg-img d-lg-block d-xl-block d-md-none d-sm-none d-xs-none"></img>
            <img src={fogotPassword} className="forgot-bg-img d-lg-block d-xl-block d-md-none d-sm-none d-xs-none"></img>
          </div>
        </div>
        <SuccessToaster
          data={successObj}
          onClose={() => setSuccessObj({ show: false, success: {} })}
        ></SuccessToaster>
        <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
      </div>
    </div>
  );
};

export default ForgotPassword;
