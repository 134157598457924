import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import NewsService from "../../service/NewsService";
import Loader from "../../component/Loader";
import ConfirmModal from "../../component/modal/ConfirmModal";
import ErrorModal from "../../component/modal/ErrorModal";
import SuccessModal from "../../component/modal/SuccessModal";
import Moment from "react-moment";
import ReactHtmlParser from "react-html-parser";
import LanguageIcon from "@material-ui/icons/Language";

const NewsView = () => {
  const { id, userType, type } = useParams();
  const [newsData, setNewsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [confirmationObj, setConfirmationObj] = useState({
    show: false,
    msg: "",
  });
  const [errModel, setErrorModal] = useState({ show: false, error: {} });
  const [successModel, setSuccessModal] = useState({
    show: false,
    success: {},
  });
  const [currentAction, setCurrentAction] = useState("");
  const history = useHistory();

  useEffect(() => {
    getNewsData();
  }, []);

  const getNewsData = async () => {
    try {
      if (type === "public") {
        const response = await NewsService.getNewsListPublic(id);
        if (response.status === 200) {
          setNewsData(response.data.result);
          setLoading(false);
        }
      } else {
        const response = await NewsService.getNewsDetails(id);
        if (response.status === 200) {
          setNewsData(response.data.result);
          setLoading(false);
        }
      }
    } catch (err) {
      setLoading(false);
      type === "public"
        ? history.push("/home")
        : history.push(`/${userType}/news`);
    }
  };
  const closePopup = () => {
    setConfirmationObj({ show: false, msg: "" });
    setCurrentAction("");
  };

  const performAction = (e) => {
    //console.log("action",e);
    e.persist();
    e.preventDefault();
    const action = e.target.dataset.value;
    setCurrentAction({ action: action, data: [id] });
    setConfirmationObj({ show: true, msg: `You want to ${action} this news?` });
  };

  const doAction = async () => {
    setLoading(true);
    setConfirmationObj({ show: false, msg: "" });

    try {
      const res = await NewsService.updateNewsStatus(currentAction.action, {
        newsIds: currentAction.data,
      });
      setCurrentAction({});
      setLoading(false);
      //console.log("success res", res);
      if (res.status === 200) {
        setSuccessModal({ show: true, success: { msg: res.data.result } });
        getNewsData();
      }
    } catch (err) {
      //console.log(err);
      setLoading(false);
      if (err && err.data) {
        setErrorModal({ show: true, error: err.data });
      }
    }
  };

  if (loading) return <Loader />;

  return (
    <div id="jobs-view" className="news-view">
      {type != "public" && (
        <div className="row cs-breadcrumb no-gutters w-100">
          <ol className="breadcrumb w-100">
            <li className="breadcrumb-item active">
              <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/news`}>News</Link>
            </li>
            <li className="breadcrumb-item">{newsData.heading}</li>
          </ol>
        </div>
      )}
      <div className="content-wrapper">
        <div className="content-container ">
          <div className="row client-details-container no-gutters">
            <div className="col-12">
              <div className="name-section row no-gutters">
                <p className="col-auto name m-0">{newsData.heading}</p>
                {type != "public" && (
                  <>
                    <p className="col-auto mb-0 ml-4 mt-2">
                      {newsData.isArchived ? (
                        <span className="badge badge-danger">Archived</span>
                      ) : newsData.isActive ? (
                        <span className="badge badge-success">Active</span>
                      ) : (
                        <span className="badge badge-warning">Inactive</span>
                      )}
                    </p>

                    <div className="col d-flex flex-row-reverse staff-detail-action ">
                      <Link
                        target={"_blank"} rel={"noopener noreferrer"}
                        to={`/${userType}/news/edit/${id}`}
                        className="btn btn-primary"
                      >
                        Edit News
                      </Link>
                      {newsData.isActive ? (
                        <button
                          type="button"
                          className="btn btn-warning"
                          data-value="deactivate"
                          onClick={performAction}
                        >
                          Deactivate News
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-warning"
                          data-value="activate"
                          onClick={performAction}
                        >
                          Activate News
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-value="delete"
                        onClick={performAction}
                      >
                        Delete Permanently
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="detail-section row no-gutters">
                <div className="col-3 col-xs-12 item-detail description-container py-2">
                  <span className="post_on">Created on: &nbsp;</span>
                  <span className="badge badge-primary">
                    <Moment
                      date={newsData.createdOn}
                      format={"MMM DD, YYYY"}
                    ></Moment>
                  </span>
                </div>
                <div className="col-3 col-xs-12 item-detail description-container py-2">
                  <span className="post_on">Created By: &nbsp;</span>
                  <span title={newsData.addedBy ? newsData.addedBy.email : ''} style={{ fontWeight: 'bold', color: 'blue' }}>
                    {newsData.addedBy ? newsData.addedBy.firstName + ' ' + newsData.addedBy.lastName : '-'}
                  </span>
                </div>
                <div className="col-3 col-xs-12 item-detail description-container py-2">
                  <span className="post_on">Post Live On: &nbsp;</span>
                  <span className="badge badge-warning">
                    <Moment
                      date={newsData.postOn}
                      format={"MMM DD, YYYY"}
                    ></Moment>
                  </span>
                </div>
                <div className="col-3 col-xs-12 item-detail description-container py-2">
                  <span className="expire_on">Auto Expire on: &nbsp;</span>
                  <span className="badge badge-danger">
                    <Moment
                      date={newsData.expireOn}
                      format={"MMM DD, YYYY"}
                    ></Moment>
                  </span>
                </div>
                <div className="col-12 item-detail description-container py-2"></div>
                <div className="col-12 item-detail description-container py-2">
                  <p className="fw-500 w-100 ">News Body Content -</p>
                  <div className="description w-100">
                    {ReactHtmlParser(newsData.content)}
                  </div>
                </div>

                <div className="col-12 text-truncate py-3 news-link">
                  <LanguageIcon className="browser-icon" />
                  <a className="ml-2" href={newsData.source} target="_blank">
                    {newsData.source}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        data={confirmationObj}
        onConfirmAction={doAction}
        onCancel={closePopup}
      ></ConfirmModal>
      <ErrorModal
        data={errModel}
        onConfirmAction={() => setErrorModal({ show: false, error: {} })}
      ></ErrorModal>
      <SuccessModal
        data={successModel}
        onConfirmAction={() => setSuccessModal({ show: false, success: {} })}
      ></SuccessModal>
    </div>
  );
};

export default NewsView;
