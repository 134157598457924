import React, { useEffect, useState } from "react";
import UseForm from "../../service/FormService";
import CandidateService from "../../service/CandidateService";
import { Link, useHistory, useParams } from "react-router-dom";
import ErrorPopup from "../../component/modal/ErrorModal";
import $ from "jquery";
import DatePicker from "react-datepicker";
import Loader from "../../component/Loader";
import moment from "moment";
import PersonalStep from "../Auth/Candidate/WizardSteps/personal";
import QualificationStep from "../Auth/Candidate/WizardSteps/qualification";
import LocationStep from "../Auth/Candidate/WizardSteps/location";
import ProfessionalStep from "../Auth/Candidate/WizardSteps/professional";
import BaseWizard from "../../component/Wizard";

const stepList = [
  { displayName: "", name: 'personal', type: PersonalStep },
  { displayName: "", name: 'professional', type: ProfessionalStep },
  { displayName: "", name: 'qualification', type: QualificationStep },
  { displayName: "", name: 'location', type: LocationStep },
]

const CandidateCreate = () => {
  const { userType } = useParams();
  const [userData,setUserData] = useState({})
  const [loading, setLoading] = useState(true);
  const [errModel, setErrorModal] = useState({ show: false, err: {} });
  const history = useHistory();

  const wizardConfig = {
    name: 'Add Candidate',
    stepCount: 4,
    steps: stepList,
    isProgression: true,
    nextLabel: "Next",
    submitLabel: "Sign Up",
    showCancel: true
  }

  useEffect(() => {
    setLoading(false);
  }, []);



  const onSubmitForm = (state) => {
    setLoading(true);
    const requestPayload = {};
    for (let key in state) {
      if (key === "dateOfBirth") {
        requestPayload[key] = getDateFormat(new Date(state[key].value));
      } else {
        requestPayload[key] = state[key].value;
      }
    }
    CandidateService.addCandidate(requestPayload).then(
      (res) => {
        ////console.log('add staff ',res);
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          history.push(`/${userType}/candidate`);
        }
      },
      (err) => {
        setLoading(false);
        if (err && err.data) {
          setErrorModal({ show: true, error: err.data });
        }
      }
    );
  };


  if (loading) return <Loader />;
  return (
    <div className="content-wrapper" id="candidate-add-container">
      <div className="content-container">
          <div className="row no-gutters">
                <div className="col-12" id="candiate-add-wizard-container">
                    <BaseWizard config={wizardConfig} data={userData}></BaseWizard>
                </div>
          </div>
      </div>
      <ErrorPopup
        data={errModel}
        onConfirmAction={() => setErrorModal({ show: false, error: {} })}
      ></ErrorPopup>
    </div>
  );
};

export default CandidateCreate;
