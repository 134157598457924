import React, { useState, useEffect } from 'react';
import ActionListIcon from '@material-ui/icons/MoreVert';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import Checkbox from '../../component/Checkbox';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import LocalService from '../../service/LocalstorageService';
import TableComponent from '../../common/tableComponent';


export const ClientNewListView = (props) => {
    const { userType } = useParams();
    const [rowSelected, setRowSelected] = useState(false);
    const permissions = LocalService.getPermision().filter(perm => perm.name === 'Client Management');
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const columns = [
        {
            label: "Name", value: "name", sortable: true,
            render: (value, index, list) => {
                return (
                    <div className='d-flex'>
                        {list.logo && list.logo.href && <img className="image-30" src={list.logo.href}></img>}
                        <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/client/view/${list.id}`} >{list.name} </Link>
                    </div>
                )
            }

        },
        { label: "Location", value: "location", sortable: false },
        {
            label: "Current Plan", value: "current_plan", sortable: false,
            render: (value, index, list) => {
                return (
                    list.subscription && list.subscription.plan ? list.subscription.plan.name : ' NA '
                )
            }
        },
        {
            label: "Plan Expiry", value: "plan_expiry", sortable: false,
            render: (value, index, list) => {
                return (
                    list.subscription && list.subscription.plan ? <Moment date={list.subscription.plan.expireOn} format={"MMM YYYY"}></Moment> : ' NA '
                )
            }
        },
        {
            label: "Status", value: "status", sortable: false,
            render: (value, index, list) => {
                return (
                    list.isArchived ? <span className="badge badge-danger">Archived</span> : list.isActive ? <span className="badge badge-success">Active</span> : <span className="badge badge-warning">Inactive</span>
                )
            }
        },
        {
            label: "Action", value: "action", sortable: false,
            render: (value, index, list) => {
                return (
                    getTableActionList(list)
                )
            }
        }
    ];

    useEffect(() => {
        if (props.rowSelected != 'intermediate') {
            setSelectedRow(props.rowSelected);
        }
    }, [props.rowSelected]);

    const getTableActionList = (data) => {
        return (
            <div className="btn-group action">
                <ActionListIcon className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                <ul className="dropdown-menu">
                    {checkAccess('read') ? <li className="dropdown-item"><Link target={"_blank"} to={`/${userType}/client/view/${data.id}`}>View </Link></li> : null}
                    {checkAccess('update') ? <li className="dropdown-item"><Link target={"_blank"} to={`/${userType}/client/edit/${data.id}`}>Edit </Link></li> : null}
                    {checkAccess('update') ?
                        <>
                            {
                                data.isArchived ?
                                    <li className="dropdown-item" data-value="unarchive" onClick={doAction}>Unarchive</li>
                                    : <li className="dropdown-item" data-value="archive" onClick={doAction}>Archive</li>
                            }
                            {
                                data.isArchived ? "" :
                                    data.isActive ?
                                        <li className="dropdown-item" data-value="deactivate" onClick={doAction}>Deactivate</li> :
                                        <li className="dropdown-item" data-value="activate" onClick={doAction}>Activate</li>

                            }
                        </>
                        : null
                    }

                </ul>
            </div>
        )
    }

    // const onCheckBoxChange = (param) => {
    //     setSelectedRow(param);
    //     props.onRowChange(props.list.id, rowSelected);
    // }
    const doAction = (e) => {
        // console.log("doaction called",e);
        props.callAction(e, [props.list.id]);
    }

    const checkAccess = (type) => {
        return permissions[0][type]
    }

    const onCheckBoxChange = (param) => {
        const selectedAllItem = [];
        if (param === true) {
            clientList.data.forEach((item) => {
                selectedAllItem.push(item.id);
            })
        }
        setSelectedRow(selectedAllItem);
        setSelectAll(param);
    }

    return (
        // <div className="table-list-container" id="client-list-wrapper">
        //     <div className="cs-header-section">
        //         <div className="row pb-2 no-gutters">
        //             <div className="checbox-content col-auto m-auto">
        //                 <Checkbox value={selectAll} classes={'staff-list-checkbox'} change={onCheckBoxChange} label={'Select all'}></Checkbox>
        //             </div>
        //             <div className="col row no-gutters">
        //                 <div className="col"></div>
        //                 <div className="col-2">
        //                     {props.paginationObj.totalCount && props.list ? <span className="pagination">{props.list.length} of {props.paginationObj.totalCount} Users </span> : ''}
        //                 </div>
        //                 <div className="col-2 text-right p-0">
        //                     <div className="dropdown" style={{ visibility: selectAll === false ? 'hidden' : 'visible' }}>
        //                         <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="action-staff-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        //                             Actions
        //                         </button>
        //                         <div className="dropdown-menu" aria-labelledby="action-staff-dropdown" >
        //                             <p className="dropdown-item m-0" data-value="archive">Archive</p>
        //                             <p className="dropdown-item m-0" data-value="unarchive">Unarchive</p>
        //                             <p className="dropdown-item m-0" data-value="activate">Activate</p>
        //                             <p className="dropdown-item m-0" data-value="deactivate">Deactivate</p>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>

        // </div>
        <TableComponent columns={columns} data={props.lists}></TableComponent>
    )

}

export const ClientListView = (props) => {
    const { userType } = useParams();
    //console.log("client list",props);
    const [rowSelected, setSelectedRow] = useState(false);
    const permissions = LocalService.getPermision().filter(perm => perm.name === 'Client Management')

    useEffect(() => {
        if (props.rowSelected != 'intermediate') {
            setSelectedRow(props.rowSelected);
        }
    }, [props.rowSelected]);

    const onCheckBoxChange = (param) => {
        //console.log(' checkbox param',param);
        setSelectedRow(param);
        props.onRowChange(props.list.id, rowSelected);
    }
    const doAction = (e) => {
        //console.log("doing action");
        props.callAction(e, [props.list.id]);
    }

    const checkAccess = (type) => {
        return permissions[0][type]
    }

    return (
        <li className="list">
            <div className="container-fluid">
                <div className="row no-gutters">
                    <div className="checbox-content col-auto m-auto">
                        <Checkbox value={rowSelected} classes={'staff-list-checkbox'} change={onCheckBoxChange}></Checkbox>
                    </div>
                    <div className={props.list.isArchived ? 'list-content col row no-gutters archived' : props.list.isActive ? 'list-content col row no-gutters active' : 'list-content col row no-gutters inactive'}>
                        {
                            props.list.logo ?
                                <img className="user-image col-auto " src={props.list.logo.href}></img> :
                                <div className="user-no-image col-auto"><div className="margin-auto">No Image</div></div>

                        }

                        <div className="user-name col text-truncate">
                            <Link target={"_blank"} to={`/${userType}/client/view/${props.list.id}`} >{props.list.name} </Link>
                        </div>
                        <div className="row-text col-2 text-truncate"> {props.list.location}</div>

                        <div className="col-2 row-text text-truncate">
                            <span className="text-value ">{props.list.subscription && props.list.subscription.plan ? props.list.subscription.plan.name : ' NA '}</span>
                        </div>
                        <div className="row-text col-2 text-truncate">
                            <span className="text-value ">
                                {props.list.subscription && props.list.subscription.plan ? <Moment date={props.list.subscription.plan.expireOn} format={"MMM YYYY"}></Moment> : ' NA '}  </span>
                        </div>
                        <div className="col-1 m-y-auto d-block text-truncate">
                            {props.list.isArchived ? <span className="badge badge-danger">Archived</span> : props.list.isActive ? <span className="badge badge-success">Active</span> : <span className="badge badge-warning">Inactive</span>}
                        </div>
                        <div className="action col-1">
                            <div className="btn-group action">
                                <ActionListIcon className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                <ul className="dropdown-menu">
                                    {checkAccess('read') ? <li className="dropdown-item"><Link target={"_blank"} to={`/${userType}/client/view/${props.list.id}`}>View </Link></li> : null}
                                    {checkAccess('update') ? <li className="dropdown-item"><Link target={"_blank"} to={`/${userType}/client/edit/${props.list.id}`}>Edit </Link></li> : null}
                                    {checkAccess('update') ?
                                        <>
                                            {
                                                props.list.isArchived ?
                                                    <li className="dropdown-item" data-value="unarchive" onClick={doAction}>Unarchive</li>
                                                    : <li className="dropdown-item" data-value="archive" onClick={doAction}>Archive</li>
                                            }
                                            {
                                                props.list.isArchived ? "" :
                                                    props.list.isActive ?
                                                        <li className="dropdown-item" data-value="deactivate" onClick={doAction}>Deactivate</li> :
                                                        <li className="dropdown-item" data-value="activate" onClick={doAction}>Activate</li>

                                            }
                                        </>
                                        : null
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

export const ClientCardView = (props) => {
    const { userType } = useParams();

    const doAction = (e) => {
        props.callAction(e, [props.list.id]);
    }
    return (
        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12 section p-2">
            <div className="client-card">
                <div className="img-container text-center">
                    <img className="user-image img-fluid" src={props.list.logo.href}></img>
                </div>
                <div className="row no-gutters">
                    <div className="user-name col-12 text-truncate">{props.list.name}</div>
                    <div className="user-text col-12 row no-gutters px-2"> <span className="col-6 text-truncate">Email</span> <span className="col-6 text-truncate">{props.list.email}</span></div>
                    <div className="user-text col-12 row no-gutters px-2"> <span className="col-6 text-truncate">Location</span> <span className="col-6 text-truncate">{props.list.location}</span></div>
                    <div className='user-text col-12 row no-gutters px-2'><span className="col-6 text-truncate"> Name</span> <span className="col-6 text-truncate">{props.list.subscription && props.list.subscription.plan ? props.list.subscription.plan.name : ' NA '}</span></div>
                    <div className="user-text col-12 row no-gutters px-2"><span className="col-6 text-truncate"> Expiry</span> <span className="col-6 text-truncate">{props.list.subscription ? <Moment date={props.list.subscription.expireOn} format={"MMM YYYY"}></Moment> : ' NA '}</span></div>
                </div>
                <div className="action w-100 py-2 px-2">
                    <div className=" row no-gutters align-items-center">
                        <div className="col">
                            {props.list.isArchived ? <span className="badge badge-danger">Archived</span> : props.list.isAccessBlocked ? <span className="badge badge-warning mt-2">Inactive</span> : <span className="badge badge-success mt-2">Active</span>}
                        </div>
                        <div className="col-auto">
                            <Link to={`/${userType}/client/view/${props.list.id}`} type="button" className="icon-btn">
                                <VisibilityOutlinedIcon />
                            </Link>
                        </div>
                        <div className="col-auto">
                            <Link to={`/${userType}/client/edit/${props.list.id}`} type="button" className="icon-btn">
                                <EditOutlinedIcon />
                            </Link>
                        </div>
                        <div className="col-auto">
                            <button className="icon-btn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <MoreVertOutlinedIcon />
                            </button>
                            <ul className="dropdown-menu">
                                {
                                    props.list.isArchived ?
                                        <li className="dropdown-item" data-value="unarchive" onClick={doAction}>Unarchive</li>
                                        : <li className="dropdown-item" data-value="archive" onClick={doAction}>Archive</li>
                                }
                                {
                                    props.list.isArchived ? "" :
                                        props.list.isAccessBlocked ?
                                            <li className="dropdown-item" data-value="activate" onClick={doAction}>Activate</li> :
                                            <li className="dropdown-item" data-value="deactivate" onClick={doAction}>Deactivate</li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const List = (props) => {
    return (
        <div></div>

    );
}

export default List;