import React from "react";
import NewsView from "../news/View";
import Navbar from "./Navbar";

const PublicNewsDetails = () => {
  return (
    <div id="public-news">
      <Navbar></Navbar>
      <NewsView></NewsView>
    </div>
  );
};

export default PublicNewsDetails;
