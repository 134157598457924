import React, { useEffect, useState } from "react";
import Autocomplete from "react-select";
import UseForm from "../../service/FormService";
import JobService from "../../service/JobService";
import { Link, useHistory, useParams } from "react-router-dom";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import $ from "jquery";
import Loader from "../../component/Loader";
import moment from "moment";

import { ErrorToaster } from "../../component/modal/ErrorModal";

const emailRegx =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const initialSchema = {
  title: { value: "", error: "" },
  description: { value: "", error: "" },
  status: { value: "", error: "" },
  postOn: { value: new Date(), error: "" },
  isHotJob: { value: false, error: "" },
  state: { value: "", error: "" },
  city: { value: "", error: "" },
  town: { value: "", error: "" },
  isCtcNegotiable: { value: false, error: "" },
  minimumCtc: { value: "", error: "" },
  maximumCtc: { value: "", error: "" },
  minimumExperience: { value: "", error: "" },
  maximumExperience: { value: "", error: "" },
};

const validationStateSchema = {
  title: {
    required: true,
  },
  description: {
    required: true,
  },
  status: {
    required: true,
  },
  postOn: {
    required: true,
  },
  isHotJob: {
    required: false,
  },
  state: {
    required: true,
  },
  city: {
    required: true,
  },
  isCtcNegotiable: {
    required: false,
  },
  town: {
    required: false,
  },
  minimumCtc: {
    required: false,
  },
  maximumCtc: {
    required: false,
  },
  minimumExperience: {
    required: true,
  },
  maximumExperience: {
    required: true,
  },
};
const selectSchema = {
  client: {},
  category: {},
  role: {},
  qualification: {},
  cources: [],
  specializations: [],
};

const StaffCreate = () => {
  const { id, userType } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [stateSchema, setStateSchema] = useState(initialSchema);
  const [clientData, setClientData] = useState({});
  const [clientList, setClientList] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(true);
  const [selectData, setSelectData] = useState(selectSchema);
  const [categoryList, setCategoryList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [qualificationList, setQualificationList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [speciList, setSpeciList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });

  useEffect(() => {
    getInitData();
    getStateList();
    setLoading(false);
  }, []);

  // useEffect(() => {
  //     //console.log("speciList",speciList);
  // },[speciList]);

  // useEffect(() => {
  //     //console.log("selectData changes",selectData);
  // },[selectData]);

  const getInitData = async () => {
    try {
      let JobsDataRes = await JobService.getJobDetails(id);
      if (JobsDataRes) {
        let res = JobsDataRes.data;
        updateSchema(res);
        const selects = $.extend(true, {}, selectData);

        getCompnayList();
        selects.client = res.result.client;

        getCategory(res.result.client.id);
        selects.category = res.result.category;

        getRoles(res.result.category.id);
        selects.role = res.result.role;

        getQualification();
        selects.qualification = res.result.qualifications[0];

        getCourses(res.result.qualifications[0].id);
        selects.cources = res.result.courses;

        getSpecialization(res.result.courses);
        selects.specializations = res.result.specializations;
        setSelectData(selects);
      }
    } catch (e) {
      setLoading(false);
      //console.log(e)
      setErrorObj({ show: true, msg: err.data.message });
    }
  };
  const updateSchema = (res) => {
    let schema = $.extend(true, {}, stateSchema);
    Object.keys(schema).forEach((key) => {
      if (res.result.hasOwnProperty(key)) {
        if (key === "postOn") {
          schema[key].value = new Date(res.result[key]);
        } else {
          schema[key].value = res.result[key] === null ? "" : res.result[key];
        }
      }
    });
    schema.state.value = res.result.jobLocation.state.id;
    schema.city.value = res.result.jobLocation.city.id;
    schema.town.value = res.result.jobLocation.town || "";
    getCityList(schema.state.value);
    setStateSchema(schema);
    //console.log("stateSchema",schema);
  };

  const getCityList = async (stateid) => {
    try {
      const response = await JobService.getCityList(stateid);
      setCityList(response.data.result);
    } catch (e) {
      //console.log("error",e);
      setErrorObj({ show: true, msg: err.data.message });
    }
  };

  const getStateList = async () => {
    try {
      const response = await JobService.getAllStateList();
      setStateList(response.data.result);
    } catch (e) {
      //console.log("error",e);
      setErrorObj({ show: true, msg: err.data.message });
    }
  };

  const getSpecialization = async (selectedItem) => {
    try {
      let courceId = "";
      if (Array.isArray(selectedItem)) {
        let arr = selectedItem.map((item) => {
          return item.id;
        });
        courceId = arr.join(",");
      } else {
        courceId = selectedItem.id;
      }
      const list = $.extend(true, [], speciList);
      const response = await JobService.getSpecialization(courceId);
      if (Array.isArray(selectedItem)) {
        let item = response.data.result.map((speci) => {
          return { label: speci.name, options: speci.specialiations };
        });
        setSpeciList([...list, ...item]);
      } else {
        const item = {
          label: selectedItem.name,
          options: response.data.result,
        };
        setSpeciList([...list, item]);
      }
    } catch (e) {
      //console.log("error",e);
      setErrorObj({ show: true, msg: err.data.message });
    }
  };

  const getCourses = async (param, url) => {
    try {
      const response = await JobService.getCourse(param, url);
      setCourseList(response.data.result);
    } catch (e) {
      setErrorObj({ show: true, msg: err.data.message });
    }
  };

  const getQualification = async (param, url) => {
    try {
      const response = await JobService.getQualification(param, url);
      setQualificationList(response.data.result);
    } catch (e) {
      setErrorObj({ show: true, msg: err.data.message });
    }
  };
  const getCategory = async (clientId, param, url) => {
    try {
      const response = await JobService.getCategoryByClient(
        clientId,
        param,
        url
      );
      setCategoryList(response.data.result.data);
    } catch (e) {
      setErrorObj({ show: true, msg: err.data.message });
    }
  };

  const getRoles = async (id, param, url) => {
    try {
      const response = await JobService.getRoles(id, param, url);
      setRoleList(response.data.result.data);
    } catch (e) {
      setErrorObj({ show: true, msg: err.data.message });
    }
  };

  const getCompnayList = async (param, url) => {
    try {
      ////console.log("get company list called")
      setCompanyLoading(true);
      const response = await JobService.getClientList(param, url);
      if (url) {
        let tempDataArr = $.extend(true, [], clientList);
        tempDataArr = tempDataArr.concat(response.data.result.data);
        response.data.result.data = tempDataArr;
      }
      setClientData(response.data);
      setClientList($.extend(true, [], response.data.result.data));
      setTimeout(() => {
        setCompanyLoading(false);
      }, 100);
    } catch (e) {
      setCompanyLoading(false);
      //console.log("error in the list",e);
      setErrorObj({ show: true, msg: err.data.message });
    }
  };

  const paginateCompanyList = (e) => {
    if (clientData.detail.links && clientData.detail.links.next) {
      getCompnayList("", clientData.detail.links.next);
    }
  };
  const onClientInputChange = (param, actionType, list) => {
    if (actionType.action && actionType.action === "input-change") {
      setClientList([]);
      param ? getCompnayList(param) : getCompnayList();
    } else if (actionType.action === "menu-close" && !selectedClient.id)
      getCompnayList();
  };

  const clientChange = (client) => {
    //console.log("change client",client)
    const selects = $.extend(true, {}, selectData);
    selects.client = client && client.id ? client : {};
    if (client && client.id) getCategory(client.id);
    selects.category = {};
    selects.role = {};
    setSelectData(selects);
  };

  const categoryChanges = (category) => {
    const selects = $.extend(true, {}, selectData);
    selects.roles = {};
    if (category && category.id) {
      selects.category = category;
      getRoles(category.id);
    } else {
      selects.category = {};
    }
    setSelectData(selects);
  };

  const roleChanges = (role) => {
    const selects = $.extend(true, {}, selectData);
    selects.role = role && role.id ? role : {};
    setSelectData(selects);
  };

  const qualificationChanges = (qualification) => {
    const selects = $.extend(true, {}, selectData);
    selects.cources = [];
    selects.specializations = [];
    if (qualification && qualification.id) {
      selects.qualification = qualification;
      getCourses(qualification.id);
    } else {
      selects.qualification = {};
    }
    setSelectData(selects);
  };

  const courceChanges = (courses) => {
    //console.log("courses changes",courses)
    const selects = $.extend(true, {}, selectData);
    selects.specializations = [];
    updateSpecilaisationList(courses, selects.cources);
    selects.cources =
      courses && courses.length > 0 ? $.extend(true, [], courses) : [];
    setSelectData(selects);
  };

  const speciChanges = (speci) => {
    //console.log("speci changes",speci);
    const selects = $.extend(true, {}, selectData);
    selects.specializations =
      speci && speci.length > 0 ? $.extend(true, [], speci) : [];
    setSelectData(selects);
  };

  const updateSpecilaisationList = (newList, previousList) => {
    const newListLength = newList ? newList.length : 0;
    const previousListLen = previousList ? previousList.length : 0;
    let action = newListLength > previousListLen ? "add" : "remove";
    newList = newList || [];
    previousList = previousList || [];
    let item = "";
    if (action === "remove") {
      previousList.forEach((prevItem) => {
        let matched = false;
        newList.forEach((newItem) => {
          if (newItem.id === prevItem.id) {
            matched = true;
          }
        });
        if (!matched) {
          item = prevItem;
        }
      });
    } else {
      item = newList[newList.length - 1];
    }
    action === "add" ? getSpecialization(item) : updateSpeciList(item);
  };

  const updateSpeciList = (item) => {
    let specList = $.extend(true, [], speciList);
    speciList.forEach((spec, index) => {
      if (spec.label === item.name) {
        specList.splice(index, 1);
      }
    });
    setSpeciList(specList);
  };

  const onStateChange = (e) => {
    e.persist();
    handleOnChange(e);
    setCityList([]);
    getCityList(e.target.value);
  };

  const formatGroupLabel = (data) => {
    return (
      <div>
        <span>{data.name}</span>
      </div>
    );
  };

  const isNegotiableChange = (e) => {
    e.persist();
    const event = {
      target: {
        name: "isCtcNegotiable",
        value: !document.getElementById("isCtcNegotiable").checked,
      },
    };
    handleOnChange(event);
    handleOnChange({ target: { name: "minimumCtc", value: "" } });
    handleOnChange({ target: { name: "maximumCtc", value: "" } });
  };

  const isHotJobChange = (e) => {
    e.persist();
    const event = {
      target: {
        name: "isHotJob",
        value: !document.getElementById("isHotJob").checked,
      },
    };
    handleOnChange(event);
  };
  const postedOnChange = (value) => {
    //console.log("date change",value);
    const event = { target: { name: "postOn", value: value } };
    handleOnChange(event);
  };
  const descriptionOnChange = (data) => {
    //console.log( 'on description change', data  );
    const event = { target: { name: "description", value: data } };
    handleOnChange(event);
  };

  const customFilter = (option, inputValue) => {
        if(option.data.name && inputValue){
            return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        }
        return true;
    };

  const isValidForm = () => {
    let isValid = false;
    const selects = $.extend(true, {}, selectData);
    isValid =
      selects.client.id &&
      selects.category.id &&
      selects.role.id &&
      selects.qualification.id &&
      selects.cources.length > 0 &&
      selects.specializations.length > 0
        ? true
        : false;
    isValid =
      isValid &&
      (!state.isCtcNegotiable.value
        ? state.minimumCtc.value && state.maximumCtc.value
          ? true
          : false
        : true);
    return !isValid;
  };
  const getDateFormat = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };
  const onSubmitForm = (state) => {
    //console.log("selectData",selectData);
    const requestPayload = {
      clientId: selectData.client.id,
      categoryId: selectData.category.id,
      roleId: selectData.role.id,
      qualifications: [selectData.qualification.id],
      courses:
        selectData.cources.length > 0
          ? selectData.cources.map((course) => course.id)
          : "",
      specializations:
        selectData.specializations.length > 0
          ? selectData.specializations.map((speci) => speci.id)
          : "",
      jobLocation: {},
    };
    const data = state;
    Object.keys(data).forEach((key) => {
      if (key === "city" || key === "state" || key === "town") {
        requestPayload.jobLocation[key] =
          state[key].value === null ? "" : state[key].value;
      } else if (key === "postOn") {
        requestPayload[key] = getDateFormat(new Date(state[key].value));
      } else {
        requestPayload[key] = state[key].value === null ? "" : state[key].value;
      }
    });
    //console.log("requestPayload",requestPayload);

    JobService.updateJob(id, requestPayload).then(
      (res) => {
        //console.log('add staff ',res);
        if (res.status === 200 || res.status === 201) {
          history.push(`/${userType}/jobs`);
        }
      },
      (err) => {
        //console.log('add staff err',err);
        setErrorObj({ show: true, msg: err.data.message });
      }
    );
  };

  const closeToast = (e) => {
    e.preventDefault();
    setErrorObj({ show: false, msg: "" });
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    stateSchema,
    validationStateSchema,
    onSubmitForm
  );

  if (loading) return <Loader />;
  return (
    <div className="content-wrapper" id="add-staff">
      <div className="content-container">
        <div className="form-wrapper">
          <div className="row h-100 w-100">
            <div className="container h-100 w-100">
              <div className="row align-items-center h-100 w-100">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-header text-center my-4">
                    <span className="text-msg">Update Job</span>
                  </div>
                  <form onSubmit={handleOnSubmit} id="edit-job">
                    <div className="form-group mb-4">
                      <div className="card">
                        <div className="card-header">Job Details</div>
                        <div className="card-body">
                          <div className="row">
                            <div className="form-group col-12 m-0">
                              <label className="label-sm">
                                Title <sup className="required">*</sup>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                id="title"
                                onChange={handleOnChange}
                                value={state.title.value}
                              />
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.title.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.title.error}
                              </small>
                            </div>
                            <div className="form-group col-12">
                              <label className="label-sm">
                                Description <sup className="required">*</sup>
                              </label>
                              <CKEditor
                                config={{
                                  toolbar: [
                                    "heading",
                                    "|",
                                    "bold",
                                    "italic",
                                    "blockQuote",
                                    "link",
                                    "numberedList",
                                    "bulletedList",
                                    "insertTable",
                                    "|",
                                    "undo",
                                    "redo",
                                  ],
                                }}
                                oninit={(editor) => {
                                  editor.data.set(state.description.value);
                                }}
                                data={state.description.value}
                                editor={ClassicEditor}
                                onBlur={(event, editor) => {
                                  const data = editor.getData();
                                  handleOnChange({
                                    target: {
                                      name: "description",
                                      value: data,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">status</label>
                              <select
                                className="form-control"
                                name="status"
                                id="status"
                                onChange={handleOnChange}
                                value={state.status.value}
                              >
                                <option value="">Select Status</option>
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="INACTIVE">INACTIVE</option>
                              </select>
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.status.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.status.error}
                              </small>
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm w-100">Post On</label>

                              <DatePicker
                                className="form-control w-100"
                                id="postOn"
                                name="postOn"
                                minDate={new Date()}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onChange={postedOnChange}
                                selected={state.postOn.value}
                                autoComplete="off"
                              ></DatePicker>
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.town.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.town.error}
                              </small>
                            </div>

                            <div className="form-group col-6">
                              <label className="label-sm mr-4 mb-3">
                                Mark as Hot Job
                              </label>
                              <div className="switch" onClick={isHotJobChange}>
                                {state.isHotJob.value ? (
                                  <input
                                    type="checkbox"
                                    name="isHotJob"
                                    id="isHotJob"
                                    value={state.isHotJob.value || true}
                                    checked={state.isHotJob.value || true}
                                    onChange={() => {}}
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    name="isHotJob"
                                    id="isHotJob"
                                    value={state.isHotJob.value || false}
                                    checked={state.isHotJob.value || false}
                                    onChange={() => {}}
                                  />
                                )}
                                <span className="slider round"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <div className="card">
                        <div className="card-header">Company Detail</div>
                        <div className="card-body">
                          <label className="label-sm">
                            Company Name <sup className="required">*</sup>
                          </label>
                          <Autocomplete
                            name="company-select"
                            placeholder="Select Company"
                            defaultOptions
                            isClearable={true}
                            options={clientList}
                            isDisabled={true}
                            filterOption={customFilter}
                            isLoading={companyLoading}
                            //defaultValue= {{label:selectedClient.name, value:selectedClient.id ,logo :selectedClient.logo}}
                            value={
                              selectData.client && selectData.client.id
                                ? {
                                    name: selectData.client.name,
                                    id: selectData.client.id,
                                    logo: selectData.client.logo,
                                  }
                                : null
                            }
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  {option.logo && option.logo.href ? (
                                    <img
                                      className="logo col-auto"
                                      src={
                                        option.logo ? `${option.logo.href}` : ""
                                      }
                                    />
                                  ) : (
                                    <div className="no-logo col-auto">
                                      <div className="margin-auto ">
                                        {option.name && option.name.charAt(0)}
                                      </div>
                                    </div>
                                  )}
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={clientChange}
                            onMenuScrollToBottom={paginateCompanyList}
                            onInputChange={onClientInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <div className="card">
                        <div className="card-header">Category Detail</div>
                        <div className="card-body">
                          <div className="row">
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Category <sup className="required">*</sup>
                              </label>
                              <Autocomplete
                                name="category-select"
                                placeholder="Select Category"
                                defaultOptions
                                isClearable={true}
                                filterOption={customFilter}
                                options={categoryList}
                                //defaultValue = {{label:selectedCategory.name,value:selectedCategory.id  || null}}
                                value={
                                  selectData.category && selectData.category.id
                                    ? {
                                        name: selectData.category.name,
                                        id: selectData.category.id,
                                      }
                                    : null
                                }
                                getOptionLabel={(option) => {
                                  return (
                                    <div className="row autocomplete-item no-gutters">
                                      <div className="text col p-1 text-truncate">
                                        <div>{option.name}</div>
                                      </div>
                                    </div>
                                  );
                                }}
                                getOptionValue={(option) => {
                                  return option.id;
                                }}
                                onChange={categoryChanges}
                              />
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Roles <sup className="required">*</sup>
                              </label>
                              <Autocomplete
                                name="roles-select"
                                placeholder="Select Roles"
                                defaultOptions
                                isClearable={true}
                                filterOption={customFilter}
                                options={roleList}
                                isDisabled={
                                  selectData.category && selectData.category.id
                                    ? false
                                    : true
                                }
                                getOptionLabel={(option) => {
                                  return (
                                    <div className="row autocomplete-item no-gutters">
                                      <div className="text col p-1 text-truncate">
                                        <div>{option.name}</div>
                                      </div>
                                    </div>
                                  );
                                }}
                                //defaultValue = {{label:selectedRole.name,value:selectedRole.id  || null}}
                                value={
                                  selectData.role && selectData.role.id
                                    ? {
                                        name: selectData.role.name,
                                        id: selectData.role.id,
                                      }
                                    : null
                                }
                                getOptionValue={(option) => {
                                  return option.id;
                                }}
                                onChange={roleChanges}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <div className="card">
                        <div className="card-header">Qualification Detail</div>
                        <div className="card-body">
                          <div className="row">
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Qualification <sup className="required">*</sup>
                              </label>
                              <Autocomplete
                                name="qualification-select"
                                placeholder="Select Qualification"
                                defaultOptions
                                isClearable={true}
                                filterOption={customFilter}
                                options={qualificationList}
                                //defaultValue = {{label:selectedQual.name,value:selectedQual.id  || null}}
                                value={
                                  selectData.qualification &&
                                  selectData.qualification.id
                                    ? {
                                        name: selectData.qualification.name,
                                        id: selectData.qualification.id,
                                      }
                                    : null
                                }
                                getOptionLabel={(option) => {
                                  return (
                                    <div className="row autocomplete-item no-gutters">
                                      <div className="text col p-1 text-truncate">
                                        <div>{option.name}</div>
                                      </div>
                                    </div>
                                  );
                                }}
                                getOptionValue={(option) => {
                                  return option.id;
                                }}
                                onChange={qualificationChanges}
                              />
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Course <sup className="required">*</sup>
                              </label>
                              <Autocomplete
                                name="course-select"
                                placeholder="Select Course"
                                defaultOptions
                                isMulti={true}
                                isClearable={true}
                                filterOption={customFilter}
                                options={courseList}
                                isDisabled={
                                  selectData.qualification &&
                                  selectData.qualification.id
                                    ? false
                                    : true
                                }
                                value={
                                  selectData.cources &&
                                  selectData.cources.length > 0
                                    ? selectData.cources.map((course) => {
                                        return {
                                          name: course.name,
                                          id: course.id,
                                        };
                                      })
                                    : null
                                }
                                getOptionLabel={(option) => {
                                  return (
                                    <div className="row autocomplete-item no-gutters">
                                      <div className="text col text-truncate">
                                        <div>{option.name}</div>
                                      </div>
                                    </div>
                                  );
                                }}
                                getOptionValue={(option) => {
                                  return option.id;
                                }}
                                onChange={courceChanges}
                              />
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Specialization <sup className="required">*</sup>
                              </label>
                              <Autocomplete
                                name="Speci-select"
                                placeholder="Select Specialization"
                                defaultOptions
                                isMulti={true}
                                isClearable={true}
                                filterOption={customFilter}
                                options={speciList}
                                isDisabled={
                                  selectData.cources &&
                                  selectData.cources.length > 0
                                    ? false
                                    : true
                                }
                                value={
                                  selectData.specializations &&
                                  selectData.specializations.length > 0
                                    ? selectData.specializations.map(
                                        (speci) => {
                                          return {
                                            name: speci.name,
                                            id: speci.id,
                                          };
                                        }
                                      )
                                    : []
                                }
                                getOptionLabel={(option) => {
                                  return (
                                    <div className="row autocomplete-item no-gutters">
                                      <div className="text col text-truncate">
                                        <div>{option.name}</div>
                                      </div>
                                    </div>
                                  );
                                }}
                                getOptionValue={(option) => {
                                  return option.id;
                                }}
                                onChange={speciChanges}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <div className="card">
                        <div className="card-header">Location Detail</div>
                        <div className="card-body">
                          <div className="row">
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                State <sup className="required">*</sup>
                              </label>
                              <select
                                className="form-control"
                                name="state"
                                id="state"
                                onChange={onStateChange}
                                value={state.state.value}
                              >
                                <option value="">Select State</option>
                                {stateList
                                  ? stateList.map((state, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={state.id}
                                          disabled={!state.isActive}
                                        >
                                          {state.name}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </select>
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.state.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.state.error}
                              </small>
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                City <sup className="required">*</sup>
                              </label>
                              <select
                                className="form-control"
                                name="city"
                                id="city"
                                onChange={handleOnChange}
                                value={state.city.value}
                                disabled={state.state.value ? false : true}
                              >
                                <option value="">Select City</option>
                                {cityList
                                  ? cityList.map((city, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={city.id}
                                          disabled={!city.isActive}
                                        >
                                          {city.name}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </select>
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.city.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.city.error}
                              </small>
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">Town </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Town"
                                id="town"
                                name="town"
                                value={state.town.value}
                                onChange={handleOnChange}
                              />
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.town.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.town.error}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-4">
                      <div className="card">
                        <div className="card-header">Experience & Salary</div>
                        <div className="card-body">
                          <div className="row">
                            <div className="form-group col-12 m-0">
                              <label className="label-sm mb-3 mr-4">
                                Salary is negotiable
                              </label>
                              <div
                                className="switch"
                                onClick={isNegotiableChange}
                              >
                                {state.isCtcNegotiable.value ? (
                                  <input
                                    type="checkbox"
                                    name="isCtcNegotiable"
                                    id="isCtcNegotiable"
                                    value={state.isCtcNegotiable.value}
                                    checked={state.isCtcNegotiable.value}
                                    onChange={() => {}}
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    name="isCtcNegotiable"
                                    id="isCtcNegotiable"
                                    value={state.isCtcNegotiable.value}
                                    checked={state.isCtcNegotiable.value}
                                    onChange={() => {}}
                                  />
                                )}
                                <span className="slider round"></span>
                              </div>
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Minimum Salary{" "}
                                {state.isCtcNegotiable.value ? (
                                  ""
                                ) : (
                                  <sup className="required">*</sup>
                                )}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="minimumCtc"
                                id="minimumCtc"
                                onChange={handleOnChange}
                                value={state.minimumCtc.value}
                                disabled={state.isCtcNegotiable.value}
                              />
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.minimumCtc.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.minimumCtc.error}
                              </small>
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Maximum Salary
                                {state.isCtcNegotiable.value ? (
                                  ""
                                ) : (
                                  <sup className="required">*</sup>
                                )}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="maximumCtc"
                                id="maximumCtc"
                                onChange={handleOnChange}
                                value={state.maximumCtc.value}
                                disabled={state.isCtcNegotiable.value}
                              />
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.maximumCtc.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.maximumCtc.error}
                              </small>
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Minimum Experience{" "}
                                <sup className="required">*</sup>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="minimumExperience"
                                id="minimumExperience"
                                onChange={handleOnChange}
                                value={state.minimumExperience.value}
                              />
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.minimumExperience.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.minimumExperience.error}
                              </small>
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Maximum Experience
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="maximumExperience"
                                id="maximumExperience"
                                onChange={handleOnChange}
                                value={state.maximumExperience.value}
                              />
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.maximumExperience.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.maximumExperience.error}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="action-btn text-center my-4">
                      <Link
                        to={`/${userType}/jobs`}
                        type="button"
                        className="btn  btn-outline-secondary login-btn mr-3"
                      >
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn  btn-primary login-btn"
                        disabled={disable || isValidForm()}
                      >
                        Update Job
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
    </div>
  );
};

export default StaffCreate;
