import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import CandidateService from "../../../service/CandidateService";
import { useParams, Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import Moment from "react-moment";
import Autocomplete from "react-select";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import UseForm from "../../../service/FormService";
import $ from "jquery";
import ErrorModal from "../../../component/modal/ErrorModal";

const initialSchema = {
  qualificationId: { value: "", error: "" },
  courseId: { value: "", error: "" },
  specializationId: { value: "", error: "" },
  isCurrentlyPursuing: { value: false, error: "" },
  joinedOn: { value: new Date(), error: "" },
  passoutYear: { value: new Date(), error: "" },
  isHighestQualification: { value: false, error: "" },
  organizationName: {value: "", error: ""}
};

const validationStateSchema = {
  qualificationId: { required: true },
  courseId: { required: true },
  specializationId: { required: true },
  isCurrentlyPursuing: { required: false },
  joinedOn: { required: true },
  passoutYear: { required: false },
  isHighestQualification: { required: false },
  organizationName: { required: false }
};

const QualificationForm = (props) => {
  const { id, userType } = useParams();
  const [stateSchema, setStateSchema] = useState(initialSchema);
  const [selectedQual, setSelectedQual] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [specificationList, setSpecificationList] = useState([]);
  const [selectedSpecification, setSelectedSpecification] = useState({});
  const [highestQual, setHighestQual] = useState(false);
  const [currentlyPresuing, setCurrentlyPresuing] = useState(false);
  const [errorObj, setErrorObj] = useState({ show: false, error: {} });

  useEffect(() => {
    if (
      props.rowIndex === props.totalRow - 1 &&
      Object.keys(props.data).length === 0
    ) {
      setEditMode(true);
      props.restrictMoveToNext(false);
    }

    if (props.data && Object.keys(props.data).length > 0) {
      upDateInitialData();
    }
  }, []);

  const upDateInitialData = () => {
    const data = props.data;
    setSelectedQual({
      id: data.qualification.id,
      name: data.qualification.name,
    });

    setSelectedCourse({ id: data.course.id, name: data.course.name });
    getCourseList(data.qualification.id, data.course.name);

    getSpecificationList(data.course.id, data.specialization.name);
    setSelectedSpecification({
      id: data.specialization.id,
      name: data.specialization.name,
    });
    setHighestQual(data.isHighestQualification);
    setCurrentlyPresuing(data.isCurrentlyPursuing);

    let schema = $.extend(true, {}, stateSchema);
    schema.qualificationId.value = data.qualification.id;
    schema.specializationId.value = data.specialization.id;
    schema.courseId.value = data.course.id;
    schema.joinedOn.value = data.joinedOn ? data.joinedOn : null;
    schema.passoutYear.value = data.passoutYear ? data.passoutYear : null;
    schema.isCurrentlyPursuing.value = data.isCurrentlyPursuing;
    schema.isHighestQualification.value = data.isHighestQualification;
    schema.organizationName.value = data.organizationName ? data.organizationName : '';
    
    setStateSchema(schema);
  };

  const editData = () => {
    setEditMode(true);
    props.restrictMoveToNext(false);
  };

  const qualificationChanges = (data) => {
    setSelectedQual(data ? { name: data.name, id: data.id } : {});
    handleOnChange({
      target: { name: "qualificationId", value: data ? data.id : "" },
    });
    setSelectedCourse({});
    setSelectedSpecification({});

    if (data && data.id) getCourseList(data.id);
  };

  const getCourseList = async (qualificationId, param) => {
    try {
      const response = await CandidateService.getCourseList(
        qualificationId,
        param
      );
      setCourseList(response.data.result);
    } catch (err) {
      //console.log(err);
    }
  };
  const courseChanges = (data) => {
    setSelectedCourse(data ? { name: data.name, id: data.id } : {});
    handleOnChange({
      target: { name: "courseId", value: data ? data.id : "" },
    });
    setSelectedSpecification({});
    if (data && data.id) getSpecificationList(data.id);
  };

  const getSpecificationList = async (courseId) => {
    try {
      const response = await CandidateService.getSpecificationList(courseId);
      setSpecificationList(response.data.result);
    } catch (err) {
      //console.log(err);
    }
  };

  const specificationChanges = (data) => {
    setSelectedSpecification(data ? { name: data.name, id: data.id } : {});
    handleOnChange({
      target: { name: "specializationId", value: data ? data.id : "" },
    });
  };

  const customFilter = (option, inputValue) => {
        if(option.data.name && inputValue){
            return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        }
        return true;
    };

  const joinedOnChange = (data) => {
    handleOnChange({ target: { name: "joinedOn", value: data ? data : "" } });
  };

  const passoutYearChange = (data) => {
    handleOnChange({
      target: { name: "passoutYear", value: data ? data : "" },
    });
  };
  const onHieghestQualChange = (data) => {
    handleOnChange({
      target: { name: "isHighestQualification", value: data ? data : "" },
    });
    setHighestQual(data);
  };
  const onCurrentlyPursuingChange = (data) => {
    data && passoutYearChange();
    handleOnChange({
      target: { name: "isCurrentlyPursuing", value: data ? data : "" },
    });
    setCurrentlyPresuing(data);
  };

  const onSubmitForm = (state) => {
    //console.log("on submit is called",state);
    setEditMode(false);
    const requestData = {};
    requestData.qualification = selectedQual;
    requestData.course = selectedCourse;
    requestData.specialization = selectedSpecification;
    requestData.joinedOn = state.joinedOn.value;
    requestData.passoutYear = state.passoutYear.value;
    requestData.isCurrentlyPursuing = state.isCurrentlyPursuing.value;
    requestData.isHighestQualification = state.isHighestQualification.value;
    requestData.organizationName = state.organizationName.value;
    requestData.id = props.data.id || "";
    
    props.updateData(requestData, props.rowIndex);
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    stateSchema,
    validationStateSchema,
    onSubmitForm
  );

  return (
    <div className="qualification-form">
      <div className="form-wrapper">
        <div className="row h-100 w-100">
          <div className="container-fluid h-100 w-100 p-0">
            <div className="row h-100 w-100">
              <div className="col-10">
                <div className="card candidate-qualification-card">
                  <div className="card-header row no-gutters">
                    <span className="count col-auto">{props.rowIndex + 1}</span>
                    <span className="card-title col">Education Detail</span>

                    {editMode ? (
                      <div className="col-auto">
                        <button
                          className="icon-btn margin-auto btn-success"
                          disabled={disable ? true : false}
                          onClick={handleOnSubmit}
                          title="Click to Save"
                        >
                          <DoneIcon className="save-icon"></DoneIcon>
                        </button>
                      </div>
                    ) : (
                      <div className="col-auto">
                        <div
                          className="icon-btn margin-auto btn-primary"
                          onClick={editData}
                        >
                          <EditIcon className="edit-icon"></EditIcon>
                        </div>
                      </div>
                    )}
                    {props.totalRow > 1 ? (
                      <div className="col-auto">
                        <div
                          className="icon-btn margin-auto btn-danger"
                          onClick={() => {
                            props.onRemove(props.rowIndex);
                          }}
                        >
                          <DeleteForeverIcon className="delete-icon"></DeleteForeverIcon>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {editMode ? (
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group col-6 m-0" >
                            <label className="label-sm">Organization</label>
                            <input type="text" className="form-control " placeholder="College/University Name" id="organizationName" name="organizationName" value={state.organizationName.value} onChange={handleOnChange} />
                            <small className="form-text  error-text m-0" style={{ visibility: state.organizationName.error ? 'visible' : 'hidden' }}>{state.organizationName.error}</small>
                        </div>
                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            Qualification <sup className="required">*</sup>
                          </label>
                          <Autocomplete
                            name="qualification"
                            placeholder="Select Qualification"
                            defaultOptions
                            isClearable={true}
                            filterOption={customFilter}
                            options={props.qualificationList}
                            value={
                              selectedQual.id
                                ? {
                                  id: selectedQual.id,
                                  name: selectedQual.name,
                                }
                                : null
                            }
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={qualificationChanges}
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.qualificationId.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.qualificationId.error}
                          </small>
                        </div>

                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            Course <sup className="required">*</sup>
                          </label>
                          <Autocomplete
                            name="course"
                            placeholder="Select Course"
                            defaultOptions
                            isClearable={true}
                            isDisabled={selectedQual.id ? false : true}
                            filterOption={customFilter}
                            options={courseList}
                            value={
                              selectedCourse.id
                                ? {
                                  id: selectedCourse.id,
                                  name: selectedCourse.name,
                                }
                                : null
                            }
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={courseChanges}
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.courseId.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.courseId.error}
                          </small>
                        </div>
                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            Specialization <sup className="required">*</sup>
                          </label>
                          <Autocomplete
                            name="Specialization"
                            placeholder="Select Specialization"
                            defaultOptions
                            isClearable={true}
                            isDisabled={selectedCourse.id ? false : true}
                            filterOption={customFilter}
                            options={specificationList}
                            value={
                              selectedSpecification.id
                                ? {
                                  id: selectedSpecification.id,
                                  name: selectedSpecification.name,
                                }
                                : null
                            }
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={specificationChanges}
                          />
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.specializationId.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.specializationId.error}
                          </small>
                        </div>

                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            Joined Year <sup className="required">*</sup>
                          </label>
                          <DatePicker
                            className="form-control w-100"
                            selected={state.joinedOn.value}
                            onChange={joinedOnChange}
                            showYearPicker={true}
                            dateFormat="yyyy"
                            autoComplete="off"
                          ></DatePicker>
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.joinedOn.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.joinedOn.error}
                          </small>
                        </div>
                        <div className="form-group col-6 m-0">
                          <label className="label-sm">
                            Passout Year
                            {!currentlyPresuing ? (
                              <sup className="required">*</sup>
                            ) : (
                              ""
                            )}
                          </label>
                          <DatePicker
                            className="form-control w-100"
                            selected={state.passoutYear.value}
                            onChange={passoutYearChange}
                            showYearPicker={true}
                            dateFormat="yyyy"
                            disabled={currentlyPresuing}
                            autoComplete="off"
                          ></DatePicker>
                          <small
                            className="form-text  error-text m-0"
                            style={{
                              visibility: state.passoutYear.error
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {state.passoutYear.error}
                          </small>
                        </div>

                        <div className="form-group col-6 m-0">
                          <div className="margin-auto-top highest-qualification">
                            <label className="label-sm mr-4 mb-3">
                              is Highest Qualification
                            </label>
                            <div
                              className="switch"
                              onClick={() => onHieghestQualChange(!highestQual)}
                            >
                              {highestQual ? (
                                <input
                                  type="checkbox"
                                  name="isHotJobTrue"
                                  id="isHotJob"
                                  value={highestQual}
                                  checked={highestQual}
                                  onChange={() => { }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  name="isHotJobFalse"
                                  id="isHotJob"
                                  value={highestQual}
                                  checked={highestQual}
                                  onChange={() => { }}
                                />
                              )}
                              <span className="slider round"></span>
                            </div>
                            <small
                              className="form-text  error-text m-0"
                              style={{
                                visibility: state.isHighestQualification.error
                                  ? "visible"
                                  : "hidden",
                              }}
                            >
                              {state.isHighestQualification.error}
                            </small>
                          </div>
                        </div>
                        <div className="form-group col-6 m-0">
                          <div className="margin-auto-top highest-qualification">
                            <label className="label-sm mr-4 mb-3">
                              is Currently Pursuing
                            </label>
                            <div
                              className="switch"
                              onClick={() =>
                                onCurrentlyPursuingChange(!currentlyPresuing)
                              }
                            >
                              {currentlyPresuing ? (
                                <input
                                  type="checkbox"
                                  name="isHotJobTrue"
                                  id="isHotJob"
                                  value={currentlyPresuing}
                                  checked={currentlyPresuing}
                                  onChange={() => { }}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  name="isHotJobFalse"
                                  id="isHotJob"
                                  value={currentlyPresuing}
                                  checked={currentlyPresuing}
                                  onChange={() => { }}
                                />
                              )}
                              <span className="slider round"></span>
                            </div>
                            <small
                              className="form-text  error-text m-0"
                              style={{
                                visibility: state.isCurrentlyPursuing.error
                                  ? "visible"
                                  : "hidden",
                              }}
                            >
                              {state.isCurrentlyPursuing.error}
                            </small>
                          </div>
                        </div>

                      </div>
                    </div>
                  ) : (
                    <div className="card-body">
                      <div className="row">
                      <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Organization
                          </div>
                          <div className="col-7 text-wrap">
                            {state.organizationName.value ? state.organizationName.value : 'NA'}
                          </div>
                        </div>
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Qualification
                          </div>
                          <div className="col-7 text-wrap">
                            {selectedQual && selectedQual.name
                              ? selectedQual.name
                              : "NA"}
                          </div>
                        </div>
                        <div className="col-6  row p-2">
                          <div className="col-5 text-wrap fw-500">Course</div>
                          <div className="col-7 ttext-wrap">
                            {selectedCourse && selectedCourse.name
                              ? selectedCourse.name
                              : "NA"}
                          </div>
                        </div>
                        <div className="col-6 row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Specialization
                          </div>
                          <div className="col-7 text-wrap">
                            {selectedSpecification && selectedSpecification.name
                              ? selectedSpecification.name
                              : "NA"}
                          </div>
                        </div>
                        <div className="col-6 row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Joined Year
                          </div>
                          <div className="col-7 text-wrap">
                            <Moment
                              date={state.joinedOn.value || null}
                              format={"YYYY"}
                            ></Moment>
                          </div>
                        </div>
                        <div className="col-6 row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Passing Year
                          </div>
                          <div className="col-7 text-wrap">
                            <Moment
                              date={state.passoutYear.value || null}
                              format={"YYYY"}
                            ></Moment>
                          </div>
                        </div>
                        <div className="col-6 row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Highest Degree
                          </div>
                          <div className="col-7 text-wrap">
                            {state.isHighestQualification.value ? "YES" : "NO"}
                          </div>
                        </div>
                        <div className="col-6 row p-2">
                          <div className="col-5 text-wrap fw-500">
                            Currently Presuing
                          </div>
                          <div className="col-7 text-wrap">
                            {state.isCurrentlyPursuing.value ? "YES" : "NO"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-2"></div>
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        data={errorObj}
        onConfirmAction={() => setErrorObj({ show: false, error: {} })}
      ></ErrorModal>
    </div>
  );
};

const initialData = { valid: false, data: {} };

const initData = (props) => {
  //console.log("init Data",props.wizard.qualifications)
  let data = [];
  props.wizard.qualifications.forEach((item, index) => {
    let itemData = item;
    itemData.joinedOn = itemData.joinedOn
      ? new Date(itemData.joinedOn.toString())
      : new Date();
    itemData.passoutYear = itemData.passoutYear
      ? new Date(itemData.passoutYear.toString())
      : new Date();
    data.push({ valid: true, data: itemData });
  });
  return data;
};

const QualificationStep = forwardRef((props, ref) => {
  const { id } = useParams();
  const [stepData, setStepData] = useState(
    props.wizard.qualifications && props.wizard.qualifications.length > 0
      ? initData(props)
      : [$.extend(true, {}, initialData)]
  );
  const [qualificationList, setQualificationList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [errorObj, setErrorObj] = useState({ show: false, error: {} });
  const [isChanged, setChanged] = useState(false);

  useEffect(() => {
    //console.log("init stepData",stepData,props,ref);
    getQualificationList();
    setLoading(false);
    setChanged(false);
  }, []);

  useEffect(() => {
    // console.log("use effect stepData", stepData);
    validateStep();
  }, [stepData]);

  const getQualificationList = async () => {
    try {
      const response = await CandidateService.getQualificationList();
      setQualificationList(response.data.result);
    } catch (err) {
      setLoading(false);
    }
  };
  const AddRow = () => {
    const steps = $.extend(true, [], stepData);
    const newItem = $.extend(true, {}, initialData);
    steps.push(newItem);
    setStepData(steps);
  };
  const removeRow = (index) => {
    const data = $.extend(true, [], stepData);
    data.splice(index, 1);
    setStepData(data);
  };
  const updateData = (rowData, index) => {
    setChanged(true);
    const steps = $.extend(true, [], stepData);
    steps[index].data = $.extend(true, {}, rowData);
    steps[index].valid = true;
    setStepData(steps);
    // console.log("steps - ---", steps)
  };
  const validateStep = () => {
    let validCount = 0;
    stepData.forEach((item) => {
      if (item.valid) validCount++;
    });
    const isValid = validCount === stepData.length ? true : false;
    props.canProceed(isValid);
  };

  useImperativeHandle(ref, () => ({
    submit() {
      //console.log("Step1 submit")
      if (isChanged) {
        const requestPayload = [];
        let isUpdate = false;
        stepData.forEach((item) => {
          if (item.data.id) {
            isUpdate = true;
          }
          const rows = {};
          rows.organizationName = item.data.organizationName;
          rows.qualificationId = item.data.qualification.id;
          rows.specializationId = item.data.specialization.id;
          rows.courseId = item.data.course.id;
          rows.isCurrentlyPursuing = item.data.isCurrentlyPursuing || false;
          rows.isHighestQualification =
            item.data.isHighestQualification || false;
          rows.joinedOn = item.data.joinedOn.getFullYear();
          rows.passoutYear = item.data.passoutYear
            ? item.data.passoutYear.getFullYear()
            : null;
          if (isUpdate) {
            rows.id = item.data.id;
          }
          requestPayload.push(rows);
        });
        if (isUpdate) {
          CandidateService.updateQualificationDetails(id, requestPayload).then(
            (res) => {
              if (res.status === 201 || res.status === 200) {
                props.updateData(res.data.result, props.stepConfig);
              }
            },
            (err) => {
              setErrorObj({ show: true, error: err.data });
            }
          );
        } else {
          CandidateService.addQualificationDetails(id, requestPayload).then(
            (res) => {
              if (res.status === 201 || res.status === 200) {
                props.updateData(res.data.result, props.stepConfig);
              }
            },
            (err) => {
              setErrorObj({ show: true, error: err.data });
            }
          );
        }
      } else {
        props.updateData(props.wizard.qualifications, props.stepConfig);
      }
    },
  }));
  return (
    <div id="qualification-step" className="wizard-step-wrapper">
      <div className="step-container">
        <div className="row mb-4">
          <p className="step-info-text m-0 col">
            User need to enterd their educational details here and mandatory to
            entered highest digree.
          </p>
          <div className="col-auto">
            <button className="btn btn-primary btn-sm" onClick={AddRow}>
              + Add New Row
            </button>
          </div>
        </div>

        <div className="step-content">
          {stepData.map((item, index) => {
            return (
              <QualificationForm
                key={index}
                data={item.data}
                updateData={updateData}
                onRemove={removeRow}
                rowIndex={index}
                totalRow={stepData.length}
                qualificationList={qualificationList}
                restrictMoveToNext={props.canProceed}
              ></QualificationForm>
            );
          })}
        </div>
      </div>
      <ErrorModal
        data={errorObj}
        onConfirmAction={() => setErrorObj({ show: false, error: {} })}
      ></ErrorModal>
    </div>
  );
});

//const OptimizedStep = onlyUpdateForKeys(['wizard'])(QualificationStep)
export default QualificationStep;
