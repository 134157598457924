import React, { useState, useEffect } from "react";
import ManageService from "../../service/ManageService";
import $ from "jquery";
import { ErrorToaster } from "../../component/modal/ErrorModal";
import LocalStorageService from "../../service/LocalstorageService";
import HtmlParser from "react-html-parser";

const LocalService = LocalStorageService.getService();

const TermsConditionPopup = (props) => {
  const [visible, setVisible] = useState(false);
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });
  const [data , setData] = useState("");

  useEffect(() => {
    async function getTermsConditionData (){
        const response = await ManageService.getTermsConditionData();
        if (response.status === 200) {
          setData(response.data.result?.description || '');
        }
    };
    getTermsConditionData();
    setVisible(true);
    
  }, []);

  const hideModal = () => {
    setVisible(false);
  };

  const closeToast = (e) => {
    e.preventDefault();
    setErrorObj({ show: false, msg: "" });
  };

  

  const acceptTermCondition = () => {
      ManageService.aceptTermCondition().then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            LocalService.setHasViewedTnc(true);
            hideModal()
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    };


  return (
    <div
      className="overlay "
      id="terms-condition-modal"
      style={{ display: visible ? "block" : "none" }}
    >
      <div className="overlay-content">
        <div className={visible ? " cs-modal showModal" : "cs-modal hideModal"}>
          <div className="header">
            Terms & Conditions
          </div>
          <div className="body">
            <div className="state-container">
                <p>{HtmlParser(data)}</p>
            </div>
            <p className="warning-info">If you click on the accept button that means you accept the terms & conditions.</p>
          </div>
          <div className="footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={acceptTermCondition}
            >
              Accept
            </button>
          </div>
        </div>
      </div>
      <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
    </div>
  );
};

export default TermsConditionPopup;
