import React, { useState, useEffect } from 'react';
import ActionListIcon from '@material-ui/icons/MoreVert';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import Checkbox from '../../component/Checkbox';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import RoomIcon from '@material-ui/icons/Room';
import LocalService from '../../service/LocalstorageService';

export const JobListView = (props) => {
    const { userType } = useParams();
    //console.log("job list",props);
    const [rowSelected, setSelectedRow] = useState(false);
    const permissions = LocalService.getPermision()

    useEffect(() => {
        if (props.rowSelected != 'intermediate') {
            setSelectedRow(props.rowSelected);
        }
    }, [props.rowSelected]);

    const onCheckBoxChange = (param) => {
        //console.log(' checkbox param',param);
        setSelectedRow(param);
        props.onRowChange(props.list.id, rowSelected);
    }
    const doAction = (e) => {
        //console.log("doing action");
        props.callAction(e, [props.list.id]);
    }

    return (
        <div className="list">
            <div className="container-fluid">
                <div className="row no-gutters">
                    <div className={`list-content col-12 row no-gutters align-items-center ${props.list.isArchived ? 'archived' : props.list.isActive ? 'active' : 'inactive'}`}>

                        <div className="col-auto checkbox-wrapper">
                            <Checkbox value={rowSelected} classes={'staff-list-checkbox'} change={onCheckBoxChange}></Checkbox>
                        </div>
                        <div className="col-auto p-2">
                            <div className="user-no-image">
                                <div className="no-image-jobs">{props.list.title.charAt(0)}</div>
                            </div>

                        </div>

                        <div className="user-name col-2 text-truncate px-2">
                            {permissions.map(perm => perm.name).includes('Hot Jobs') ?
                                <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/jobs/view/${props.list.id}`}>{props.list.title} </Link>
                                : permissions.map(perm => perm.name).includes('Job Post Management') ?
                                    <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/job-post/view/${props.list.id}`}>{props.list.title} </Link>
                                    : null
                            }

                        </div>

                        <div className="row-text col-1 text-truncate px-2">
                            <RoomIcon className="list-location-icon" /> {props.list.jobLocation.city}
                        </div>
                        <div className="col-2 row-text text-truncate px-2">
                            <span className="text-value">{props.list.category}</span>
                        </div>

                        <div className="row-text col-2 text-truncate px-2">
                            <span className="text-value">{props.list.role}  </span>
                        </div>

                        <div className="row-text col-1 text-truncate px-2">
                            <Moment date={props.list.postOn} format={"DD MMM YYYY"}></Moment>
                        </div>

                        <div className="row-text col-2 text-truncate px-2">
                            <span className="text-value">{props.list.client.name}  </span>
                        </div>

                        <div className="col-1   text-truncate px-2">
                            {props.list.isArchived ? <span className="badge badge-danger">Archived</span> : props.list.isActive ? <span className="badge badge-success">Active</span> : <span className="badge badge-warning">Inactive</span>}
                        </div>
                        <div className="action col-auto text-center px-2">
                            <div className="btn-group action ">
                                <ActionListIcon className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                <ul className="dropdown-menu">
                                    <li className="dropdown-item"><Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/jobs/view/${props.list.id}`}>View </Link></li>
                                    <li className="dropdown-item"><Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/jobs/edit/${props.list.id}`}>Edit </Link></li>
                                    {
                                        props.list.isArchived ?
                                            <li className="dropdown-item" data-value="unarchive" data-id={props.list.id} onClick={doAction}>Unarchive</li>
                                            : <li className="dropdown-item" data-value="archive" data-id={props.list.id} onClick={doAction}>Archive</li>
                                    }
                                    {
                                        props.list.isArchived ? "" :
                                            props.list.isActive ?
                                                <li className="dropdown-item" data-value="deactivate" data-id={props.list.id} onClick={doAction}>Deactivate</li> :
                                                <li className="dropdown-item" data-value="activate" data-id={props.list.id} onClick={doAction}>Activate</li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    {
                        props.list.isHotJob ?
                            <div className="ribbon-container">
                                <span className="ribbon">hot job</span>
                            </div> : ''
                    }

                </div>
            </div>
        </div>
    )
}

export const StaffCardView = (props) => {
    const { userType } = useParams();

    const doAction = (e) => {
        //console.log("doing action");
        props.callAction(e, [props.list.id]);
    }
    return (
        <div className="col-4 section">
            <div className="client-card">
                <div className="row no-gutters">
                    <div className="col-3">
                        <div className="margin-auto">
                            {
                                props.list.logo ?
                                    <img className="user-image col-1" src={props.list.logo.href}></img> :
                                    <div className="user-no-image col-1"><div>No Image</div></div>

                            }
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="user-name">{props.list.name}</div>
                        <div className="row-text">
                            <span className="text-label">Location - </span>
                            <span className="text-value">{props.list.location}</span>
                        </div>
                        <div className="row-text">
                            <span className="text-label">Subscription Type - </span>
                            <span className="text-value">{props.list.subscription ? props.list.subscription.subscriptionTag : ' NA '}</span>
                        </div>
                        <div className="row-text">
                            <span className="text-label">Subscription Expiry - </span>
                            <span className="text-value">
                                {props.list.subscription ? <Moment date={props.list.subscription.expireOn} format={"MMM YYYY"}></Moment> : ' NA '}  </span>
                        </div>
                    </div>
                </div>

                <div className={props.list.isArchived ? 'row no-gutters archived' : props.list.isActive ? 'row no-gutters active' : 'row no-gutters inactive'}>
                    <div className="col-3"></div>
                    <div className="col-9">
                        <div className="action-section">
                            <a className="float-left mt-1">
                                {props.list.isArchived ? <span className="badge badge-danger">Archived</span> : props.list.isActive ? <span className="badge badge-success">Active</span> : <span className="badge badge-warning">Inactive</span>}
                            </a>
                            <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/client/view/${props.list.id}`}> <VisibilityOutlinedIcon /> </Link>
                            <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/client/edit/${props.list.id}`}><EditOutlinedIcon /> </Link>
                            <MoreVertOutlinedIcon className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                            <ul className="dropdown-menu">
                                {
                                    props.list.isArchived ?
                                        <li className="dropdown-item" data-value="unarchive" onClick={doAction}>Unarchive</li>
                                        : <li className="dropdown-item" data-value="archive" onClick={doAction}>Archive</li>
                                }
                                {
                                    props.list.isArchived ? "" :
                                        props.list.isActive ?
                                            <li className="dropdown-item" data-value="deactivate" onClick={doAction}>Deactivate</li> :
                                            <li className="dropdown-item" data-value="activate" onClick={doAction}>Activate</li>

                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const List = (props) => {
    return (
        <div></div>

    );
}

export default List;