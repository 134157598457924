import React, { useState, useEffect } from "react";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "../../component/Checkbox";
import { Link } from "react-router-dom";
import ActionListIcon from "@material-ui/icons/MoreVert";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import Loader from "../../component/Loader";
import ManageService from "../../service/ManageService";
import Moment from "react-moment";
import WarningIcon from "@material-ui/icons/Warning";
import AddLead from "../../component/modal/AddLead";
import ErrorModal from "../../component/modal/ErrorModal";
import SuccessModal from "../../component/modal/SuccessModal";
import ConfirmModal from "../../component/modal/ConfirmModal";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const LeadLists = (props) => {
  //console.log("prpos StaffRoleLists",props);
  const [rowSelected, setSelectedRow] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [listData, setListData] = useState({});

  useEffect(() => {
    if (props.rowSelected != "intermediate") {
      setSelectedRow(props.rowSelected);
    }
  }, [props.rowSelected]);

  const onCheckBoxChange = (param) => {
    setSelectedRow(param);
    props.onRowChange(props.list.id, rowSelected);
  };

  const getLeadById = async () => {
    try {
      setFetchingData(true);
      let res = await ManageService.getLeadById(props.list.id);
      setListData(res.data.result);
      setFetchingData(false);
    } catch (e) {
      console.log("unable to fetch data", e);
      setFetchingData(false);
    }
  };

  const doAction = (e) => {
    e.target.dataset.value === "edit"
      ? props.callAction(e, props.list)
      : props.callAction(e, [props.list.id]);
  };

  return (
    <div className="card lead-list-card mb-2">
      <div
        className="card-header px-3 py-2 m-0"
        id={`lead-list-${props.list.id}`}
      >
        <div className="row no-gutters align-items-center">
          <div className="checbox-content col-auto m-auto">
            <Checkbox
              value={rowSelected}
              classes={"staff-list-checkbox"}
              change={onCheckBoxChange}
            ></Checkbox>
          </div>
          <div
            className={`list-content col row align-items-center no-gutters
              ${props.list.isArchived
                ? " archived"
                : props.list.isActive
                  ? "active"
                  : "inactive"
              }
            `}
          >
            <button
              className="btn btn-link collapsed user-name col-2 text-truncate"
              type="button"
              data-toggle="collapse"
              data-target={`#target-${props.list.id}`}
              aria-expanded="false"
              aria-controls={`target-${props.list.id}`}
              title="Contact Person Organization Name"
              onClick={getLeadById}
            >
              {props.list.organizationName}
            </button>
            <div className="col-2 row-text text-truncate">
              <span className="text-value" title="Contact Person Name">{props.list.personName}</span>
            </div>
            <div className="row-text col-2 text-truncate">
              <span className="text-value" title="Contact Person Designation">
                {props.list.designation || "NA"}
              </span>
            </div>

            <div className="col-2 my-auto text-center text-truncate" title="Query Status">
              {props.list.isQueryResolved ? (
                <span className="badge badge-success">Resolved</span>
              ) : (
                <span className="badge badge-warning">Pending</span>
              )}
            </div>

            <div className="col-2 my-auto text-center text-truncate">
              { props.list.isActive ? (
                <span className="badge badge-success">Active</span>
              ) : (
                <span className="badge badge-warning">Inactive</span>
              )}
            </div>
            <div className="action col-2 text-center">
              <div className="btn-group">
                <ActionListIcon
                  className="dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
                <ul className="dropdown-menu">
                  {<li className="dropdown-item spl-link-buttn-css" data-value="edit" onClick={doAction}>Edit</li>}
                  {
                    props.list.isActive ?
                      <li className="dropdown-item spl-link-buttn-css" data-value="deactivate" onClick={doAction}>Deactivate</li> :
                      <li className="dropdown-item spl-link-buttn-css" data-value="activate" onClick={doAction}>Activate</li>
                  }
                  {<li className="dropdown-item spl-red-buttn-css" data-value="delete" onClick={doAction}>Delete Permanently</li>}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id={`target-${props.list.id}`}
        className="collapse"
        aria-labelledby={`lead-list-${props.list.id}`}
        data-parent="#leadAccordion"
      >
        <div className="card-body">
          {fetchingData && (
            <div className="custom-loader">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {!fetchingData && (
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 row my-1" title="Lead Request Generated By">
                  <div className="col-4 label">Lead Origin</div>
                  <div className="col-2">:</div>
                  <div className="col-6">
                    {listData.isSelfCreated ? (
                      <span className="badge badge-warning">STAFF MEMBER</span>
                    ) : (
                      <span className="badge badge-warning">WEBSITE</span>
                    )}
                  </div>
                </div>
                <div className="col-6 row my-1" title="Lead Request Created By">
                  <div className="col-4 label">Created By</div>
                  <div className="col-2">:</div>
                  <div className="col-6">
                    {listData.isSelfCreated ? (
                      <>
                        {
                          listData.addedBy ?
                            (<span className="badge badge-warning" title={listData.addedBy.email}>{listData.addedBy.firstName + ' ' + listData.addedBy.lastName}</span>) : ('-')
                        }</>
                    ) : (
                      <span className="badge badge-warning">{listData.personName}</span>
                    )}
                  </div>
                </div>
                <div className="col-6 row my-1" title="Lead Contact Email">
                  <div className="col-4 label">Contact Email</div>
                  <div className="col-2">:</div>
                  <div className="col-6">{listData.contactEmail}</div>
                </div>
                <div className="col-6 row my-1" title="Lead Contact Phone Number">
                  <div className="col-4 label">Contact Number</div>
                  <div className="col-2">:</div>
                  <div className="col-6">{listData.contactNumber}</div>
                </div>
                <div className="col-6 row my-1" title="Location">
                  <div className="col-4 label">State</div>
                  <div className="col-2">:</div>
                  <div className="col-6">{listData?.state?.name || "NA"}</div>
                </div>
                <div className="col-6 row my-1" title="Location">
                  <div className="col-4 label">City</div>
                  <div className="col-2">:</div>
                  <div className="col-6">{listData?.city?.name || "NA"}</div>
                </div>
                <div className="col-6 row my-1" title="Service Queried For">
                  <div className="col-4 label">Service Interested</div>
                  <div className="col-2">:</div>
                  <div className="col-6">{listData.service}</div>
                </div>
                <div className="col-6 row my-1" title="Requested Created On">
                  <div className="col-4 label">Created On</div>
                  <div className="col-2">:</div>
                  <div className="col-6">
                    <Moment
                      date={listData.createdOn}
                      format="DD MMM YYYY"
                    ></Moment>
                  </div>
                </div>
                <div className="col-6 row my-1" title="Requested Current Status">
                  <div className="col-4 label">Current Status</div>
                  <div className="col-2">:</div>
                  <div className="col-6">
                    {listData.isQueryResolved ? (
                      <span className="badge badge-success">Resolved</span>
                    ) : (
                      <span className="badge badge-warning">Pending</span>
                    )}
                  </div>
                </div>
                <div className="col-6 row my-1" title="Last Reviewed By Team On">
                  <div className="col-4 label">Reviewed On</div>
                  <div className="col-2">:</div>
                  <div className="col-6">
                    <Moment
                      date={listData.lastViewedOn}
                      format="DD MMM YYYY"
                    ></Moment>
                  </div>
                </div>
                <div className="col-12 row my-1">
                </div>
                {listData.isQueryResolved ? (
                  <div className="col-6 row my-1" title={listData.resolvedBy.email}>
                    <div className="col-4 label">Resolved By</div>
                    <div className="col-2">:</div>
                    <div className="col-6">{listData.resolvedBy.firstName + ' ' + listData.resolvedBy.lastName}</div>
                  </div>
                ) : (
                  ""
                )}
                {listData.isQueryResolved ? (
                  <div className="col-6 row my-1" title="Query Resolved On">
                    <div className="col-4 label">Resolved On</div>
                    <div className="col-2">:</div>
                    <div className="col-6">
                      <Moment
                        date={listData.resolvedOn}
                        format="DD MMM YYYY"
                      ></Moment>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-12 row my-1">
                </div>
                <div className="col-12 row my-1">
                  <div className="col-12 label">Full Address</div>
                  <div className="col-12">
                    <address>{listData.address}</address>
                  </div>
                </div>
                <div className="col-12 row my-1">
                </div>
                <div className="col-12 row my-1">
                  <div className="col-12 label">Lead Query</div>
                  <div className="col-12">{listData.query}</div>
                </div>
                <div className="col-12 row my-1">
                </div>
                {listData.isQueryResolved && (
                  <div className="col-12 row my-1">
                    <div className="col-12 label">Resolve Note</div>
                    <div className="col-12">{listData.note}</div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

const LeadsDetails = (props) => {
  const [loading, setLoading] = useState(true);
  const [leadList, setLeadList] = useState([]);
  const [paginationObj, setPaginationObj] = useState({});
  const [listLoading, setListLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [confirmationObj, setConfirmationObj] = useState({
    show: false,
    msg: "",
  });
  const [currentAction, setCurrentAction] = useState("");
  const [errModel, setErrorModal] = useState({ show: false, error: {} });
  const [successModel, setSuccessModal] = useState({
    show: false,
    success: {},
  });
  const [showUpdatePopup, setShowUpdatePopup] = useState({
    show: false,
    lead: {},
  });
  const thresholdHeight = 40;

  useEffect(() => {
    if (props.selectedTab === "leads") {
      setSelectedRow([]);
      setSelectAll(false);
      setCurrentAction("");
      getLeadList();
    }
  }, [props]);

  const getLeadList = async (param, url) => {
    const response = await ManageService.getLeadList(param, url);
    if (response.status === 200) {
      if (url) {
        let list = [];
        list = list.concat(leadList.data, response.data.result.data);
        response.data.result.data = list;
      }
      setLeadList(response.data.result);
      setPaginationObj(response.data.detail);
      setLoading(false);
      setListLoading(false);
    } else {
      setLoading(false);
      setListLoading(false);
    }
  };

  const handleScroll = (e) => {
    e.persist();
    e.preventDefault();
    const scrollableHeight = e.target.scrollHeight - e.target.clientHeight;
    if (!paginationObj.links.next || listLoading) {
      return;
    }
    if (scrollableHeight - e.target.scrollTop < thresholdHeight) {
      setListLoading(true);
      getLeadList("", paginationObj.links.next);
    }
  };

  const selectedRowChange = (id) => {
    let selectedList = selectedRow;
    const ocuuranceId = selectedList.indexOf(id);
    ocuuranceId > -1
      ? selectedList.splice(ocuuranceId, 1)
      : selectedList.push(id);
    setSelectedRow(selectedList);
    changeStateOfCheckbox();
  };

  const changeStateOfCheckbox = () => {
    let status = "";
    const len = selectedRow.length;
    if (len === 0) {
      status = false;
    } else if (len === leadList.data.length) {
      status = true;
    } else {
      status = "intermediate";
    }
    setSelectAll(status);
  };

  const onCheckBoxChange = (param) => {
    const selectedAllItem = [];
    if (param === true) {
      leadList.data.forEach((item) => {
        selectedAllItem.push(item.id);
      });
    }
    setSelectedRow(selectedAllItem);
    setSelectAll(param);
  };

  const closePopup = () => {
    setConfirmationObj({ show: false, msg: "" });
    setCurrentAction("");
  };

  const performAction = (e, list) => {
    e.persist();
    e.preventDefault();
    const action = e.target.dataset.value;
    if (action === "edit") {
      addEquipment(list);
    } else {
      setCurrentAction({ action: action, data: list ? list : selectedRow });
      setConfirmationObj({
        show: true,
        msg: `You want to ${action} this Lead?`,
      });
    }
  };

  const doAction = async () => {
    setLoading(true);
    setConfirmationObj({ show: false, msg: "" });

    try {
      const res = await ManageService.updateLeadStatus(currentAction.action, {
        leadIds: currentAction.data,
      });
      setCurrentAction({});
      setLoading(false);
      //console.log("success res", res);
      if (res.status === 200) {
        setSuccessModal({ show: true, success: { msg: res.data.result } });
        setSelectAll(false);
        getLeadList();
      }
    } catch (err) {
      //console.log(err);
      setLoading(false);
      if (err && err.data) {
        setErrorModal({ show: true, error: err.data });
      }
    }
  };

  const onSearch = (e) => {
    e.persist();
    e.preventDefault();
    e.stopPropagation();
    const value = e.target.value;
    if ((value && value.length > 2) || !value) {
      getLeadList(value);
    }
  };
  const onCancelLeadPopup = () => {
    ////console.log("onCancelSubscription called",data);
    setShowUpdatePopup({ show: false, lead: {} });
  };

  const addEquipment = (data) => {
    //console.log("add catedgory called");
    setShowUpdatePopup({ show: true, lead: data ? data : {} });
  };

  const afterAddCategory = (data) => {
    onCancelLeadPopup();
    getLeadList();
  };

  if (loading) return <Loader />;

  //console.log("Category Details is called");
  return (
    <div id="category-list-container" className="manage-list-container">
      <div className="container-fluid">
        <div className="row p-3 filter-row">
          <div className="col-3">
            <h3>Lead List</h3>
          </div>
          <div className="col">
            <div className="form-group m-0">
              <div className="d-flex justify-content-center">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="What are you looking for?"
                  onChange={onSearch}
                />
                <SearchIcon className="search-icon"></SearchIcon>
              </div>
            </div>
          </div>
          <div className="col-1"></div>
          <div className="col-2">
            <button
              className="btn btn-primary btn-padding add-staff-btn"
              onClick={addEquipment}
            >
              {" "}
              +Add{" "}
            </button>
          </div>
        </div>

        <div className="list-card list-view" id="category-list-view">
          <div className="row">
            <div className="list-container container-fluid">
              <div className="row col-12">
                <div className="list border-0 m-0 col-12">
                  <div className="container-fluid">
                    <div className="row pb-2 no-gutters">
                      <div className="checbox-content col-auto m-auto">
                        <Checkbox
                          value={selectAll}
                          classes={"staff-list-checkbox"}
                          change={onCheckBoxChange}
                          label={"Select all"}
                        ></Checkbox>
                      </div>
                      <div className="col row no-gutters">
                        <div className="col"></div>
                        <div className="col-2">
                          {paginationObj.totalCount && leadList.data ? (
                            <span className="pagination">
                              {leadList.data.length} of{" "}
                              {paginationObj.totalCount} Roles{" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-2 text-right p-0">
                          <div
                            className="dropdown"
                            style={{
                              visibility:
                                selectAll === false ? "hidden" : "visible",
                            }}
                          >
                            <button
                              className="btn btn-secondary btn-sm dropdown-toggle"
                              type="button"
                              id="action-staff-dropdown"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Actions
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="action-staff-dropdown"
                              onClick={performAction}
                            >
                              <p
                                className="dropdown-item m-0"
                                data-value="activate"
                              >
                                Activate
                              </p>
                              <p
                                className="dropdown-item m-0"
                                data-value="deactivate"
                              >
                                Deactivate
                              </p>
                              <p
                                className="dropdown-item m-0 spl-red-buttn-css"
                                data-value="delete"
                              >
                                Delete Permanently
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="container-fluid list-container scrollable-list "
              onScroll={handleScroll}
            >
              <div className="row col-12 m-0">
                <div className="accordion w-100" id="leadAccordion">
                  {leadList.data.map((item) => {
                    return (
                      <LeadLists
                        list={item}
                        key={item.id}
                        onRowChange={selectedRowChange}
                        rowSelected={selectAll}
                        callAction={performAction}
                      ></LeadLists>
                    );
                  })}
                </div>
                {listLoading ? (
                  <div className="List col-12">
                    <div
                      className="spinner-border text-primary table-loader"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {!listLoading &&
                  leadList.data &&
                  leadList.data.length === 0 &&
                  paginationObj.totalCount > 0 ? (
                  <div className="no-option-avialble w-100 m-3">
                    <div className="margin-auto text-center">
                      <WarningIcon></WarningIcon>
                      <p>No Result Found </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {!listLoading && paginationObj.totalCount === 0 ? (
                  <div className="no-option-avialble w-100 m-3">
                    <div className="margin-auto text-center">
                      <WarningIcon></WarningIcon>
                      <p>No Lead added yet! </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        data={confirmationObj}
        onConfirmAction={doAction}
        onCancel={closePopup}
      ></ConfirmModal>
      <ErrorModal
        data={errModel}
        onConfirmAction={() => setErrorModal({ show: false, error: {} })}
      ></ErrorModal>
      <SuccessModal
        data={successModel}
        onConfirmAction={() => setSuccessModal({ show: false, success: {} })}
      ></SuccessModal>
      <AddLead
        data={showUpdatePopup}
        onConfirmAction={afterAddCategory}
        onCancel={onCancelLeadPopup}
      ></AddLead>
    </div>
  );
};

export default LeadsDetails;
