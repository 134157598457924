import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import NoDataFound from '../../common/NoDataFound';
import JobService from '../../service/JobService';
import Loader from '../../component/Loader';
import SearchIcon from '@material-ui/icons/Search';
import Checkbox from '../../component/Checkbox';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import BusinessIcon from '@material-ui/icons/Business';
import ConfirmModal from '../../component/modal/ConfirmModal';
import { JobListView } from './List';
import ErrorModal from '../../component/modal/ErrorModal';
import SuccessModal from '../../component/modal/SuccessModal';
import WarningIcon from '@material-ui/icons/Warning';
import TableComponent from '../../common/tableComponent';
import LocalService from '../../service/LocalstorageService';
import ActionListIcon from '@material-ui/icons/MoreVert';
import Moment from 'react-moment';

const Main = (props) => {
    const { userType } = useParams();
    const [loading, setLoading] = useState(true);
    const [jobList, setJobList] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [confirmationObj, setConfirmationObj] = useState({ show: false, msg: '' });
    const [paginationObj, setPaginationObj] = useState({});
    const [currentAction, setCurrentAction] = useState('');
    const [errModel, setErrorModal] = useState({ show: false, error: {} })
    const [successModel, setSuccessModal] = useState({ show: false, success: {} })
    const [hotJobOnly, setHotJobOnly] = useState(false);
    // const thresholdHeight = 40;
    const allPermission = LocalService.getPermision();
    const permissions = allPermission.filter(perm => perm.name === 'Job Post Management') || {};
    const perPageItem = 10;
    const [tableLoader, setTableLoader] = useState(false);
    const [tableError, setTableError] = useState(false);

    const columns = [
        {
            label: "Name", value: "name", sortable: true,
            render: (value, index, list) => {
                return (
                    <div className='d-flex align-items-center'>
                        <div className="no-image-28">{list.title.substr(0, 1)}</div>
                        {allPermission.map(perm => perm.name).includes('Hot Jobs') ?
                            <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/jobs/view/${list.id}`}>{list.title} </Link>
                            : allPermission.map(perm => perm.name).includes('Job Post Management') ?
                                <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/job-post/view/${list.id}`}>{list.title} </Link>
                                : null
                        }
                    </div>
                )
            }

        },
        {
            label: "Location", value: "location", sortable: true,
            render: (value, index, list) => {
                return list.jobLocation.city || "NA"
            }
        },
        {
            label: "Category", value: "category", sortable: false,
            render: (value, index, list) => {
                return list.category || "NA"
            }
        },
        {
            label: "Role", value: "role", sortable: false,
            render: (value, index, list) => {
                return list.role || 'NA'
            }
        },
        {
            label: "Posted On", value: "postOn", sortable: false,
            render: (value, index, list) => {
                return <Moment date={list.postOn} format={"DD MMM YYYY"}></Moment>
            }
        },
        {
            label: "Client", value: "client", sortable: false,
            render: (value, index, list) => {
                return (
                    <div className='d-flex align-items-center'>
                        {
                            list.client.logo && list.client.logo.href ?
                                <img className="image-30" src={list.client.logo.href}></img> :
                                <div className="no-image-28">{list.client.name.substr(0, 1)}</div>
                        }
                        <div className="d-flex align-items-center">{list.client.name} </div>
                    </div>
                )
            }
        },
        {
            label: "Status", value: "status", sortable: false,
            render: (value, index, list) => {
                return (
                    list.isArchived ? <span className="badge badge-danger">Archived</span> : list.isActive ? <span className="badge badge-success">Active</span> : <span className="badge badge-warning">Inactive</span>
                )
            }
        },
        {
            label: "Action", value: "action", sortable: false,
            render: (value, index, list) => {
                return (
                    getTableActionList(list)
                )
            }
        }
    ];

    const getTableActionList = (data) => {
        return (
            <div className="btn-group action">
                <ActionListIcon className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                <ul className="dropdown-menu">
                    <li className="dropdown-item"><Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/jobs/view/${data.id}`}>View </Link></li>
                    {permissions[0]['update'] ? <li className="dropdown-item"><Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/jobs/update/${data.id}`}>Edit </Link></li> : ''}
                    {permissions[0]['delete'] && !data.isArchived ? <li className="dropdown-item" data-value="delete" onClick={() => performAction("archive", [data.id])}>Delete</li> : ''}
                    {permissions[0]['update'] && !data.isArchived && data.isActive ? <li className="dropdown-item" data-value="deactivate" onClick={() => performAction("deactivate", [data.id])}>Deactivate</li> : ''}
                    {permissions[0]['update'] && !data.isArchived && !data.isActive ? <li className="dropdown-item" data-value="activate" onClick={() => performAction("activate", [data.id])}>Activate</li> : ''}
                </ul>
            </div>
        )
    }

    useEffect(() => {
        getJobList();
    }, []);

    useEffect(() => {
        getJobList();
    }, [hotJobOnly])

    const getJobList = async (search = "", offset = 10, page = 1, sortBy) => {
        // hotJobOnly ? params.jobType = 'hot' : '';
        try {
            const response = await JobService.getJobList(search = "", offset = 10, page = 1, sortBy, hotJobOnly);
            if (response.status === 200) {
                setJobList(response.data.result);
                setPaginationObj(response.data.detail);
                setLoading(false);
                setTableLoader(false);
                setTableError(false);
            }
        }
        catch (e) {
            setLoading(false);
            setTableLoader(false);
            setTableError(true);
            setJobList([]);
            setPaginationObj({});
        }
    }

    const searchBy = (searchParam) => {
        let tableConfig = { ...config, search: searchParam };
        getTableData(tableConfig.search, tableConfig.perPageItem, tableConfig.page, tableConfig.sortBy);
    }

    const getTableData = (search, offset, page, sortBy) => {
        setTableLoader(true);
        let tableConfig = { ...config, search, "perPageItem": offset, page, sortBy }
        setConfig(tableConfig);
        getCandidateList(search, offset, page, sortBy);
    }

    const onRowSelectionChange = (list) => {
        setSelectedRow(list ? [...list]: null);
    }

    const performAction = (action, list) => {
        setCurrentAction({ action: action, data: list  });
        setConfirmationObj({ show: true, msg: `You want to ${action.toUpperCase()} this candidate?` });
    }

    let tableConfig = {
        pagination: true,
        showSelectRow: true,
        perPageItem: perPageItem,
        getTableData: getTableData,
        onRowSelection: onRowSelectionChange,
        onActionButton: performAction,
        actionList: ['edit', "delete", "activate", "deactivate"],
        search: "",
        sortBy: { name: "", type: "" },
        page: 1
    }

    const [config , setConfig] = useState({...tableConfig});

    const closePopup = () => {
        setConfirmationObj({ show: false, msg: '' });
        setCurrentAction("");
    }

    const doAction = async () => {
        setLoading(true);
        setConfirmationObj({ show: false, msg: '' });

        try {
            const res = await JobService.updateJobStatus(currentAction.action, { jobPostIds: currentAction.data ? currentAction.data : selectedRow});
            setCurrentAction({});
            setLoading(false);
            if (res.status === 200) {
                setTableLoader(true);
                getJobList(config.search, config.perPageItem, config.page, config.sort);
                setSuccessModal({ show: true, success: { msg: res.data.result } });
            }
        } catch (err) {
            setLoading(false);
            if (err && err.data) {
                setErrorModal({ show: true, error: err.data })
            }
        }
    }


    if (loading) return <Loader />;

    return (
        <div className="content-wrapper p-0" id="jobs-list-container">
            <div className="container-fluid">
                <div className="row p-3" style={{ display: props.data && props.data.id ? 'none' : '' }}>
                    <div className="list-card row no-gutters count-summery" >
                        <div className="col-4 row">
                            <div className="col-3">
                                <div className="icon-round-container text-center total-count">
                                    <BusinessIcon></BusinessIcon>
                                </div>
                            </div>
                            <div className="col-9">
                                <p><b className="count-header">Total</b></p>
                                <p><b className="count">{jobList.totalPostings}</b> Jobs</p>
                            </div>
                        </div>
                        <div className="col-4 row">
                            <div className="col-3">
                                <div className="icon-round-container text-center active-count">
                                    <BusinessIcon></BusinessIcon>
                                </div>
                            </div>
                            <div className="col-9">
                                <p><b className="count-header">Active</b></p>
                                <p><b className="count">{jobList.activePostings}</b> Jobs</p>
                            </div>
                        </div>
                        <div className="col-4 row border-0">
                            <div className="col-3">
                                <div className="icon-round-container text-center inactive-count">
                                    <BusinessIcon></BusinessIcon>
                                </div>
                            </div>
                            <div className="col-9">
                                <p><b className="count-header">Inactive</b></p>
                                <p><b className="count">{jobList.inactivePostings}</b> Jobs</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-3 filter-row align-items-center">
                    <div className="col-2">
                        <h4>Job List</h4>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3">
                        <label className="label-sm mr-2">Hot jobs</label>
                        <div className="switch" onClick={() => setHotJobOnly(!hotJobOnly)}>
                            {
                                hotJobOnly ?
                                    <input type="checkbox" name="isHotJobTrue" id="isHotJob" value={hotJobOnly} checked={hotJobOnly} onChange={() => { }} /> :
                                    <input type="checkbox" name="isHotJobFalse" id="isHotJob" value={hotJobOnly} checked={hotJobOnly} onChange={() => { }} />
                            }
                            <span className="slider round"></span>
                        </div>
                    </div>
                    <div className="col-auto">
                        <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/jobs/add`}>
                            <button className="btn btn-primary btn-padding add-btn"> +Add</button>
                        </Link>
                    </div>
                </div>
                
                <div className="list-card list-view" id="candidate-list-view">
                    <div className='list-container'>
                        <TableComponent
                            loading={tableLoader}
                            error={tableError}
                            columns={columns}
                            data={jobList.data || []}
                            config={config}
                            paginationObj={paginationObj}>
                        </TableComponent>
                    </div>

                </div>
            </div>
            <ConfirmModal data={confirmationObj} onConfirmAction={doAction} onCancel={closePopup}></ConfirmModal>
            <ErrorModal data={errModel} onConfirmAction={() => setErrorModal({ show: false, error: {} })} ></ErrorModal>
            <SuccessModal data={successModel} onConfirmAction={() => setSuccessModal({ show: false, success: {} })}></SuccessModal>
        </div>
    )
}

export default Main;