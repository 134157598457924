import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import JobService from '../../service/JobPostService';
import Loader from '../../component/Loader';
import ConfirmModal from '../../component/modal/ConfirmModal';
import ErrorModal from '../../component/modal/ErrorModal';
import SuccessModal from '../../component/modal/SuccessModal';
import Moment from 'react-moment';
import ReactHtmlParser from 'react-html-parser';

const ClientView = () => {
    const { id, userType } = useParams();
    const [jobData, setJobData] = useState({});
    const [loading, setLoading] = useState(true);
    const [confirmationObj, setConfirmationObj] = useState({ show: false, msg: '' });
    const [errModel, setErrorModal] = useState({ show: false, error: {} })
    const [successModel, setSuccessModal] = useState({ show: false, success: {} })
    const [currentAction, setCurrentAction] = useState('');
    const history = useHistory();

    useEffect(() => {
        getJobData();
    }, []);

    const getJobData = async () => {
        try {
            const response = await JobService.getJobDetails(id);
            if (response.status === 200) {
                setJobData(response.data.result);
                // console.log("Job data", response.data.result);
                // console.log(response.data.result.submission.profileViewedOn)
                setLoading(false);
            }
        }
        catch (err) {
            setLoading(false);
            history.push(`/can/cand-jobs`)
        }

    }
    const closePopup = () => {
        setConfirmationObj({ show: false, msg: '' });
        setCurrentAction("");
    }

    const performAction = (e) => {
        //console.log("action",e);
        e.persist()
        e.preventDefault();
        const action = e.target.dataset.value;
        setCurrentAction({ action: action, data: [id] });
        setConfirmationObj({ show: true, msg: `You want to ${action} this job?` });
    }

    const doAction = async () => {
        setLoading(true);
        setConfirmationObj({ show: false, msg: '' });

        try {
            const res = await JobService.applyJob(jobData.id);
            setCurrentAction({});
            setLoading(false);
            //console.log("success res", res);
            if (res.status === 200) {
                setSuccessModal({ show: true, success: { msg: res.data.result } });
                getJobData();
            }
        } catch (err) {
            //console.log(err);
            setLoading(false);
            if (err && err.data) {
                setErrorModal({ show: true, error: err.data })
            }
        }
    }


    if (loading) return <Loader />;

    return (
        <div id="jobs-view">
            <div className="row cs-breadcrumb no-gutters w-100">
                <ol className="breadcrumb w-100">
                    <li className="breadcrumb-item active">
                        <Link target={"_blank"} rel={"noopener noreferrer"} to={`/can/cand-jobs`}>Jobs</Link>
                    </li>
                    <li className="breadcrumb-item">{jobData.title}</li>
                </ol>
            </div>
            <div className="content-wrapper" >
                <div className="content-container ">
                    <div className="row client-details-container no-gutters">
                        <div className="col-12">
                            <div className="name-section row no-gutters">
                                <p className="col-auto name m-0">{jobData.title}</p>
                                {/* <p className="col-auto mb-0 ml-4 mt-2">
                                {jobData.isArchived ? <span className="badge badge-danger">Archived</span> :jobData.isActive ? <span className="badge badge-success">Active</span>:<span className="badge badge-warning">Inactive</span>}
                                </p> */}
                                <div className="col staff-detail-action text-right">
                                    {jobData.submission ?
                                        <span>
                                            {jobData.submission.profileViewedOn ?
                                                <><span className="mr-3">
                                                    <span className="fw-500">Profile Viewed On: </span>
                                                    <Moment date={jobData.submission.profileViewedOn} format={"DD MMM YYYY hh:mm A"}></Moment>
                                                </span><span className="badge badge-primary">{jobData.submission.submissionType}</span></> :
                                                <span className="badge badge-warning">PROFILE NOT VIEWED</span>
                                            }
                                        </span> :
                                        <button type="button" className="btn btn-primary" data-value="apply for" onClick={performAction}>Apply Now</button>
                                    }
                                </div>
                            </div>
                            <div className="detail-section row no-gutters custom-background-color">
                                <div className="col-6 item-detail" title="Company">
                                    <span className="fw-500">Company :  </span>
                                    <span >{jobData.client.name}</span>
                                </div>
                                <div className="col-6 item-detail">
                                    <span className="fw-500">Location : </span>
                                    <span >{jobData.jobLocation.city.name} {jobData.jobLocation.state.name}</span>
                                </div>
                                <div className="col-6 item-detail" title="Company">
                                    <span className="fw-500">Experience :  </span>
                                    {(jobData.minimumExperience || jobData.maximumExperience) ?
                                        <span>{jobData.minimumExperience}{(jobData.minimumExperience && jobData.maximumExperience) ? ' - ' : null}{jobData.maximumExperience} years</span> :
                                        <span>Negotiable</span>
                                    }
                                </div>
                                <div className="col-6 item-detail">
                                    <span className="fw-500">Salary : </span>
                                    <span >{jobData.isCtcNegotiable ? 'Negotiable' : `${jobData.maximumCtc} LPA`}</span>
                                </div>
                                <div className="col-6 item-detail">
                                    <span className="fw-500">Posted Date :</span>
                                    <span ><Moment date={jobData.postOn} format={"DD MMM YYYY hh:mm A"}></Moment></span>
                                </div>
                                <div className="col-6 item-detail">
                                    <span className="fw-500">Category :</span>
                                    <span >{jobData.category.name}</span>
                                </div>
                                <div className="col-6 item-detail">
                                    <span className="fw-500">Role :</span>
                                    <span >{jobData.role.name}</span>
                                </div>
                                <div className="col-6 item-detail">
                                    <span className="fw-500" >Qualifications :</span>
                                    <span >{jobData.qualifications[0].name}</span>
                                </div>
                                <div className="col-6 item-detail row no-gutters">
                                    <span className="fw-500 col-auto ">Courses :</span>
                                    <span className=" col">{jobData.courses && jobData.courses.map(item => <span className="badge badge-info text-truncate mr-2" key={item.id} title={item.name}>{item.name}</span>)}</span>
                                </div>
                                <div className="col-6 item-detail row no-gutters">
                                    <span className="fw-500 col-auto">Specializations :</span>
                                    <span className=" col">{jobData.specializations && jobData.specializations.map(item => <span className="badge badge-secondary text-truncate mr-2" key={item.id} title={item.name}>{item.name}</span>)}</span>
                                </div>


                                <div className="col-12 item-detail description-container">
                                    <p className="fw-500 w-100">Description : </p>
                                    <div className="description w-100">{ReactHtmlParser(jobData.description)}</div>
                                </div>


                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <ConfirmModal data={confirmationObj} onConfirmAction={doAction} onCancel={closePopup}></ConfirmModal>
            <ErrorModal data={errModel} onConfirmAction={() => setErrorModal({ show: false, error: {} })} ></ErrorModal>
            <SuccessModal data={successModel} onConfirmAction={() => setSuccessModal({ show: false, success: {} })}></SuccessModal>
        </div>
    )
}

export default ClientView;
