import { createContext, useContext } from 'react';

const initialState = {
    user : {},
    token:"",
    userType:"",
    permission:[],
    isLoggedIn: false
}

export const AuthContext = createContext({
  data:{},
  updateAuthData : () => {}
});

export function useAuth() {
  return useContext(AuthContext);
}
