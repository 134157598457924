import React, { useState, useContext, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";
import PersonOutlineIcon from "@material-ui/icons/EmojiPeople";
import SearchIcon from "@material-ui/icons/Search";
import WorkIcon from "@material-ui/icons/Work";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SettingsIcon from "@material-ui/icons/Settings";
import StoreIcon from '@material-ui/icons/Store';
import { AuthContext } from "../component/AuthContext";
import { PrivateRouteList } from "../service/ComponentList";
import LocalStorageService from "../service/LocalstorageService";
import ShowTermsConditions from '../component/modal/ShowTermsConditions';

const LocalService = LocalStorageService.getService();
const routeList = PrivateRouteList;

const Sidebar = (props) => {
  const [routerPrifix, setRouterPrifix] = useState("");
  const [user, setUser] = useState({});
  const context = useContext(AuthContext);
  const history = useHistory();
  const clientId = LocalService.getClientData()
    ? LocalService.getClientData()["id"]
    : null;

  const showTermConditions = () => {
    if (!LocalService.getHasViewedTnc()) {
      return <ShowTermsConditions></ShowTermsConditions>
    }
    return '';

  }

  useEffect(() => {
    let user = LocalService.getUserData();
    setUser(user);
  }, []);

  useEffect(() => {
    setRouterPrifix(LocalService.getRoutePrefix());
  }, [context]);

  const getPermissionByName = (name) => {
    let obj = {};
    context.data.permissions.forEach((permission) => {
      if (permission.displayName === name) obj = permission;
    });
    return obj;
  };

  const checkAccessForRoleManagment = () => {
    const obj = { read: false };
    context.data.permissions.forEach((permission) => {
      if (
        (permission.name === "Subscription Plan Management" ||
          permission.name === "Staff Role Management" ||
          permission.name === "Qualification Management" ||
          permission.name === "Category Role Management" ||
          permission.name === "Location Management" ||
          permission.name === "Leads Management" ||
          permission.name === "Equipment Management") &&
        permission.read
      )
        obj.read = obj.read || permission.read;
    });
    return obj;
  };

  const checkAccess = (parent) => {
    let result = false;
    if (parent === "Role Management") {
      const permission = checkAccessForRoleManagment();
      result = permission.read ? true : false;
    } else {
      PrivateRouteList[context?.data?.userType].forEach((route) => {
        if (route.parent === parent) {
          const permissionObj = route.parent
            ? getPermissionByName(route.parent)
            : { read: false };
          if (!route.checkAccess) result = true;
          else result = permissionObj.read;
        }
      });
    }

    //console.log("sidebar checkaccess",parent , result);
    return result;
  };
  if (!routerPrifix) return "";
  return (
    <div className="main">
      <aside>
        <div className="sidebar left ">
          <ul className="list-sidebar">
            <NavLink
              to={`/${routerPrifix}/dashboard`}
              activeClassName="active"
              exact
            >
              <li>
                <EqualizerIcon />
                <span className="nav-label"> Dashboard </span>
              </li>
            </NavLink>

            {checkAccess("Staff Management") ? (
              <NavLink
                to={`/${routerPrifix}/staff`}
                activeClassName="active"
                exact
              >
                <li>
                  <PersonIcon></PersonIcon>
                  <span className="nav-label">Staff Management</span>
                </li>
              </NavLink>
            ) : (
              ""
            )}

            {checkAccess("Client Management") ? (
              <>
                {routerPrifix === "cli" ? (
                  <NavLink
                    to={`/${routerPrifix}/client/view/${clientId}`}
                    activeClassName="active"
                  >
                    <li>
                      <BusinessIcon></BusinessIcon>
                      <span className="nav-label">Employer Management</span>
                    </li>
                  </NavLink>
                ) : (
                  <NavLink
                    to={`/${routerPrifix}/client`}
                    activeClassName="active"
                  >
                    <li>
                      <BusinessIcon></BusinessIcon>
                      <span className="nav-label">Employer Management</span>
                    </li>
                  </NavLink>
                )}
              </>
            ) : (
              ""
            )}

            {checkAccess("Candidate Management") && routerPrifix !== "can" ? (
              <NavLink
                to={`/${routerPrifix}/candidate`}
                activeClassName="active"
              >
                <li>
                  <PersonOutlineIcon></PersonOutlineIcon>
                  <span className="nav-label">Applicant Management</span>
                </li>
              </NavLink>
            ) : (
              ""
            )}

            {checkAccess("Candidate Management") && routerPrifix === "can" ? (
              <NavLink
                to={`/${routerPrifix}/profile-view/${user.id}`}
                activeClassName="active"
              >
                <li>
                  <PersonOutlineIcon></PersonOutlineIcon>
                  <span className="nav-label">Manage Profile</span>
                </li>
              </NavLink>
            ) : (
              ""
            )}

            {checkAccess("Search Management") ? (
              <NavLink
                to={`/${routerPrifix}/search-candidate`}
                activeClassName="active"
              >
                <li>
                  <SearchIcon></SearchIcon>
                  <span className="nav-label">Search Applicants</span>
                </li>
              </NavLink>
            ) : (
              ""
            )}

            {checkAccess("Job Post Management") ? (
              <NavLink
                to={`/${routerPrifix}/job-post`}
                activeClassName="active"
              >
                <li>
                  <WorkIcon></WorkIcon>
                  <span className="nav-label">Job Management</span>
                </li>
              </NavLink>
            ) : (
              ""
            )}

            {checkAccess("Hot Jobs") ? (
              <NavLink
                to={
                  routerPrifix === "can"
                    ? `/${routerPrifix}/cand-jobs`
                    : `/${routerPrifix}/jobs`
                }
                activeClassName="active"
              >
                <li>
                  <WorkIcon></WorkIcon>
                  {routerPrifix === "can" ? (
                    <span className="nav-label">Jobs</span>
                  ) : (
                    <span className="nav-label">Hot Jobs</span>
                  )}
                </li>
              </NavLink>
            ) : (
              ""
            )}

            {checkAccess("News Management") ? (
              <NavLink to={`/${routerPrifix}/news`} activeClassName="active">
                <li>
                  <ReceiptIcon />
                  <span className="nav-label">News Management</span>
                </li>
              </NavLink>
            ) : (
              ""
            )}

            {checkAccess("Role Management") ? (
              <NavLink to={`/${routerPrifix}/manage`} activeClassName="active">
                <li>
                  <StoreIcon></StoreIcon>
                  <span className="nav-label">Corpus Management</span>
                </li>
              </NavLink>
            ) : (
              ""
            )}

            {checkAccess("Generic App") ? (
              <NavLink
                to={`/${routerPrifix}/settings`}
                activeClassName="active"
              >
                <li>
                  <SettingsIcon></SettingsIcon>
                  <span className="nav-label">Profile Setting</span>
                </li>
              </NavLink>
            ) : (
              ""
            )}
          </ul>
        </div>
      </aside>
      {showTermConditions()}
    </div>
  );
};

export default Sidebar;
