import Api from "./Interceptor";
import LocalStorageService from "./LocalstorageService";

const localStorage = LocalStorageService.getService();

const ClientService = (function () {
  const ajax = function (param) {
    return Api(param);
  };

  const generateParams = function (param) {
    if (!param) return "";
    let params = "";

    Object.keys(param).forEach((key) => {
      params = `${params}${params ? "&" : "?"}${key}=${param[key]}`;
    });
    return params;
  };

  return {
    getRole: function () {
      let requestObj = { method: "GET", url: "own/role" };
      return ajax(requestObj);
    },
    isLoggedIn: function () {
      return localStorage.getAccessToken() ? true : false;
    },
    addJob: function (data) {
      let requestObj = { method: "POST", url: "/requirement/post", data: data };
      return ajax(requestObj);
    },
    getJobList: function (search, offset, page, sortby, jobType, clientId) {
      const requestObj = {
        method: "GET",
        url: "/requirement/post",
      };
      let paramSting = "";
      if (search) {
        paramSting = paramSting + `search=${search}`
      }
      if (offset) {
        paramSting = (paramSting ? paramSting + '&' : paramSting) + `limit=${offset}`
      }
      if (page) {
        paramSting = (paramSting ? paramSting + '&' : paramSting) + `page=${page}`
      }
      if (sortby) {
        paramSting = (paramSting ? paramSting + '&' : paramSting) + `sort=${sortby.type}&sortby=${sortby.name}`
      }
      if (jobType) {
        paramSting = (paramSting ? paramSting + '&' : paramSting) + 'jobType=hot'
      }
      if (clientId) {
        paramSting = (paramSting ? paramSting + '&' : paramSting) + `clientId=${clientId}`
      }

      if (paramSting) {
        requestObj.url = requestObj.url + "?" + paramSting;
      }
      return ajax(requestObj);
    },
    getJobDetails: function (id) {
      let requestObj = { method: "GET", url: `/requirement/post/${id}` };
      return ajax(requestObj);
    },
    updateJob: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `/requirement/post/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateJobStatus: function (action, data) {
      let requestObj = {
        method: "PUT",
        url: `/requirement/post/update/bulk?action=${action}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getCategoryByClient: function (clientId, param, url) {
      const requestObj = {
        method: "GET",
        url: `/own/category${clientId ? `?clientId=${clientId}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getRoles: function (id, param, url) {
      const requestObj = {
        method: "GET",
        url: `/own/category/role/${id}${param ? `?search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getAllCategoryRoles: function (param, url) {
      const requestObj = {
        method: "GET",
        url: `/own/category/role${param ? `?limited=true&search=${param}` : "?limited=true"}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getClientList: function (param, url) {
      const requestObj = {
        method: "GET",
        url: `/client/detail${param ? `?search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getCourse: function (id, param, url) {
      const requestObj = {
        method: "GET",
        url: `/own/course/${id}?items=all${param ? `&search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getQualification: function (param, url) {
      const requestObj = {
        method: "GET",
        url: `/own/qualification?items=all${param ? `&search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getSpecialization: function (id, param) {
      const requestObj = {
        method: "GET",
        url: `/own/specialization/${id}?items=all${param ? `&search=${param}` : ""
          }`,
      };
      return ajax(requestObj);
    },
    getAllStateList: function () {
      let requestObj = { method: "GET", url: `/own/state?items=all` };
      return ajax(requestObj);
    },
    getCityList: function (stateId) {
      let requestObj = {
        method: "GET",
        url: `own/state/city/${stateId}?items=all`,
      };
      return ajax(requestObj);
    },
    searchJobPublic: function (data) {
      let requestObj = {
        method: "POST",
        url: `webcontent/search?searchFor=job`,
        data: data,
      };
      return ajax(requestObj);
    },
  };
})();

export default ClientService;
