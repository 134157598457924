import React from 'react';
import {Link} from 'react-router-dom';
import LoginImage from '../../assets/imgs/login3.png';

const intialState = {
    fname: '',
    lname:'',
    email:'',
    password:'',
    confirmPassword:'',
    acceptTermCondition:'',
    formError:{
        fname: '',
        lname:'',
        email:'',
        password:'',
        confirmPassword:'',
        acceptTermCondition:''
    },
    validationSchema : {
        fname: {dname: "First Name",min: 3,type: 'string', required : true},
        lname: {dname: "Last Name", min: 3,type: 'string', required : true},
        email: {dname: "Email",type: 'email', required : true},
        password: {dname: "Password",min: 6,type: 'string', required : true},
        confirmPassword: {dname: "Confirm Password",min: 6,type: 'string', required : true},
        acceptTermCondition: {dname: "Term & Condition",type: 'boolean', required : true},

    }
}

class Register extends React.Component{
    constructor(props){
        super(props);
        this.state = intialState;
        this.setState({formError:intialState});
        this.onBlur = this.onBlur.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    onBlur(e){
        e.persist();
        e.preventDefault();
        let errorMsg = this.state.formError;
        const {name , value} = event.target;
        const validation = ValidationService.checkValidField(name,value,this.state.validationSchema);
        if(validation.valid){
            let obj = {};
            obj[name] = value;
            this.setState(obj);
            errorMsg[name] = "";
            this.setState({'formError':errorMsg});
        }
        else{
            errorMsg[name] = validation.msg;
            this.setState({'formError':errorMsg});
        }
    }

    onSubmit(e){
        e.persist();
        e.preventDefault();
        console.log("form submit" ,e);
    }

    render(){
        return (
            <div className="container-fluid p-0 m-0">
                <div className= "form-wrapper login-page-container">
                    <div className="row vh-100 w-100">
                        <div className="col-7">
                            <img src={LoginImage} className="login-bg-img"></img>
                        </div>
                        <div className="col-5">
                            <div className="container vh-100 w-100">
                                <div className="row align-items-center vh-100 w-100">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="form-header text-center">
                                            <span className="text-msg">Sign Up</span>
                                        </div>
                                        <form onSubmit={this.onSubmit}>
                                            <div className="row">
                                                <div className="form-group col-6">
                                                    <label className="label-sm">First Name</label>
                                                    <input type="text" className="form-control" id="fname"  placeholder="First Name" name="fname" defaultValue={this.state.fname} onChange={this.onChange}/>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label className="label-sm">Last Name</label>
                                                    <input type="text" className="form-control " placeholder="Last Name" id="lname" name="lname" defaultValue={this.state.lname} onBlur={this.onBlur}/>
                                                </div>  
                                            </div>
                                            <div className="form-group">
                                                <label className="label-sm">User name/Email ID</label>
                                                <input type="text" className="form-control " placeholder="name@email.com" id="username" name="username" defaultValue={this.state.username} onBlur={this.onBlur}/>
                                            </div>
                                            <div className="form-group">
                                                <label className="label-sm">Password</label>
                                                <input type="password" className="form-control" placeholder="********" id="password" name="password" defaultValue={this.state.password} onBlur={this.onBlur}/>
                                            </div>
                                            <div className="form-group">
                                                <label className="label-sm">Confirm Password</label>
                                                <input type="cpassword" className="form-control " placeholder="********" id="cpassword" name="cpassword" defaultValue={this.state.confirmPassword} onBlur={this.onBlur}/>
                                            </div>
                                            <div className="form-group form-check">
                                                <input type="checkbox" className="form-check-input" name="termCodition" id="termCodition" defaultValue={this.state.acceptTermCondition} onBlur={this.onBlur} />
                                                <label className="form-check-label" >I am accepting terms & conditions</label>
                                            </div>
                                            <div className="action-btn text-center">
                                                <button type="submit" className="btn  btn-primary login-btn">Sign UP</button>
                                            </div>
                                        </form>

                                        <div className="bottom-align">
                                            <p className="text-right">Already have  an account?
                                                <Link to="/login" className="no-decoration">
                                                    <span className="signup-text">login</span>
                                                </Link>    
                                            </p>
                                        </div>

                                    </div>
                                    <div className="col-2"></div>
                                </div>
                            </div>
                        </div>            
                    </div>
                </div>
            </div>
        );
    }
}

export default Register;