import React, { useState, useEffect } from 'react';
import ActionListIcon from '@material-ui/icons/MoreVert';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import Checkbox from '../../component/Checkbox';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import DefaultMaleProfilePic from '../../../assets/imgs/matthew@2x.png';
import DefaultFemaleProfilePic from '../../../assets/imgs/molly@2x.png';


const List = (props) => {
    return (
        <div></div>

    );
}

export default List;