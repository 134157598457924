import React, { useEffect, useState } from "react";
import HomePageService from "../../service/HomePageService";
import { useLocation } from "react-router-dom";
import NavBar from "./Navbar";
import EmployerHeroSection from "./Employer/HeroSection";
import EmployeeHeroSection from "./Employee/HeroSection";
import EmployeeJobSection from "./Employee/JobSection";
import EmployerCompanyList from "./Employer/CompanyList";
import TrendingNews from "./TrendingNews";
import ReviewSection from "./Employer/Review";
import UploadResumeSection from "./UploadResume";
import QueryComponent from "./Employer/QueryComponent";
import Footer from "./Footer";
import EmployerCategoryList from "./Employer/CategoryList";
import VideoSection from "./VideoSection";

const Home = (props) => {
  const [state, setState] = useState("employee");
  const [pageData, setPageData] = useState({});
  const location = useLocation();


  useEffect(() => {
    if (location && location.state && location.state.type) {
      setState(location.state.type);
    }
  }, [])

  useEffect(() => {
    getPageData();
  }, [state]);

  const getPageData = async () => {
    try {
      const response = await HomePageService.getPageData(state);
      setPageData(response.data.result);
    } catch (e) {
      console.log("error in fetching data", e);
    }
  };

  const tabChange = (tabName) => {
    setState(tabName);
  };

  return (
    <div className="home-page">
      <NavBar currentState={state} onTabChange={tabChange} />
      <div id="employer-home">
        {state === "employer" ? (
          <>
            <EmployerHeroSection />
            {pageData.categories && pageData.categories.length ?
              <EmployerCategoryList data={pageData.categories} /> : ''
            }
          </>
        ) : (
          <>
            <EmployeeHeroSection />
            {pageData.hotJobs && pageData.hotJobs.length ?
              <EmployeeJobSection data={pageData.hotJobs} /> : ''
            }
          </>
        )}
        {pageData.clients && pageData.clients.length ?
          <EmployerCompanyList data={pageData.clients} /> : ''
        }

        {pageData.news && pageData.news.length ?
          <TrendingNews data={pageData.news} /> : ''
        }


        {pageData.reviews && pageData.reviews.length ?
          <ReviewSection data={pageData.reviews} /> : ''
        }

        {pageData.videos && pageData.videos.length ?
          <VideoSection data={pageData.videos} /> : ''
        }

        {state === "employee" ? <UploadResumeSection /> : <QueryComponent />}
        <Footer />
      </div>
    </div>
  );
};

export default Home;
