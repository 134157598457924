import Api from './Interceptor';
import LocalStorageService from './LocalstorageService';

const localStorage = LocalStorageService.getService();

const ClientService = (function () {
    const ajax = function (param) {
        return Api(param)
    }

    return {
        getRole: function () {
            let requestObj = { method: 'GET', url: 'own/role' }
            return ajax(requestObj);
        },
        isLoggedIn: function () {
            return localStorage.getAccessToken() ? true : false;
        },
        addClient: function (data) {
            let requestObj = { method: 'POST', url: '/client/detail', data: data, headers: { 'content-type': 'multipart/form-data' } };
            return ajax(requestObj);
        },
        getClientList: function (search, offset, page, sortby) {
            const requestObj = { method: 'GET', url: `/client/detail` };
            let paramSting = "";
            if (search) {
                paramSting = paramSting + `search=${search}`
            }
            if (offset) {
                paramSting = (paramSting ? paramSting + '&' : paramSting) + `offset=${offset}`
            }
            if (page) {
                paramSting = (paramSting ? paramSting + '&' : paramSting) + `page=${page}`
            }
            if (sortby) {
                paramSting = (paramSting ? paramSting + '&' : paramSting) + `sort=${sortby.type}&sortby=${sortby.name}`
            }

            if (paramSting) {
                requestObj.url = requestObj.url + "?" + paramSting;
            }
            return ajax(requestObj);
        },
        getClientListLimitedData: function (search, offset, page, sortby) {
            const requestObj = { method: 'GET', url: `/client/detail` };
            let paramSting = "";
            if (search) {
                paramSting = paramSting + `search=${search}&limited=true`
            } else {
                paramSting = "limited=true"
            }
            if (offset) {
                paramSting = (paramSting ? paramSting + '&' : paramSting) + `offset=${offset}`
            }
            if (page) {
                paramSting = (paramSting ? paramSting + '&' : paramSting) + `page=${page}`
            }
            if (sortby) {
                paramSting = (paramSting ? paramSting + '&' : paramSting) + `sort=${sortby.type}&sortby=${sortby.name}`
            }

            if (paramSting) {
                requestObj.url = requestObj.url + "?" + paramSting;
            }
            return ajax(requestObj);
        },
        getClientDetails: function (id) {
            let requestObj = { method: 'GET', url: `/client/detail/${id}` };
            return ajax(requestObj);
        },
        updateClient: function (id, data) {
            let requestObj = { method: 'PUT', url: `client/detail/${id}`, data: data };
            return ajax(requestObj);
        },
        updateClientStatus: function (action, data) {
            let requestObj = { method: 'PUT', url: `/client/update/bulk?action=${action}`, data: data }
            return ajax(requestObj);
        },
        getCategory: function () {
            const requestObj = { method: 'GET', url: `/own/category?items=all` };
            return ajax(requestObj);
        },
        addSubscription: function (data) {
            const requestObj = { method: 'POST', url: `/client/subscription`, data: data };
            return ajax(requestObj);
        },
        getDashboardCounts: function (data) {
            const requestObj = { method: 'GET', url: `/user/dashboard` };
            return ajax(requestObj);
        },
        getUserActivityLog: function (data) {
            const requestObj = { method: 'GET', url: `/user/activity` };
            return ajax(requestObj);
        },
        getJobPosts: function (data) {
            const requestObj = { method: 'GET', url: `/requirement/post?items=&search=active` };
            return ajax(requestObj);
        },
        getSuggestedJobs: function (data) {
            const requestObj = { method: 'GET', url: `/requirement/post?filterBy=suggested` };
            return ajax(requestObj);
        },
        changePassword: function (data) {
            const requestObj = { method: 'PUT', url: `/user/password`, data: data };
            return ajax(requestObj);
        },
        getUserActivity: function (id) {
            let requestObj = { method: 'GET', url: `user/activity?userId=${id}` };
            return ajax(requestObj);
        },
        addClientUser: function (data) {
            const requestObj = { method: 'POST', url: `client/user`, data: data };
            return ajax(requestObj);
        },
        upload: function (data) {
            let requestObj = { method: 'POST', url: `file/upload`, data: data, headers: { 'content-type': 'multipart/form-data' } };
            return ajax(requestObj);
        },
        addReview: function (data) {
            const requestObj = { method: 'POST', url: `client/review`, data: data };
            return ajax(requestObj);
        },
        updateReview: function (id, data) {
            const requestObj = { method: 'PUT', url: `client/review/${id}`, data: data };
            return ajax(requestObj);
        },
        getReviews: function (id) {
            let requestObj = { method: 'GET', url: `client/review?client_id=${id}` };
            return ajax(requestObj);
        },
        fetchReview: function (id) {
            let requestObj = { method: 'GET', url: `client/review/${id}` };
            return ajax(requestObj);
        },
        getDownloadedProfileList: function (search, offset, page, sortby, clientId) {
            const requestObj = { method: 'GET', url: `client/profiles` };
            let paramSting = "clientId=" + clientId;
            if (search) {
                paramSting = paramSting + `search=${search}`
            }
            if (offset) {
                paramSting = (paramSting ? paramSting + '&' : paramSting) + `offset=${offset}`
            }
            if (page) {
                paramSting = (paramSting ? paramSting + '&' : paramSting) + `page=${page}`
            }
            if (sortby) {
                paramSting = (paramSting ? paramSting + '&' : paramSting) + `sort=${sortby.type}&sortby=${sortby.name}`
            }

            if (paramSting) {
                requestObj.url = requestObj.url + "?" + paramSting;
            }
            return ajax(requestObj);
        },
        getSubsciptionList: function (clientId, params) {
            const requestObj = { method: 'GET', url: `client/subscription?clientId=${clientId}` };
            if (params) {
                for (let key in params) {
                    if (params[key]) {
                        requestObj.url = requestObj.url + '&' + key + '=' + params[key];
                    }
                }
            }
            return ajax(requestObj);
        }
    }
})()

export default ClientService;