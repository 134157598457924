import React, { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import Navbar from "../Navbar";
import { useHistory } from "react-router-dom";
import HomePageService from "../../../service/HomePageService";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ReactHtmlParser from "react-html-parser";
import Moment from "react-moment";
import WarningIcon from "@material-ui/icons/Warning";
import MultiRangeSlider from "multi-range-slider-react";
import HtmlParser from "react-html-parser";
import ortho from '../../../../assets/imgs/ortho.png';
import opthal from '../../../../assets/imgs/opthal.png';
import cardio from '../../../../assets/imgs/cardio.png';
import general_role from '../../../../assets/imgs/general_role.png';
import psycologist from '../../../../assets/imgs/psycologist.png';
import dental from '../../../../assets/imgs/dental.png';
import CheckboxV2 from "../../../component/CheckboxV2";
import SelectLocation from "../../../component/modal/SelectLocation";
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import SelectRole from "../../../component/modal/SelectRole";
import TocIcon from '@material-ui/icons/Toc';
import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../../component/Loader";

import add_circle from '../../../../assets/imgs/healthcare_icons/add_circle.svg';
import cardiology from '../../../../assets/imgs/healthcare_icons/cardiology.svg';
import dentistry from '../../../../assets/imgs/healthcare_icons/dentistry.svg';
import dermatology from '../../../../assets/imgs/healthcare_icons/dermatology.svg';
import ecg from '../../../../assets/imgs/healthcare_icons/ecg.svg';
import emergency from '../../../../assets/imgs/healthcare_icons/emergency.svg';
import fertile from '../../../../assets/imgs/healthcare_icons/fertile.svg';
import gastroenterology from '../../../../assets/imgs/healthcare_icons/gastroenterology.svg';
import gynaecolog from '../../../../assets/imgs/healthcare_icons/gynaecolog.svg';
import health_and_safety from '../../../../assets/imgs/healthcare_icons/health_and_safety.svg';
import hematology from '../../../../assets/imgs/healthcare_icons/hematology.svg';
import immunology from '../../../../assets/imgs/healthcare_icons/immunology.svg';
import labs from '../../../../assets/imgs/healthcare_icons/labs.svg';
import monitor_heart from '../../../../assets/imgs/healthcare_icons/monitor_heart.svg';
import nephrology from '../../../../assets/imgs/healthcare_icons/nephrology.svg';
import neurology from '../../../../assets/imgs/healthcare_icons/neurology.svg';
import oncology from '../../../../assets/imgs/healthcare_icons/oncology.svg';
import ophthalmology from '../../../../assets/imgs/healthcare_icons/ophthalmology.svg';
import orthopedics from '../../../../assets/imgs/healthcare_icons/orthopedics.svg';
import pediatrics from '../../../../assets/imgs/healthcare_icons/pediatrics.svg';
import physical_therapy from '../../../../assets/imgs/healthcare_icons/physical_therapy.svg';
import pulmonology from '../../../../assets/imgs/healthcare_icons/pulmonology.svg';
import radiology from '../../../../assets/imgs/healthcare_icons/radiology.svg';
import rheumatology from '../../../../assets/imgs/healthcare_icons/rheumatology.svg';
import rib_cage from '../../../../assets/imgs/healthcare_icons/rib_cage.svg';
import stethoscope from '../../../../assets/imgs/healthcare_icons/stethoscope.svg';
import urology from '../../../../assets/imgs/healthcare_icons/urology.svg';
import ward from '../../../../assets/imgs/healthcare_icons/ward.svg';
import surgical from '../../../../assets/imgs/healthcare_icons/surgical.svg';
import medication from '../../../../assets/imgs/healthcare_icons/medication.svg';

const SearchPublicJob = (props) => {
  const history = useHistory();
  const [selectedRole, setSelectedRole] = useState(props?.location?.state?.skills?.split(',') || []);
  const [selectedLocation, setSelectedLocation] = useState(props?.location?.state?.locations?.split(',') || []);
  const [jobList, setJobList] = useState([]);
  const [jobData, setJobData] = useState({});
  const [minExp, setMinExp] = useState(0);
  const [maxExp, setMaxExp] = useState(60);
  const [roleList, setRoleList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [showLocationPopup, setShowlocationPopup] = useState({ show: false, location: [] });
  const [showRolePopup, setShowRolePopup] = useState({ show: false, role: [] });
  const [isFilterShow, setIsFilterShow] =useState(false);
  const [isLoadding, setIsLoading]= useState(true);

  useEffect(() => {
    getLocationList();
    getRoleList();
    fetchData();
  }, [selectedRole, selectedLocation, minExp, maxExp]);

  const getRoleList = async () => {
    try {
      const response = await HomePageService.getSelectedCategoryRole(selectedRole)
      const role = [];
      if (response.data && response.data.result) {
        response.data.result.checked.forEach((item) => {
          role.push({ label: item, checked: true });
        })
        response.data.result.uncheckedVals.forEach((item) => {
          role.push({ label: item, checked: false });
        })
        setRoleList([...role]);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("error", err);
    }
  }

  const getLocationList = async () => {
    try {
      const response = await HomePageService.getSelectedLocation(selectedLocation)
      const location = [];
      if (response.data && response.data.result) {
        response.data.result.checked.forEach((item) => {
          location.push({ label: item, checked: true });
        })
        response.data.result.uncheckedVals.forEach((item) => {
          location.push({ label: item, checked: false });
        })
        setLocationList([...location]);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("error", err);
    }
  }

  const fetchData = async () => {
    try {
      const payload = {
        "searchStr": selectedRole.join(','),
        "locationStr": selectedLocation.join(','),
        "minExp": minExp,
        "maxExp": maxExp
      };
      let response = await HomePageService.searchJobPublic(payload);
      setJobList(response.data.result.data);
      setJobData(response.data.result);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("error", err);
    }
  };

  const updateSelectedLocation = (event, location) => {
    const selectedLocs = [...selectedLocation];
    if(event){
      selectedLocs.push(location)
    }else{
      const index = selectedLocation.indexOf(location);
      selectedLocs.splice(index, 1);
    }
    setSelectedLocation([...selectedLocs]);
  }

  const updateSelectedRole = (event, role) => {
    const selectedRoles = [...selectedRole];
    if(event){
      selectedRoles.push(role)
    }else{
      const index = selectedRoles.indexOf(role);
      selectedRoles.splice(index, 1);
    }
    setSelectedRole([...selectedRoles]);
  }


  const onLocationSelectionChange = (event, location) => {
    const locations = [...locationList];
    locations.forEach(loc => {
      if(loc.label === location){
        loc.checked = event;
      }
    })
    setLocationList([...locations]);
    updateSelectedLocation(event, location);
  }

  const onRoleSelectionChange = (event, role) => {
    const roles = [...roleList];
    roles.forEach(loc => {
      if(loc.label === role){
        loc.checked = event;
      }
    })
    setRoleList([...roles]);
    updateSelectedRole(event, role);
  }

  const showAllLocation = () => {
    const locationPopup = {...showLocationPopup};
    locationPopup.show = true;
    locationPopup.location = [...selectedLocation];
    setShowlocationPopup({...locationPopup});
  }

  const showAllRole = () => {
    const rolePopup = {...showRolePopup};
    rolePopup.show = true;
    rolePopup.role = [...selectedRole];
    setShowRolePopup({...rolePopup});
  }

  const onLocationSelection = (event) => {
    setSelectedLocation([...event]);
    onCancelPopup();
  }

  const onRoleSelection = (event) => {
    setSelectedRole([...event]);
    onCancelPopup();
  }

  const onCancelPopup = () => {
    const locationPopup = {...showLocationPopup};
    locationPopup.show = false;
    const rolePopup = {...showRolePopup};
    rolePopup.show = false;
    setShowRolePopup({...rolePopup});
    setShowlocationPopup({...locationPopup});
  }

  const showFilter = () => {
    scrollToTop();
    setIsFilterShow(!isFilterShow);
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const goToLoginPage = () => {
    history.push('/can/login');
  };
  if (isLoadding) return <Loader />;
  return (
    <div id="public-job-list">
      <Navbar></Navbar>
      <div className="container-fluid job-list-container">
        <div className="container selected-filter-container container-md container-sm container-xs">
          <div className="row align-items-center no-gutters">
            {jobData.count && jobData.count.map((item, index) => {
              return (
                <div className="col-lg-2 col-xl-2 col-md-3 col-sm-4 col-xs-6 mb-2" key={index}>
                  <div className="filter-card-wrapper row no-gutters mx-2">
                    <div className="count-section col-12">{item.count}+ Jobs</div>
                    <div className="filter-name-section col-12 text-truncate">{item.name}</div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="d-lg-none d-xl-none d-md-none d-xs-block d-sm-block mx-2">
            <button type="button" className="btn btn-custom" onClick={showFilter}><TocIcon></TocIcon>Filters</button>
          </div>
        </div>
        <div className="container list-container container-md container-sm container-xs">
          {isFilterShow ? <CloseIcon className="close-icon" onClick={showFilter}></CloseIcon> : ''}
          <div className="row align-items-start">
            <div className={"col-xl-3 col-lg-4 col-md-4 d-xl-block d-lg-block d-md-block  p-0 filter-list-section" +  ( isFilterShow ? ' d-sm-block d-xs-block' : ' d-sm-none d-xs-none')}>
              <div className="row no-gutters px-2 filter-list-section-wrapper">
                <div className="col-12 section-title">Filters</div>
                <div className="col-12 row no-gutters">
                  <div className="col-12 category-title py-1">Location</div>
                  <div className="col-12 row no-gutters option-container">
                    {locationList.length && locationList.map((item, index) => {
                      if (index < 10) {
                        return (
                          <div className="custom-check col-12 d-flex" key={index}>
                            <CheckboxV2 value={item.checked}
                              classes="home-page-list-checkbox"
                              name={`location-filter-${index}`}
                              size={16}
                              change={(event) => onLocationSelectionChange(event, item.label)}>
                            </CheckboxV2>
                            <label className="home-page-list-checkbox pl-2" >{item.label}</label>
                          </div>
                        )
                      }
                    })}
                    {locationList.length > 10 ? (
                      <div className="custom-check col-12 d-flex view-more" onClick={showAllLocation}>
                        View More
                      </div>
                    ):""}
                  </div>
                </div>
                <div className="col-12 row no-gutters">
                  <div className="col-12 category-title py-1">Role</div>
                  <div className="col-12 row no-gutters option-container align-items-center">
                    {roleList.length && roleList.map((item, index) => {
                      if (index < 10) {
                        return (
                          <div className="custom-check col-12 d-flex" key={index}>
                            <CheckboxV2 value={item.checked}
                              classes="home-page-list-checkbox"
                              name={`role-filter-${index}`}
                              size={16}
                              change={(event) => {onRoleSelectionChange(event, item.label)}}>
                            </CheckboxV2>
                            <label className="home-page-list-checkbox pl-2" >{item.label}</label>
                          </div>
                        )
                      }
                    })}
                    {roleList.length > 10 ? (
                      <div className="custom-check col-12 d-flex view-more" onClick={showAllRole}>
                        View More
                      </div>
                    ):""}
                  </div>
                </div>
                <div className="col-12 row no-gutters">
                  <div className="col-12 category-title py-1">Experience (In Years)</div>
                  <MultiRangeSlider
                    min={0}
                    max={60}
                    step={1}
                    ruler={false}
                    baseClassName="multi-range-slider experience-range-slider"
                    minValue={minExp}
                    maxValue={maxExp}
                    onInput={(e) => {
                      setMinExp(e.minValue);
                      setMaxExp(e.maxValue);
                    }}></MultiRangeSlider>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12 col-xs-12 job-list-section row">
              {jobList && jobList.length > 0 && <div className="col-12 pagination-section">1 - {jobList.length} of {jobData.total}</div>}
              <div className="col-12 job-card-section">
                {jobList && jobList.length > 0 ? jobList.map((item, index) => {
                  return (
                    <div className="job-card" key={index}>
                      <div className="row no-gutters">
                        <div className="col-auto image-section px-3">
                          {item.logo ? (
                            <div className="round-shape">
                              {/* {item.logo === 'ortho' && <img className="round-image" src={ortho}></img>}
                              {item.logo === 'opthal' && <img className="round-image" src={opthal}></img>}
                              {item.logo === 'cardio' && <img className="round-image" src={cardio}></img>}
                              {item.logo === 'general_role' && <img className="round-image" src={general_role}></img>}
                              {item.logo === 'psycologist' && <img className="round-image" src={psycologist}></img>}
                              {item.logo === 'dental' && <img className="round-image" src={dental}></img>} */}

                              {item.logo === 'add_circle' && <img className="round-image" src={add_circle}></img>}
                              {item.logo === 'cardiology' && <img className="round-image" src={cardiology}></img>}
                              {item.logo === 'dentistry' && <img className="round-image" src={dentistry}></img>}
                              {item.logo === 'dermatology' && <img className="round-image" src={dermatology}></img>}
                              {item.logo === 'ecg' && <img className="round-image" src={ecg}></img>}
                              {item.logo === 'emergency' && <img className="round-image" src={emergency}></img>}
                              {item.logo === 'fertile' && <img className="round-image" src={fertile}></img>}
                              {item.logo === 'gastroenterology' && <img className="round-image" src={gastroenterology}></img>}
                              {item.logo === 'gynaecolog' && <img className="round-image" src={gynaecolog}></img>}
                              {item.logo === 'health_and_safety' && <img className="round-image" src={health_and_safety}></img>}
                              {item.logo === 'hematology' && <img className="round-image" src={hematology}></img>}
                              {item.logo === 'immunology' && <img className="round-image" src={immunology}></img>}
                              {item.logo === 'labs' && <img className="round-image" src={labs}></img>}
                              {item.logo === 'monitor_heart' && <img className="round-image" src={monitor_heart}></img>}
                              {item.logo === 'nephrology' && <img className="round-image" src={nephrology}></img>}
                              {item.logo === 'neurology' && <img className="round-image" src={neurology}></img>}
                              {item.logo === 'oncology' && <img className="round-image" src={oncology}></img>}
                              {item.logo === 'ophthalmology' && <img className="round-image" src={ophthalmology}></img>}
                              {item.logo === 'orthopedics' && <img className="round-image" src={orthopedics}></img>}
                              {item.logo === 'pediatrics' && <img className="round-image" src={pediatrics}></img>}
                              {item.logo === 'physical_therapy' && <img className="round-image" src={physical_therapy}></img>}
                              {item.logo === 'pulmonology' && <img className="round-image" src={pulmonology}></img>}
                              {item.logo === 'radiology' && <img className="round-image" src={radiology}></img>}
                              {item.logo === 'rheumatology' && <img className="round-image" src={rheumatology}></img>}
                              {item.logo === 'rib_cage' && <img className="round-image" src={rib_cage}></img>}
                              {item.logo === 'stethoscope' && <img className="round-image" src={stethoscope}></img>}
                              {item.logo === 'urology' && <img className="round-image" src={urology}></img>}
                              {item.logo === 'ward' && <img className="round-image" src={ward}></img>}
                              {item.logo === 'surgical' && <img className="round-image" src={surgical}></img>}
                              {item.logo === 'medication' && <img className="round-image" src={medication}></img>}

                            </div>

                          ) : (
                            <div className="user-no-image">
                              <div className="margin-auto">No Image</div>
                            </div>
                          )}
                        </div>
                        <div className="col content-section row no-gutters">
                          <div className="col-12 job-card-header">{item.title}</div>
                          <div className="col-12 row no-gutters py-1">
                            <div className="col-6 col-xs-12 text-truncate"><b>Category :</b> {item.category}</div>
                            <div className="col-6 col-xs-12 text-truncate job-card-location"><b>Location :</b> {item?.jobLocation?.city || "NA"}</div>
                          </div>
                          <div className="col-12 row no-gutters py-1">
                            <div className="col-6 col-xs-12"><b>Experience:</b> {item.minimumExperience || "NA"}-{item.maximumExperience || 'NA'}</div>
                            <div className="col-6 col-xs-12 text-truncate"><b>Role:</b> {item?.role || "NA"}</div>
                          </div>
                          <div className="col-12 job-card-description py-1">{HtmlParser(item.description)}</div>
                          <div className="col-12 text-right">
                            <button className="contact-btn" onClick={goToLoginPage}>View Details</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }): (
                  <div className="no-result-section d-flex flex-wrap">
                      
                      <div className="err-text-section text-center">
                        <NewReleasesIcon></NewReleasesIcon>
                        <div className="err-text">No records present for the selected filter, Please change the filter to see records.</div>
                      </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SelectLocation data={showLocationPopup} onConfirmAction={onLocationSelection} onCancel={onCancelPopup} ></SelectLocation>
      <SelectRole data={showRolePopup} onConfirmAction={onRoleSelection} onCancel={onCancelPopup} ></SelectRole>
    </div>
  );
};

export default SearchPublicJob;
