import React, { useState, useEffect } from "react";
import Navbar from "./common/Navbar";
import Sidebar from "./common/Sidebar";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  matchPath,
} from "react-router-dom";
import { withRouter } from "react-router";
import { AuthContext, useAuth } from "./component/AuthContext";
import AuthService from "./service/AuthService";
import LocalStorageService from "./service/LocalstorageService";
import { PublicRouteList, PrivateRouteList } from "./service/ComponentList";
import PublicRoutes from "./component/PublicRoutes";
import PrivateRoute from "./component/PrivateRoute";
import Loader from "./component/Loader";

const LocalService = LocalStorageService.getService();

const ShowNavSide = withRouter(({ match, location, history }) => {
  const isPublicRoute = () => {
    let result = false;

    PublicRouteList.forEach((route) => {
      result =
        result || matchPath(location.pathname, { ...route, exact: true });
    });
    return result?.path ? true : false;
  };

  return (
    <>
      {isPublicRoute() ? (
        ""
      ) : (
        <>
          <Navbar />
          <Sidebar />
        </>
      )}
    </>
  );
});

const App = (props) => {
  const [authData, setAuthData] = useState("");
  const [isLoading, setLoading] = useState(true);

  const setData = (data) => {
    LocalService.setData(data);
    setAuthData(data);
  };

  useEffect(() => {
    validateAccessToken();
  }, []);

  useEffect(() => {
    if (authData) {
      setLoading(false);
    }
  }, [authData]);

  const validateAccessToken = async () => {
    try {
      const response = await AuthService.validateAccessToken();
      setAuthData(response.data.result);
      LocalService.setData(response.data.result);
    } catch (err) {
      setAuthData({});
      LocalService.clearToken();
      <Redirect to={{ pathname: "/home" }}></Redirect>;
    }
  };
  if (isLoading) {
    return <Loader></Loader>;
  }

  return (
    <AuthContext.Provider value={{ data: authData, updateAuthData: setData }}>
      <Router>
        <div className="container-fluid p-0 m-0 app">
          {authData.token &&
          (authData.userType === "Owner" ||
            authData.userType === "Staff" ||
            authData.userType === "Client" ||
            authData.userType === "Candidate") ? (
            <ShowNavSide></ShowNavSide>
          ) : (
            ""
          )}
          <Switch>
            {PublicRouteList.map((route, index) => {
              return (
                <PublicRoutes
                  exact
                  path={route.path}
                  component={route.component}
                  key={index}
                />
              );
            })}
            {PrivateRouteList["Owner"].map((route, index) => {
              return (
                <PrivateRoute
                  exact
                  path={route.path}
                  component={route.component}
                  key={index}
                  routeData={route}
                />
              );
            })}
            {PrivateRouteList["Staff"].map((route, index) => {
              return (
                <PrivateRoute
                  exact
                  path={route.path}
                  component={route.component}
                  key={index}
                  routeData={route}
                />
              );
            })}
            {PrivateRouteList["Client"].map((route, index) => {
              return (
                <PrivateRoute
                  exact
                  path={route.path}
                  component={route.component}
                  key={index}
                  routeData={route}
                />
              );
            })}
            {PrivateRouteList["Candidate"].map((route, index) => {
              return (
                <PrivateRoute
                  exact
                  path={route.path}
                  component={route.component}
                  key={index}
                  routeData={route}
                />
              );
            })}
            <Redirect to="/pagenotfound"></Redirect>
          </Switch>
        </div>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
