import React, { useState, useEffect } from "react";
import DefaultLogo from "../../../assets/imgs/new_logo-transparent.png"
import WhiteLogo from "../../../assets/imgs/white_logo.png";
import { Link } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';

const Navbar = (props) => {
  const { currentState, theme = 'default', onTabChange, isTabHidden } = props;
  const [currentTab, setCurrentTab] = useState(currentState);
  const currentTabNew = currentState;
  const [visible, setVisible] = useState(false);

  const changeTab = (e) => {
    const tab = e.currentTarget.getAttribute("role");
    setCurrentTab(tab);
    if (onTabChange) {
      onTabChange(tab);
    }
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY

    if (currentScrollPos > 100) {
      if (!visible) {
        setVisible(true)
      }
    } else {
      setVisible(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  })

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light bg-light ${visible ? 'active-navbar' : ''}`}
      id="home-navbar"
    >
      <a className="navbar-brand p-0" href="/home">
        {theme === 'light' && !visible && <img className="flipInX" src={WhiteLogo} height="100" />}
        {(theme === 'default' || visible) && <img className="flipInX" src={DefaultLogo} height="100" />}
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navToggler"
        aria-controls="navToggler"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navToggler">

        <CloseIcon className="close-icon"
          data-toggle="collapse"
          data-target="#navToggler"
          aria-controls="navToggler">
        </CloseIcon>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item collapse-logo-container">
            <img className="collapse-logo" src={DefaultLogo} height="100" />
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about">
              About Us
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/equipments">
              Equipments
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact">
              Contact Us
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/plans">
              Plans
            </Link>
          </li>
          {!isTabHidden && (
            <li className="nav-item">
              <div className="row no-gutters align-items-center h-100">
                <Link
                  type="button"
                  className="col-lg-auto col-xl-auto col-md-12 col-sm-12 col-xs-12 login-btn  py-1 px-3"
                  to={`/${currentTabNew === "employer" ? 'cli' : 'can'}/login`}
                >
                  Login
                </Link>
                <Link
                  type="button"
                  className="col-lg-auto col-xl-auto col-md-12 col-sm-12 col-xs-12  signup-btn py-1 px-3"
                  to={`/${currentTabNew === "employer" ? 'contact' : 'can/register'}`}
                >
                  Signup
                </Link>
              </div>
            </li>
          )}
          <li className="nav-item">
            <div className="btn-group row no-gutter align-items-center select-dropdown">
              <button className="btn btn- btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {currentTabNew === "employer" ? "Employer" : "Job Seeker"}
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <div className="dropdown-item" role="employee" onClick={changeTab}>
                  <Link
                    to={{
                      pathname: '/home',
                      state: { type: 'employee' }
                    }}>Job Seeker</Link>
                </div>
                <div className="dropdown-item" role="employer" onClick={changeTab}>
                  <Link
                    to={{
                      pathname: '/home',
                      state: { type: 'employer' }
                    }}>Employer</Link>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
