import React, { useState, useEffect } from "react";
import UseForm from "../../service/FormService";
import ManageService from "../../service/ManageService";
import $ from "jquery";
import { ErrorToaster } from "../../component/modal/ErrorModal";
import Autocomplete from "react-select";

const stateSchema = {
  stateId: { value: "", error: "" },
  name: { value: "", error: "" },
};

const validationStateSchema = {
  stateId: {
    required: true,
  },
  name: {
    required: true,
  },
};

const AddCity = (props) => {
  const [visible, setVisible] = useState(props.data.show || false);
  const [intialSchema, setInitionalSchema] = useState(
    $.extend(true, {}, stateSchema)
  );
  const [dropdownList, setDropDownList] = useState([]);
  const [selectedList, setSelectedList] = useState("");
  const [listLoading, setListLoading] = useState(false);
  const [ListData, setListData] = useState({});
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });

  useEffect(() => {
    setVisible(props.data.show);
    if (!props.data.show) {
      setInitionalSchema($.extend(true, {}, stateSchema));
      setErrorObj({ show: false, msg: "" });
      setDropDownList([]);
      setSelectedList("");
      setListData({});
    } else {
      setListLoading(true);
      getStateList();
      setListLoading(false);
      if (props.data.city && props.data.city.id) {
        setSelectedList({
          id: props.data.city.state.id,
          name: props.data.city.state.name,
        });
        let schema = $.extend(true, {}, stateSchema);
        schema.name.value = props.data.city.name;
        schema.stateId.value = props.data.city.state.id;
        setInitionalSchema(schema);
      }
    }
  }, [props]);

  const getStateList = async (param, url) => {
    const response = await ManageService.getStateList(param, url);
    if (response.status === 200) {
      if (url) {
        let list = [];
        list = list.concat(dropdownList, response.data.result.data);
        response.data.result.data = list;
      }
      setDropDownList(response.data.result.data);
      setListData(response.data);
    }
  };

  const customFilter = (option, inputValue) => {
        if(option.data.name && inputValue){
            return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        }
        return true;
    };

  const acChange = (item) => {
    item && item.id ? setSelectedList(item) : setSelectedList({});
    handleOnChange({
      target: { name: "stateId", value: item && item.id ? item.id : "" },
    });
  };

  const paginateACList = (e) => {
    if (ListData.detail.links && ListData.detail.links.next) {
      setListLoading(true);
      getStateList("", ListData.detail.links.next);
      setListLoading(false);
    }
  };

  const onACInputChange = (param, actionType, list) => {
    if (actionType.action && actionType.action === "input-change") {
      param ? getStateList(param) : getStateList();
    } else if (actionType.action === "menu-close" && !selectedList.id)
      getStateList();
  };

  const hideModal = () => {
    setVisible(false);
    props.onCancel();
  };

  const closeToast = (e) => {
    e.preventDefault();
    setErrorObj({ show: false, msg: "" });
  };

  const onSubmitForm = (state) => {
    const requestPayload = {
      name: state.name.value,
      stateId: state.stateId.value,
    };
    if (props.data.city && props.data.city.id) {
      ManageService.updateCity(props.data.city.id, requestPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            props.onConfirmAction(res.data.result);
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    } else {
      ManageService.createCity(requestPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            props.onConfirmAction(res.data.result);
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    }
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    intialSchema,
    validationStateSchema,
    onSubmitForm
  );

  return (
    <div
      className="overlay "
      id="city-model"
      style={{ display: visible ? "block" : "none" }}
    >
      <div className="overlay-content">
        <div className={visible ? " cs-modal showModal" : "cs-modal hideModal"}>
          <div className="header">
            {props.data.city && props.data.city.id ? "Update City" : "Add City"}{" "}
          </div>
          <div className="body">
            <div className="city-container">
              <form>
                <div className="row">
                  <div className="form-group col-12 m-0">
                    <label className="label-sm">
                      State<sup className="required">*</sup>
                    </label>
                    <Autocomplete
                      name="state"
                      placeholder="Select State"
                      defaultOptions
                      isClearable={true}
                      isDisabled={
                        props.data.city && props.data.city.id ? true : false
                      }
                      options={dropdownList}
                      value={selectedList.id ? selectedList : null}
                      filterOption={customFilter}
                      isLoading={listLoading}
                      getOptionLabel={(option) => {
                        return (
                          <div className="autocomplete-list">{option.name}</div>
                        );
                      }}
                      getOptionValue={(option) => {
                        return (
                          <div className="autocomplete-list">{option.id}</div>
                        );
                      }}
                      onChange={acChange}
                      onMenuScrollToBottom={paginateACList}
                      onInputChange={onACInputChange}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.stateId.error ? "visible" : "hidden",
                      }}
                    >
                      {state.stateId.error}
                    </small>
                  </div>
                  <div className="form-group col-12 m-0">
                    <label className="label-sm">
                      City Name <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      onChange={handleOnChange}
                      value={state.name.value}
                      placeholder="For Multiple Cities, Add Comma Seperated"
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.name.error ? "visible" : "hidden",
                      }}
                    >
                      {state.name.error}
                    </small>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="footer">
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary mr-3"
              onClick={hideModal}
            >
              cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary"
              disabled={disable}
              onClick={handleOnSubmit}
            >
              Procced
            </button>
          </div>
        </div>
      </div>
      <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
    </div>
  );
};

export default AddCity;
