import React, { useState, useEffect } from "react";
import UseForm from "../../service/FormService";
import ManageService from "../../service/ManageService";
import UploadService from "../../service/CandidateService";
import $ from "jquery";
import { ErrorToaster } from "../../component/modal/ErrorModal";

const stateSchema = {
  name: { value: "", error: "" },
  description: { value: "", error: "" },
  category: { value: "", error: "" },
  subCategory: { value: "", error: "" },
  image: { value: "", error: "" },
};

const validationStateSchema = {
  description: {
    required: true,
  },
  name: {
    required: true,
  },
  category: {
    required: true,
  },
  subCategory: {
    required: true,
  },
  image: {
    required: false,
  },
};

const AddEquipment = (props) => {
  const [visible, setVisible] = useState(props.data.show || false);
  const [intialSchema, setInitionalSchema] = useState(
    $.extend(true, {}, stateSchema)
  );
  const [imageLoading, setImageLoading] = useState(false);
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });

  useEffect(() => {
    setVisible(props.data.show);
    if (!props.data.show) {
      setInitionalSchema($.extend(true, {}, stateSchema));
      setErrorObj({ show: false, msg: "" });
    } else {
      if (props.data.equipment && props.data.equipment.id) {
        let schema = $.extend(true, {}, stateSchema);
        schema.name.value = props.data.equipment.name;
        schema.description.value = props.data.equipment.description;
        schema.category.value = props.data.equipment.category;
        schema.subCategory.value = props.data.equipment.subCategory;
        schema.image.value = props.data.equipment.image;
        setInitionalSchema(schema);
      }
    }
  }, [props]);

  const hideModal = () => {
    setVisible(false);
    props.onCancel();
  };
  const closeToast = (e) => {
    e.preventDefault();
    setErrorObj({ show: false, msg: "" });
  };

  const onFileChange = (e) => {
    setImageLoading(true);
    e.persist();
    e.preventDefault();
    let formData = new FormData();
    formData.append("files", e.target.files[0]);
    formData.append("mediaType", "image");
    UploadService.upload(formData).then(
      (res) => {
        setImageLoading(false);
        handleOnChange({
          target: { name: "image", value: res.data.result[0] },
        });
      },
      (error) => {
        console.log(error);
        setImageLoading(false);
      }
    );
  };

  const onSubmitForm = (state) => {
    const requestPayload = {
      name: state.name.value,
      description: state.description.value,
      category: state.category.value,
      subCategory: state.subCategory.value,
      image: state.image.value.id,
    };

    if (props.data.equipment && props.data.equipment.id) {
      ManageService.updateEquipment(
        props.data.equipment.id,
        requestPayload
      ).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            props.onConfirmAction(res.data.result);
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    } else {
      ManageService.createEquipment(requestPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            props.onConfirmAction(res.data.result);
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    }
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    intialSchema,
    validationStateSchema,
    onSubmitForm
  );

  return (
    <div
      className="overlay "
      id="equipment-model"
      style={{ display: visible ? "block" : "none" }}
    >
      <div className="overlay-content">
        <div className={visible ? " cs-modal showModal" : "cs-modal hideModal"}>
          <div className="header">
            {props.data.equipment && props.data.equipment.id
              ? "Update Equipment"
              : "Add Equipment"}
          </div>
          <div className="body">
            <div className="equipment-container">
              <form name="add-equipment">
                <div className="row">
                  <div className="form-group col-12 m-0">
                    <label className="label-sm">
                      Name <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      onChange={handleOnChange}
                      value={state.name.value}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.name.error ? "visible" : "hidden",
                      }}
                    >
                      {state.name.error}
                    </small>
                  </div>
                  <div className="form-group col-12 m-0">
                    <label className="label-sm">
                      Category <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="category"
                      id="category"
                      onChange={handleOnChange}
                      value={state.category.value}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.category.error ? "visible" : "hidden",
                      }}
                    >
                      {state.category.error}
                    </small>
                  </div>
                  <div className="form-group col-12 m-0">
                    <label className="label-sm">
                      Sub Category <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="subCategory"
                      id="subCategory"
                      onChange={handleOnChange}
                      value={state.subCategory.value}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.subCategory.error
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      {state.subCategory.error}
                    </small>
                  </div>
                  <div className="form-group m-0 col-6">
                    <label className="label-sm w-100">Profile Pic</label>
                    <div className="row no-gutters align-items-center">
                      <div className="fileUpload btn btn-secondary cs-upload-1 m-0 col-auto">
                        {imageLoading ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <span>Browse</span>
                        )}
                        <input
                          type="file"
                          className="upload"
                          name="image"
                          id="image"
                          onChange={onFileChange}
                          accept="image/*"
                          disabled={imageLoading}
                        />
                      </div>
                      <div className="col file-name">
                        {state.image.value && state.image.value.id ? (
                          <img src={state.image.value.href} height="80"></img>
                        ) : (
                          ""
                        )}
                        <div>
                          {state.image.value
                            ? state.image.value.originalName
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-12 m-0">
                    <label className="label-sm">
                      Description <sup className="required">*</sup>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="description"
                      id="description"
                      onChange={handleOnChange}
                      value={state.description.value}
                    ></textarea>
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.description.error
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      {state.description.error}
                    </small>
                  </div>
                </div>
              </form>
            </div>
            <div className="footer">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary mr-3"
                onClick={hideModal}
              >
                cancel
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-primary"
                disabled={disable}
                onClick={handleOnSubmit}
              >
                Procced
              </button>
            </div>
          </div>
        </div>
      </div>
      <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
    </div>
  );
};

export default AddEquipment;
