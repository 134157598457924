import AdminDashboard from "../pages/AdminDashboard/Main";
import StaffDashboard from "../pages/StaffDashboard";
import ClientDashboard from "../pages/ClientDashboard";
import CandidateDashboard from "../pages/CandidateDashboard";
import CandidateJobsList from "../pages/CanJobs/Main";
import CandidateJobsView from "../pages/CanJobs/View";
import JobPostManagement from "../pages/JobPostManagement/Main";
import JobPostView from "../pages/JobPostManagement/View";
import JobPostCreate from "../pages/JobPostManagement/Create";
import JobPostEdit from "../pages/JobPostManagement/Edit";
import JobPostCandView from "../pages/JobPostManagement/Candidates/View";
import Client from "../pages/client/Main";
import News from "../pages/news/Main";
import Jobs from "../pages/jobs/Main";
import Settings from "../pages/settings";
import AdminLogin from "../pages/Auth/Admin/Login";
import StaffLogin from "../pages/Auth/Staff/Login";
import ClientLogin from "../pages/Auth/Client/Login";
import CandidateLogin from "../pages/Auth/Candidate/Login";
import Signup from "../pages/Auth/Candidate/Signup";
import ForgotPassword from "../pages/Auth/Candidate/ForgotPassword";
import ActivateAccount from "../pages/Auth/Candidate/ActivateAccount";
import SetNewPassword from "../pages/Auth/Candidate/SetNewPassword";
import Register from "../pages/Register";
import ResetPassword from "../pages/Resetpassword";
import Home from "../pages/Home/Main";
import Staff from "../pages/staff/Main";
import StaffCreate from "../pages/staff/Create";
import StaffView from "../pages/staff/View";
import StaffEdit from "../pages/staff/Edit";
import ClientCreate from "../pages/client/Create";
import ClientEdit from "../pages/client/Edit";
import ClientView from "../pages/client/View";
import ClientDetails from "../pages/client/Details";
import JobsCreate from "../pages/jobs/Create";
import JobsView from "../pages/jobs/View";
import JobsEdit from "../pages/jobs/Edit";
import Manage from "../pages/manage/main";
import NewsCreate from "../pages/news/Create";
import NewsView from "../pages/news/View";
import NewsEdit from "../pages/news/Edit";
import Candidate from "../pages/candidate/Main";
import CandidateCreate from "../pages/candidate/Create";
import CandidateView from "../pages/candidate/View";
import CandidateUpdate from "../pages/candidate/Update";
import SearchCandidate from "../pages/SearchCandidate/Main";
import SearchCandidateView from "../pages/SearchCandidate/View";
import Unauthorised from "../pages/UnAuthorised";
import Nomatch from "../pages/Nomatch";
import CandidateViewProfile from "../pages/CandidateProfile/View";
import PublicNewsDetails from "../pages/Home/PublicNewsDetails";
import SearchPublicJob from "../pages/Home/Search/SearchPublicJob";
import SearchPublicCandidate from "../pages/Home/Search/SearchPublicCandidate";
import ContactUs from "../pages/Home/Contact/Main";
import AboutUs from "../pages/Home/About/Main";
import Equipment from "../pages/Home/Equipment/Main";
import Plans from "../pages/Home/Plans/Main";
import TermsConnditions from "../pages/Home/TermsCondition";

export const PublicRouteList = [
  {
    name: "Admin Login",
    component: AdminLogin,
    path: "/adm/login",
  },
  {
    name: "Staff Login",
    component: StaffLogin,
    path: "/stf/login",
  },
  {
    name: "Client Login",
    component: ClientLogin,
    path: "/cli/login",
  },
  {
    name: "Candidate Login",
    component: CandidateLogin,
    path: "/can/login",
  },
  {
    name: "Register Candidate",
    component: Signup,
    path: "/can/register",
  },
  {
    name: "Candidate Forgot Password",
    component: ForgotPassword,
    path: "/can/forgot-password",
  },
  {
    name: "client Forgot Password",
    component: ForgotPassword,
    path: "/cli/forgot-password",
  },
  {
    name: "Staff Forgot Password",
    component: ForgotPassword,
    path: "/stf/forgot-password",
  },
  {
    name: "Admin Forgot Password",
    component: ForgotPassword,
    path: "/adm/forgot-password",
  },
  {
    name: "Activate Account",
    component: ActivateAccount,
    path: "/activate-account/:code",
  },
  {
    name: "Set New Account Password",
    component: SetNewPassword,
    path: "/set-password/:code",
  },
  {
    name: "Home",
    component: Home,
    path: "/home",
  },
  {
    name: "Public News",
    component: PublicNewsDetails,
    path: "/news/:type/:id",
  },
  {
    name: "Public Candidate Search",
    component: SearchPublicCandidate,
    path: "/candidates/public/list",
  },
  {
    name: "Public Job Search",
    component: SearchPublicJob,
    path: "/jobs/public/list",
  },
  {
    name: "Contact Us",
    component: ContactUs,
    path: "/contact",
  },
  {
    name: "About Us",
    component: AboutUs,
    path: "/about",
  },
  {
    name: "Equipments",
    component: Equipment,
    path: "/equipments",
  },
  {
    name: "Plans",
    component: Plans,
    path: "/plans",
  },
  {
    name: "Home",
    component: Home,
    path: "/",
  },
  {
    name: "TermsAndConditions",
    component: TermsConnditions,
    path: "/termsconditions",
  },
  {
    name: "Unauthorised",
    component: Unauthorised,
    path: "/unauthorised",
  },
  {
    name: "PageNotFound",
    component: Nomatch,
    path: "/pagenotfound",
  },
];

const commonRoute = [
  {
    parent: "Job Post Management",
    name: "read",
    component: JobPostManagement,
    path: "/:userType/job-post",
    checkAccess: true,
  },
  {
    parent: "Job Post Management",
    name: "read",
    component: JobPostView,
    path: "/:userType/job-post/view/:id",
    checkAccess: true,
  },
  {
    parent: "Job Post Management",
    name: "write",
    component: JobPostCreate,
    path: "/:userType/job-post/add",
    checkAccess: true,
  },
  {
    parent: "Job Post Management",
    name: "update",
    component: JobPostEdit,
    path: "/:userType/job-post/edit/:id",
    checkAccess: true,
  },
  {
    parent: "Job Post Management",
    name: "read",
    component: JobPostCandView,
    path: "/:userType/job-post/view/candidate/view/:id",
    checkAccess: true,
  },
  {
    parent: "Staff Management",
    name: "update",
    component: StaffEdit,
    path: "/:userType/staff/edit/:id",
    checkAccess: true,
  },
  {
    parent: "Staff Management",
    name: "read",
    component: StaffView,
    path: "/:userType/staff/view/:id",
    checkAccess: true,
  },
  {
    parent: "Staff Management",
    name: "write",
    component: StaffCreate,
    path: "/:userType/staff/add",
    checkAccess: true,
  },
  {
    parent: "Staff Management",
    name: "read",
    component: Staff,
    path: "/:userType/staff",
    checkAccess: true,
  },
  {
    parent: "Client Management",
    name: "read",
    component: ClientView,
    path: "/:userType/client/view/:id",
    checkAccess: true,
  },
  {
    parent: "Client Management",
    name: "update",
    component: ClientEdit,
    path: "/:userType/client/edit/:id",
    checkAccess: true,
  },
  {
    parent: "Client Management",
    name: "write",
    component: ClientCreate,
    path: "/:userType/client/add",
    checkAccess: true,
  },
  {
    parent: "Client Management",
    name: "read",
    component: Client,
    path: "/:userType/client",
    checkAccess: true,
  },
  {
    parent: "Client Management",
    name: "read",
    component: ClientDetails,
    path: "/:userType/client/details/:id",
    checkAccess: true,
  },
  {
    parent: "News Management",
    name: "read",
    component: NewsView,
    path: "/:userType/news/view/:id",
    checkAccess: true,
  },
  {
    parent: "News Management",
    name: "write",
    component: NewsCreate,
    path: "/:userType/news/add",
    checkAccess: true,
  },
  {
    parent: "News Management",
    name: "update",
    component: NewsEdit,
    path: "/:userType/news/edit/:id",
    checkAccess: true,
  },
  {
    parent: "News Management",
    name: "read",
    component: News,
    path: "/:userType/news",
    checkAccess: true,
  },
  {
    parent: "Hot Jobs",
    name: "read",
    component: JobsView,
    path: "/:userType/jobs/view/:id",
    checkAccess: true,
  },
  {
    parent: "Hot Jobs",
    name: "update",
    component: JobsEdit,
    path: "/:userType/jobs/edit/:id",
    checkAccess: true,
  },
  {
    parent: "Hot Jobs",
    name: "write",
    component: JobsCreate,
    path: "/:userType/jobs/add",
    checkAccess: true,
  },
  {
    parent: "Hot Jobs",
    name: "read",
    component: Jobs,
    path: "/:userType/jobs",
    checkAccess: true,
  },
  {
    parent: "Role Management",
    name: "read",
    component: Manage,
    path: "/:userType/manage",
    checkAccess: true,
  },
  {
    parent: "Candidate Management",
    name: "read",
    component: CandidateView,
    path: "/:userType/candidate/view/:id",
    checkAccess: true,
  },
  {
    parent: "Candidate Management",
    name: "update",
    component: CandidateUpdate,
    path: "/:userType/candidate/update/:id",
    checkAccess: true,
  },
  {
    parent: "Candidate Management",
    name: "write",
    component: CandidateCreate,
    path: "/:userType/candidate/add",
    checkAccess: true,
  },
  {
    parent: "Candidate Management",
    name: "read",
    component: Candidate,
    path: "/:userType/candidate",
    checkAccess: true,
  },
  {
    parent: "Search Management",
    name: "read",
    component: SearchCandidate,
    path: "/:userType/search-candidate",
    checkAccess: true,
  },
  {
    parent: "Search Management",
    name: "read",
    component: SearchCandidateView,
    path: "/:userType/search-candidate/view/:id",
    checkAccess: true,
  },
  {
    parent: "Candidate Management",
    name: "read",
    component: CandidateViewProfile,
    path: "/:userType/profile-view/:id",
    checkAccess: true,
  },
  {
    parent: "Hot Jobs",
    name: "read",
    component: CandidateJobsList,
    path: "/can/cand-jobs",
    checkAccess: true,
  },
  {
    parent: "Hot Jobs",
    name: "read",
    component: CandidateJobsView,
    path: "/can/cand-jobs/view/:id",
    checkAccess: true,
  },
];

export const PrivateRouteList = {
  Owner: [
    {
      parent: "Generic App",
      name: "Dashboard",
      component: AdminDashboard,
      path: "/adm/dashboard",
      checkAccess: false,
    },
    {
      parent: "Generic App",
      name: "Setting",
      component: Settings,
      path: "/adm/settings",
      checkAccess: false,
    },
  ].concat(commonRoute),
  Staff: [
    {
      parent: "Generic App",
      name: "Dashboard",
      component: StaffDashboard,
      path: "/stf/dashboard",
      checkAccess: false,
    },
    {
      parent: "Generic App",
      name: "Setting",
      component: Settings,
      path: "/stf/settings",
      checkAccess: false,
    },
  ].concat(commonRoute),
  Client: [
    {
      parent: "Generic App",
      name: "Client Dashboard",
      component: ClientDashboard,
      path: "/cli/dashboard",
      checkAccess: false,
    },
    {
      parent: "Generic App",
      name: "Setting",
      component: Settings,
      path: "/cli/settings",
      checkAccess: false,
    },
  ].concat(commonRoute),
  Candidate: [
    {
      parent: "Generic App",
      name: "Candidate Dashboard",
      component: CandidateDashboard,
      path: "/can/dashboard",
      checkAccess: false,
    },
    {
      parent: "Generic App",
      name: "Setting",
      component: Settings,
      path: "/can/settings",
      checkAccess: false,
    },
  ].concat(commonRoute),
};

const components = [];
export default components;
