import React, { useState, useEffect } from 'react';
import ActionListIcon from '@material-ui/icons/MoreVert';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import Checkbox from '../../component/Checkbox';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import LocalService from '../../service/LocalstorageService';
import TableComponent from '../../common/tableComponent';
import DownloadProfile from './DownloadProfile';
import Subscription from './Subscription';
import Activity from './Activity';
import ClientJobs from './Jobs';

export const ClientDetails = (props) => {
    const { userType } = useParams();
    const [tab, setTab] = useState("downloaded_profile");

    const onTabChange = (e) => {
        e.persist();
        setTab(e.currentTarget.dataset.tabname);
    };

    return (
        <div >
            {/* <div className="row cs-breadcrumb no-gutters">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <Link to={`/${userType}/staff`}>Staff</Link>
                    </li>
                    <li className="breadcrumb-item">{staffData.firstName ? `${staffData.firstName} ${staffData.lastName} ` : ''}</li>
                </ol>
            </div> */}
            <div className="content-wrapper m-0 p-0" id="client-details-section">
                <nav className="cs-navbar">
                    <div className="cs-nav-content" id="manage-section">
                        <div className="cs-navbar-nav ">
                            <button
                                type="button"
                                className={`btn btn-secondary btn-detail-header ${"downloaded_profile" === tab ? "active" : ""
                                    }`}
                                data-tabname="downloaded_profile"
                                onClick={onTabChange}
                            >
                                Viewed Profile History
                            </button>
                            <button
                                type="button"
                                className={`btn btn-secondary btn-detail-header ${"subscription" === tab ? "active" : ""
                                    }`}
                                data-tabname="subscription"
                                onClick={onTabChange}
                            >
                                Subscription History
                            </button>
                            <button
                                type="button"
                                className={`btn btn-secondary btn-detail-header ${"activity" === tab ? "active" : ""
                                    }`}
                                data-tabname="activity"
                                onClick={onTabChange}
                            >
                                User Activity History
                            </button>
                            <button
                                type="button"
                                className={`btn btn-secondary btn-detail-header ${"jobs" === tab ? "active" : ""
                                    }`}
                                data-tabname="jobs"
                                onClick={onTabChange}
                            >
                                Jobs Posting History
                            </button>
                        </div>
                    </div>
                </nav>

                <div className='client-details-content'>
                    {"downloaded_profile" === tab && <div className='download-profile-section'><DownloadProfile></DownloadProfile></div>}
                    {"subscription" === tab && <div className='download-profile-section'><Subscription></Subscription></div>}
                    {"activity" === tab && <div className='download-profile-section'><Activity></Activity></div>}
                    {"jobs" === tab && <div className='download-profile-section'><ClientJobs></ClientJobs></div>}
                </div>


            </div>
        </div>
    )
}

export default ClientDetails;