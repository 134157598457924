import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Validation from "../../../service/ValidationService";
import AuthService from "../../../service/AuthService";
import LocalStorageService from "../../../service/LocalstorageService";
import { ErrorToaster } from "../../../component/modal/ErrorModal";
import LoginImage from "../../../../assets/imgs/login3.png";
import { AuthContext } from "../../../component/AuthContext";
import whiteLogo from "../../../../assets/imgs/logo-white-transparent-hight.png";
import DefaultLogo from "../../../../assets/imgs/new_logo-transparent.png";
import HomeIcon from '@material-ui/icons/Home';

const LocalService = LocalStorageService.getService();
const ValidationService = Validation.getService();

const validationSchema = {
  username: { dname: "Username", type: "email", required: true },
  password: { dname: "Password", min: 6, type: "string", required: true },
};

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setusernameError] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });
  const history = useHistory();
  const context = useContext(AuthContext);

  useEffect(() => {
    const isUserLogin = LocalService.getAccessToken();
    isUserLogin
      ? history.push(`/${LocalService.getRoutePrefix()}/dashboard`)
      : "";
  }, []);

  const onBlur = (e) => {
    e.persist();
    e.preventDefault();
    const { name, value } = e.target;
    const validation = ValidationService.checkValidField(
      name,
      value,
      validationSchema
    );

    if (name === "username") {
      setUsername(validation.valid ? value : "");
      setusernameError(validation.msg || "");
    } else {
      setPassword(validation.valid ? value : "");
      setpasswordError(validation.msg || "");
    }
  };
  const closeToast = (e) => {
    e.preventDefault();
    setErrorObj({ show: false, msg: "" });
  };

  const onSubmit = async (e) => {
    e.persist();
    e.preventDefault();
    const error = usernameError || passwordError ? true : false;
    if (!error) {
      setLoading(true);
      try {
        const response = await AuthService.login({
          email: username,
          password: password,
          userType: "admin"
        });
        if (response.status === 200) {
          LocalService.setData(response.data.result);
          context.updateAuthData(response.data.result);
          setLoading(false);
          history.push(`/${LocalService.getRoutePrefix()}/dashboard`);
        }
      } catch (err) {
        console.log("Error", err);
        setLoading(false);
        setErrorObj({ show: true, msg: err.data.message });
      }
    }
  };

  return (
    <div className="container-fluid p-0 m-0">
      <Link to="/home" className="goToHome d-md-none d-sm-none d-xs-none d-lg-block d-xl-block">
        <HomeIcon />
        <span>Home</span>
      </Link>
      <div className="form-wrapper login-page-container">
        <div className="row vh-100 w-100 no-gutters">
          <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
            <img src={LoginImage} className="login-bg-img"></img>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 login-form-container">
            <div className="container vh-100 w-100">
              <div className="row align-items-center vh-100 w-100 login-form-section">
                <Link to="/home" className="goToHome d-md-block d-sm-block d-xs-block d-lg-none d-xl-none">
                  <HomeIcon />
                  <span>Home</span>
                </Link>
                <div className="col-md-2 col-lg-2 col-xl-2 d-sm-none d-xs-none d-md-block d-lg-block d-xl-block"></div>
                <div className="col-md-8 col-lg-8 col-xl-8 col-sm-12 col-xs-12">
                  <div className="logo-section text-center d-flex justify-content-center mb-4">
                    <img src={whiteLogo} height={"80px"} className="d-md-flex d-sm-flex d-xs-flex d-lg-none d-xl-none" />
                    <img src={DefaultLogo} height={"80px"} className="d-md-none d-sm-none d-xs-none d-lg-block d-xl-block" />
                  </div>
                  <div className="form-header text-center">
                    <span className="text-msg ">Admin Sign In</span>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <label className="label-sm">User name/Email ID</label>
                      <input
                        type="text"
                        className="form-control "
                        id="username"
                        placeholder="name@email.com"
                        name="username"
                        defaultValue={username}
                        onBlur={onBlur}
                      />
                      {usernameError ? (
                        <small className="form-text  error-text">
                          {usernameError}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group">
                      <label className="label-sm">Password</label>
                      <input
                        type="password"
                        className="form-control "
                        id="password"
                        placeholder="********"
                        name="password"
                        defaultValue={password}
                        onBlur={onBlur}
                      />
                      {passwordError ? (
                        <small className="form-text error-text">
                          {passwordError}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <p className="text-right reset-password-section">
                      <Link to="/adm/forgot-password" className="no-decoration">
                        <span className="text-muted">Reset Password</span>
                      </Link>
                    </p>
                    <div className="action-btn text-center">
                      <button type="submit" className="btn  btn-primary" disabled={loading}>
                        {loading ?
                          <div className="spinner-border-1_3 white-color" role="status">
                            <span className="sr-only">Loading...</span>
                          </div> : 'Sign In'}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2 d-sm-none d-xs-none d-md-block d-lg-block d-xl-block"></div>
              </div>
            </div>
          </div>
        </div>
        <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
      </div>
    </div>
  );
};

export default Login;
