import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ClientService from '../../service/ClientService';
import Loader from '../../component/Loader';
import ErrorModal from '../../component/modal/ErrorModal';
import SuccessModal from '../../component/modal/SuccessModal';
import TableComponent from '../../common/tableComponent';
import LocalService from '../../service/LocalstorageService';
import Moment from 'react-moment';


export const DownloadProfile = (props) => {
    const { id, userType } = useParams();
    const [loading, setLoading] = useState(true);
    const [profileList, setProfileList] = useState([]);
    const [paginationObj, setPaginationObj] = useState({});
    const [currentAction, setCurrentAction] = useState('');
    const [errModel, setErrorModal] = useState({ show: false, error: {} })
    const [successModel, setSuccessModal] = useState({ show: false, success: {} });
    const permissions = LocalService.getPermision().filter(perm => perm.name === 'Client Management') || [];
    const perPageItem = 10;
    const [tableLoader, setTableLoader] = useState(false);
    const [tableError, setTableError] = useState(false);

    const columns = [
        {
            label: "Candidate", value: "name", sortable: true,
            render: (value, index, list) => {
                return (
                    <div className='d-flex align-items-center'>
                        {
                            list.candidate.profilePic && list.candidate.profilePic.href ?
                                <img className="image-30" src={list.candidate.profilePic.href}></img> :
                                <div className="no-image-28">{list.candidate.firstName.substr(0, 1)}</div>
                        }
                        {userType == 'cli' ? (
                            <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/search-candidate/view/${list.candidate.id}`} className="d-flex align-items-center">{`${list.candidate.firstName} ${list.candidate.middleName || ''} ${list.candidate.lastName}`} </Link>
                        ) : (<Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/candidate/view/${list.candidate.id}`} className="d-flex align-items-center">{`${list.candidate.firstName} ${list.candidate.middleName || ''} ${list.candidate.lastName}`} </Link>)}

                    </div>
                )
            }

        },
        {
            label: "Subscription", value: "subscription", sortable: false,
            render: (value, index, list) => {
                return list.subscription && list.subscription.plan ? list.subscription.plan.name : "NA"
            }
        },
        {
            label: "Category", value: "category", sortable: false,
            render: (value, index, list) => {
                return (
                    list.category && list.category.name ? list.category.name : ' NA '
                )
            }
        },
        {
            label: "Download On", value: "chargedOn", sortable: false,
            render: (value, index, list) => {
                return (
                    list.chargedOn ? <Moment format="DD MMM, YYYY">{list.chargedOn}</Moment> : "NA"
                )
            }
        },
    ];


    useEffect(() => {
        //console.log("candidate",props)
        getProfileList();
    }, []);


    const getProfileList = async (search = "", offset = 10, page = 1, sortBy) => {
        try {
            const response = await ClientService.getDownloadedProfileList(search, offset, page, sortBy, id);
            if (response.status === 200) {
                setProfileList(response.data.result);
                setPaginationObj(response.data.detail);
                setLoading(false);
                setTableLoader(false);
                setTableError(false);
            }
        } catch (e) {
            setTableLoader(false)
            setProfileList([]);
            setPaginationObj({});
            setLoading(false);
            setTableError(true);
        }
    }

    const getTableData = (search, offset, page, sortBy) => {
        setTableLoader(true);
        let tableConfig = { ...config, search, "perPageItem": offset, page, sortBy }
        setConfig(tableConfig);
        getProfileList(search, offset, page, sortBy, id);
    }

    const performAction = (action, list) => {
        setCurrentAction({ action: action, data: list ? list : selectedRow });
        setConfirmationObj({ show: true, msg: `You want to ${action.toUpperCase()} this candidate?` });
    }

    let tableConfig = {
        pagination: true,
        showSelectRow: false,
        perPageItem: perPageItem,
        getTableData: getTableData,
        onActionButton: performAction,
        actionList: [],
        search: "",
        sortBy: { name: "", type: "" },
        page: 1
    }

    const [config , setConfig] = useState({...tableConfig});

    if (loading) return <Loader />;

    return (
        <div className="p-0" id="download-profile-container">
            <div className="container-fluid p-0">

                <div className="row p-2 no-gutters filter-row align-items-center">
                    {/* <div className="col-auto px-2">
                        <h5 className="m-0">Viewed Profiles List</h5>
                    </div> */}
                    <div className="col">
                    </div>
                </div>
                <div className="list-card list-view px-4 py-0" id="download-profile-list">
                    <div className='list-container'>
                        <TableComponent
                            loading={tableLoader}
                            error={tableError}
                            columns={columns}
                            data={profileList.data || []}
                            config={config}
                            paginationObj={paginationObj}>
                        </TableComponent>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default DownloadProfile;

