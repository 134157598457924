import React, { useState, useEffect } from "react";
import Countcard from "../component/Countcard";
import AuthService from "../service/AuthService";
import { useHistory, useLocation, Link } from "react-router-dom";
import WorkIcon from "@material-ui/icons/Work";
import PersonIcon from "@material-ui/icons/Person";
import NotificationsIcon from "@material-ui/icons/Notifications";
import StaffService from "../service/StaffService";
import LocalStorageService from "../service/LocalstorageService";
import ErrorPopup from "../component/modal/ErrorModal";
import Loader from "../component/Loader";
import Moment from "react-moment";
import WarningIcon from "@material-ui/icons/Warning";

const isLoggedIn = () => {
  const history = useHistory();
  if (!AuthService.isLoggedIn()) {
    history.push("/sta/login");
  }
};

const StaffDashboard = () => {
  isLoggedIn();
  const [isLoading, setLoading] = useState(true);
  const [staffData, setStaffData] = useState(null);
  const [staffActivity, setStaffActivity] = useState(null);
  const [errModel, setErrorModal] = useState({ show: false, err: {} });
  const location = useLocation();
  const userPrifix = location.pathname.split("/")[1];

  useEffect(() => {
    getStaffData();
    getStaffActivity();
  }, []);

  const getStaffData = async () => {
    try {
      setLoading(true);
      const response = await StaffService.getDashboardData();
      if (response.status === 200) {
        setStaffData(response.data.result);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err && err.data) {
        setErrorModal({ show: true, error: err.data });
      }
    }
  };

  const getStaffActivity = async () => {
    try {
      setLoading(true);
      const response = await StaffService.getStaffActivity();
      if (response.status === 200) {
        setStaffActivity(response.data.result);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err && err.data) {
        setErrorModal({ show: true, error: err.data });
      }
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div id="staff-dashboard" className="content-wrapper">
      <div className="dashboard-container">
        <h3>Staff Overview</h3>
        <div className="container-fluid p-0 mt-4">
          <div className="row">
            <div className="col-3">
              <Countcard
                count={staffData?.counts?.jobsPosted}
                msg={"Total Jobs Posted"}
                name={"total-count"}
                icon={WorkIcon}
              />
            </div>
            <div className="col-3">
              <Countcard
                count={staffData?.counts?.candidatesAdded}
                msg={"Candidates Added"}
                name={"active-count"}
                icon={PersonIcon}
              />
            </div>
            <div className="col-3">
              <Countcard
                count={staffData?.counts?.hotJobsAdded}
                msg={"Hot Jobs Added"}
                name={"inactive-count"}
                icon={WorkIcon}
              />
            </div>
            <div className="col-3">
              <Countcard
                count={staffData?.counts?.dailyNewsAdded}
                msg={"Daily News Added"}
                name={"new-count"}
                icon={NotificationsIcon}
              />
            </div>
          </div>
        </div>
        <div className="row py-4 h-500">
          <div className="col-7">
            <div className="card count-card staff-dashboard-profile h-100">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto header-text">Profile</div>
                  <div className="col"></div>
                  <div className="col-auto color-grey p-0">Last Updated</div>
                  <div className="col-auto color-grey">
                    <Moment
                      date={staffData?.profile?.updatedOn}
                      format={"MMM  DD YYYY"}
                    ></Moment>
                  </div>
                </div>
                <div className="row position-relative">
                  <div className="img-container col-12">
                    <div className="margin-auto">
                      {staffData?.profile?.profilePic?.href ? (
                        <img
                          className="profile-img"
                          src={staffData?.profile?.profilePic?.href}
                        ></img>
                      ) : (
                        <div className="no-profile-img">
                          {staffData?.profile?.firstName.charAt(0)}
                        </div>
                      )}
                      <p className="name text-center">{`${staffData?.profile?.firstName} ${staffData?.profile?.lastName}`}</p>
                    </div>
                  </div>
                  <div className="progression-container row col-12">
                    <div className="col-2"></div>
                    <div className="progress col-8 p-0">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${staffData?.profileCompletion}%` }}
                        aria-valuenow={`${staffData?.profileCompletion}`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {staffData?.profileCompletion}%
                      </div>
                    </div>
                    <div className="col-2"></div>
                    <div className="col-2"></div>
                    <div className="col-8 row progression-text mx-0 px-0 py-2">
                      <div className="col-auto text p-0">
                        {staffData?.profileCompletion}% profile completed
                      </div>
                      <div className="col"></div>
                      <div className="col-auto text p-0">Finish Profile</div>
                    </div>
                    <div className="col-2"></div>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col"></div>
                  <div className="col-auto">
                    <a
                      type="button"
                      className="btn btn-primary"
                      href={`/${userPrifix}/settings`}
                    >
                      View Full Profile
                    </a>
                  </div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="card count-card staff-dashboard-profile h-100">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto header-text">LOG</div>
                </div>
                <div className="row position-relative log-timeline-container">
                  <div className="container">
                    <div className="timeline">
                      {staffActivity?.length &&
                        staffActivity.map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="timeline-month">
                                <Moment
                                  date={new Date(item.date)}
                                  format={"DD MMMM YYYY"}
                                ></Moment>
                                <span>3 Entries</span>
                              </div>
                              {item.messages.map((msg, index) => {
                                return (
                                  <div className="timeline-section" key={index}>
                                    <div className="row m-2">
                                      <div className="col-auto time">
                                        <Moment
                                          date={msg.createdOn}
                                          format={"HH:mm A"}
                                        ></Moment>
                                      </div>
                                      <div className="col">{msg.message}</div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row py-4 h-500">
          <div className="col-12">
            <div className="card count-card job-posted-container h-100">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto header-text">Job Posted</div>
                  <div className="col"></div>
                  <Link
                    to={`/${userPrifix}/job-post`}
                    className="col-auto color-grey f-sm"
                  >
                    Know More
                  </Link>
                </div>
                <div className="row job-posted-lists no-gutters">
                  {staffData?.postedJobs?.length > 0 &&
                    staffData.postedJobs.map((item) => {
                      return (
                        <li className="job-list col-12 py-1" key={item.id}>
                          <div className="container-fluid px-0">
                            <div className="list-content col-12 row no-gutters align-items-center px-0">
                              <div className="col-auto">
                                {item?.client?.logo?.href ? (
                                  <img
                                    className="user-image"
                                    src={item?.client?.logo?.href}
                                    title={item.client.name}
                                  />
                                ) : (
                                  <div
                                    className="no-user-image"
                                    title={item.client.name}
                                  >
                                    {item.client.name.charAt(0)}
                                  </div>
                                )}
                              </div>

                              <Link
                                to={`/${userPrifix}/jobs/view/${item.id}`}
                                className="user-name col text-truncate px-3"
                              >
                                {item.title}
                              </Link>
                              <div className="col-2 d-block text-truncate">
                                <span className="color-grey f-sm">
                                  {item.role}
                                </span>
                              </div>
                              <div className="col-2 d-block text-truncate">
                                <span className="color-primary f-sm">
                                  {item.jobLocation?.state || "NA"}
                                </span>
                              </div>
                              <div className="col-2 d-block text-truncate">
                                {item.isArchived ? (
                                  <span className="badge badge-danger">
                                    Archived
                                  </span>
                                ) : item.isActive ? (
                                  <span className="badge badge-success">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-warning">
                                    Inactive
                                  </span>
                                )}
                              </div>
                              <div className="col-2 d-block text-truncate">
                                <span className="color-grey f-sm">
                                  {item.postOn}
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  {!staffData?.postedJobs?.length ? (
                    <li className="ls-none col-auto py-1 m-auto align-items-center">
                      <p>
                        <WarningIcon
                          style={{ fontSize: 32 }}
                          className="warning-color"
                        ></WarningIcon>
                        No Job Posted
                      </p>
                    </li>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-4 h-500">
          <div className="col-6">
            <div className="card count-card staff-dashboard-candidate h-100">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto header-text">Candidates Added</div>
                  <div className="col"></div>
                  <Link
                    to={`/${userPrifix}/candidate`}
                    className="col-auto color-grey f-sm"
                  >
                    Know More
                  </Link>
                </div>
                <div className="row staff-candidate-lists h-100 py-2">
                  {staffData?.candidates?.length > 0 &&
                    staffData.candidates.map((item) => {
                      return (
                        <li
                          className="candidate-list col-12 py-1"
                          key={item.id}
                        >
                          <div className="container-fluid px-0">
                            <div className="list-content col-12 row no-gutters align-items-center px-0 py-1">
                              <div className="col-auto">
                                {item?.profilePic?.href ? (
                                  <img
                                    className="user-image"
                                    src={item?.profilePic?.href}
                                  />
                                ) : (
                                  <div className="no-user-image">
                                    {item.firstName.charAt(0)}
                                  </div>
                                )}
                              </div>
                              <Link
                                to={`/${userPrifix}/candidate/view/${item.id}`}
                                className="col news-header text-truncate ml-2"
                                title={`${item.firstName} ${item.lastName}`}
                              >
                                {`${item.firstName} ${item.lastName}`}
                              </Link>

                              <div
                                className="col-6 f-sm text-truncate"
                                title={item.email}
                              >
                                {item.email}
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  {!staffData?.candidates?.length ? (
                    <li className="ls-none col-auto py-1 m-auto align-items-center">
                      <p>
                        <WarningIcon
                          style={{ fontSize: 32 }}
                          className="warning-color"
                        ></WarningIcon>
                        No candidate added
                      </p>
                    </li>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card count-card staff-dashboard-news h-100">
              <div className="card-body">
                <div className="row">
                  <div className="col-auto header-text">Daily News Added</div>
                  <div className="col"></div>
                  <Link
                    to={`/${userPrifix}/news`}
                    className="col-auto color-grey f-sm"
                  >
                    Know More
                  </Link>
                </div>
                <div className="row job-posted-lists h-100 py-2">
                  {staffData?.dailyNews?.length > 0 &&
                    staffData.dailyNews.map((item) => {
                      return (
                        <li className="job-list col-12 py-1" key={item.id}>
                          <div className="container-fluid px-0">
                            <div className="list-content col-12 row no-gutters align-items-center px-0">
                              <div className="col-auto">
                                {item?.addedBy?.profilePic?.href ? (
                                  <img
                                    className="user-image"
                                    src={item?.addedBy?.profilePic?.href}
                                  />
                                ) : (
                                  <div className="no-user-image">
                                    {item.addedBy.firstName.charAt(0)}
                                  </div>
                                )}
                              </div>
                              <div className="col row px-3 ">
                                <Link
                                  to={`/${userPrifix}/news/view/${item.id}`}
                                  className="col-12 news-header"
                                >
                                  {item.heading}
                                </Link>
                                <a
                                  href={item.source}
                                  className="col-12 news-desc"
                                  target="_blank"
                                >
                                  {item.source}
                                </a>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  {!staffData?.dailyNews?.length ? (
                    <li className="ls-none col-auto py-1 m-auto align-items-center">
                      <p>
                        <WarningIcon
                          style={{ fontSize: 32 }}
                          className="warning-color"
                        ></WarningIcon>
                        No news Added
                      </p>
                    </li>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorPopup
        data={errModel}
        onConfirmAction={() => setErrorModal({ show: false, error: {} })}
      ></ErrorPopup>
    </div>
  );
};

export default StaffDashboard;
