import React, { useState, useContext } from "react";
import LocalStorageService from "../service/LocalstorageService";
import { useHistory, Link } from "react-router-dom";
import AuthService from "../service/AuthService";
import { AuthContext } from "../component/AuthContext";
import Logo from "../../assets/imgs/logo-white-transparent.png"

const localService = LocalStorageService.getService();

const Navbar = (props) => {
  const history = useHistory();
  const context = useContext(AuthContext);

  const logout = async () => {
    const data = context.data;
    const response = await AuthService.logout();
    if (response.status === 200) {
      context.updateAuthData({});
      localService.clearToken();
      history.push(`/${localService.getRoutePrefix(data.userType)}/login`);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light justify-content-between" style={{ backgroundColor: '#435db3' }}>
      <Link className="navbar-brand" to="/home">
        <img className="flipInX" src={Logo} height="70" width="250" style={{ marginLeft: '-1.7em', borderRadius: '0.2em' }} />
      </Link>
      <ul className="navbar-nav ml-auto custom-nav">
        <li className="nav-item client-section">
          {localService.getUserType() === "Client" ? (
            <span className="badge badge-primary client-name">
              {localService.getClientData()["name"]}
            </span>
          ) : null}
        </li>
        <li className="nav-item dropdown">
          {context.data.token ? (
            <>
              <a
                className="nav-link dropdown-toggle"
                type="button"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: 'white' }}
              >
                <img
                  className="navbar-img"
                  src={
                    context.data.user.profilePic
                      ? context.data.user.profilePic.href
                      : null
                  }
                ></img>
                <span className="user-name" style={{ fontWeight: 'bold' }}> {`${context.data.user.firstName} ${context.data.user.lastName}`} </span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a className="dropdown-item" type="button" onClick={logout}>
                  Logout
                </a>
              </div>
            </>
          ) : (
            ""
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
