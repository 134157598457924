import Api from './Interceptor';
import LocalStorageService from './LocalstorageService';

const localStorage = LocalStorageService.getService();

const DashboardService  = (function(){
    const ajax = function(param){
        return Api(param)
    }

    return {
        isLoggedIn : function(){
           return localStorage.getAccessToken() ? true : false;
        },
        getAdminOverviewData : function(type){
            let requestObj = {method :'GET', url : `user/dashboard?detail=${type}`};
            return ajax(requestObj);
        },
        getJobList : function(url){
            const requestObj = {method:'GET',url: `/requirement/post`};
            if(url){
                requestObj.url = url;
            }
            return ajax(requestObj);
        },

    }
})()

export default DashboardService;