import Api from "./Interceptor";
import LocalStorageService from "./LocalstorageService";

const localStorage = LocalStorageService.getService();

const HomePageService = (function () {
  const ajax = function (param) {
    return Api(param);
  };

  const generateParams = function (param) {
    if (!param) return "";
    let params = "";

    Object.keys(param).forEach((key) => {
      params = `${params}${params ? "&" : "?"}${key}=${param[key]}`;
    });
    return params;
  };

  return {
    isLoggedIn: function () {
      return localStorage.getAccessToken() ? true : false;
    },
    getPageData: function (tab) {
      const requestObj = {
        method: "GET",
        url: `/webcontent/updates?page=${tab}`,
      };
      return ajax(requestObj);
    },
    getLocation: function(param){
      const requestObj = {
        method: "GET",
        url: `/webcontent/location?${param}`,
      };
      return ajax(requestObj);
    },
    getSkill: function(param){
      const requestObj = {
        method: "GET",
        url: `/webcontent/categoryrole?${param}`,
      };
      return ajax(requestObj);
    },
    searchJobPublic: function(data){
      const requestObj = {
        method: "POST",
        data:data,
        url:'/webcontent/search?searchFor=job'
      }
      return ajax(requestObj);
    },
    searchJobCandidate: function(data){
      const requestObj = {
        method: "POST",
        data:data,
        url:'/webcontent/search?searchFor=job-seeker'
      }
      return ajax(requestObj);
    },
    getSelectedLocation: function(selectedList, isAll = true){
      const requestObj = {
        method: "POST",
        data:{checked: selectedList.join(',')},
        url:`/webcontent/location?all=${isAll}`
      }
      return ajax(requestObj);
    },
    getSelectedCategoryRole: function(selectedList, isAll = true){
      const requestObj = {
        method: "POST",
        data:{checked: selectedList.join(',')},
        url:`/webcontent/categoryrole?all=${isAll}`
      }
      return ajax(requestObj);
    }
  };
})();

export default HomePageService;
