import React, { useState } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

export const SuccessToaster = (props) => {
  const onClose = (e) => {
    props.onClose(e);
  };
  return (
    <div
      className={`toast fade success-toast ${
        props.data && props.data.show ? "show" : "hide"
      }`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="toast-header">
        <CheckCircleRoundedIcon></CheckCircleRoundedIcon>
        <strong className="mr-auto">Success</strong>
        <button
          type="button"
          className="ml-2 mb-1 close"
          data-dismiss="toast"
          aria-label="Close"
          onClick={onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="toast-body">{props.data.msg}</div>
    </div>
  );
};

const SuccessModal = (props) => {
  const [visible, setVisible] = useState(props.data.show);

  const onConfirm = () => {
    props.onConfirmAction(props.action);
  };

  return (
    <div
      className={props.data.show ? "overlay showModal" : "overlay hideModal"}
    >
      <div className="overlay-content">
        <div className="cs-modal" id="success-popup">
          <div className="header">
            <div className="icon-wrapper">
              <CheckCircleIcon></CheckCircleIcon>
            </div>
          </div>
          <div className="body">
            <p className="body-text"> Great! </p>
            <p className="body-text">
              {" "}
              {props.data.success && props.data.success.msg
                ? props.data.success.msg
                : ""}{" "}
            </p>
          </div>
          <div className="footer">
            <button
              type="button"
              className="btn btn-sm btn-outline-success"
              onClick={onConfirm}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
