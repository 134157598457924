import React, { useEffect, useState } from "react";
import UseForm from "../../service/FormService";
import ClientService from "../../service/ClientService";
import { Link, useParams, useHistory } from "react-router-dom";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import VideoLibraryOutlinedIcon from "@material-ui/icons/VideoLibraryOutlined";
import ErrorPopup from "../../component/modal/ErrorModal";
import $ from "jquery";
import Loader from "../../component/Loader";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const emailRegx =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const imgRegex = /(JPE?G|PNG|SVG)$/i;
const videoRegex = /(MP4|3GP|WMV|MPEG|WAV)$/i;

const initSchema = {
  name: { value: "", error: "" },
  website: { value: "", error: "" },
  location: { value: "", error: "" },
  description: { value: "", error: "" },
  personContactEmail: { value: "", error: "" },
  personContactNumber: { value: "", error: "" },
  logo: { value: "", error: "" },
  imageUploads: { value: "", error: "" },
  videoUploads: { value: "", error: "" },
};

const validationStateSchema = {
  name: {
    required: true,
    validator: {
      regEx: /^([a-zA-Z\.\s \- \@ 1-9]{3,})+$/,
      error: "Invalid first name format.",
    },
  },
  website: {
    required: true,
  },
  location: {
    required: true,
  },
  description: {
    required: true,
  },
  personContactEmail: {
    required: true,
    validator: {
      regEx: emailRegx,
      error: "Invalid Email",
    },
  },
  personContactNumber: {
    required: true,
    validator: {
      regEx: /^([0-9]{10})$/,
      error: "Invalid phone number format.",
    },
  },
  logo: {
    required: true,
  },
  imageUploads: {
    required: false,
  },
  videoUploads: {
    required: false,
  },
};
const resetObj = { value: "", error: "" };

const StaffCreate = () => {
  const { id, userType } = useParams();
  const [stateSchema, setStateSchema] = useState(initSchema);
  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState({});

  const [logo, setLogo] = useState(resetObj);
  const [logoValid, setLogoValid] = useState(true);

  const [images, setImages] = useState(resetObj);
  const [imageList, setImageList] = useState([]);
  const [imageValid, setImageValid] = useState(true);

  const [videos, setVideos] = useState(resetObj);
  const [videoList, setVideoList] = useState([]);
  const [videoValid, setVideoValid] = useState(true);

  const [errModel, setErrorModal] = useState({ show: false, err: {} });

  const history = useHistory();

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    try {
      let staffDataRes = await ClientService.getClientDetails(id);
      const res = staffDataRes.data.result;
      setClientData(res);
      setLogo(res.logo ? { value: res.logo, error: "" } : resetObj);
      setImageList(
        res.imageUploads && res.imageUploads.length > 0 ? res.imageUploads : []
      );
      setVideoList(
        res.videoUploads && res.videoUploads.length > 0 ? res.videoUploads : []
      );
      updateSchema(staffDataRes.data.result);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const updateSchema = (data) => {
    let schema = $.extend(true, {}, stateSchema);
    Object.keys(schema).forEach((key) => {
      if (data.hasOwnProperty(key) && data[key]) {
        if (key === "role") {
          schema[key].value = data[key].id;
        } else if (key === "videoUploads" || key === "imageUploads") {
          schema[key].value = data[key] || [];
        } else {
          schema[key].value = data[key];
        }
      }
    });
    setStateSchema(schema);
  };

  const updateLogo = function (logo) {
    const event = { target: { name: "logo", value: logo } };
    handleOnChange(event);
  };
  const updateImageList = function (images) {
    const event = { target: { name: "imageUploads", value: images } };
    handleOnChange(event);
  };
  const updateVideoList = function (videos) {
    const event = { target: { name: "videoUploads", value: videos } };
    handleOnChange(event);
  };

  const onSubmitForm = (state) => {
    setLoading(true);
    const requestPayload = {
      name: state.name.value,
      website: state.website.value,
      location: state.location.value,
      description: state.description.value,
      personContactEmail: state.personContactEmail.value,
      personContactNumber: state.personContactNumber.value,
      logo: state.logo.value
        ? state.logo.value.id
          ? state.logo.value.id
          : state.logo.value
        : null,
    };
    const imageLists = state.imageUploads.value || [];
    const videoLists = state.videoUploads.value || [];

    if (imageLists.length > 0) {
      requestPayload["images"] = [];
      requestPayload["imageUploads"] = [];
      Array.from(imageList).forEach((item) => {
        item.id
          ? requestPayload["imageUploads"].push(item.id)
          : requestPayload["images"].push(item);
      });
      requestPayload["imageUploads"] =
        requestPayload["imageUploads"].length > 0
          ? requestPayload["imageUploads"].join(",")
          : null;
    } else {
      requestPayload["images"] = null;
    }

    if (videoLists.length > 0) {
      requestPayload["videos"] = [];
      requestPayload["videoUploads"] = [];
      Array.from(videoList).forEach((item) => {
        item.id
          ? requestPayload["videoUploads"].push(item.id)
          : requestPayload["videos"].push(item);
      });
      requestPayload["videoUploads"] =
        requestPayload["videoUploads"].length > 0
          ? requestPayload["videoUploads"].join(",")
          : null;
    } else {
      requestPayload["videos"] = null;
    }

    let formData = new FormData();

    for (let key in requestPayload) {
      if ((key === "images" || key === "videos") && requestPayload[key]) {
        Array.from(requestPayload[key]).forEach((item) => {
          formData.append(`${key}`, item, item.name);
        });
      } else {
        if (requestPayload[key]) {
          formData.append(key, requestPayload[key]);
        }
      }
    }
    ClientService.updateClient(id, formData).then(
      (res) => {
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          history.push(`/${userType}/client`);
        }
      },
      (err) => {
        setLoading(false);
        if (err && err.data) {
          setErrorModal({ show: true, error: err.data });
        }
      }
    );
  };

  const validateOnType = (type, file, fileLength) => {
    const extList = file.name.split(".");
    const ext = extList[extList.length - 1];
    if (type === "image") {
      if (!imgRegex.test(ext)) {
        return {
          isValid: false,
          resObj: {
            value: "",
            error: "Invalid file type, It supports only JPEG,JPG,PNG and SVG.",
          },
        };
      }
      if (file.size > 2 * 1024 * 1024) {
        return {
          isValid: false,
          resObj: {
            value: "",
            error: "Maximum file size should not greater than 2MB",
          },
        };
      }
      return { isValid: true, resObj: { value: file, error: "" } };
    } else {
      if (!videoRegex.test(ext)) {
        return {
          isValid: false,
          resObj: {
            value: "",
            error:
              "Invalid file type, It supports only MP4,3GP,WMV,MPEG and WAV.",
          },
        };
      }
      if (file.size > 20 * 1024 * 1024) {
        return {
          isValid: false,
          resObj: {
            value: "",
            error: "Maximum file size should not greater than 20MB",
          },
        };
      }
      if (fileLength + videoList.length > 2) {
        return {
          isValid: false,
          resObj: { value: "", error: "Maximum 2 video file can be uploaded" },
        };
      }
      return { isValid: true, resObj: { value: file, error: "" } };
    }
  };

  const validateFile = (file, type, fileLength) => {
    ////console.log("validate file",file,type);
    let obj;
    if (type === "logo" || type === "images") {
      obj = validateOnType("image", file);
      type === "logo" ? setLogo(obj.resObj) : setImages(obj.resObj);
    } else {
      obj = validateOnType("video", file, fileLength);
      setVideos(obj.resObj);
    }
    ////console.log("validate file after",obj);
    return obj.isValid;
  };

  const onFileChange = (e) => {
    e.persist();
    e.preventDefault();
    const file =
      e.target.files && e.target.files.length > 0 ? e.target.files : null;
    const name = e.target.name;
    if (file) {
      let valid = true;
      Array.from(file).forEach((item) => {
        valid = valid && validateFile(item, name, file.length);
      });
      if (name === "images" && valid) {
        let images = imageList;
        Array.from(file).forEach((item) => {
          images.push(item);
        });
        setImageList(images);
        setImageValid(true);
        updateImageList(images);
      } else if (name === "videos" && valid) {
        let videos = videoList;
        Array.from(file).forEach((item) => {
          videos.push(item);
        });
        setVideoList(videos);
        setVideoValid(true);
        updateVideoList(videos);
      } else if (name === "logo" && valid) {
        setLogo({ value: file[0], error: "" });
        setLogoValid(true);
        updateLogo(file[0]);
      } else {
        validateFileField(name, valid);
      }
    } else {
      name === "logo"
        ? setLogo(resetObj)
        : name === "images"
        ? setImages(resetObj)
        : setVideos(resetObj);
      validateFileField(name, true);
      name === "logo"
        ? updateLogo(null)
        : name === "images"
        ? updateImageList(null)
        : updateVideoList(null);
    }
  };
  const validateFileField = (name, valid) => {
    switch (name) {
      case "logo":
        setLogoValid(valid);
        break;
      case "images":
        setImageValid(valid);
        break;
      case "videos":
        setVideoValid(valid);
        break;
      default: //console.log("Unkown type");
    }
  };

  const getFileLink = (file, index, type) => {
    if (file && file.id) {
      setTimeout(() => {
        $(`#${type}-preview-${index}`).attr("src", file.media.href);
      }, 10);
    } else {
      const reader = new FileReader();
      reader.onload = function (e) {
        $(`#${type}-preview-${index}`).prop("src", e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = (e) => {
    let images = [].concat(imageList);
    e.persist();
    //e.preventDefault();
    const index = e.currentTarget.dataset.index;
    images.splice(index, 1);
    setImageList(images);
    updateImageList(images);
    //console.log("remove image is called",images);
  };

  const removeVideos = (e) => {
    let videos = [].concat(videoList);
    e.persist();
    //e.preventDefault();
    const index = e.currentTarget.dataset.index;
    videos.splice(index, 1);
    setVideoList(videos);
    updateVideoList(videos);
    //console.log("remove video is called",videoList);
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    stateSchema,
    validationStateSchema,
    onSubmitForm
  );

  if (loading) return <Loader />;
  return (
    <div className="content-wrapper" id="client-add-container">
      <div className="content-container">
        <div className="form-wrapper">
          <div className="row h-100 w-100">
            <div className="container h-100 w-100">
              <div className="row align-items-center h-100 w-100">
                <div className="col-2"></div>
                <div className="col-8">
                  <div className="form-header text-center mt-4">
                    <span className="text-msg">Edit Client</span>
                  </div>
                  <form onSubmit={handleOnSubmit} name="add-client">
                    <div className="row">
                      <div className="form-group col-6 m-0">
                        <label className="label-sm">Company Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Company Name"
                          name="name"
                          value={state.name.value}
                          onChange={handleOnChange}
                        />
                        <small
                          className="form-text  error-text m-0"
                          style={{
                            visibility: state.name.error ? "visible" : "hidden",
                          }}
                        >
                          {state.name.error}
                        </small>
                      </div>
                      <div className="form-group col-6 m-0">
                        <label className="label-sm">Website URL</label>
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Ex: https://www.website.com "
                          id="website"
                          name="website"
                          value={state.website.value}
                          onChange={handleOnChange}
                        />
                        <small
                          className="form-text  error-text m-0"
                          style={{
                            visibility: state.website.error
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          {state.website.error}
                        </small>
                      </div>
                    </div>
                    <div className="form-group m-0">
                      <label className="label-sm">Location</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Location"
                        id="location"
                        name="location"
                        value={state.location.value}
                        onChange={handleOnChange}
                      />
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.location.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {state.location.error}
                      </small>
                    </div>
                    <div className="form-group m-0">
                      <label className="label-sm">Contact Email ID</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="name@email.com"
                        id="personContactEmail"
                        name="personContactEmail"
                        value={state.personContactEmail.value}
                        onChange={handleOnChange}
                      />
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.personContactEmail.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {state.personContactEmail.error}
                      </small>
                    </div>
                    <div className="form-group m-0">
                      <label className="label-sm">Contact Phone Number</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Phone number"
                        id="personContactNumber"
                        name="personContactNumber"
                        value={state.personContactNumber.value}
                        onChange={handleOnChange}
                      />
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.personContactNumber.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {state.personContactNumber.error}
                      </small>
                    </div>
                    <div className="form-group m-0">
                      <label className="label-sm w-100">Logo</label>
                      <div className="row no-gutters">
                        <div className="fileUpload btn btn-secondary cs-upload-1 m-0 col-auto">
                          <span>Browse</span>
                          <input
                            type="file"
                            className="upload"
                            name="logo"
                            id="logo"
                            onChange={onFileChange}
                          />
                        </div>
                        <div className="col file-name">
                          <span className="margin-top-auto">
                            {logo.value
                              ? logo.value.name || logo.value.originalName
                              : ""}
                          </span>
                        </div>
                      </div>

                      <small id="logoHelp" className="form-text text-muted">
                        Max size of file is 2MB.
                      </small>
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: logo.error ? "visible" : "hidden",
                        }}
                      >
                        {logo.error}
                      </small>
                    </div>
                    <div className="form-group m-0">
                      <label className="label-sm">
                        Description (About Company)
                      </label>
                      <CKEditor
                        data={state.description.value}
                        editor={ClassicEditor}
                        config={{
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "blockQuote",
                            "link",
                            "numberedList",
                            "bulletedList",
                            "insertTable",
                            "|",
                            "alignment",
                            "|",
                            "undo",
                            "redo",
                          ],
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          //console.log( 'on change',{ event, editor, data } );
                          handleOnChange({
                            target: {
                              name: "description",
                              value: data,
                            },
                          });
                        }}
                      />
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.description.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {state.description.error}
                      </small>
                    </div>
                    <div className="form-group m-0">
                      <label className="label-sm w-100">Upload Images</label>
                      {imageList.length > 0 ? (
                        <div className="box">
                          <div className="row no-gutters image-list">
                            {imageList.map((image, index) => {
                              return (
                                <div
                                  className="card bg-dark text-white"
                                  key={index}
                                >
                                  <img
                                    className="card-img "
                                    id={`image-preview-${index}`}
                                    src={getFileLink(image, index, "image")}
                                    alt="Card image"
                                  />
                                  <div className="card-img-overlay">
                                    <p
                                      className="card-text close-btn-container"
                                      data-index={index}
                                      onClick={removeImage}
                                    >
                                      <CloseOutlinedIcon className="close-icon"></CloseOutlinedIcon>
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                            <div className="card bg-dark text-white upload-image-card">
                              <div className="card-body">
                                <div className="card-text margin-auto text-center fileUpload cs-upload-1">
                                  <CloudUploadIcon></CloudUploadIcon>
                                  <p>Browse</p>
                                  <input
                                    type="file"
                                    className="upload"
                                    name="images"
                                    id="images"
                                    onChange={onFileChange}
                                    multiple
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="box">
                          <div className="margin-auto no-image-section text-center">
                            <ImageOutlinedIcon></ImageOutlinedIcon>
                            <p className="text-center">
                              No photos are available to show. Upload Now.
                            </p>
                            <div className="fileUpload btn btn-outline-secondary cs-upload-1 m-0 col-auto">
                              <span>Browse</span>
                              <input
                                type="file"
                                className="upload"
                                name="images"
                                id="images"
                                onChange={onFileChange}
                                multiple
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <small
                        className="form-text  error-text "
                        style={{
                          visibility: images.error ? "visible" : "hidden",
                        }}
                      >
                        {images.error}
                      </small>
                    </div>

                    <div className="form-group m-0">
                      <label className="label-sm w-100">Upload Videos</label>
                      {videoList.length > 0 ? (
                        <div className="box">
                          <div className="row no-gutters image-list">
                            {videoList.map((video, index) => {
                              return (
                                <div
                                  className="card bg-dark text-white col-5 h-100"
                                  key={index}
                                >
                                  <div className="card-body p-0">
                                    <video
                                      id={`video-preview-${index}`}
                                      src={getFileLink(video, index, "video")}
                                      width="100%"
                                      height="200"
                                      controls
                                    ></video>
                                    <p
                                      className="card-text close-btn-container"
                                      data-index={index}
                                      onClick={removeVideos}
                                    >
                                      <CloseOutlinedIcon className="close-icon"></CloseOutlinedIcon>
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                            <div className="card bg-dark text-white upload-image-card">
                              <div className="card-body">
                                <div className="card-text margin-auto text-center fileUpload cs-upload-1">
                                  <CloudUploadIcon></CloudUploadIcon>
                                  <p>Browse</p>
                                  <input
                                    type="file"
                                    className="upload"
                                    name="videos"
                                    id="videos"
                                    onChange={onFileChange}
                                    multiple
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="box">
                          <div className="margin-auto no-image-section text-center">
                            <VideoLibraryOutlinedIcon></VideoLibraryOutlinedIcon>
                            <p className="text-center">
                              No videos are available to show. Upload Now.
                            </p>
                            <div className="fileUpload btn btn-outline-secondary cs-upload-1 m-0 col-auto">
                              <span>Browse</span>
                              <input
                                type="file"
                                className="upload"
                                name="videos"
                                id="videos"
                                onChange={onFileChange}
                                multiple
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <small
                        className="form-text  error-text"
                        style={{
                          visibility: videos.error ? "visible" : "hidden",
                        }}
                      >
                        {videos.error}
                      </small>
                    </div>

                    <div className="action-btn text-center my-4">
                      <Link
                        target={"_blank"} rel={"noopener noreferrer"}
                        to={`/${userType}/client`}
                        type="button"
                        className="btn  btn-outline-secondary login-btn mr-3"
                      >
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn  btn-primary login-btn"
                        disabled={
                          disable || !logoValid || !imageValid || !videoValid
                        }
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorPopup
        data={errModel}
        onConfirmAction={() => setErrorModal({ show: false, error: {} })}
      ></ErrorPopup>
    </div>
  );
};

export default StaffCreate;
