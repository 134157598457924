import Api from './Interceptor';
import LocalStorageService from './LocalstorageService';

const localStorage = LocalStorageService.getService();

const SearchCandidateService = (function () {
    const ajax = function (param) {
        return Api(param)
    }

    return {
        getRole: function () {
            let requestObj = { method: 'GET', url: 'own/role' }
            return ajax(requestObj);
        },
        isLoggedIn: function () {
            return localStorage.getAccessToken() ? true : false;
        },
        addCandidate: function (data) {
            let requestObj = { method: 'POST', url: '/candidate/user', data: data };
            return ajax(requestObj);
        },
        getCandidateList: function (param, filter, url, data, strict) {
            let paramStr = param ? '?search=' + param : '';
            paramStr = paramStr + (filter ? (paramStr ? '&' : '?') + 'filterBy=' + filter : '');
            paramStr = paramStr + (strict ? (paramStr ? '&' : '?') + 'mode=strict' : '');
            let api_url = `/candidate/search${paramStr ? paramStr : ''}`;
            const requestObj = { method: 'POST', url: api_url, data: data };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        getCandidateDetails: function (id) {
            let api_url = `/candidate/user/${id}`
            let requestObj = { method: 'GET', url: api_url };
            return ajax(requestObj);
        },

        //filter
        getStateList: function () {
            let requestObj = { method: 'GET', url: `/own/state?items=all&search=active` };
            return ajax(requestObj);
        },
        getCitiesList: function (stateId) {
            let requestObj = { method: 'GET', url: `own/state/city/${stateId}?items=all&search=active` };
            return ajax(requestObj);
        },
        getQualification: function (param, url) {
            const requestObj = { method: 'GET', url: `/own/qualification?items=all${param ? `&search=active,${param}` : '&search=active'}` };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        getCourse: function (id, param, url) {
            const requestObj = { method: 'GET', url: `/own/course/${id}?items=all${param ? `&search=${param}` : '&search=active'}` };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        getSpecialization: function (id, param) {
            const requestObj = { method: 'GET', url: `/own/specialization/${id}?items=all${param ? `&search=${param}` : '&search=active'}` };
            return ajax(requestObj);
        },
        getCategoryByClient: function (clientId, param, url) {
            const requestObj = { method: 'GET', url: `/own/category${clientId ? `?clientId=${clientId}&items=all&search=active` : '?items=all&search=active'}` };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        getRoles: function (id, param, url) {
            const requestObj = { method: 'GET', url: `/own/category/role/${id}${param ? `?search=active${param}&items=all` : '?items=all&search=active'}` };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        filterCandidate: function (data, filterMode) {
            let requestObj = { method: 'POST', url: `/candidate/search${filterMode ? '?mode=strict' : ''}`, data: data };
            return ajax(requestObj);
        },
        getJobs: function (url) {
            const requestObj = { method: 'GET', url: '/requirement/post' };
            if (url) {
                requestObj.url = url;
            }
            return ajax(requestObj);
        },
        filterJobs: function (data) {
            let requestObj = { method: 'POST', url: '/requirement/post?_type=filter', data: data };
            return ajax(requestObj);
        },
        submitProfile: function (data) {
            let requestObj = { method: 'POST', url: '/requirement/post/candidate', data: data };
            return ajax(requestObj);
        }
    }
})()

export default SearchCandidateService;