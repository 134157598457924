import React, { useState, useEffect } from "react";
import Loader from "../../component/Loader";
import ManageService from "../../service/ManageService";
import { ErrorToaster } from "../../component/modal/ErrorModal";
import { SuccessToaster } from "../../component/modal/SuccessModal";
import UseForm from "../../service/FormService";
import $ from "jquery";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const stateSchema = {
  header: { value: "", error: "" },
  aboutus: { value: "", error: "" },
  missionHeader1: { value: "", error: "" },
  missionText1: { value: "", error: "" },
  missionHeader2: { value: "", error: "" },
  missionText2: { value: "", error: "" },
  missionHeader3: { value: "", error: "" },
  missionText3: { value: "", error: "" },
  missionHeader4: { value: "", error: "" },
  missionText4: { value: "", error: "" },
};

const validationStateSchema = {
  header: {
    required: true,
  },
  aboutus: {
    required: true,
  },
  missionHeader1: {
    required: true,
  },
  missionText1: {
    required: true,
  },
  missionHeader2: {
    required: true,
  },
  missionText2: {
    required: true,
  },
  missionHeader3: {
    required: true,
  },
  missionText3: {
    required: true,
  },
  missionHeader4: {
    required: true,
  },
  missionText4: {
    required: true,
  },
};

const AboutUs = (props) => {
  const [loading, setLoading] = useState(true);
  const [errModel, setErrorModal] = useState({ show: false, msg: "" });
  const [successModel, setSuccessModal] = useState({
    show: false,
    msg: "",
  });
  const [intialSchema, setInitionalSchema] = useState(
    $.extend(true, {}, stateSchema)
  );

  useEffect(() => {
    if (props.selectedTab === "about_us") {
      getAboutUsData();
    }
  }, [props]);

  const getAboutUsData = async () => {
    const response = await ManageService.getAboutorContentPageData("about-us");
    if (response.status === 200) {
      updateInitialData(response.data.result?.content);
    }
    setLoading(false);
  };
  const updateInitialData = (response) => {
    let schema = $.extend(true, {}, stateSchema);
    schema.header.value = response.header || "";
    schema.aboutus.value = response.aboutus || "";
    schema.missionHeader1.value = response.missionHeader1 || "";
    schema.missionText1.value = response.missionText1 || "";
    schema.missionHeader2.value = response.missionHeader2 || "";
    schema.missionText2.value = response.missionText2 || "";
    schema.missionHeader3.value = response.missionHeader3 || "";
    schema.missionText3.value = response.missionText3 || "";
    schema.missionHeader4.value = response.missionHeader4 || "";
    schema.missionText4.value = response.missionText4 || "";
    setInitionalSchema(schema);
  };

  const onSubmitForm = (state) => {
    const requestPayload = {
      content: {
        header: state.header.value,
        aboutus: state.aboutus.value,
        missionHeader1: state.missionHeader1.value,
        missionText1: state.missionText1.value,
        missionHeader2: state.missionHeader2.value,
        missionText2: state.missionText2.value,
        missionHeader3: state.missionHeader3.value,
        missionText3: state.missionText3.value,
        missionHeader4: state.missionHeader4.value,
        missionText4: state.missionText4.value,
      },
      name: "about-us",
    };
    ManageService.addAboutorContentPageData(requestPayload).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          setSuccessModal({ show: true, msg: "Data updated Successfully" });
        }
      },
      (err) => {
        setErrorModal({ show: true, msg: err.data.message });
        setTimeout(() => {
          setErrorModal({ show: false, msg: "" });
        }, 3000);
      }
    );
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    intialSchema,
    validationStateSchema,
    onSubmitForm
  );

  return (
    <div id="category-list-container" className="about-us-container">
      <div className="container-fluid">
        <div className="row pb-3 filter-row">
          <div className="col-12 p-0">
            <h3>About Us</h3>
          </div>
        </div>
      </div>
      <div className="list-card list-view" id="category-list-view">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="content">Header </label>
              <input
                className="form-control"
                id="header"
                name="header"
                rows="5"
                onChange={handleOnChange}
                value={state.header.value}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="content">About Us </label>
              <CKEditor
                  data={state.aboutus.value}
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "blockQuote",
                      "link",
                      "numberedList",
                      "bulletedList",
                      "insertTable",
                      "|",
                      "alignment",
                      "|",
                      "undo",
                      "redo",
                    ],
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    //console.log( 'on change',{ event, editor, data } );
                    handleOnChange({
                      target: {
                        name: "aboutus",
                        value: data,
                      },
                    });
                  }}
                />
                <small
                  className="form-text  error-text m-0"
                  style={{
                    visibility: state.aboutus.error
                      ? "visible"
                      : "hidden",
                  }}
                >
                  {state.aboutus.error}
                </small>
              {/* <textarea
                className="form-control"
                id="aboutus"
                name="aboutus"
                rows="5"
                onChange={handleOnChange}
                value={state.aboutus.value}
              ></textarea> */}
            </div>
          </div>
          <div className="col-12 row">
            <div className="form-group col-6">
              <label htmlFor="content">Mission Header 1 </label>
              <input
                className="form-control"
                id="missionHeader1"
                name="missionHeader1"
                onChange={handleOnChange}
                value={state.missionHeader1.value}
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="content">Mission text 1 </label>
              <input
                className="form-control"
                id="missionText1"
                name="missionText1"
                onChange={handleOnChange}
                value={state.missionText1.value}
              />
            </div>
          </div>
          <div className="col-12 row">
            <div className="form-group col-6">
              <label htmlFor="content">Mission Header 2 </label>
              <input
                className="form-control"
                id="missionHeader2"
                name="missionHeader2"
                onChange={handleOnChange}
                value={state.missionHeader2.value}
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="content">Mission text 2 </label>
              <input
                className="form-control"
                id="missionText2"
                name="missionText2"
                onChange={handleOnChange}
                value={state.missionText2.value}
              />
            </div>
          </div>
          <div className="col-12 row">
            <div className="form-group col-6">
              <label htmlFor="content">Mission Header 3 </label>
              <input
                className="form-control"
                id="missionHeader3"
                name="missionHeader3"
                onChange={handleOnChange}
                value={state.missionHeader3.value}
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="content">Mission text 3 </label>
              <input
                className="form-control"
                id="missionText3"
                name="missionText3"
                onChange={handleOnChange}
                value={state.missionText3.value}
              />
            </div>
          </div>
          <div className="col-12 row">
            <div className="form-group col-6">
              <label htmlFor="content">Mission Header 4 </label>
              <input
                className="form-control"
                id="missionHeader4"
                name="missionHeader4"
                onChange={handleOnChange}
                value={state.missionHeader4.value}
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="content">Mission text 4 </label>
              <input
                className="form-control"
                id="missionText4"
                name="missionText4"
                onChange={handleOnChange}
                value={state.missionText4.value}
              />
            </div>
          </div>
          <div className="col-12">
            <button
              type="button"
              className="btn  btn-primary"
              disabled={disable}
              onClick={handleOnSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <ErrorToaster
        data={errModel}
        onClose={() => setErrorModal({ show: false, msg: "" })}
      ></ErrorToaster>
      <SuccessToaster
        data={successModel}
        onClose={() => setSuccessModal({ show: false, msg: "" })}
      ></SuccessToaster>
    </div>
  );
};

export default AboutUs;
