import React, { useState, useEffect } from 'react';
import ActionListIcon from '@material-ui/icons/MoreVert';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import Checkbox from '../../component/Checkbox';
import LanguageIcon from '@material-ui/icons/Language';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';

export const NewsListView = (props) => {
    const { userType } = useParams();
    //console.log("client list",props);
    const [rowSelected, setSelectedRow] = useState(false);

    useEffect(() => {
        if (props.rowSelected != 'intermediate') {
            setSelectedRow(props.rowSelected);
        }
    }, [props.rowSelected]);

    const onCheckBoxChange = (param) => {
        //console.log(' checkbox param',param);
        setSelectedRow(param);
        props.onRowChange(props.list.id, rowSelected);
    }
    const doAction = (e) => {
        //console.log("doing action");
        props.callAction(e, [props.list.id]);
    }

    return (
        <div className="list">
            <div className="container-fluid">
                <div className="row no-gutters">
                    <div className={`list-content col row no-gutters align-items-center ${props.list.isArchived ? ' archived' : props.list.isActive ? 'active' : 'inactive'}`}>
                        <div className="col-auto checbox-content  m-auto">
                            <Checkbox value={rowSelected} classes={'staff-list-checkbox '} change={onCheckBoxChange}></Checkbox>
                        </div>
                        <div className="user-name col text-truncate" title={props.list.heading}>
                            <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/news/view/${props.list.id}`}>{props.list.heading} </Link>
                        </div>
                        <div className="col-3 row-text text-truncate" title={props.list.source}>
                            <span className="text-value">{props.list.source ? <a href={props.list.source}>{props.list.source}</a> : ' NA '}</span>
                        </div>
                        <div className="row-text col-2 text-truncate pl-4">
                            <span className="text-value">
                                {props.list.createdOn ? <Moment date={props.list.createdOn} format={"MMM YYYY"}></Moment> : ' NA '}  </span>
                        </div>
                        <div className="row-text col-2 text-truncate pl-4">
                            <span className="text-value">
                                {props.list.addedBy ? `${props.list.addedBy.firstName} ${props.list.addedBy.lastName}` : ' NA '}  </span>
                        </div>
                        <div className="col-1 d-block text-truncate">
                            {props.list.isArchived ? <span className="badge badge-danger">Archived</span> : props.list.isActive ? <span className="badge badge-success">Active</span> : <span className="badge badge-warning">Inactive</span>}
                        </div>
                        <div className="action col-1">
                            <div className="btn-group action">
                                <ActionListIcon className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                <ul className="dropdown-menu">
                                    <li className="dropdown-item"><Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/news/view/${props.list.id}`}>View </Link></li>
                                    <li className="dropdown-item"><Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/news/edit/${props.list.id}`}>Edit </Link></li>
                                    {
                                        props.list.isArchived ?
                                            <li className="dropdown-item" data-value="unarchive" onClick={doAction}>Unarchive</li>
                                            : <li className="dropdown-item" data-value="archive" onClick={doAction}>Archive</li>
                                    }
                                    {
                                        props.list.isArchived ? "" :
                                            props.list.isActive ?
                                                <li className="dropdown-item" data-value="deactivate" onClick={doAction}>Deactivate</li> :
                                                <li className="dropdown-item" data-value="activate" onClick={doAction}>Activate</li>

                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export const NewsCardView = (props) => {
    const { userType } = useParams();

    const doAction = (e) => {
        props.callAction(e, [props.list.id]);
    }
    return (
        <div className="col-4">
            <div className={`news-card ${props.list.isArchived ? 'archived' : props.list.isActive ? 'active' : 'inactive'}`}>
                <div className="detail-section">
                    <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/news/view/${props.list.id}`}>
                        <div className="news-heading text-truncate my-1" title={props.list.heading}>{props.list.heading}</div>
                    </Link>
                    <div className="news-link text-truncate my-1" title={props.list.source}>
                        <LanguageIcon className="browser-icon" />
                        <a href={props.list.source}>{props.list.source}</a>
                    </div>
                    <div className="row no-gutters my-1">
                        <div className="news-date col">
                            <DateRangeOutlinedIcon className="date-icon" /> <Moment className="date-format" date={props.list.createdOn} format={"DD MMM YYYY"}></Moment>
                        </div>
                        <div className="float-right col-auto mt-1">
                            {props.list.isArchived ? <span className="badge badge-danger">Archived</span> : props.list.isActive ? <span className="badge badge-success">Active</span> : <span className="badge badge-warning">Inactive</span>}
                        </div>
                    </div>
                </div>
                <div className="action-section row no-gutters">
                    <div className="added-by-details col-6 text-truncate">
                        <img className="user-image" src={props.list.addedBy && props.list.addedBy.profilePic ? props.list.addedBy.profilePic.href : ''}></img>
                        <p className="user-name">{`${props.list.addedBy ? props.list.addedBy.firstName : ''} ${props.list.addedBy ? props.list.addedBy.lastName : ''}`}</p>
                    </div>
                    <div className="col"></div>
                    <div className="action-buttons col-auto">
                        <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/news/view/${props.list.id}`}> <VisibilityOutlinedIcon /> </Link>
                        <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/news/edit/${props.list.id}`}><EditOutlinedIcon /> </Link>
                        <MoreVertOutlinedIcon className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <ul className="dropdown-menu">
                            {
                                props.list.isArchived ?
                                    <li className="dropdown-item" data-value="unarchive" onClick={doAction}>Unarchive</li>
                                    : <li className="dropdown-item" data-value="archive" onClick={doAction}>Archive</li>
                            }
                            {
                                props.list.isArchived ? "" :
                                    props.list.isActive ?
                                        <li className="dropdown-item" data-value="deactivate" onClick={doAction}> Deactivate</li> :
                                        <li className="dropdown-item" data-value="activate" onClick={doAction}>Activate</li>
                            }
                        </ul>
                    </div>


                </div>
            </div>
        </div>
    )
}

const List = (props) => {
    return (
        <div></div>

    );
}

export default List;