import React, { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import Navbar from "../Navbar";
import { useHistory } from "react-router-dom";
import HomePageService from "../../../service/HomePageService";
import MultiRangeSlider from "multi-range-slider-react";
import CheckboxV2 from "../../../component/CheckboxV2";
import SelectLocation from "../../../component/modal/SelectLocation";
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import SelectRole from "../../../component/modal/SelectRole";
import TocIcon from '@material-ui/icons/Toc';
import CloseIcon from '@material-ui/icons/Close';
import MaleAvatar from '../../../../assets/imgs/male-avatar.png';
import FemaleAvatar from '../../../../assets/imgs/female-avatar.png';
import Loader from "../../../component/Loader";

const SearchPublicCandidate= (props) => {
  const history = useHistory();
  const [selectedRole, setSelectedRole] = useState(props?.location?.state?.skills?.split(',') || []);
  const [selectedLocation, setSelectedLocation] = useState(props?.location?.state?.locations?.split(',') || []);
  const [jobList, setJobList] = useState([]);
  const [jobData, setJobData] = useState({});
  const [minExp, setMinExp] = useState(0);
  const [maxExp, setMaxExp] = useState(60);
  const [roleList, setRoleList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [showLocationPopup, setShowlocationPopup] = useState({ show: false, location: [] });
  const [showRolePopup, setShowRolePopup] = useState({ show: false, role: [] });
  const [isFilterShow, setIsFilterShow] =useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getLocationList();
    getRoleList();
    fetchData();
  }, [selectedRole, selectedLocation, minExp, maxExp]);

  const getRoleList = async () => {
    try {
      const response = await HomePageService.getSelectedCategoryRole(selectedRole)
      const role = [];
      if (response.data && response.data.result) {
        response.data.result.checked.forEach((item) => {
          role.push({ label: item, checked: true });
        })
        response.data.result.uncheckedVals.forEach((item) => {
          role.push({ label: item, checked: false });
        })
        setRoleList([...role]);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("error", err);
    }
  }

  const getLocationList = async () => {
    try {
      const response = await HomePageService.getSelectedLocation(selectedLocation)
      const location = [];
      if (response.data && response.data.result) {
        response.data.result.checked.forEach((item) => {
          location.push({ label: item, checked: true });
        })
        response.data.result.uncheckedVals.forEach((item) => {
          location.push({ label: item, checked: false });
        })
        setLocationList([...location]);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("error", err);
    }
  }

  const fetchData = async () => {
    try {
      const payload = {
        "searchStr": selectedRole.join(','),
        "locationStr": selectedLocation.join(','),
        "minExp": minExp,
        "maxExp": maxExp
      };
      let response = await HomePageService.searchJobCandidate(payload);
      setJobList(response.data.result.data);
      setJobData(response.data.result);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("error", err);
    }
  };

  const updateSelectedLocation = (event, location) => {
    const selectedLocs = [...selectedLocation];
    if(event){
      selectedLocs.push(location)
    }else{
      const index = selectedLocation.indexOf(location);
      selectedLocs.splice(index, 1);
    }
    setSelectedLocation([...selectedLocs]);
  }

  const updateSelectedRole = (event, role) => {
    const selectedRoles = [...selectedRole];
    if(event){
      selectedRoles.push(role)
    }else{
      const index = selectedRoles.indexOf(role);
      selectedRoles.splice(index, 1);
    }
    setSelectedRole([...selectedRoles]);
  }


  const onLocationSelectionChange = (event, location) => {
    const locations = [...locationList];
    locations.forEach(loc => {
      if(loc.label === location){
        loc.checked = event;
      }
    })
    setLocationList([...locations]);
    updateSelectedLocation(event, location);
  }

  const onRoleSelectionChange = (event, role) => {
    const roles = [...roleList];
    roles.forEach(loc => {
      if(loc.label === role){
        loc.checked = event;
      }
    })
    setRoleList([...roles]);
    updateSelectedRole(event, role);
  }

  const showAllLocation = () => {
    const locationPopup = {...showLocationPopup};
    locationPopup.show = true;
    locationPopup.location = [...selectedLocation];
    setShowlocationPopup({...locationPopup});
  }

  const showAllRole = () => {
    const rolePopup = {...showRolePopup};
    rolePopup.show = true;
    rolePopup.role = [...selectedRole];
    setShowRolePopup({...rolePopup});
  }

  const onLocationSelection = (event) => {
    setSelectedLocation([...event]);
    onCancelPopup();
  }

  const onRoleSelection = (event) => {
    setSelectedRole([...event]);
    onCancelPopup();
  }

  const onCancelPopup = () => {
    const locationPopup = {...showLocationPopup};
    locationPopup.show = false;
    const rolePopup = {...showRolePopup};
    rolePopup.show = false;
    setShowRolePopup({...rolePopup});
    setShowlocationPopup({...locationPopup});
  }

  const showFilter = () => {
    scrollToTop();
    setIsFilterShow(!isFilterShow);
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const goToLoginPage = () => {
    history.push('/cli/login');
  };

  if (isLoading) return <Loader />;
  return (
    <div id="public-job-list">
      <Navbar></Navbar>
      <div className="container-fluid job-list-container">
        <div className="container selected-filter-container container-md container-sm container-xs">
          <div className="row align-items-center no-gutters">
            {jobData.count && jobData.count.map((item, index) => {
              return (
                <div className="col-lg-2 col-xl-2 col-md-3 col-sm-4 col-xs-6 mb-2" key={index}>
                  <div className="filter-card-wrapper row no-gutters mx-2">
                    <div className="count-section col-12">{item.count}+ Jobs</div>
                    <div className="filter-name-section col-12 text-truncate">{item.name}</div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="d-lg-none d-xl-none d-md-none d-xs-block d-sm-block mx-2">
            <button type="button" className="btn btn-custom" onClick={showFilter}><TocIcon></TocIcon>Filters</button>
          </div>
        </div>
        <div className="container list-container container-md container-sm container-xs">
          {isFilterShow ? <CloseIcon className="close-icon" onClick={showFilter}></CloseIcon> : ''}
          <div className="row align-items-start">
            <div className={"col-xl-3 col-lg-4 col-md-4 d-xl-block d-lg-block d-md-block  p-0 filter-list-section" +  ( isFilterShow ? ' d-sm-block d-xs-block' : ' d-sm-none d-xs-none')}>
              <div className="row no-gutters px-2 filter-list-section-wrapper">
                <div className="col-12 section-title">Filters</div>
                <div className="col-12 row no-gutters">
                  <div className="col-12 category-title py-1">Location</div>
                  <div className="col-12 row no-gutters option-container">
                    {locationList.length && locationList.map((item, index) => {
                      if (index < 10) {
                        return (
                          <div className="custom-check col-12 d-flex" key={index}>
                            <CheckboxV2 value={item.checked}
                              classes="home-page-list-checkbox"
                              name={`location-filter-${index}`}
                              size={16}
                              change={(event) => onLocationSelectionChange(event, item.label)}>
                            </CheckboxV2>
                            <label className="home-page-list-checkbox pl-2" >{item.label}</label>
                          </div>
                        )
                      }
                    })}
                    {locationList.length > 10 ? (
                      <div className="custom-check col-12 d-flex view-more" onClick={showAllLocation}>
                        View More
                      </div>
                    ):""}
                  </div>
                </div>
                <div className="col-12 row no-gutters">
                  <div className="col-12 category-title py-1">Role</div>
                  <div className="col-12 row no-gutters option-container align-items-center">
                    {roleList.length && roleList.map((item, index) => {
                      if (index < 10) {
                        return (
                          <div className="custom-check col-12 d-flex" key={index}>
                            <CheckboxV2 value={item.checked}
                              classes="home-page-list-checkbox"
                              name={`role-filter-${index}`}
                              size={16}
                              change={(event) => {onRoleSelectionChange(event, item.label)}}>
                            </CheckboxV2>
                            <label className="home-page-list-checkbox pl-2" >{item.label}</label>
                          </div>
                        )
                      }
                    })}
                    {roleList.length > 10 ? (
                      <div className="custom-check col-12 d-flex view-more" onClick={showAllRole}>
                        View More
                      </div>
                    ):""}
                  </div>
                </div>
                <div className="col-12 row no-gutters">
                  <div className="col-12 category-title py-1">Experience (In Years)</div>
                  <MultiRangeSlider
                    min={0}
                    max={60}
                    step={1}
                    ruler={false}
                    baseClassName="multi-range-slider experience-range-slider"
                    minValue={minExp}
                    maxValue={maxExp}
                    onInput={(e) => {
                      setMinExp(e.minValue);
                      setMaxExp(e.maxValue);
                    }}></MultiRangeSlider>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12 col-xs-12 job-list-section row">
              {jobList && jobList.length > 0 && <div className="col-12 pagination-section">1 - {jobList.length} of {jobData.total}</div>}
              <div className="col-12 job-card-section">
                {jobList && jobList.length > 0 ? jobList.map((item, index) => {
                  return (
                    <div className="job-card" key={index}>
                      <div className="row no-gutters">
                        <div className="col-auto image-section px-3">
                          {item.gender  === 'male'? (
                            <div className="round-shape" style={{backgroundColor: 'transparent'}}>
                              <img className="round-image" src={MaleAvatar}></img>
                            </div>

                          ) : (
                            <div className="round-shape" style={{backgroundColor: 'transparent'}}>
                              <img className="round-image" src={FemaleAvatar}></img>
                            </div>
                          )}
                        </div>
                        <div className="col content-section row no-gutters">
                          <div className="col-12 job-card-header">{item.displayName}</div>
                          <div className="col-12 py-1">{item.designation}</div>
                          <div className="col-12 row no-gutters py-1">
                            <div className="col-6 col-xs-12 text-truncate"> <b>Experience:</b> {item.experienceInYr ?  item.experienceInYr + 'Years ': "NA"}{item.experienceInMnth ? item.experienceInMnth + 'Months' : ''}</div>
                            <div className="col-6 col-xs-12 text-truncate job-card-location"><b>Location:</b> {item?.location || "NA"}</div>
                          </div>
                          <div className="col-12  py-1">
                            <b>Role:</b> {item?.role || "NA"}
                          </div>
                          <div className="col-12 text-right">
                            <button className="contact-btn" onClick={goToLoginPage}>View Details</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }): (
                  <div className="no-result-section d-flex flex-wrap">
                      
                      <div className="err-text-section text-center">
                        <NewReleasesIcon></NewReleasesIcon>
                        <div className="err-text">No records present for the selected filter, Please change the filter to see records.</div>
                      </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SelectLocation data={showLocationPopup} onConfirmAction={onLocationSelection} onCancel={onCancelPopup} ></SelectLocation>
      <SelectRole data={showRolePopup} onConfirmAction={onRoleSelection} onCancel={onCancelPopup} ></SelectRole>
    </div>
  );
};

export default SearchPublicCandidate;
