import React from "react";
import Carousel from "react-multi-carousel";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const CompanyList = ({ data: clientList }) => {
  return (
    <div id="company-list">
      <div className="container py-4">
        <div className="row section-header text-center p-4">
          <h2 className="col-md-12">Top Companies</h2>
          <p className="col-md-12">Top companies who trust us</p>
        </div>
      </div>
      <div className="company-carousel-container px-0 py-4">
        <div className="container">
          <Carousel
            responsive={responsive}
            containerClass="py-4"
            itemAriaLabel="company list"
            itemClass="d-flex align-items-center justify-content-center"
            arrows={false}
            swipeable={false}
            draggable={false}
            showDots={true}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            transitionDuration={500}
            removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            {clientList.map((item, index) => {
              return (
                <div className="row" key={item.id}>
                  <div className="col-auto company-icon-card d-flex align-items-center justify-content-center">
                    <img src={item.logo?.href} className="img-fluid" />
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default CompanyList;
