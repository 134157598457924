import React from 'react';
import {Link} from 'react-router-dom';
import LoginImage from '../../assets/imgs/login3.png';

class Login extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            currPassword:'',
            newPassword:'',
            conPassword:'',
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    onChange(e){
        console.log("field change",e);
    }

    onSubmit(e){
        console.log("form submit" ,e);
    }

    render(){
        return (
            <div className="container-fluid p-0 m-0">
                <div className= "form-wrapper login-page-container">
                    <div className="row vh-100 w-100">
                        <div className="col-7">
                            <img src={LoginImage} className="login-bg-img"></img>
                        </div>
                        <div className="col-5">
                            <div className="container vh-100 w-100">
                                <div className="row align-items-center vh-100 w-100">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="login-text text-center">
                                            <span className="text-msg">Reset Password</span>
                                        </div>
                                        <form onSubmit={this.onSubmit}>
                                            <div className="form-group">
                                                <label className="label-sm">Current Password</label>
                                                <input type="password" className="form-control " id="currpassword" placeholder="********" name="currpassword" value={this.state.currPassword} onChange={this.onChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label className="label-sm">New Password</label>
                                                <input type="password" className="form-control " id="newpassword" placeholder="********" name="newpassword" value={this.state.newPassword} onChange={this.onChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label className="label-sm">Confirm Password</label>
                                                <input type="password" className="form-control " id="confpassword" placeholder="********" name="confpassword" value={this.state.conPassword} onChange={this.onChange}/>
                                            </div>
                                            <div className="action-btn text-center">
                                                <button type="submit" className="btn  btn-primary login-btn">Confirm</button>
                                            </div>
                                        </form>

                                        <div className="bottom-align">
                                            <p className="text-right">Don't have you an account?
                                                <Link to="/signup" className="no-decoration">
                                                    <span className="signup-text">Sign Up</span>
                                                </Link>    
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;