const LocalStorageService = (function () {
  let _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setData(data) {
    if (data && data.token) {
      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("usertype", JSON.stringify(data.userType));
      localStorage.setItem("access_token", JSON.stringify(data.token));
      localStorage.setItem("permissions", JSON.stringify(data.permissions));
      localStorage.setItem("hasViewedTnc", JSON.stringify(data.hasViewedTnc || false));
    }
  }
  function _getRoutePrefix(user) {
    const userType = user || JSON.parse(localStorage.getItem("usertype")) || "";
    let prefix = "";
    switch (userType) {
      case "Owner":
        prefix = "adm";
        break;
      case "Staff":
        prefix = "stf";
        break;
      case "Client":
        prefix = "cli";
        break;
      case "Candidate":
        prefix = "can";
        break;
      default:
        console.log("invalid UserType");
    }
    return prefix;
  }
  function _getUserTypeFromPrefix(userPrifix) {
    let userType = "";
    switch (userPrifix) {
      case "adm":
        userType = "Owner";
        break;
      case "stf":
        userType = "Staff";
        break;
      case "cli":
        userType = "Client";
        break;
      case "can":
        userType = "Candidate";
        break;
      default:
        console.log("invalid prefix");
    }
    return userType;
  }

  function _getUserData() {
    return JSON.parse(localStorage.getItem("user"));
  }

  function _getUserType() {
    return JSON.parse(localStorage.getItem("usertype"));
  }
  function _getPermision() {
    return JSON.parse(localStorage.getItem("permissions"));
  }
  function _getClientData() {
    return JSON.parse(localStorage.getItem("client"));
  }
  function _setToken(tokenObj) {
    localStorage.setItem("access_token", JSON.stringify(tokenObj.token));
  }
  function _getAccessToken() {
    return JSON.parse(localStorage.getItem("access_token"));
  }
  function _clearToken() {
    localStorage.clear();
  }
  function _getServerIp() {
    return "https://doctorjobbs.com/api/";
  }
  function _gethasViewedTnc() {
    return JSON.parse(localStorage.getItem("hasViewedTnc"));
  }
  function _sethasViewedTnc(value) {
    localStorage.setItem("hasViewedTnc", JSON.stringify(value));
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    clearToken: _clearToken,
    setData: _setData,
    getUserData: _getUserData,
    getUserType: _getUserType,
    getPermision: _getPermision,
    getRoutePrefix: _getRoutePrefix,
    getUserTypeFromPrefix: _getUserTypeFromPrefix,
    getClientData: _getClientData,
    getServerIp: _getServerIp,
    getHasViewedTnc: _gethasViewedTnc,
    setHasViewedTnc: _sethasViewedTnc
  };
})();
export default LocalStorageService;
