import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import NoDataFound from '../../../common/NoDataFound';
import CandidateService from '../../../service/JobPostService';
import Loader from '../../../component/Loader';
import ConfirmModal from '../../../component/modal/ConfirmModal';
import { CandidateListView } from './List';
import ErrorModal from '../../../component/modal/ErrorModal';
import SuccessModal from '../../../component/modal/SuccessModal';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useParams } from 'react-router-dom';

const NoData = (props) => {
    return (
        <div className="content-wrapper">
            <div className="content-container">
                <NoDataFound name={props.name} redirectUrl={props.redirectUrl} hideButton={props.hideButton}></NoDataFound>
            </div>
        </div>
    )
}

const Main = (props) => {
    const { userType } = useParams();
    const [loading, setLoading] = useState(true);
    const [candidateList, setCandidateList] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [confirmationObj, setConfirmationObj] = useState({ show: false, msg: '' });
    const [paginationObj, setPaginationObj] = useState({});
    const [listLoading, setListLoading] = useState(false);
    const [currentAction, setCurrentAction] = useState('');
    const [errModel, setErrorModal] = useState({ show: false, error: {} })
    const [successModel, setSuccessModal] = useState({ show: false, success: {} });
    const [permission, setPermission] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [filterValue, setFilterValue] = useState('all');
    const thresholdHeight = 40;

    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        if (queryParams.has('type')) {
            setFilterValue(queryParams.get('type'))
            queryParams.delete('type')
            history.replace({
                search: queryParams.toString(),
            })
        }
    }, []);

    useEffect(() => {
        getCandidateList()
    }, [searchValue, filterValue])

    const getCandidateList = async (url) => {
        const id = localStorage.getItem('jobPostId')
        const response = await CandidateService.getCandidateList(id, searchValue, filterValue, url);
        if (response.status === 200) {
            if (url) {
                let list = []
                list = list.concat(candidateList.data, response.data.result.data);
                response.data.result.data = list;
            }

            if (filterValue !== 'bot') {
                let candidates = response.data.result.data.map(cand => {
                    let obj = {}
                    obj = cand.candidate
                    obj['candId'] = cand.id
                    obj['lastViewedOn'] = cand.lastViewedOn
                    obj['submittedBy'] = cand.submittedBy
                    obj['submittedOn'] = cand.createdOn
                    obj['isRejected'] = cand.isRejected
                    obj['isShortlisted'] = cand.isShortlisted
                    return obj
                })
                let candObj = { 'data': candidates }
                //console.log(candObj)
                setCandidateList(candObj);
            }
            else {
                setCandidateList(response.data.result);
            }
            setPaginationObj(response.data.detail);
            setLoading(false);
            setListLoading(false);
        }
        else {
            setLoading(false);
            setListLoading(false);
        }
    }

    const handleScroll = (e) => {
        e.persist();
        e.preventDefault();
        const scrollableHeight = e.target.scrollHeight - e.target.clientHeight;
        if (!paginationObj.links.next || listLoading) {
            return;
        }
        if (scrollableHeight - e.target.scrollTop < thresholdHeight) {
            setListLoading(true);
            getCandidateList(paginationObj.links.next);
        }
    }

    // const changeListView = () =>{
    //     setListView(!listView);
    // }

    const selectedRowChange = (id) => {
        let selectedList = selectedRow;
        const ocuuranceId = selectedList.indexOf(id);
        ocuuranceId > -1 ? selectedList.splice(ocuuranceId, 1) : selectedList.push(id);
        setSelectedRow(selectedList);
    }

    const closePopup = () => {
        setConfirmationObj({ show: false, msg: '' });
        setCurrentAction("");
    }

    const performAction = (e, list) => {
        //console.log("action",e);
        e.persist()
        e.preventDefault();
        const action = e.target.dataset.value;
        setCurrentAction({ action: action, data: list ? list : selectedRow });
        setConfirmationObj({ show: true, msg: `You want to ${action} this candidate?` });
    }

    const doAction = async () => {
        setLoading(true);
        setConfirmationObj({ show: false, msg: '' });

        try {
            const res = await CandidateService.updateCandidateStatus(currentAction.action, { userIds: currentAction.data });
            setCurrentAction({});
            setLoading(false);
            //console.log("success res", res);
            if (res.status === 200) {
                setSuccessModal({ show: true, success: { msg: res.data.result } });
                getCandidateList();
            }
        } catch (err) {
            //console.log(err);
            setLoading(false);
            if (err && err.data) {
                setErrorModal({ show: true, error: err.data })
            }
        }
    }

    const onSearch = (e) => {
        e.persist()
        e.preventDefault();
        e.stopPropagation()
        const value = e.target.value;
        if (value && value.length > 2 || !value) {
            setSearchValue(value)
            // getCandidateList();
        }
    }

    const onFilterChange = (event) => {
        const text = event.target.value
        setFilterValue(text)
        // getCandidateList();
    }

    if (loading) return <Loader />;

    return (
        <div className="content-wrapper p-0" id="condidate-list-container">
            <div className="list-card list-view" id="candidate-list-view">
                <ul className="list-container m-0">
                    <li className="list border-0 m-0">
                        <div className="container-fluid">
                            <div className="row pb-3 filter-row" style={{ alignItems: 'center' }}>
                                <div className="col-3">
                                    <h5>Matched Candidates</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-group m-0">
                                        <div className="d-flex justify-content-center">
                                            <input type="text" className="form-control search-input" placeholder="Search Here" onChange={onSearch} />
                                            <SearchIcon className="search-icon"></SearchIcon>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <FormControl variant="outlined" className="w-100">
                                        <InputLabel id="demo-simple-select-outlined-label">Filter By</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={filterValue}
                                            onChange={(event) => onFilterChange(event)}
                                            label="Tenure"
                                        >
                                            <MenuItem value="all">All Profiles</MenuItem>
                                            <MenuItem value="applied">Interested Applicants</MenuItem>
                                            <MenuItem value="bot">Bot Suggestion</MenuItem>
                                            <MenuItem value="suggested">Support Team Suggestion</MenuItem>
                                            <MenuItem value="selected">Selected By Employer</MenuItem>
                                            <MenuItem value="rejected">Rejected Applicants</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-2 text-center p-0">
                                    {paginationObj.totalCount && candidateList.data ? <span>{candidateList.data.length} of {paginationObj.totalCount} Users </span> : ''}
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul className="list-container search-cand-list" onScroll={handleScroll}>
                    <div className="container">
                        <div className="row">
                            {
                                candidateList.data.length ?
                                    candidateList.data.map((item) => { return (<CandidateListView list={item} key={item.id} onRowChange={selectedRowChange} callAction={performAction} type={filterValue} candId={item.candId}> </CandidateListView>) }) :
                                    <h4 className="text-center no-cand">No Candidates Found</h4>
                            }
                            {listLoading ? <li className="List text-center">
                                <div className="spinner-border text-primary table-loader" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div></li> : ''}
                        </div>
                    </div>
                </ul>
            </div>
            <ConfirmModal data={confirmationObj} onConfirmAction={doAction} onCancel={closePopup}></ConfirmModal>
            <ErrorModal data={errModel} onConfirmAction={() => setErrorModal({ show: false, error: {} })} ></ErrorModal>
            <SuccessModal data={successModel} onConfirmAction={() => setSuccessModal({ show: false, success: {} })}></SuccessModal>
        </div>
    )
}

export default Main;