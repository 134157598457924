import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import AuthService from "../../../../service/AuthService";
import { SuccessToaster } from "../../../../component/modal/SuccessModal";
import { ErrorToaster } from "../../../../component/modal/ErrorModal";
import UseForm from '../../../../service/FormService';
import Autocomplete from 'react-select';
import CandidateService from '../../../../service/CandidateService';
import { useDebouncedCallback } from 'use-debounce';
import { param } from "jquery";

const initialSchema = {
    "preferredLocations": { value: [], error: "" },
    "state": { value: "", error: "" },
    "city": { value: "", error: "" },
    "town": { value: "", error: "" },
    "isCurrentLocation": { value: false, error: "" }
}

const validationStateSchema = {
    preferredLocations: { required: false },
    state: { required: true },
    city: { required: true },
    town: { required: false },
    isCurrentLocation: { required: false },
};

const LocationStep = forwardRef((props, ref) => {
    const [stateSchema, setStateSchema] = useState(initialSchema);
    const [cityList, setCityList] = useState([]);
    const [cityData, setCityData] = useState({});
    const [selectedCity, setSelectedCity] = useState([]);
    const [cityLoading, setCityLoading] = useState(true);
    const [currentAddress, setCurrentAddress] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [cityLists, setCityLists] = useState([]);
    const [successObj, setSuccessObj] = useState({ show: false, success: {} });
    const [errorObj, setErrorObj] = useState({ show: false, msg: "" });
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        getAllCityList();
        getStateList();
        if (props.wizard && props.wizard.location) {
            upDateInitialData();
        }
        setCityLoading(false)
        props.canProceed(false);
    }, []);

    const upDateInitialData = () => {
        const data = props.wizard.personal;
        let schema = { ...stateSchema };
        Object.keys(schema).forEach(key => {
            if (data.hasOwnProperty(key)) {
                schema[key].value = data[key];
            }
        })
        setStateSchema(schema);
    };

    const getStateList = async () => {
        try {
            const response = await CandidateService.getStateList();
            setStateList(response.data.result);
        } catch (err) {
            console.log(err)
        }
    }

    const getCityByStateId = async (stateId) => {
        try {
            const response = await CandidateService.getCityList(stateId);
            setCityLists(response.data.result);
        } catch (err) {
            console.log(err);
        }
    }
    const getAllCityList = async (param, url) => {
        try {
            const response = await CandidateService.getCityListByPagination(param, url);
            if (url) {
                let tempDataArr = cityList;
                tempDataArr = tempDataArr.concat(response.data.result.data);
                response.data.result.data = tempDataArr;
            }
            setCityData(response.data)
            setCityList([...response.data.result.data]);
        } catch (err) {
            console.log(err)
        }

    }

    const paginateCityList = (e) => {
        if (cityData.detail.links && cityData.detail.links.next) {
            setCityLoading(true)
            getAllCityList("", cityData.detail.links.next);
            setCityLoading(false)
        }

    }

    const debounce = useDebouncedCallback((param, actionType) => {
        onCityInputChange(param, actionType);
    }, 300);

    const onCityInputChange = (param, actionType, list) => {
        if (actionType.action && actionType.action === 'input-change') {
            setCityList([]);
            param ? getAllCityList(param) : getAllCityList();
        }
        else if (actionType.action === 'menu-close' && !selectedCity.length > 0)
            getAllCityList()
    }

    const cityChange = (cities) => {
        if (cities && cities.length > 0) {
            setSelectedCity(cities);
            handleOnChange({ target: { name: 'preferredLocations', value: cities } })
        }
        else {
            setSelectedCity([]);
            handleOnChange({ target: { name: 'preferredLocations', value: '' } })
        }
        getAllCityList()
    }

    const onStateChange = (e) => {
        e.persist();
        handleOnChange({ target: { name: 'state', value: e.target.value } });
        handleOnChange({ target: { name: 'city', value: '' } });
        handleOnChange({ target: { name: 'town', value: '' } });
        getCityByStateId(e.target.value);
    }

    const getCityList = async (courseId) => {
        try {
            const response = await CandidateService.getCityList(courseId);
            setCityList(response.data.result);
        } catch (err) {
            //console.log(err);
        }
    };

    const customFilter = (option, inputValue) => {
        if (option.data.name && inputValue) {
            return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        }
        return true;
    };

    const currentAddressChanges = (data) => {
        handleOnChange({
            target: { name: "isCurrentLocation", value: data ? data : false },
        });
        setCurrentAddress(data);
    };

    const onSubmitForm = (state) => {
        setEditMode(false);
        props.canProceed(true);
        setChanged(true);
    }

    const createSignupData = (location) => {
        const data = { location, ...props.wizard };
        const requestData = {};
        // console.log(data, requestData)
        for (let key in data) {
            if (key === 'location') {
                requestData.currentLocation = {};
                for (let loc in data[key]) {
                    if (loc === 'preferredLocations') {
                        if (data[key][loc] === "") {
                            data[key][loc] = []
                        }
                        requestData.preferredLocations = data[key][loc].map((location) => location.id)
                    }
                    else {
                        requestData.currentLocation[loc] = data[key][loc];
                    }
                }
            }
            else if (key === 'personal') {
                for (let per in data[key]) {
                    requestData[per] = data[key][per];
                }
            }
            else if (key === 'professional') {
                requestData.professional = {};
                for (let prof in data[key]) {
                    if (prof === 'category') {
                        requestData.professional.categoryId = data[key][prof].id;
                    }
                    else if (prof === 'role') {
                        requestData.professional.roleId = data[key][prof].id;
                    } else if (prof === 'resume') {
                        requestData.resume = data[key][prof].id;
                    }
                    else {
                        requestData.professional[prof] = data[key][prof];
                    }
                }
            } else {
                requestData.qualification = {};
                for (let qali in data[key]) {
                    if (qali === 'qualificationId' || qali === 'courseId' || qali === 'specializationId') {
                        requestData.qualification[qali] = data[key][qali].id;
                    }
                    else if (qali === "joinedOn" || qali === "passoutYear") {
                        requestData.qualification[qali] = data[key][qali] ? new Date(data[key][qali]).getFullYear() : null;
                    } else {
                        requestData.qualification[qali] = data[key][qali];
                    }

                }
            }
        }

        return requestData;

    }
    const closeToast = (e) => {
        e.preventDefault();
        setErrorObj({ show: false, msg: "" });
    };

    const { userType } = useParams();
    useImperativeHandle(ref, () => ({
        async submit() {
            props.enableLoader(true);
            const location = {};
            for (let key in state) {
                location[key] = state[key].value;
            }
            const requestData = createSignupData(location);
            try {
                const response = await AuthService.signup(requestData);
                if (response.status === 201) {
                    props.enableLoader(false);
                    setSuccessObj({ show: true, msg: "Account Registered Successfully. Please Check your Email For Account Activation Link!" });
                    if (userType) {
                        history.push(`/${userType}/candidate`);
                        window.location.reload();
                    } else {
                        history.push(`/can/login`);
                    }
                }
            } catch (err) {
                props.enableLoader(false);
                console.log("Error", err);
                setErrorObj({ show: true, msg: err.data.message });
            }
        }
    }));

    const { state, handleOnChange, handleOnSubmit, disable, setValueWithoutError } = UseForm(stateSchema, validationStateSchema, onSubmitForm);
    useEffect(() => {
        validateInput();
        props.canProceed(true);
    }, [state]);

    const validateInput = () => {
        let isValid = true;
        for (let key in state) {
            if ((validationStateSchema[key].required && !state[key].value) || state[key].error) {
                isValid = false;
            }
        }
        if (isValid) {
            props.canProceed(true);
        } else {
            props.canProceed(false);
        }
    }

    return (
        <div className="candidate-signup-location-step">
            <div className="form-wrapper h-100">
                <div className="candidate-personal-card mt-4">
                    <div className="cs-header row no-gutters">
                        <p className="cs-title col"><b>Location</b></p>
                    </div>
                    <div className="cs-body">
                        <div className="row no-gutter">
                            <div className="form-group col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                <label className="label-sm">State <sup className="required">*</sup></label>
                                <select className="form-control" name="state" id="state" onChange={onStateChange} value={state.state.value}>
                                    <option value="">Select State</option>
                                    {stateList ? stateList.map((state, index) => { return (<option key={index} value={state.id}>{state.name}</option>) }) : ''}
                                </select>
                                <small className="form-text  error-text m-0" style={{ visibility: state.state.error ? 'visible' : 'hidden' }}>{state.state.error}</small>
                            </div>
                            <div className="form-group col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                <label className="label-sm">City <sup className="required">*</sup></label>
                                <select className="form-control" name="city" id="city" onChange={handleOnChange} value={state.city.value} disabled={state.state.value ? false : true}>
                                    <option value="">Select City</option>
                                    {cityLists ? cityLists.map((city, index) => { return (<option key={index} value={city.id}>{city.name}</option>) }) : ''}
                                </select>
                                <small className="form-text  error-text m-0" style={{ visibility: state.city.error ? 'visible' : 'hidden' }}>{state.city.error}</small>
                            </div>
                            <div className="form-group col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                <label className="label-sm">Town </label>
                                <input type="text" className="form-control" placeholder="Town" id="town" name="town" value={state.town.value} onChange={handleOnChange} />
                                <small className="form-text  error-text m-0" style={{ visibility: state.town.error ? 'visible' : 'hidden' }}>{state.town.error}</small>
                            </div>

                            <div className="form-group col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                <div className="margin-auto-top highest-qualification">
                                    <label className="label-sm mr-4 mb-3">
                                        is Current Address
                                    </label>
                                    <div
                                        className="switch"
                                        onClick={() =>
                                            currentAddressChanges(!currentAddress)
                                        }
                                    >
                                        {currentAddress ? (
                                            <input
                                                type="checkbox"
                                                name="isCurrentLocation"
                                                id="isCurrentLocation"
                                                value={currentAddress}
                                                checked={currentAddress}
                                                onChange={() => { }}
                                            />
                                        ) : (
                                            <input
                                                type="checkbox"
                                                name="isCurrentLocation"
                                                id="isCurrentLocation"
                                                value={currentAddress}
                                                checked={currentAddress}
                                                onChange={() => { }}
                                            />
                                        )}
                                        <span className="slider round"></span>
                                    </div>
                                    <small
                                        className="form-text  error-text m-0"
                                        style={{
                                            visibility: state.isCurrentLocation.error
                                                ? "visible"
                                                : "hidden",
                                        }}
                                    >
                                        {state.isCurrentLocation.error}
                                    </small>
                                </div>
                            </div>

                            <div className="form-group col-12 m-0">
                                <label className="label-sm">Preferred Locations<sup className="required">*</sup></label>
                                <Autocomplete
                                    name="preferred-locations"
                                    placeholder="Preferred Locations"
                                    defaultOptions
                                    isMulti={true}
                                    isClearable={true}
                                    options={cityList}
                                    value={selectedCity && selectedCity.length > 0 ?
                                        selectedCity.map(city => {
                                            return { name: city.name, id: city.id }
                                        }) : null}
                                    filterOption={customFilter}
                                    isLoading={cityLoading}
                                    getOptionLabel={(option) => {
                                        return (
                                            <div className="text col p-1 text-truncate">
                                                <div>{option.name}</div>
                                            </div>
                                        );
                                    }}
                                    getOptionValue={(option) => {
                                        return option.id;
                                    }}
                                    onChange={cityChange}
                                    onMenuScrollToBottom={paginateCityList}
                                    onInputChange={debounce}
                                />
                                <small className="form-text  error-text m-0" style={{ visibility: state.preferredLocations.error ? 'visible' : 'hidden' }}>{state.preferredLocations.error}</small>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <SuccessToaster
                data={successObj}
                onClose={() => setSuccessObj({ show: false, success: {} })}
            ></SuccessToaster>
            <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
        </div>
    )
})
export default LocationStep;