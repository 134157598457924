import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useMemo,
} from "react";
import CandidateService from "../../../../service/CandidateService";
import { useParams, Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import Moment from "react-moment";
import Autocomplete from "react-select";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import UseForm from "../../../../service/FormService";
import $ from "jquery";
import ErrorModal from "../../../../component/modal/ErrorModal";

const initialSchema = {
    qualificationId: { value: "", error: "" },
    courseId: { value: "", error: "" },
    specializationId: { value: "", error: "" },
    isCurrentlyPursuing: { value: false, error: "" },
    joinedOn: { value: "", error: "" },
    passoutYear: { value: "", error: "" },
    isHighestQualification: { value: false, error: "" },
    organizationName: {value: "", error: ""}
};

const validationStateSchema = {
    qualificationId: { required: true },
    courseId: { required: true },
    specializationId: { required: true },
    isCurrentlyPursuing: { required: false },
    joinedOn: { required: false },
    passoutYear: { required: false },
    isHighestQualification: { required: false },
    organizationName: { required: false }
};

const initialData = { valid: false, data: {} };

const initData = (props) => {
    //console.log("init Data",props.wizard.qualifications)
    let data = [];
    props.wizard.qualifications.forEach((item, index) => {
        let itemData = item;
        itemData.joinedOn = itemData.joinedOn
            ? new Date(itemData.joinedOn.toString())
            : new Date();
        itemData.passoutYear = itemData.passoutYear
            ? new Date(itemData.passoutYear.toString())
            : new Date();
        data.push({ valid: true, data: itemData });
    });
    return data;
};

const QualificationStep = forwardRef((props, ref) => {
    const { id } = useParams();
    const [stepData, setStepData] = useState({});
    const [qualificationList, setQualificationList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [stateSchema, setStateSchema] = useState(initialSchema);
    const [selectedQual, setSelectedQual] = useState({});
    const [courseList, setCourseList] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState({});
    const [specificationList, setSpecificationList] = useState([]);
    const [selectedSpecification, setSelectedSpecification] = useState({});
    const [highestQual, setHighestQual] = useState(false);
    const [currentlyPresuing, setCurrentlyPresuing] = useState(false);
    const [errorObj, setErrorObj] = useState({ show: false, error: {} });

    useEffect(() => {
        getQualificationList();
        setLoading(false);
        if (props.wizard && props.wizard.qualification) {
            upDateInitialData();
        }
    }, []);

    const upDateInitialData = () => {
        const data = props.wizard.qualification;
        setSelectedQual({
            id: data.qualificationId.id,
            name: data.qualificationId.name,
        });

        setSelectedCourse({ id: data.courseId.id, name: data.courseId.name });
        getCourseList(data.qualificationId.id, data.courseId.name);

        getSpecificationList(data.courseId.id, data.specializationId.name);
        setSelectedSpecification({
            id: data.specializationId.id,
            name: data.specializationId.name,
        });
        setHighestQual(data.isHighestQualification);
        setCurrentlyPresuing(data.isCurrentlyPursuing);

        let schema = { ...stateSchema };
        schema.qualificationId.value = data.qualificationId;
        schema.specializationId.value = data.specializationId;
        schema.courseId.value = data.courseId;
        schema.joinedOn.value = data.joinedOn ? data.joinedOn : null;
        schema.passoutYear.value = data.passoutYear ? data.passoutYear : null;
        schema.isCurrentlyPursuing.value = data.isCurrentlyPursuing;
        schema.isHighestQualification.value = data.isHighestQualification;
        schema.organizationName.value = data.organizationName;

        setStateSchema(schema);
    };

    const getQualificationList = async () => {
        try {
            const response = await CandidateService.getQualificationList();
            setQualificationList(response.data.result);
        } catch (err) {
            setLoading(false);
        }
    };


    const qualificationChanges = (data) => {
        setSelectedQual(data ? { name: data.name, id: data.id } : {});
        handleOnChange({
            target: { name: "qualificationId", value: data  || "" },
        });
        setSelectedCourse({});
        setSelectedSpecification({});

        if (data && data.id) getCourseList(data.id);
    };

    const getCourseList = async (qualificationId, param) => {
        try {
            const response = await CandidateService.getCourseList(
                qualificationId,
                param
            );
            setCourseList(response.data.result);
        } catch (err) {
            //console.log(err);
        }
    };
    const courseChanges = (data) => {
        setSelectedCourse(data ? { name: data.name, id: data.id } : {});
        handleOnChange({
            target: { name: "courseId", value: data || "" },
        });
        setSelectedSpecification({});
        if (data && data.id) getSpecificationList(data.id);
    };

    const getSpecificationList = async (courseId) => {
        try {
            const response = await CandidateService.getSpecificationList(courseId);
            setSpecificationList(response.data.result);
        } catch (err) {
            //console.log(err);
        }
    };

    const specificationChanges = (data) => {
        setSelectedSpecification(data ? { name: data.name, id: data.id } : {});
        handleOnChange({
            target: { name: "specializationId", value: data || "" },
        });
    };

    const customFilter = (option, inputValue) => {
        if(option.data.name && inputValue){
            return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        }
        return true;
    };

    const joinedOnChange = (data) => {
        handleOnChange({ target: { name: "joinedOn", value: data ? data : "" } });
    };

    const passoutYearChange = (data) => {
        handleOnChange({
            target: { name: "passoutYear", value: data ? data : "" },
        });
    };
    const onHieghestQualChange = (data) => {
        handleOnChange({
            target: { name: "isHighestQualification", value: data ? data : "" },
        });
        setHighestQual(data);
    };
    const onCurrentlyPursuingChange = (data) => {
        data && passoutYearChange();
        handleOnChange({
            target: { name: "isCurrentlyPursuing", value: data ? data : false },
        });
        setCurrentlyPresuing(data);
    };

    useImperativeHandle(ref, () => ({
        submit() {

            const qualification = {};
            for (let key in state) {
                qualification[key] = state[key].value;
            }
            props.updateData(qualification, props.stepConfig)


        },
    }));

    const onSubmitForm = (state) => {
        setEditMode(false);
        props.canProceed(true);
        setChanged(true);
    }

    const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
        stateSchema,
        validationStateSchema,
        onSubmitForm
    );

    useEffect(() => {
        validateInput();
    }, [state]);

    const validateInput = () => {
        let isValid = true;
        for (let key in state) {
            if ((validationStateSchema[key].required && !state[key].value) || state[key].error) {
                isValid = false;
            }
        }
        if (isValid) {
            props.canProceed(true);
        } else {
            props.canProceed(false);
        }
    }
    return (
        <div className="candidate-signup-qualification-step">
            <div className="step-content p-2">
                <div className="row h-100 w-100">
                    <div className="col-12">
                        <div className="candidate-qualification-card">
                            <div className="cs-header row no-gutters">
                                <p className="cs-title col"><b>Qualification Details</b></p>

                            </div>
                            <div className="cs-body">
                                <div className="row">
                                    <div className="form-group col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 m-0" >
                                        <label className="label-sm">Organization</label>
                                        <input type="text" className="form-control " placeholder="College/University Name" id="organizationName" name="organizationName" value={state.organizationName.value} onChange={handleOnChange} />
                                        <small className="form-text  error-text m-0" style={{ visibility: state.organizationName.error ? 'visible' : 'hidden' }}>{state.organizationName.error}</small>
                                    </div>
                                    <div className="form-group col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                        <label className="label-sm">
                                            Qualification(Degree Name) <sup className="required">*</sup>
                                        </label>
                                        <Autocomplete
                                            name="qualification"
                                            placeholder="Select the Degree"
                                            defaultOptions
                                            isClearable={true}
                                            filterOption={customFilter}
                                            options={qualificationList}
                                            value={
                                                selectedQual.id
                                                    ? {
                                                        id: selectedQual.id,
                                                        name: selectedQual.name,
                                                    }
                                                    : null
                                            }
                                            getOptionLabel={(option) => {
                                                return (
                                                    <div className="row autocomplete-item no-gutters">
                                                        <div className="text col p-1 text-truncate">
                                                            <div>{option.name}</div>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                            getOptionValue={(option) => {
                                                return option.id;
                                            }}
                                            onChange={qualificationChanges}
                                        />
                                        <small
                                            className="form-text  error-text m-0"
                                            style={{
                                                visibility: state.qualificationId.error
                                                    ? "visible"
                                                    : "hidden",
                                            }}
                                        >
                                            {state.qualificationId.error}
                                        </small>
                                    </div>

                                    <div className="form-group col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                        <label className="label-sm">
                                            Course <sup className="required">*</sup>
                                        </label>
                                        <Autocomplete
                                            name="course"
                                            placeholder="Select the Course"
                                            defaultOptions
                                            isClearable={true}
                                            isDisabled={selectedQual.id ? false : true}
                                            filterOption={customFilter}
                                            options={courseList}
                                            value={
                                                selectedCourse.id
                                                    ? {
                                                        id: selectedCourse.id,
                                                        name: selectedCourse.name,
                                                    }
                                                    : null
                                            }
                                            getOptionLabel={(option) => {
                                                return (
                                                    <div className="row autocomplete-item no-gutters">
                                                        <div className="text col p-1 text-truncate">
                                                            <div>{option.name}</div>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                            getOptionValue={(option) => {
                                                return option.id;
                                            }}
                                            onChange={courseChanges}
                                        />
                                        <small
                                            className="form-text  error-text m-0"
                                            style={{
                                                visibility: state.courseId.error
                                                    ? "visible"
                                                    : "hidden",
                                            }}
                                        >
                                            {state.courseId.error}
                                        </small>
                                    </div>
                                    <div className="form-group col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                        <label className="label-sm">
                                            Specialization <sup className="required">*</sup>
                                        </label>
                                        <Autocomplete
                                            name="Specialization"
                                            placeholder="Select the Specialization"
                                            defaultOptions
                                            isClearable={true}
                                            isDisabled={selectedCourse.id ? false : true}
                                            filterOption={customFilter}
                                            options={specificationList}
                                            value={
                                                selectedSpecification.id
                                                    ? {
                                                        id: selectedSpecification.id,
                                                        name: selectedSpecification.name,
                                                    }
                                                    : null
                                            }
                                            getOptionLabel={(option) => {
                                                return (
                                                    <div className="row autocomplete-item no-gutters">
                                                        <div className="text col p-1 text-truncate">
                                                            <div>{option.name}</div>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                            getOptionValue={(option) => {
                                                return option.id;
                                            }}
                                            onChange={specificationChanges}
                                        />
                                        <small
                                            className="form-text  error-text m-0"
                                            style={{
                                                visibility: state.specializationId.error
                                                    ? "visible"
                                                    : "hidden",
                                            }}
                                        >
                                            {state.specializationId.error}
                                        </small>
                                    </div>

                                    <div className="form-group col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                        <label className="label-sm">
                                            Joined On(In Year)
                                        </label>
                                        <DatePicker
                                            className="form-control w-100"
                                            selected={state.joinedOn.value}
                                            onChange={joinedOnChange}
                                            showYearPicker={true}
                                            dateFormat="yyyy"
                                            autoComplete="off"
                                        ></DatePicker>
                                        <small
                                            className="form-text  error-text m-0"
                                            style={{
                                                visibility: state.joinedOn.error
                                                    ? "visible"
                                                    : "hidden",
                                            }}
                                        >
                                            {state.joinedOn.error}
                                        </small>
                                    </div>
                                    <div className="form-group col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                        <label className="label-sm">
                                            Passout On(In Year)
                                            {/* {!currentlyPresuing ? (
                                                <sup className="required">*</sup>
                                            ) : (
                                                ""
                                            )} */}
                                        </label>
                                        <DatePicker
                                            className="form-control w-100"
                                            selected={state.passoutYear.value}
                                            onChange={passoutYearChange}
                                            showYearPicker={true}
                                            dateFormat="yyyy"
                                            disabled={currentlyPresuing}
                                            autoComplete="off"
                                        ></DatePicker>
                                        <small
                                            className="form-text  error-text m-0"
                                            style={{
                                                visibility: state.passoutYear.error
                                                    ? "visible"
                                                    : "hidden",
                                            }}
                                        >
                                            {state.passoutYear.error}
                                        </small>
                                    </div>

                                    <div className="form-group col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                        <label className="label-sm"> </label>
                                        <div className="margin-auto-top highest-qualification">
                                            <label className="label-sm mr-4 mb-3">
                                                is Highest Qualification
                                            </label>
                                            <div
                                                className="switch"
                                                onClick={() => onHieghestQualChange(!highestQual)}
                                            >
                                                {highestQual ? (
                                                    <input
                                                        type="checkbox"
                                                        name="isHotJobTrue"
                                                        id="isHotJob"
                                                        value={highestQual}
                                                        checked={highestQual}
                                                        onChange={() => { }}
                                                    />
                                                ) : (
                                                    <input
                                                        type="checkbox"
                                                        name="isHotJobFalse"
                                                        id="isHotJob"
                                                        value={highestQual}
                                                        checked={highestQual}
                                                        onChange={() => { }}
                                                    />
                                                )}
                                                <span className="slider round"></span>
                                            </div>
                                            <small
                                                className="form-text  error-text m-0"
                                                style={{
                                                    visibility: state.isHighestQualification.error
                                                        ? "visible"
                                                        : "hidden",
                                                }}
                                            >
                                                {state.isHighestQualification.error}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                        <label className="label-sm"> </label>
                                        <div className="margin-auto-top highest-qualification">
                                            <label className="label-sm mr-4 mb-3">
                                                is Currently Pursuing
                                            </label>
                                            <div
                                                className="switch"
                                                onClick={() =>
                                                    onCurrentlyPursuingChange(!currentlyPresuing)
                                                }
                                            >
                                                {currentlyPresuing ? (
                                                    <input
                                                        type="checkbox"
                                                        name="isHotJobTrue"
                                                        id="isHotJob"
                                                        value={currentlyPresuing}
                                                        checked={currentlyPresuing}
                                                        onChange={() => { }}
                                                    />
                                                ) : (
                                                    <input
                                                        type="checkbox"
                                                        name="isHotJobFalse"
                                                        id="isHotJob"
                                                        value={currentlyPresuing}
                                                        checked={currentlyPresuing}
                                                        onChange={() => { }}
                                                    />
                                                )}
                                                <span className="slider round"></span>
                                            </div>
                                            <small
                                                className="form-text  error-text m-0"
                                                style={{
                                                    visibility: state.isCurrentlyPursuing.error
                                                        ? "visible"
                                                        : "hidden",
                                                }}
                                            >
                                                {state.isCurrentlyPursuing.error}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-2"></div>
                </div>
            </div>
        </div>
    );
});

export default QualificationStep;
