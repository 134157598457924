import React, { useState, useEffect } from 'react';
import { Link, useParams, withRouter } from 'react-router-dom';
import ClientService from '../../service/ClientService';
import Loader from '../../component/Loader';
import Moment from 'react-moment';
import ErrorModal from "../../component/modal/ErrorModal";


export const Activity = (props) => {
    const { id, userType } = useParams();
    const [loading, setLoading] = useState(true);
    const [activityData, setActivityData] = useState([]);
    const [errModel, setErrorModal] = useState({ show: false, error: {} });

    const getUserId = () => {
        let userId = null;
        if (props.location.search) {
            let paramArray = props.location.search.substr(1, props.location.search.length).split("&");
            paramArray.forEach(param => {
                if (param.includes("userId")) {
                    userId = param.split('=')[1] || null
                }
            })
        }
        return userId;
    }

    const userId = getUserId();

    useEffect(() => {
        if (userId) {
            getActivityData(userId);
        } else {
            setLoading(false);
        }
    }, []);

    const getActivityData = async (userId) => {
        try {
            const response = await ClientService.getUserActivity(userId);
            if (response.status === 200) {
                setActivityData(response.data.result);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            if (err && err.data) {
                setErrorModal({ show: true, error: err.data });
            }
        }
    };

    if (loading) return <Loader />;

    return (
        <div className="p-0" id="activity-container">
            <div className="container-fluid">

                <div className="list-view p-0" id="client-activity-list">
                    <div className='list-container'>
                        {!loading && activityData.length === 0 && <div className='table-loader-wrapper  position-absolute w-100 h-100 top-0 left-0'>
                            <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                                <div className='font-weight-bold loader-text'>No User Activity Found!!</div>
                            </div>
                        </div>}
                        {activityData.length && activityData.map((activity) => {
                            return (
                                <div className="activity-date-section">
                                    <p>
                                        <Moment
                                            date={new Date(activity.date)}
                                            format={" MMMM DD YYYY"}
                                        ></Moment>
                                    </p>
                                    {activity.messages &&
                                        activity.messages.map((item, index) => {
                                            return (
                                                <div className="card activity-list-card ml-4" key={index}>
                                                    <div className="card-body p-2">
                                                        <div className="card-title row no-gutters mb-2">
                                                            <p className="col m-0">{item.detailedMessage}</p>
                                                            <div className="col-auto">
                                                                <Moment date={item.createdOn} format={"hh:mm A"}></Moment>
                                                            </div>
                                                        </div>

                                                        {/* <p className="card-text">{item.detailedMessage}</p> */}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            );
                        })}
                    </div>
                </div>


                <ErrorModal
                    data={errModel}
                    onConfirmAction={() => setErrorModal({ show: false, error: {} })}
                ></ErrorModal>
            </div>
        </div>

    )
}

export default withRouter(Activity);

