import React from "react";
import Moment from "react-moment";
import Slider from "react-slick";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import add_circle from '../../../../assets/imgs/healthcare_icons/add_circle.svg';
import cardiology from '../../../../assets/imgs/healthcare_icons/cardiology.svg';
import dentistry from '../../../../assets/imgs/healthcare_icons/dentistry.svg';
import dermatology from '../../../../assets/imgs/healthcare_icons/dermatology.svg';
import ecg from '../../../../assets/imgs/healthcare_icons/ecg.svg';
import emergency from '../../../../assets/imgs/healthcare_icons/emergency.svg';
import fertile from '../../../../assets/imgs/healthcare_icons/fertile.svg';
import gastroenterology from '../../../../assets/imgs/healthcare_icons/gastroenterology.svg';
import gynaecolog from '../../../../assets/imgs/healthcare_icons/gynaecolog.svg';
import health_and_safety from '../../../../assets/imgs/healthcare_icons/health_and_safety.svg';
import hematology from '../../../../assets/imgs/healthcare_icons/hematology.svg';
import immunology from '../../../../assets/imgs/healthcare_icons/immunology.svg';
import labs from '../../../../assets/imgs/healthcare_icons/labs.svg';
import monitor_heart from '../../../../assets/imgs/healthcare_icons/monitor_heart.svg';
import nephrology from '../../../../assets/imgs/healthcare_icons/nephrology.svg';
import neurology from '../../../../assets/imgs/healthcare_icons/neurology.svg';
import oncology from '../../../../assets/imgs/healthcare_icons/oncology.svg';
import ophthalmology from '../../../../assets/imgs/healthcare_icons/ophthalmology.svg';
import orthopedics from '../../../../assets/imgs/healthcare_icons/orthopedics.svg';
import pediatrics from '../../../../assets/imgs/healthcare_icons/pediatrics.svg';
import physical_therapy from '../../../../assets/imgs/healthcare_icons/physical_therapy.svg';
import pulmonology from '../../../../assets/imgs/healthcare_icons/pulmonology.svg';
import radiology from '../../../../assets/imgs/healthcare_icons/radiology.svg';
import rheumatology from '../../../../assets/imgs/healthcare_icons/rheumatology.svg';
import rib_cage from '../../../../assets/imgs/healthcare_icons/rib_cage.svg';
import stethoscope from '../../../../assets/imgs/healthcare_icons/stethoscope.svg';
import urology from '../../../../assets/imgs/healthcare_icons/urology.svg';
import ward from '../../../../assets/imgs/healthcare_icons/ward.svg';
import surgical from '../../../../assets/imgs/healthcare_icons/surgical.svg';
import medication from '../../../../assets/imgs/healthcare_icons/medication.svg';

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <ArrowBackIosIcon style={{ fontSize: '18px' }} />
    </div>
  );
}

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon style={{ fontSize: '18px' }} />
    </div>
  );
}

const JobCard = ({ data: jobDetails }) => {
  return (
    <div className="job-card">
      <div className="job-header">
        <div className="image-container text-center py-4">
          {jobDetails.logo ? (
            <div className="round-shape">
              {jobDetails.logo === 'add_circle' && <img className="round-image" src={add_circle}></img>}
              {jobDetails.logo === 'cardiology' && <img className="round-image" src={cardiology}></img>}
              {jobDetails.logo === 'dentistry' && <img className="round-image" src={dentistry}></img>}
              {jobDetails.logo === 'dermatology' && <img className="round-image" src={dermatology}></img>}
              {jobDetails.logo === 'ecg' && <img className="round-image" src={ecg}></img>}
              {jobDetails.logo === 'emergency' && <img className="round-image" src={emergency}></img>}
              {jobDetails.logo === 'fertile' && <img className="round-image" src={fertile}></img>}
              {jobDetails.logo === 'gastroenterology' && <img className="round-image" src={gastroenterology}></img>}
              {jobDetails.logo === 'gynaecolog' && <img className="round-image" src={gynaecolog}></img>}
              {jobDetails.logo === 'health_and_safety' && <img className="round-image" src={health_and_safety}></img>}
              {jobDetails.logo === 'hematology' && <img className="round-image" src={hematology}></img>}
              {jobDetails.logo === 'immunology' && <img className="round-image" src={immunology}></img>}
              {jobDetails.logo === 'labs' && <img className="round-image" src={labs}></img>}
              {jobDetails.logo === 'monitor_heart' && <img className="round-image" src={monitor_heart}></img>}
              {jobDetails.logo === 'nephrology' && <img className="round-image" src={nephrology}></img>}
              {jobDetails.logo === 'neurology' && <img className="round-image" src={neurology}></img>}
              {jobDetails.logo === 'oncology' && <img className="round-image" src={oncology}></img>}
              {jobDetails.logo === 'ophthalmology' && <img className="round-image" src={ophthalmology}></img>}
              {jobDetails.logo === 'orthopedics' && <img className="round-image" src={orthopedics}></img>}
              {jobDetails.logo === 'pediatrics' && <img className="round-image" src={pediatrics}></img>}
              {jobDetails.logo === 'physical_therapy' && <img className="round-image" src={physical_therapy}></img>}
              {jobDetails.logo === 'pulmonology' && <img className="round-image" src={pulmonology}></img>}
              {jobDetails.logo === 'radiology' && <img className="round-image" src={radiology}></img>}
              {jobDetails.logo === 'rheumatology' && <img className="round-image" src={rheumatology}></img>}
              {jobDetails.logo === 'rib_cage' && <img className="round-image" src={rib_cage}></img>}
              {jobDetails.logo === 'stethoscope' && <img className="round-image" src={stethoscope}></img>}
              {jobDetails.logo === 'urology' && <img className="round-image" src={urology}></img>}
              {jobDetails.logo === 'ward' && <img className="round-image" src={ward}></img>}
              {jobDetails.logo === 'surgical' && <img className="round-image" src={surgical}></img>}
              {jobDetails.logo === 'medication' && <img className="round-image" src={medication}></img>}
            </div>

          ) : (
            <div className="user-no-image">
              <div className="margin-auto">No Image</div>
            </div>
          )}
        </div>
      </div>
      <div className="job-body">
        <p className="job-title text-truncate" title="Job Title">{jobDetails.title}</p>
        <p className="job-category text-truncate" title="Job Category">{jobDetails.category}</p>
        {/* <p className="job-role text-truncate" title="Job Role">{jobDetails.role}</p> */}
        <p className="job-role text-truncate" title="Job Location">{jobDetails.jobLocation ? jobDetails.jobLocation.state : ''}</p>
      </div>
      <div className="job-footer row no-gutters">
        <div className="col-6">
          <span className="badge badge-custom" title="Required Experience">
            {`${jobDetails.minimumExperience ? jobDetails.minimumExperience : 'Freshers'}${jobDetails.maximumExperience
              ? "-" + jobDetails.maximumExperience + 'year(s)'
              : jobDetails.minimumExperience ? "+ years" : ''
              }`}
          </span>
        </div>
        <div className="col-6 text-right">
          <span className="badge badge-custom" title="Job Posted On">
            <Moment date={jobDetails.postOn} format={"MMM DD, YYYY"}></Moment>
          </span>
        </div>
      </div>
    </div>
  );
};

const JobSection = ({ data: joblist }) => {
  const settings = {
    className: "center",
    infinite: true,
    speed: 500,
    rows: 1,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    autoplay: true,
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "transparent",
          padding: "10px",
          bottom: "-50px"
        }}
      >
        <ul className="custom-dots-list" style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div
        style={{
          width: "13px",
          height: "13px",
          border: "2px solid #8860D0",
          borderRadius: "50%"
        }}
      >
      </div>
    ),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 910, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 615, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div id="emp-job-section" className="py-4">
      <div className="container py-4">
        <div className="row section-header text-center py-4">
          <h2 className="col-md-12">Hot Jobs</h2>
          <p className="col-md-12">
            Leading employers alreading using DoctorJobbs.
          </p>
        </div>
        <Slider {...settings}>
          {joblist?.length && joblist.map((job, index) => {
            return (
              <JobCard data={job} key={index}></JobCard>
            )
          })}
        </Slider>
      </div>
    </div>
  );
};

export default JobSection;
