import React from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const UserLogo = (props) => {
  const { userImg, size, className } = props;
  return (
    <>
      {userImg ? (
        <img className={className} src={userImg} height={size} width={size} />
      ) : (
        <div className="user-logo-common">
          <AccountCircleIcon
            style={{ width: size, height: size }}
          ></AccountCircleIcon>
        </div>
      )}
    </>
  );
};

export default UserLogo;
