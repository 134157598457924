import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import UseForm from "../../service/FormService";
import ClientService from "../../service/ClientService";
import ManageService from "../../service/ManageService";
import $ from "jquery";
import { ErrorToaster } from "../../component/modal/ErrorModal";
import Autocomplete from "react-select";
import moment from "moment";

const stateSchema = {
  planId: { value: "", error: "" },
  activateOn: { value: new Date(), error: "" },
  expireOn: { value: new Date(), error: "" },
};

const validationStateSchema = {
  planId: {
    required: true,
  },
  activateOn: {
    required: true,
  },
  expireOn: {
    required: true,
  },
};

const AddSubscription = (props) => {
  const [visible, setVisible] = useState(props.data.show || false);
  const [intialSchema, setInitionalSchema] = useState(
    $.extend(true, {}, stateSchema)
  );
  const [planList, setPlanList] = useState([]);
  const [planData, setPlanData] = useState("");
  const [selectedPlan, setSelectedPlan] = useState({});
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });
  const [planLoading, setPlanLoading] = useState(false);

  useEffect(() => {
    setVisible(props.data.show);
    if (props.data.show) {
      getPlanList();
    } else {
      setInitionalSchema($.extend(true, {}, stateSchema));
      setPlanList([]);
      setPlanData(null);
      setSelectedPlan({});
      setErrorObj({ show: false, msg: "" });
    }
  }, [props]);

  const customFilter = (option, inputValue) => {
        if(option.data.name && inputValue){
            return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        }
        return true;
    };

  const planChange = (plan) => {
    if (plan && plan.id) {
      setSelectedPlan(plan);
      handleOnChange({ target: { name: "planId", value: plan.id } });
    } else {
      setSelectedPlan({});
      handleOnChange({ target: { name: "planId", value: "" } });
    }
  };

  const getPlanList = async (param, url) => {
    const response = await ManageService.getPlanList(
      param ? `${param},active` : "active",
      url
    );
    if (response.status === 200) {
      if (url) {
        let list = categoryList || [];
        list = list.concat(response.data.result.data);
        response.data.result.data = list;
      }
      setPlanData(response.data);
      setPlanList($.extend(true, [], response.data.result.data));
    }
  };
  const onPlanInputChange = (param, actionType, list) => {
    if (actionType.action && actionType.action === "input-change") {
      setPlanList([]);
      param ? getPlanList(param) : getPlanList();
    } else if (actionType.action === "menu-close" && !selectedPlan.id)
      getPlanList();
  };

  const paginatePlanList = (e) => {
    if (planData.detail.links && planData.detail.links.next) {
      setPlanLoading(true);
      getPlanList("", planData.detail.links.next);
      setPlanLoading(false);
    }
  };

  const hideModal = () => {
    setVisible(false);
    props.onCancel();
  };

  const activateOnChange = (date) => {
    // console.log("activate on change", date);
    handleOnChange({ target: { name: "activateOn", value: date } });
  };

  const expireOnChange = (date) => {
    // console.log("expire on change", date);
    handleOnChange({ target: { name: "expireOn", value: date } });
    const errorObj = { key: "expireOn", errorMsg: "" };
    if (date) {
      const errorObj = { key: "expireOn", errorMsg: "" };
      date.getTime() > state.activateOn.value.getTime()
        ? ""
        : (errorObj.errorMsg =
          "Expiry date should be always greater than activation date.");
      date.getTime() > new Date().getTime()
        ? ""
        : (errorObj.errorMsg =
          "Expiry date should be always greater than today date.");
      if (errorObj.errorMsg) {
        setErrorInField(errorObj);
      }
    }
  };

  const getDateFormat = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const closeToast = (e) => {
    e.preventDefault();
    setErrorObj({ show: false, msg: "" });
  };

  const onSubmitForm = (state) => {
    const requestPayload = {
      clientId: props.data.client.id,
      planId: state.planId.value,
      activateOn: getDateFormat(new Date(state.activateOn.value)),
      expireOn: getDateFormat(new Date(state.expireOn.value)),
    };
    // console.log("requestPayload", requestPayload);

    ClientService.addSubscription(requestPayload).then(
      (res) => {
        // console.log("add staff ", res);
        if (res.status === 200 || res.status === 201) {
          props.onConfirmAction(res.data.result);
        }
      },
      (err) => {
        // console.log("add staff err", err);
        setErrorObj({ show: true, msg: err.data.message });
        setTimeout(() => {
          setErrorObj({ show: false, msg: "" });
        }, 3000);
      }
    );
  };

  const { state, handleOnChange, handleOnSubmit, disable, setErrorInField } =
    UseForm(intialSchema, validationStateSchema, onSubmitForm);

  return (
    <div
      className="overlay "
      id="subscription-model"
      style={{ display: visible ? "block" : "none" }}
    >
      <div className="overlay-content">
        <div className={visible ? " cs-modal showModal" : "cs-modal hideModal"}>
          <div className="header">
            {props.data.client.subscriptions &&
              props.data.client.subscriptions.length > 0
              ? "Update Subscription"
              : "Add Subscription"}
          </div>
          <div className="body">
            <div className="subscription-container">
              <form>
                <div className="row">
                  <div className="form-group col-12 m-0">
                    <label className="label-sm">
                      Plan Name<sup className="required">*</sup>
                    </label>
                    <Autocomplete
                      name="plan-select"
                      placeholder="Select Plan"
                      defaultOptions
                      isClearable={true}
                      options={planList}
                      value={
                        selectedPlan && selectedPlan.id ? selectedPlan : ""
                      }
                      filterOption={customFilter}
                      isLoading={planLoading}
                      getOptionLabel={(option) => {
                        return (
                          <div className="row autocomplete-item no-gutters">
                            <div className="text col p-1 text-truncate">
                              <div>{option.name}</div>
                            </div>
                          </div>
                        );
                      }}
                      getOptionValue={(option) => {
                        return option.id;
                      }}
                      onChange={planChange}
                      onMenuScrollToBottom={paginatePlanList}
                      onInputChange={onPlanInputChange}
                    />
                    <small
                      className="form-text  error-text m-0"
                      style={{
                        visibility: state.planId.error ? "visible" : "hidden",
                      }}
                    >
                      {state.planId.error}
                    </small>
                  </div>
                  <div className="row col-12 no-gutters">
                    <div className="form-group col-5 m-0">
                      <label className="label-sm">
                        Activate Date<sup className="required">*</sup>
                      </label>

                      <DatePicker
                        className="form-control w-100 mb-1"
                        id="activateOn"
                        name="activateOn"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={activateOnChange}
                        selected={state.activateOn.value}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                          },
                        }}
                        autoComplete="off"
                      ></DatePicker>

                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.activateOn.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {state.activateOn.error}
                      </small>
                    </div>
                    <div className="form-group col-2 m-0"></div>
                    <div className="form-group col-5 m-0">
                      <label className="label-sm">
                        Expire Date<sup className="required">*</sup>
                      </label>

                      <DatePicker
                        className="form-control w-100 mb-1"
                        id="expireOn"
                        name="expireOn"
                        minDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={expireOnChange}
                        selected={state.expireOn.value}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                          },
                        }}
                        autoComplete="off"
                      ></DatePicker>

                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.expireOn.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {state.expireOn.error}
                      </small>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="footer">
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary mr-3"
              onClick={hideModal}
            >
              cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary"
              disabled={disable}
              onClick={handleOnSubmit}
            >
              Procced
            </button>
          </div>
        </div>
      </div>
      <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
    </div>
  );
};

export default AddSubscription;
