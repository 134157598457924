import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import NoDataFound from '../../common/NoDataFound';
import ClientService from '../../service/ClientService';
import Loader from '../../component/Loader';
import SearchIcon from '@material-ui/icons/Search';
import Checkbox from '../../component/Checkbox';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import BusinessIcon from '@material-ui/icons/Business';
import ConfirmModal from '../../component/modal/ConfirmModal';
import { ClientCardView, ClientListView, ClientNewListView } from './List';
import ErrorModal from '../../component/modal/ErrorModal';
import SuccessModal from '../../component/modal/SuccessModal';
import WarningIcon from '@material-ui/icons/Warning';
import TableComponent from '../../common/tableComponent';
import LocalService from '../../service/LocalstorageService';
import ActionListIcon from '@material-ui/icons/MoreVert';
import Moment from 'react-moment';

const NoData = (props) => {
    return (
        <div className="content-wrapper">
            <div className="content-container">
                <NoDataFound name={props.name} redirectUrl={props.redirectUrl} hideButton={props.hideButton}></NoDataFound>
            </div>
        </div>
    )
}

const Main = (props) => {
    const { userType } = useParams();
    const [loading, setLoading] = useState(true);
    const [clientList, setClientList] = useState([]);
    const [listView, setListView] = useState(true);
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [confirmationObj, setConfirmationObj] = useState({ show: false, msg: '' });
    const [paginationObj, setPaginationObj] = useState({});
    const [listLoading, setListLoading] = useState(false);
    const [currentAction, setCurrentAction] = useState('');
    const [errModel, setErrorModal] = useState({ show: false, error: {} })
    const [successModel, setSuccessModal] = useState({ show: false, success: {} })
    const thresholdHeight = 40;
    const permissions = LocalService.getPermision().filter(perm => perm.name === 'Client Management');
    const perPageItem = 10;
    const [tableLoader, setTableLoader] = useState(false);
    const [tableError, setTableError] = useState(false);
    const columns = [
        {
            label: "Client Name", value: "name", sortable: true,
            render: (value, index, list) => {
                return (
                    <div className='d-flex'>
                        {
                            list.logo && list.logo.href ? <img className="image-30" src={list.logo.href}></img>
                                : <div className="no-image-28">{list.name.substr(0, 1)}</div>
                        }
                        <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/client/view/${list.id}`} className="d-flex align-items-center">{list.name} </Link>
                    </div>
                )
            }

        },
        { label: "Location", value: "location", sortable: false },
        {
            label: "Current Plan", value: "current_plan", sortable: false,
            render: (value, index, list) => {
                return (
                    list.subscription && list.subscription.plan ? list.subscription.plan.name : ' NA '
                )
            }
        },
        {
            label: "Plan Expiry", value: "plan_expiry", sortable: false,
            render: (value, index, list) => {
                return (
                    list.subscription && list.subscription.plan ? <Moment date={list.subscription.plan.expireOn} format={"DD MMM YYYY"}></Moment> : ' NA '
                )
            }
        },
        {
            label: "Status", value: "status", sortable: false,
            render: (value, index, list) => {
                return (
                    list.isArchived ? <span className="badge badge-danger">Archived</span> : list.isActive ? <span className="badge badge-success">Active</span> : <span className="badge badge-warning">Inactive</span>
                )
            }
        },
        {
            label: "Action", value: "action", sortable: false,
            render: (value, index, list) => {
                return (
                    getTableActionList(list)
                )
            }
        }
    ];


    const getClientList = async (search, offset = perPageItem, page = 1, sortBy) => {
        try {
            const response = await ClientService.getClientList(search, offset, page, sortBy);
            if (response.status === 200) {
                setClientList(response.data.result);
                setPaginationObj(response.data.detail);
                setLoading(false);
                setTableLoader(false)
                setTableError(false);
            }
        }
        catch (e) {
            setTableLoader(false)
            setClientList([]);
            setPaginationObj({});
            setLoading(false);
            setTableError(true);
        }
    }

    const getTableData = (search, offset, page, sortBy) => {
        setTableLoader(true);
        let tableConfig = { ...config, search, "perPageItem": offset, page, sortBy };
        setConfig(tableConfig);
        getClientList(search, offset, page, sortBy);
    }

    const searchBy = (searchParam) => {
        let tableConfig = { ...config, search: searchParam };
        getTableData(tableConfig.search, tableConfig.perPageItem, tableConfig.page, tableConfig.sortBy);
    }

    const onRowSelectionChange = (list) => {
        setSelectedRow(list ? [...list]: null);
    }

    const performAction = (action, list) => {
        setCurrentAction({ action: action, data: list});
        setConfirmationObj({ show: true, msg: `You want to ${action.toUpperCase()} this client?` });
    }

    let tableConfig = {
        pagination: true,
        showSelectRow: true,
        perPageItem: perPageItem,
        getTableData: getTableData,
        onRowSelection: onRowSelectionChange,
        onActionButton: performAction,
        actionList: ['edit', "delete", "activate", "deactivate"],
        search: "",
        sortBy: { name: "", type: "" },
        page : 1
    }

    const [config , setConfig] = useState({...tableConfig});

    const getTableActionList = (data) => {
        return (
            <div className="btn-group action">
                <ActionListIcon className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                <ul className="dropdown-menu">
                    {checkAccess('read') ? <li className="dropdown-item"><Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/client/view/${data.id}`}>View Account</Link></li> : null}
                    {checkAccess('update') ? <li className="dropdown-item"><Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/client/edit/${data.id}`}>Edit Account</Link></li> : null}
                    {checkAccess('update') && data.isActive ? <li className="dropdown-item spl-link-buttn-css" data-value="deactivate" onClick={() => performAction("deactivate", [data.id])}>Deactivate Account</li> : <li className="dropdown-item spl-link-buttn-css" data-value="activate" onClick={() => performAction("activate", [data.id])}>Activate Account</li>}
                    {checkAccess('delete') ? <li className="dropdown-item spl-red-buttn-css" data-value="delete" onClick={() => performAction("delete", [data.id])}>Delete Account Permanently</li> : ''}
                </ul>
            </div>
        )
    }

    useEffect(() => {
        getClientList();
    }, []);

    const checkAccess = (type) => {
        return permissions[0][type]
    }

    const closePopup = () => {
        setConfirmationObj({ show: false, msg: '' });
        setCurrentAction("");
    }

    const doAction = async () => {
        setLoading(true);
        setConfirmationObj({ show: false, msg: '' });

        try {
            const res = await ClientService.updateClientStatus(currentAction.action, { clientIds: currentAction.data ? currentAction.data : selectedRow  });
            setCurrentAction({});
            setLoading(false);
            if (res.status === 200) {
                setTableLoader(true);
                getClientList(config.search, config.perPageItem, config.page, config.sort);
                setSuccessModal({ show: true, success: { msg: res.data.result } });
            }
        } catch (err) {
            setLoading(false);
            if (err && err.data) {
                setErrorModal({ show: true, error: err.data })
            }
        }
    }


    if (loading) return <Loader />;

    if (clientList.totalClient === 0) {
        return <NoData name={'Client'} redirectUrl={`/${userType}/client/add`} hideButton={props.permission.indexOf('write') > -1 ? false : true} ></NoData>
    }
    return (
        <div className="content-wrapper p-0" id="client-list-container">
            <div className="container-fluid">
                <div className="row px-2 pt-2">
                    <div className="col-12 d-flex count-summery p-0">
                        <div className="col-4 px-0">
                            <div className="card-counter primary" onClick={() => searchBy("")}>
                                <BusinessIcon></BusinessIcon>
                                <span className="count-numbers">{clientList.totalClient}</span>
                                <span className="count-name">Total Clients</span>
                            </div>
                        </div>
                        <div className="col-4 px-0">
                            <div className="card-counter success" onClick={() => searchBy("Active")}>
                                <BusinessIcon></BusinessIcon>
                                <span className="count-numbers">{clientList.activeClient}</span>
                                <span className="count-name">Active Clients</span>
                            </div>
                        </div>
                        <div className="col-4 px-0">
                            <div className="card-counter warning" onClick={() => searchBy("Inactive")}>
                                <BusinessIcon></BusinessIcon>
                                <span className="count-numbers">{clientList.inactiveClient}</span>
                                <span className="count-name">Inactive Clients</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-2 filter-row align-items-center">
                    <div className="col-auto">
                        <h5 className="m-0">Client List</h5>
                    </div>
                    <div className="col">
                    </div>
                    <div className="col-auto">
                        {checkAccess('write') ?
                            <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/client/add`}>
                                <button className="btn btn-primary btn-sm btn-padding add-btn"> +Add</button>
                            </Link> : ''
                        }
                    </div>
                </div>
                <div className="list-card" id="client-list-view">
                    <div className='list-container'>
                        <TableComponent
                            loading={tableLoader}
                            error={tableError}
                            columns={columns}
                            config={config}
                            paginationObj={paginationObj}
                            data={clientList.data || []} >
                        </TableComponent>
                    </div>
                </div>
            </div>
            <ConfirmModal data={confirmationObj} onConfirmAction={doAction} onCancel={closePopup}></ConfirmModal>
            <ErrorModal data={errModel} onConfirmAction={() => setErrorModal({ show: false, error: {} })} ></ErrorModal>
            <SuccessModal data={successModel} onConfirmAction={() => setSuccessModal({ show: false, success: {} })}></SuccessModal>
        </div>
    )
}

export default Main;