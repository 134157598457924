import React, { useState, useEffect } from "react";
import Countcard from "../../component/Countcard";
import BusinessIcon from "@material-ui/icons/Business";
import AreaGraph from "../../component/Areagraph";
import DashboardService from "../../service/DashboardService";
import ErrorPopup from "../../component/modal/ErrorModal";
import BarGraph from "../../component/BarChats";
import { JobListView } from "../jobs/List";
import { Link, useLocation } from "react-router-dom";

const barData = [
  { label: "active", color: "#A4A1FB" },
  { label: "inactive", color: "#56D9FE" },
];
const configData = [
  { label: "subs", color: "#A3A0FB" },
  { label: "exp", color: "#54D8FF" },
];

const ClientOverview = (props) => {
  const [loading, setLoading] = useState(false);
  const [clientData, setClientData] = useState("");
  const [errModel, setErrorModal] = useState({ show: false, err: {} });
  const [jobList, setJobList] = useState([]);
  const location = useLocation();
  const userPrifix = location.pathname.split("/")[1];

  useEffect(() => {
    if (props.visible) {
      getOverViewData();
      getJobList();
    } else {
      setClientData("");
    }
  }, [props.visible]);

  const getOverViewData = async () => {
    try {
      setLoading(true);
      const response = await DashboardService.getAdminOverviewData("client");
      if (response.status === 200) {
        setClientData(response.data.result);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err && err.data) {
        setErrorModal({ show: true, error: err.data });
      }
    }
  };
  const getJobList = async () => {
    try {
      setLoading(true);
      const response = await DashboardService.getJobList();
      if (response.status === 200) {
        setJobList(response.data.result.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err && err.data) {
        setErrorModal({ show: true, error: err.data });
      }
    }
  };

  return (
    <div id="client-overview">
      <h3>Client Overview</h3>
      <div className="container-fluid p-0 mt-4">
        <div className="row">
          <div className="col-3">
            <Countcard
              count={clientData ? clientData.counts.total : 0}
              msg={"Total"}
              name={"total-count"}
              icon={BusinessIcon}
            />
          </div>
          <div className="col-3 ">
            <Countcard
              count={clientData ? clientData.counts.active : 0}
              msg={"Active"}
              name={"active-count"}
              icon={BusinessIcon}
            />
          </div>
          <div className="col-3">
            <Countcard
              count={clientData ? clientData.counts.inactive : 0}
              msg={"Inactive"}
              name={"inactive-count"}
              icon={BusinessIcon}
            />
          </div>
          <div className="col-3">
            <Countcard
              count={clientData ? clientData.counts.new : 0}
              msg={"New"}
              name={"new-count"}
              icon={BusinessIcon}
            />
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-12 subvsexpGraph">
          <div className="col-12 graph-header-text header-font">
            <p className="col-auto">Subscription Vs Expiration</p>
          </div>
          {props.visible ? (
            <AreaGraph
              data={clientData ? clientData.subsExp : []}
              config={configData}
            />
          ) : (
            ""
          )}
        </div>
        <div className="col-12 activevsinactivegraph row no-gutters">
          <div className="col-12">
            <div className="col-12 graph-header-text header-font">
              <p className="col-auto">Active Vs Inactive</p>
            </div>
            {props.visible ? (
              <BarGraph
                data={clientData ? clientData.activeInactive : []}
                config={barData}
              />
            ) : (
              ""
            )}
          </div>
          <div className="col-12 client-dashboard-job-container">
            <div className="col-12 p-0">
              <div className="job-list-container row col-12 no-gutters">
                <div className="header col-12 row no-gutters mb-2">
                  <p className=" header-font col m-y-auto">Latest Jobs</p>
                  <Link
                    to={`/${userPrifix}/jobs`}
                    className="know-more-link col-auto"
                  >
                    {" "}
                    know more
                  </Link>
                </div>

                <div className="job-list col-12 ">
                  {jobList.map((job, index) => {
                    return (
                      <div
                        className="col-12 row no-gutters list-item"
                        key={index}
                      >
                        <div className="col-1 pr-1">
                          {job.client && job.client.logo ? (
                            <img
                              src={job.client.logo.href}
                              className="job-logo"
                              title={job.client.name}
                            ></img>
                          ) : (
                            <div className="user-no-image">
                              <div className="no-image-jobs">
                                {job.title.charAt(0)}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-3 text-truncate" title={job.title}>
                          <Link
                            target={"_blank"}
                            className="col-12 text-truncate title"
                            to={`/${userPrifix}/job-post/view/${job.id}`}
                          >
                            {job.title}{" "}
                          </Link>
                        </div>
                        <div className="col-3 vertical-columns text-truncate">
                          <div className="col-12 label">Category</div>
                          <div
                            className="col-12 value text-truncate"
                            title={job.category}
                          >
                            {job.category}
                          </div>
                        </div>
                        <div className="col-3 vertical-columns ">
                          <div className="col-12 label">Role</div>
                          <div
                            className="col-12 value text-truncate"
                            title={job.role}
                          >
                            {job.role}
                          </div>
                        </div>
                        <div className="col-2 text-truncate vertical-columns">
                          <div className="col-12 label">Posted On</div>
                          <div
                            className="col-12 value text-truncate"
                            title={job.postOn}
                          >
                            {job.postOn}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {!jobList.length ? (
                    <div className="col-12 row no-gutters h-100">
                      <div className="d-block margin-auto">No Jobs Found</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 profile-balnce">
          <div className="col-12  no-gutters profile-container">
            <div className="header col-12 row no-gutters mb-2">
              <p className=" header-font col">
                Clients resume download balance
              </p>
              <Link
                to={`/${userPrifix}/client`}
                className="know-more-link col-auto"
              >
                {" "}
                know more
              </Link>
            </div>
            <div className="content col-12  no-gutters">
              {clientData &&
                clientData?.profileBalance?.map((item, index) => {
                  return (
                    <div className="col-12 py-2 row no-gutters" key={index}>
                      <div className="col-auto col-xl-1 m-y-auto">
                        {item.logo ? (
                          <img
                            src={item.logo.href}
                            className="job-logo"
                            title={item.name}
                          ></img>
                        ) : (
                          <div className="user-no-image">
                            <div className="no-image-jobs">
                              {item.name.charAt(0)}
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="col col-xl-3 m-y-auto row no-gutters"
                        title={item.name}
                      >
                        <Link
                          target={"_blank"}
                          className="name col-12 text-truncate"
                          to={`/${userPrifix}/client/view/${item.id}`}
                        >
                          {item.name}{" "}
                        </Link>
                      </div>
                      <div className="col-2 col-xl-2 m-y-auto">
                        <div className="total-per">{item.total} </div>
                      </div>
                      <div className="col-4 col-xl-4 m-y-auto">
                        <div className="progress d-flex mx-2">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${item.total === 0 ? 0 : Math.round(
                                (((100 * item.usedPer) / item.total) * 10) / 10
                              ).toFixed(2)}%`,
                            }}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                          {item.total === 0 ? 0 : Math.round(
                            (((100 * item.usedPer) / item.total) * 10) / 10
                          ).toFixed(2)}
                          %
                        </div>
                      </div>
                      <div className="col-2 col-xl-2 m-y-auto">
                        <div className="total-per m-0 p-0">
                          {item.total === 0 ? 0 : Math.round(
                            (((100 * item.usedPer) / item.total) * 10) / 10
                          ).toFixed(2)}{" "}
                          %
                        </div>
                      </div>
                    </div>
                  );
                })}
              {clientData && !clientData?.profileBalance?.length ? (
                <div className="col-12 row no-gutters h-100">
                  <div className="d-block margin-auto">
                    No Subscription avilable for client
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <ErrorPopup
        data={errModel}
        onConfirmAction={() => setErrorModal({ show: false, error: {} })}
      ></ErrorPopup>
    </div>
  );
};

export default ClientOverview;
