import React, { useState, useEffect } from "react";
import NavBar from "../Navbar";
import Footer from "../Footer";
import ManageService from "../../../service/ManageService";
import { SuccessToaster } from "../../../component/modal/SuccessModal";
import { ErrorToaster } from "../../../component/modal/ErrorModal";
import ContactForm from "../Contact/ContactForm";
import CloseIcon from '@material-ui/icons/Close';

const EquipmentCard = (props) => {

  const openContact = () => {
    props.onCardClick(props.data);
  }

  return (
    <div className="card-wrapper col-12 justify-content-center p-4 my-4 row no-gutters">
      <div className="card image-card col-auto ">
        <img className="card-img" src={props.data?.image?.href}></img>
      </div>
      <div className="card content-card col-auto">
        <div className="card-body">
          <div className="content-title">{props.data.name}</div>
          <p className="content-description">{props.data.description}</p>
          <div className="content-action">
            <button className="btn brand btn-sm" onClick={openContact}>Contact US</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Equipments = () => {
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });
  const [successObj, setSuccessObj] = useState({
    show: false,
    msg: "",
  });
  const [equipmentList, setEquipmentList] = useState({ data: [] });
  const [selectedEqup, setSelectedEqup] = useState({});
  const [showContactModal, setShowContactModal] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [equipmentData, setEquipmentData] = useState()

  useEffect(() => {
    getEquipmentList();
  }, []);

  const getEquipmentList = async (url) => {
    const res = await ManageService.getEquipmentList("", url ? url : null);
    if (url) {
      const updatedData = { ...res.data };
      updatedData.result.data = updatedData.result.data.concat(equipmentList.data);
      setEquipmentList(updatedData.result);
    } else {
      setEquipmentList(res.data.result);
    }
    setEquipmentData(res.data);
  };

  const openContactForm = (data) => {
    setSelectedEqup({ ...data });
    setShowContactModal(true)

  }

  const onFormSubmit = (data) => {
    if (data.error) {
      setErrorObj({ show: true, msg: data.msg })
    } else {
      setSelectedEqup({});
      setShowContactModal(false);
      setSuccessObj({ show: true, msg: "Your request is submitted successfully, \n we will reach you as soon as possible" })
    }
  }

  const hideContactModal = (data) => {
    setSelectedEqup({});
    setShowContactModal(false);
  }

  const onLoadMore = () => {
    setIsLoadMore(true);
    getEquipmentList(equipmentData.detail?.links?.next)
  }

  return (
    <div id="equipments">
      <NavBar />
      <div id="hero-section">
        <div className="row no-gutters align-items-center jujstify-content-center text-container">
          <h1 className="text w-100 text-center">EQUIPMENTS</h1>
        </div>
      </div>
      <div className="container-fluid  equipment-list-wrapper">
        <div className="product-card-container row no-gutters align-items-center justify-content-center">
          <div className="card-list col-12 row no-gutters align-items-center justify-content-center">
            {equipmentList.data.map((item, index) => {
              return <EquipmentCard data={item} key={index} onCardClick={openContactForm}></EquipmentCard>;
            })}
          </div>
          {
            equipmentData?.detail?.links?.next && (
              <div className="row no-gutters align-items-center justify-content-center py-4">
                <button className="btn btn-brand" type="button" onClick={onLoadMore}>
                  {!isLoadMore && <span>Load More</span>}
                  {isLoadMore && (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span>Loading...</span>
                    </>)
                  }
                </button>
              </div>
            )
          }
        </div>
      </div>
      <Footer />
      <SuccessToaster
        data={successObj}
        onClose={() => {
          setSuccessObj({ show: false, msg: "" });
        }}
      ></SuccessToaster>
      <ErrorToaster
        data={errorObj}
        onClose={() => {
          setErrorObj({ show: false, msg: "" });
        }}
      ></ErrorToaster>
      {/* <div className="">
        <ContactForm onFormSubmit={onFormSubmit}></ContactForm>
      </div> */}
      <div
        className="overlay "
        id="equipment-contact-model"
        style={{ display: showContactModal ? "block" : "none" }}
      >
        <div className="overlay-content">
          <div className={showContactModal ? " cs-modal showModal" : "cs-modal hideModal"}>
            <div className="content" >
              <div className="equipment-contact-heaader-section row no-gutters align-items-center">
                <div className="equipment-contact-header col">Equipment lead</div>
                <div className="col-auto">
                  <CloseIcon className="close-icon" onClick={hideContactModal}></CloseIcon>
                </div>
              </div>
              <ContactForm
                onFormSubmit={onFormSubmit}
                service="EQUIPMENT PURCHASE"
                isServiceDisabled={true}
                equipmentId={selectedEqup.id}></ContactForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Equipments;
