import React, { useEffect, useState } from "react";
import Autocomplete from "react-select";
import UseForm from "../../service/FormService";
import JobService from "../../service/JobPostService";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import Moment from "react-moment";
import { SuccessToaster } from "../../component/modal/SuccessModal";
import { ErrorToaster } from "../../component/modal/ErrorModal";
import $ from "jquery";
import Loader from "../../component/Loader";
import moment from "moment";

const emailRegx =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const stateSchema = {
  title: { value: "", error: "" },
  description: { value: "", error: "" },
  status: { value: "", error: "" },
  postOn: { value: new Date(), error: "" },
  isHotJob: { value: false, error: "" },
  state: { value: "", error: "" },
  city: { value: "", error: "" },
  town: { value: "", error: "" },
  isCtcNegotiable: { value: false, error: "" },
  isExperienceNegotiable: { value: false, error: "" },
  minimumCtc: { value: "", error: "" },
  maximumCtc: { value: "", error: "" },
  minimumExperience: { value: "", error: "" },
  maximumExperience: { value: "", error: "" },
};

const validationStateSchema = {
  title: {
    required: true,
  },
  description: {
    required: true,
  },
  status: {
    required: true,
  },
  postOn: {
    required: true,
  },
  isHotJob: {
    required: false,
  },
  state: {
    required: true,
  },
  city: {
    required: true,
  },
  isCtcNegotiable: {
    required: false,
  },
  isExperienceNegotiable: {
    required: false
  },
  town: {
    required: false,
  },
  minimumCtc: {
    required: false,
  },
  maximumCtc: {
    required: false,
  },
  minimumExperience: {
    required: false,
  },
  maximumExperience: {
    required: false,
  },
};

const JobPostCreate = () => {
  const { userType } = useParams();
  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState({});
  const [clientList, setClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [companyLoading, setCompanyLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [qualificationList, setQualificationList] = useState([]);
  const [selectedQual, setSelectedQual] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [speciList, setSpeciList] = useState([]);
  const [selectedSpeci, setSelectedSpeci] = useState("");
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [successObj, setSuccessObj] = useState({ show: false, success: {} });
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location && location.state && location.state.id) {
      setSelectedClient(location.state);
      getCategory(location.state.id);
    } else {
      getCompnayList();
      getCategory();
    }
    getQualification();
    getStateList();
    setLoading(false);
    setCompanyLoading(false);
  }, []);

  const getCityList = async (stateid) => {
    try {
      const response = await JobService.getCitiesList(stateid);
      setCityList(response.data.result);
    } catch (e) {
      //console.log("error",e);
    }
  };

  const getStateList = async () => {
    try {
      const response = await JobService.getAllStateList();
      setStateList(response.data.result);
    } catch (e) {
      //console.log("error",e);
    }
  };

  const getSpecialization = async (selectedItem) => {
    try {
      const response = await JobService.getSpecialization(selectedItem.id);
      const item = { label: selectedItem.name, options: response.data.result };
      const list = $.extend(true, [], speciList);
      list.push(item);
      setSpeciList(list);
    } catch (e) {
      //console.log("error",e);
    }
  };

  const getCourses = async (param, url) => {
    try {
      const response = await JobService.getCourse(param, url);
      setCourseList(response.data.result);
    } catch (e) { }
  };

  const getQualification = async (param, url) => {
    try {
      const response = await JobService.getQualification(param, url);
      setQualificationList(response.data.result);
    } catch (e) { }
  };
  const getCategory = async (clientId, param, url) => {
    try {
      const response = await JobService.getCategoryByClient(
        clientId,
        param,
        url
      );
      setCategoryList(response.data.result);
      setSelectedCategory("");
    } catch (e) { }
  };

  const getRoles = async (id, param, url) => {
    try {
      const response = await JobService.getRoles(id, param, url);
      setRoleList(response.data.result);
    } catch (e) { }
  };

  const categoryChanges = (category) => {
    setSelectedRole({});
    if (category && category.id) {
      setSelectedCategory(category.id);
      getRoles(category.id);
    } else {
      setSelectedCategory("");
    }
  };

  const clientChange = (client) => {
    client && client.id ? setSelectedClient(client) : setSelectedClient({});
    if (client && client.id) getCategory(client.id);
  };

  const getCompnayList = async (param, url) => {
    try {
      const response = await JobService.getClientList(param, url);
      if (url) {
        let tempDataArr = clientList;
        tempDataArr = tempDataArr.concat(response.data.result.data);
        response.data.result.data = tempDataArr;
      }
      setClientData(response.data);
      setClientList($.extend(true, [], response.data.result.data));
    } catch (e) {
      console.log("error in the list", e);
    }
  };

  const paginateCompanyList = (e) => {
    if (clientData.detail.links && clientData.detail.links.next) {
      setCompanyLoading(true);
      getCompnayList("", clientData.detail.links.next);
      setCompanyLoading(false);
    }
  };

  const onClientInputChange = (param, actionType, list) => {
    if (actionType.action && actionType.action === "input-change") {
      setClientList([]);
      param ? getCompnayList(param) : getCompnayList();
    } else if (actionType.action === "menu-close" && !selectedClient.id)
      getCompnayList();
  };

  const roleChanges = (role) => {
    role && role.id ? setSelectedRole(role) : setSelectedRole("");
  };

  const qualificationChanges = (qualification) => {
    setSelectedCourse([]);
    setSelectedSpeci([]);
    if (qualification && qualification.id) {
      setSelectedQual(qualification.id);
      getCourses(qualification.id);
    } else {
      setSelectedQual("");
    }
  };

  const courceChanges = (courses) => {
    setSelectedSpeci([]);
    updateSpecilaisationList(courses, selectedCourse);
    if (courses && courses.length > 0) {
      setSelectedCourse(courses);
    } else {
      setSelectedCourse([]);
    }
  };

  const speciChanges = (speci) => {
    speci && speci.length > 0 ? setSelectedSpeci(speci) : setSelectedSpeci([]);
  };

  const updateSpecilaisationList = (newList, previousList) => {
    const newListLength = newList ? newList.length : 0;
    const previousListLen = previousList ? previousList.length : 0;
    let action = newListLength > previousListLen ? "add" : "remove";
    newList = newList || [];
    previousList = previousList || [];
    let item = "";
    if (action === "remove") {
      previousList.forEach((prevItem) => {
        let matched = false;
        newList.forEach((newItem) => {
          if (newItem.id === prevItem.id) {
            matched = true;
          }
        });
        if (!matched) {
          item = prevItem;
        }
      });
    } else {
      item = newList[newList.length - 1];
    }
    action === "add" ? getSpecialization(item) : updateSpeciList(item);
  };

  const updateSpeciList = (item) => {
    let specList = $.extend(true, [], speciList);
    speciList.forEach((spec, index) => {
      if (spec.label === item.name) {
        specList.splice(index, 1);
      }
    });
    setSpeciList(specList);
    setSelectedSpeci([]);
  };

  const onStateChange = (e) => {
    e.persist();
    handleOnChange(e);
    setCityList([]);
    getCityList(e.target.value);
  };

  const formatGroupLabel = (data) => {
    return (
      <div>
        <span>{data.name}</span>
      </div>
    );
  };

  const isNegotiableChange = (e) => {
    e.persist();
    const event = {
      target: {
        name: "isCtcNegotiable",
        value: !document.getElementById("isCtcNegotiable").checked,
      },
    };
    handleOnChange(event);
  };

  const isExpNegotiableChange = (e) => {
    e.persist();
    const event = {
      target: {
        name: "isExperienceNegotiable",
        value: !document.getElementById("isExperienceNegotiable").checked,
      },
    };
    handleOnChange(event);
  };

  const isHotJobChange = (e) => {
    e.persist();
    const event = {
      target: {
        name: "isHotJob",
        value: !document.getElementById("isHotJob").checked,
      },
    };
    handleOnChange(event);
  };

  const postedOnChange = (value) => {
    const event = { target: { name: "postOn", value: value } };
    handleOnChange(event);
  };

  const isValidForm = () => {
    let isValid = false;
    if (userType === "adm" || userType === "stf") {
      isValid =
        selectedClient.id &&
          selectedCategory &&
          selectedRole &&
          selectedRole.id &&
          selectedQual &&
          selectedCourse.length > 0 &&
          selectedSpeci.length > 0
          ? true
          : false;
    } else {
      isValid =
        selectedCategory &&
          selectedRole &&
          selectedRole.id &&
          selectedQual &&
          selectedCourse.length > 0 &&
          selectedSpeci.length > 0
          ? true
          : false;
    }
    return !isValid;
  };
  const customFilter = (option, inputValue) => {
        if(option.data.name && inputValue){
            return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        }
        return true;
    };
  const getDateFormat = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const onSubmitForm = (state) => {
    const requestPayload = {
      categoryId: selectedCategory,
      roleId: selectedRole.id,
      qualifications: [selectedQual],
      courses: selectedCourse.map((course) => course.id),
      specializations: selectedSpeci.map((speci) => speci.id),
      jobLocation: {},
    };
    if (userType === "adm" || userType === "stf") {
      requestPayload["clientId"] = selectedClient.id;
    }
    const data = state;
    Object.keys(data).forEach((key) => {
      if (key === "city" || key === "state" || key === "town") {
        requestPayload.jobLocation[key] = state[key].value || null;
      } else if (key === "postOn") {
        requestPayload[key] = getDateFormat(new Date(state[key].value));
      } else {
        requestPayload[key] = state[key].value || null;
      }
    });

    JobService.addJob(requestPayload).then(
      (res) => {
        if (res.status === 201 || res.status === 200) {
          setSuccessObj({ show: true, msg: "Job Created Successfully" });
          history.push(`/${userType}/job-post`);
        }
      },
      (err) => {
        setErrorObj({ show: true, msg: err.data.message });
      }
    );
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    stateSchema,
    validationStateSchema,
    onSubmitForm
  );

  if (loading) return <Loader />;
  return (
    <div className="content-wrapper" id="add-staff">
      <div className="content-container">
        <div className="form-wrapper">
          <div className="row h-100 w-100">
            <div className="container h-100 w-100">
              <div className="row align-items-center h-100 w-100">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-header text-center my-4">
                    <span className="text-msg">Add Job</span>
                  </div>
                  <form onSubmit={handleOnSubmit}>
                    <div className="form-group mb-4">
                      <div className="card">
                        <div className="card-header">Job Details</div>
                        <div className="card-body">
                          <div className="row">
                            <div className="form-group col-12 m-0">
                              <label className="label-sm">
                                Title <sup className="required">*</sup>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                id="title"
                                onChange={handleOnChange}
                                value={state.title.value}
                              />
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.title.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.title.error}
                              </small>
                            </div>
                            <div className="form-group col-12">
                              <label className="label-sm">
                                Description <sup className="required">*</sup>
                              </label>
                              <CKEditor
                                data={state.description.value}
                                editor={ClassicEditor}
                                config={{
                                  toolbar: [
                                    "heading",
                                    "|",
                                    "bold",
                                    "italic",
                                    "blockQuote",
                                    "link",
                                    "numberedList",
                                    "bulletedList",
                                    "insertTable",
                                    "|",
                                    "alignment",
                                    "|",
                                    "undo",
                                    "redo",
                                  ],
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  handleOnChange({
                                    target: {
                                      name: "description",
                                      value: data,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">status</label>
                              <select
                                className="form-control"
                                name="status"
                                id="status"
                                onChange={handleOnChange}
                                value={state.status.value}
                              >
                                <option value="">Select Status</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                              </select>
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.status.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.status.error}
                              </small>
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm w-100">
                                Posted On
                              </label>

                              <DatePicker
                                className="form-control w-100"
                                id="postOn"
                                name="postOn"
                                minDate={new Date()}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onChange={postedOnChange}
                                selected={state.postOn.value}
                                autoComplete="off"
                              ></DatePicker>
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.town.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.town.error}
                              </small>
                            </div>
                            {userType === "adm" || userType === "stf" ? (
                              <div className="form-group col-6">
                                <label className="label-sm mr-4 mb-3">
                                  Mark as Hot Job
                                </label>
                                <div
                                  className="switch"
                                  onClick={isHotJobChange}
                                >
                                  {state.isHotJob.value ? (
                                    <input
                                      type="checkbox"
                                      name="isHotJob"
                                      id="isHotJob"
                                      value={state.isHotJob.value || true}
                                      checked={state.isHotJob.value || true}
                                      onChange={isHotJobChange}
                                    />
                                  ) : (
                                    <input
                                      type="checkbox"
                                      name="isHotJob"
                                      id="isHotJob"
                                      value={state.isHotJob.value || false}
                                      checked={state.isHotJob.value || false}
                                      onChange={isHotJobChange}
                                    />
                                  )}
                                  <span className="slider round"></span>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {userType === "adm" || userType === "stf" ? (
                      <div className="form-group mb-4">
                        <div className="card">
                          <div className="card-header">Company Detail</div>
                          <div className="card-body">
                            <label className="label-sm">
                              Company Name <sup className="required">*</sup>
                            </label>
                            <Autocomplete
                              name="company-select"
                              placeholder="Select Company"
                              defaultOptions
                              isClearable={true}
                              options={clientList}
                              isDisabled={
                                location && location.state && location.state.id
                                  ? true
                                  : false
                              }
                              value={selectedClient.id ? selectedClient : null}
                              filterOption={customFilter}
                              isLoading={companyLoading}
                              getOptionLabel={(option) => {
                                return (
                                  <div className="row autocomplete-item no-gutters">
                                    {option.logo && option.logo.href ? (
                                      <img
                                        className="logo col-auto"
                                        src={
                                          option.logo
                                            ? `${option.logo.href}`
                                            : ""
                                        }
                                      />
                                    ) : (
                                      <div className="no-logo col-auto">
                                        <div className="margin-auto ">
                                          {option.name && option.name.charAt(0)}
                                        </div>
                                      </div>
                                    )}
                                    <div className="text col p-1 text-truncate">
                                      <div>{option.name}</div>
                                    </div>
                                  </div>
                                );
                              }}
                              getOptionValue={(option) => {
                                return option.id;
                              }}
                              onChange={clientChange}
                              onMenuScrollToBottom={paginateCompanyList}
                              onInputChange={onClientInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="form-group mb-4">
                      <div className="card">
                        <div className="card-header">Category Detail</div>
                        <div className="card-body">
                          <div className="row">
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Category <sup className="required">*</sup>
                              </label>
                              <Autocomplete
                                name="category-select"
                                placeholder="Select Category"
                                defaultOptions
                                isClearable={true}
                                options={categoryList}
                                filterOption={customFilter}
                                getOptionLabel={(option) => {
                                  return (
                                    <div className="row autocomplete-item no-gutters">
                                      <div className="text col p-1 text-truncate">
                                        <div>{option.name}</div>
                                      </div>
                                    </div>
                                  );
                                }}
                                getOptionValue={(option) => {
                                  return option.id;
                                }}
                                onChange={categoryChanges}
                              />
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Roles <sup className="required">*</sup>
                              </label>
                              <Autocomplete
                                name="roles-select"
                                placeholder="Select Roles"
                                defaultOptions
                                isClearable={true}
                                options={roleList}
                                filterOption={customFilter}
                                isDisabled={selectedCategory ? false : true}
                                getOptionLabel={(option) => {
                                  return (
                                    <div className="row autocomplete-item no-gutters">
                                      <div className="text col p-1 text-truncate">
                                        <div>{option.name}</div>
                                      </div>
                                    </div>
                                  );
                                }}
                                value={
                                  selectedRole && selectedRole.id
                                    ? {
                                      id: selectedRole.id,
                                      name: selectedRole.name,
                                    }
                                    : null
                                }
                                getOptionValue={(option) => {
                                  return option.id;
                                }}
                                onChange={roleChanges}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <div className="card">
                        <div className="card-header">Qualification Detail</div>
                        <div className="card-body">
                          <div className="row">
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Qualification <sup className="required">*</sup>
                              </label>
                              <Autocomplete
                                name="qualification-select"
                                placeholder="Select Qualification"
                                defaultOptions
                                isClearable={true}
                                options={qualificationList}
                                filterOption={customFilter}
                                getOptionLabel={(option) => {
                                  return (
                                    <div className="row autocomplete-item no-gutters">
                                      <div className="text col p-1 text-truncate">
                                        <div>{option.name}</div>
                                      </div>
                                    </div>
                                  );
                                }}
                                getOptionValue={(option) => {
                                  return option.id;
                                }}
                                onChange={qualificationChanges}
                              />
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Course <sup className="required">*</sup>
                              </label>
                              <Autocomplete
                                name="course-select"
                                placeholder="Select Course"
                                defaultOptions
                                isMulti={true}
                                isClearable={true}
                                filterOption={customFilter}
                                options={courseList}
                                isDisabled={selectedQual ? false : true}
                                value={selectedCourse}
                                getOptionLabel={(option) => {
                                  return (
                                    <div className="row autocomplete-item no-gutters">
                                      <div className="text col text-truncate">
                                        <div>{option.name}</div>
                                      </div>
                                    </div>
                                  );
                                }}
                                getOptionValue={(option) => {
                                  return option.id;
                                }}
                                onChange={courceChanges}
                              />
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Specialization <sup className="required">*</sup>
                              </label>
                              <Autocomplete
                                name="Speci-select"
                                placeholder="Select Specialization"
                                defaultOptions
                                isMulti={true}
                                isClearable={true}
                                filterOption={customFilter}
                                options={speciList}
                                isDisabled={
                                  selectedCourse && selectedCourse.length > 0
                                    ? false
                                    : true
                                }
                                value={selectedSpeci}
                                getOptionLabel={(option) => {
                                  return (
                                    <div className="row autocomplete-item no-gutters">
                                      <div className="text col text-truncate">
                                        <div>{option.name}</div>
                                      </div>
                                    </div>
                                  );
                                }}
                                getOptionValue={(option) => {
                                  return option.id;
                                }}
                                onChange={speciChanges}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <div className="card">
                        <div className="card-header">Location Detail</div>
                        <div className="card-body">
                          <div className="row">
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                State <sup className="required">*</sup>
                              </label>
                              <select
                                className="form-control"
                                name="state"
                                id="state"
                                onChange={onStateChange}
                                value={state.state.value}
                              >
                                <option value="">Select State</option>
                                {stateList
                                  ? stateList.map((state, index) => {
                                    return (
                                      <option key={index} value={state.id}>
                                        {state.name}
                                      </option>
                                    );
                                  })
                                  : ""}
                              </select>
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.state.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.state.error}
                              </small>
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                City <sup className="required">*</sup>
                              </label>
                              <select
                                className="form-control"
                                name="city"
                                id="city"
                                onChange={handleOnChange}
                                value={state.city.value}
                                disabled={state.state.value ? false : true}
                              >
                                <option value="">Select City</option>
                                {cityList
                                  ? cityList.map((city, index) => {
                                    return (
                                      <option key={index} value={city.id}>
                                        {city.name}
                                      </option>
                                    );
                                  })
                                  : ""}
                              </select>
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.city.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.city.error}
                              </small>
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">Town </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Town"
                                id="town"
                                name="town"
                                value={state.town.value}
                                onChange={handleOnChange}
                              />
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.town.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.town.error}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-4">
                      <div className="card">
                        <div className="card-header">Experience & Salary</div>
                        <div className="card-body">
                          <div className="row">
                            <div className="form-group col-12 m-0">
                              <label className="label-sm mb-3 mr-4">
                                Salary is negotiable
                              </label>
                              <div
                                className="switch"
                                onClick={isNegotiableChange}
                              >
                                {state.isCtcNegotiable.value ? (
                                  <input
                                    type="checkbox"
                                    name="isCtcNegotiable"
                                    id="isCtcNegotiable"
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    name="isCtcNegotiable"
                                    id="isCtcNegotiable"
                                  />
                                )}
                                <span className="slider round"></span>
                              </div>
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Minimum Salary{" "}
                                {state.isCtcNegotiable.value ? (
                                  ""
                                ) : (
                                  <sup className="required">*</sup>
                                )}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="minimumCtc"
                                id="minimumCtc"
                                onChange={handleOnChange}
                                value={state.minimumCtc.value}
                                disabled={state.isCtcNegotiable.value}
                              />
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.minimumCtc.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.minimumCtc.error}
                              </small>
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Maximum Salary
                                {state.isCtcNegotiable.value ? (
                                  ""
                                ) : (
                                  <sup className="required">*</sup>
                                )}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="maximumCtc"
                                id="maximumCtc"
                                onChange={handleOnChange}
                                value={state.maximumCtc.value}
                                disabled={state.isCtcNegotiable.value}
                              />
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.maximumCtc.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.maximumCtc.error}
                              </small>
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-12 m-0">
                              <label className="label-sm mb-3 mr-4">
                                Any Experience Range
                              </label>
                              <div
                                className="switch"
                                onClick={isExpNegotiableChange}
                              >
                                {state.isExperienceNegotiable.value ? (
                                  <input
                                    type="checkbox"
                                    name="isExperienceNegotiable"
                                    id="isExperienceNegotiable"
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    name="isExperienceNegotiable"
                                    id="isExperienceNegotiable"
                                  />
                                )}
                                <span className="slider round"></span>
                              </div>
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Minimum Experience{" "}
                                {state.isExperienceNegotiable.value ? (
                                  ""
                                ) : (
                                  <sup className="required">*</sup>
                                )}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="minimumExperience"
                                id="minimumExperience"
                                onChange={handleOnChange}
                                value={state.minimumExperience.value}
                                disabled={state.isExperienceNegotiable.value}
                              />
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.minimumExperience.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.minimumExperience.error}
                              </small>
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Maximum Experience
                                {state.isExperienceNegotiable.value ? (
                                  ""
                                ) : (
                                  <sup className="required">*</sup>
                                )}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="maximumExperience"
                                id="maximumExperience"
                                onChange={handleOnChange}
                                value={state.maximumExperience.value}
                                disabled={state.isExperienceNegotiable.value}
                              />
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.maximumExperience.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.maximumExperience.error}
                              </small>
                            </div>
                          </div>
                          {/* <div className="row">
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Minimum Experience{" "}
                                <sup className="required">*</sup>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="minimumExperience"
                                id="minimumExperience"
                                onChange={handleOnChange}
                                value={state.minimumExperience.value}
                              />
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.minimumExperience.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.minimumExperience.error}
                              </small>
                            </div>
                            <div className="form-group col-6 m-0">
                              <label className="label-sm">
                                Maximum Experience
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="maximumExperience"
                                id="maximumExperience"
                                onChange={handleOnChange}
                                value={state.maximumExperience.value}
                              />
                              <small
                                className="form-text  error-text m-0"
                                style={{
                                  visibility: state.maximumExperience.error
                                    ? "visible"
                                    : "hidden",
                                }}
                              >
                                {state.maximumExperience.error}
                              </small>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="action-btn text-center my-4">
                      <Link
                        to={`/${userType}/job-post`}
                        type="button"
                        className="btn  btn-outline-secondary login-btn mr-3"
                      >
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn  btn-primary login-btn"
                        disabled={disable || isValidForm()}
                      >
                        Add Job
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessToaster
        data={successObj}
        onClose={() => setSuccessObj({ show: false, success: {} })}
      ></SuccessToaster>
      <ErrorToaster
        data={errorObj}
        onClose={() => setErrorObj({ show: false, msg: "" })}
      ></ErrorToaster>
    </div>
  );
};

export default JobPostCreate;
