import React, { useEffect, useState } from "react";
import ManageService from "../../../service/ManageService";
import NavBar from "../Navbar";
import Footer from "../Footer";
import { SuccessToaster } from "../../../component/modal/SuccessModal";
import { ErrorToaster } from "../../../component/modal/ErrorModal";
import ContactForm from "./ContactForm";
import ContactImage from '../../../../assets/imgs/pose_6.png';
import HtmlParser from "react-html-parser";

const SuccessMsg =
  "Your request got submitted successfuly and we will look into this as soon as possible";

const ContactUs = () => {
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });
  const [headerData, setHeaderData] = useState("");
  const [successObj, setSuccessObj] = useState({
    show: false,
    msg: "",
  });

  useEffect(() => {
    setErrorObj({ show: false, msg: "" });
    getContactUsData();
  }, []);

  const getContactUsData = async () => {
    const response = await ManageService.getAboutorContentPageData(
      "contact-us"
    );
    if (response.status === 200) {
      setHeaderData(response.data.result?.content);
    }
  };

  const onFormSubmit = (data) => {
    if (data && data.success) {
      setSuccessObj({ show: true, msg: SuccessMsg });
      setTimeout(() => {
        setSuccessObj({ show: false, msg: "" });
      }, 3000);
    } else {
      setErrorObj({ show: true, msg: data.msg });
      setTimeout(() => {
        setErrorObj({ show: false, msg: "" });
      }, 3000);
    }
  }

  return (
    <div id="contact-us">
      <NavBar />
      <div className="hero-section justify-content-center d-flex">
        <div className="container">
          <h3 className="hero-title">CONTACT US</h3>
          <div className="hero-text text-center"><div>{HtmlParser(headerData)}</div></div>
        </div>
      </div>
      <div className="content-section">
        <div className="row no-gutters">
          <div className="col-md-1 col-lg-1 col-xl-1 d-sm-none d-xs-none d-md-block d-lg-block d-xl-block">
          </div>
          <div className="col-md-10 col-lg-10 col-xl-10 col-sm-12 col-xs-12">
            <div className="container">
              <div className="row no-gutters justify-content-end">
                <div className="col-md-8 col-lg-8 col-xl-8 col-sm-12 col-xs-12 lead-form-section">
                  <img src={ContactImage} className="contactus-image d-sm-none d-xs-none d-md-block d-lg-block d-xl-block"></img>

                  <ContactForm onFormSubmit={onFormSubmit}></ContactForm>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1 col-lg-1 col-xl-1 d-sm-none d-xs-none d-md-block d-lg-block d-xl-block">
          </div>
        </div>
      </div>
      <Footer />
      <SuccessToaster
        data={successObj}
        onClose={() => {
          setSuccessObj({ show: false, msg: "" });
          setInitionalSchema(intialSchema);
        }}
      ></SuccessToaster>
      <ErrorToaster
        data={errorObj}
        onClose={() => {
          setErrorObj({ show: false, msg: "" });
        }}
      ></ErrorToaster>
    </div>
  );
};

export default ContactUs;
