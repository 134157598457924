import React, { useState, useRef, useEffect, createRef, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import $ from "jquery";

const BaseWizard = (props) => {
  const { userType } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [stepConfig, setStepConfig] = useState(props.config.steps);
  const [wizardData, setWizardData] = useState(props.data);
  const [wizardConfig, setWizardConfig] = useState(props.config);
  const [canProceed, setCanProceed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const domRef = useRef();
  const stepRef = useRef(props.config.steps.map(() => createRef()));
  const history = useHistory();
  const nextLabel = props.config.nextLabel || "Save & Proceed";
  const submitLabel = props.config.submitLabel || "Submit";
  const showCancel = props.config.showCancel === false ? false : true;

  useEffect(() => {
    updateHeader();
  }, [currentStep]);

  const goToNextStep = () => {
    const steps = stepConfig;
    steps[currentStep].revisited = true;
    setStepConfig(steps);
    setCurrentStep(currentStep + 1);
    setCanProceed(false);
  };

  const onStepSubmit = () => {
    props.config.isProgression
      ? stepRef.current[currentStep].current.submit()
      : goToNextStep();
  };

  const goToPrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const updateStepData = (stepData, stepConfigs) => {
    const data = wizardData;
    data[stepConfigs.name] = stepData;
    setWizardData(data);
    goToNextStep();
  };
  const enableNextButton = (flag) => {
    setCanProceed(flag);
  };

  const toggleLoader = (flag) => {
    setIsLoading(flag);
  }

  const updateHeader = () => {
    const curStepIndex = currentStep;
    const $stepLabels = $(domRef.current).find(".wiz-header .step-label");
    const offset = curStepIndex / (stepConfig.length - 1);

    for (let i = 0; i < stepConfig.length; i++) {
      $stepLabels
        .eq(i)
        .toggleClass("prior", i < curStepIndex)
        .toggleClass("current", i === curStepIndex);
    }

    $(domRef.current).find(".step-slider .inner")[0].style.transform =
      "scaleX(" + offset + ")";
  };

  const submitForm = () => {
    if (props.config.isProgression)
      stepRef.current[currentStep].current.submit();
  };

  const goBack = () => {
    if(userType){
      history.push(`/${userType}/candidate`);
    }else{
      history.push(`/home`);
    }
  };
  return (
    <div className="content-wrapper p-0" id="wizard-container" ref={domRef}>
      <div className="container-fluid">
        <div className="wizard-container">
          {isLoading &&
            <div className="loader-container">
              <div className="loader-wrapper">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <span>Loading...</span>
              </div>
            </div>
          }
          <div className="wiz-header">
            <div className="contain">
              <div className="wiz-title">{props.config.name}</div>
              <div className="step-labels">
                {stepConfig.map((step, index) => {
                  return (
                    <div
                      className={`step-label ${currentStep === index ? "current" : ""
                        }`}
                      key={index}
                      data-step-index={index}
                      style={{ width: `${100 / stepConfig.length}%` }}
                    >
                      <div className="index">{index + 1}</div>
                      <div className="text">{step.displayName}</div>
                    </div>
                  );
                })}

                <div
                  className="step-slider"
                  style={{
                    left: `${100 / stepConfig.length / 2}%`,
                    right: `${100 / stepConfig.length / 2}%`,
                  }}
                >
                  <div className="inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="wiz-body">
            {stepConfig.map((step, index) => {
              let Component = step.type;
              if (currentStep === index) {
                return (
                  //<div className={`${currentStep === index ? 'shown' :'hide'}`} key={index}>
                  <div
                    className={`contain step-frame ${currentStep === index ? "shown" : "hide"
                      }`}
                    key={index}
                  >
                    <Component
                      ref={stepRef.current[index]}
                      wizard={wizardData}
                      stepConfig={step}
                      updateData={updateStepData}
                      canProceed={enableNextButton}
                      afterStepSubmission={goToNextStep}
                      enableLoader={toggleLoader}
                    ></Component>
                  </div>
                  //</div>
                );
              } else {
                return (
                  //<div className={`${currentStep === index ? 'shown' :'hide'}`} key={index}>
                  <div className={`contain step-frame`} key={index}></div>
                  //</div>
                );
              }
            })}
          </div>

          <div className="wiz-footer">
            <div className="contain buttons">
              {/* {JSON.stringify(stepConfig)} */}
              {showCancel && <a className="cancel" onClick={goBack}>
                cancel
              </a>}
              {currentStep > 0 ? (
                <button
                  type="button"
                  name="prev"
                  className="btn btn-outline-primary prev-btn"
                  onClick={goToPrevStep}
                >
                  {" "}
                  Previous
                </button>
              ) : (
                ""
              )}
              {currentStep < props.config.steps.length - 1 ? (
                <button
                  type="button"
                  name="next"
                  className="btn btn-primary next-btn"
                  onClick={onStepSubmit}
                  disabled={canProceed ? false : true}
                >
                  {nextLabel}
                </button>
              ) : (
                <button
                  type="button"
                  name="next"
                  className="btn btn-primary next-btn"
                  onClick={submitForm}
                  disabled={canProceed ? false : true}
                >
                  {submitLabel}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseWizard;
