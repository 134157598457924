import React, { useEffect, useState } from "react";
import UseForm from "../../../service/FormService";
import ManageService from "../../../service/ManageService";
import CandidateService from "../../../service/CandidateService";
import Autocomplete from "react-select";
import $ from "jquery";
const emailRegx =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const stateSchema = {
  organizationName: { value: "", error: "" },
  personName: { value: "", error: "" },
  designation: { value: "", error: "" },
  contactNumber: { value: "", error: "" },
  contactEmail: { value: "", error: "" },
  service: { value: "", error: "" },
  state: { value: "", error: "" },
  city: { value: "", error: "" },
  address: { value: "", error: "" },
  query: { value: "", error: "" },
};

const validationStateSchema = {
  organizationName: {
    required: true,
  },
  personName: {
    required: true,
  },
  designation: {
    required: true,
  },
  contactNumber: {
    required: true,
    validator: {
      regEx: /^([0-9]{10})$/,
      error: "Invalid phone number format.",
    },
  },
  contactEmail: {
    required: false,
    validator: {
      regEx: emailRegx,
      error: "Invalid Email",
    },
  },
  service: {
    required: true,
  },
  state: {
    required: true,
  },
  city: {
    required: true,
  },
  address: {
    required: true,
  },
  query: {
    required: true,
  },
};

const ContactForm = (props) => {
    const [intialSchema, setInitionalSchema] = useState(
        $.extend(true, {}, {...stateSchema, service: {value: props.service || "",error : ""}})
      );
      const [stateList, setStateList] = useState([]);
      const [cityList, setCityList] = useState([]);
    
      useEffect(() => {
        getStateList();
      }, []);

      const getCityList = async (stateId) => {
        try {
          const response = await CandidateService.getCityList(stateId);
          setCityList(response.data.result);
        } catch (err) {
          //setLoading(false);
        }
      };
      const getStateList = async () => {
        try {
          const response = await CandidateService.getStateList("active");
          setStateList(response.data.result);
        } catch (err) {
          //setLoading(false);
        }
      };
    
      const stateChanges = (data) => {
        handleOnChange({
          target: {
            name: "state",
            value: data ? { id: data.id, name: data.name } : "",
          },
        });
        handleOnChange({ target: { name: "city", value: "" } });
        if (data && data.id) getCityList(data.id);
      };
    
      const cityChanges = (data) => {
        handleOnChange({
          target: {
            name: "city",
            value: data ? { id: data.id, name: data.name } : "",
          },
        });
      };
    
      const customFilter = (option, inputValue) => {
            if(option.data.name && inputValue){
                return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
            }
            return true;
        };
    
      const onSubmitForm = (state) => {
        const requestPayload = {
          organizationName: state.organizationName.value,
          personName: state.personName.value,
          designation: state.designation.value,
          contactNumber: state.contactNumber.value,
          contactEmail: state.contactEmail.value,
          service: state.service.value,
          state: state.state.value.id,
          city: state.city.value.id,
          address: state.address.value,
          query: state.query.value,
        };
        const key = props.planId ? 'planId': props.equipmentId ? 'equipmentId' : '';
        const value = props.planId || props.equipmentId || '';
        ManageService.createLead(requestPayload, key, value).then(
          (res) => {
            if (res.status === 200 || res.status === 201) {
              setInitionalSchema($.extend(true, {}, stateSchema));
              props.onFormSubmit({success : true})
            }
          },
          (err) => {
            props.onFormSubmit({error : true, msg: err.data.message});
          }
        );
      };
    
      const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
        intialSchema,
        validationStateSchema,
        onSubmitForm
      );
    
      return (
        <div id="contactus-form">
          <div className="form-container align-items-center">
              <div className="lead-container">
                <form name="add-lead">
                  <div className="row no-gutter">
                    <div className="form-group col-12 m-0">
                      <input
                        type="text"
                        placeholder=" Organization  Name"
                        className="form-control"
                        name="organizationName"
                        id="organizationName"
                        onChange={handleOnChange}
                        value={state.organizationName.value}
                      />
                      <small
                        className="form-text  error-text my-1"
                        style={{
                          visibility: state.organizationName.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {state.organizationName.error}
                      </small>
                    </div>
                    <div className="form-group col-12 m-0">
                      <input
                        type="text"
                        placeholder="Person Name"
                        className="form-control"
                        name="personName"
                        id="personName"
                        onChange={handleOnChange}
                        value={state.personName.value}
                      />
                      <small
                        className="form-text  error-text my-1"
                        style={{
                          visibility: state.personName.error ? "visible" : "hidden",
                        }}
                      >
                        {state.personName.error}
                      </small>
                    </div>
                    <div className="form-group col-12 m-0">
                      <input
                        type="text"
                        placeholder="Designation"
                        className="form-control"
                        name="designation"
                        id="designation"
                        onChange={handleOnChange}
                        value={state.designation.value}
                      />
                      <small
                        className="form-text  error-text my-1"
                        style={{
                          visibility: state.designation.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {state.designation.error}
                      </small>
                    </div>
                    <div className="form-group col-12 m-0">
                      <input
                        type="text"
                        placeholder="Contact Number"
                        className="form-control"
                        name="contactNumber"
                        id="contactNumber"
                        onChange={handleOnChange}
                        value={state.contactNumber.value}
                      />
                      <small
                        className="form-text  error-text my-1"
                        style={{
                          visibility: state.contactNumber.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {state.contactNumber.error}
                      </small>
                    </div>
    
                    <div className="form-group col-12 m-0">
                      <input
                        type="text"
                        placeholder=" Contact Email"
                        className="form-control"
                        name="contactEmail"
                        id="contactEmail"
                        onChange={handleOnChange}
                        value={state.contactEmail.value}
                      />
                      <small
                        className="form-text  error-text my-1"
                        style={{
                          visibility: state.contactEmail.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {state.contactEmail.error}
                      </small>
                    </div>
                    <div className="form-group col-12 m-0">
                      <select
                        type="text"
                        className="form-control"
                        placeholder="Service"
                        name="service"
                        id="service"
                        onChange={handleOnChange}
                        value={state.service.value}
                        disabled={props.isServiceDisabled}
                      >
                        <option value="">Select Service</option>
                        <option value="JOB POSTING SERVICES">
                          Posting Services
                        </option>
                        <option value="DATABASE SERVICES">
                          Database Service
                        </option>
                        <option value="EQUIPMENT PURCHASE">
                          Equipment Purchase
                        </option>
                      </select>
                      <small
                        className="form-text  error-text my-1"
                        style={{
                          visibility: state.service.error ? "visible" : "hidden",
                        }}
                      >
                        {state.service.error}
                      </small>
                    </div>
                    <div className="form-group col-12 m-0">
                      <Autocomplete
                        name="State"
                        placeholder="Select State"
                        defaultOptions
                        class="contactus-form-state"
                        classNamePrefix="lead-state"
                        isClearable={true}
                        filterOption={customFilter}
                        options={stateList}
                        value={state.state.value ? state.state.value : null}
                        getOptionLabel={(option) => {
                          return (
                            <div className="row autocomplete-item no-gutters">
                              <div className="text col p-1 text-truncate">
                                <div>{option.name}</div>
                              </div>
                            </div>
                          );
                        }}
                        getOptionValue={(option) => {
                          return option.id;
                        }}
                        onChange={stateChanges}
                      />
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.state.error ? "visible" : "hidden",
                        }}
                      >
                        {state.state.error}
                      </small>
                    </div>
                    <div className="form-group col-12 m-0">
                      <Autocomplete
                        name="city"
                        placeholder="Select City"
                        classNamePrefix="lead-city"
                        defaultOptions
                        isClearable={true}
                        isDisabled={
                          state.state.value && state.state.value.id ? false : true
                        }
                        filterOption={customFilter}
                        options={cityList}
                        value={state.city.value ? state.city.value : null}
                        getOptionLabel={(option) => {
                          return (
                            <div className="row autocomplete-item no-gutters">
                              <div className="text col p-1 text-truncate">
                                <div>{option.name}</div>
                              </div>
                            </div>
                          );
                        }}
                        getOptionValue={(option) => {
                          return option.id;
                        }}
                        onChange={cityChanges}
                      />
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.city.error ? "visible" : "hidden",
                        }}
                      >
                        {state.city.error}
                      </small>
                    </div>
                    <div className="form-group col-12 m-0">
                      <input
                        type="text"
                        placeholder="Address"
                        className="form-control"
                        name="address"
                        id="address"
                        onChange={handleOnChange}
                        value={state.address.value}
                      />
                      <small
                        className="form-text  error-text my-1"
                        style={{
                          visibility: state.address.error ? "visible" : "hidden",
                        }}
                      >
                        {state.address.error}
                      </small>
                    </div>
                    <div className="form-group col-12 m-0">
                      <textarea
                        type="text"
                        placeholder="Query"
                        className="form-control"
                        name="query"
                        id="query"
                        onChange={handleOnChange}
                        value={state.query.value}
                      ></textarea>
                      <small
                        className="form-text  error-text my-1"
                        style={{
                          visibility: state.query.error ? "visible" : "hidden",
                        }}
                      >
                        {state.query.error}
                      </small>
                    </div>
                    <div className="form-group col-12 m-0 justify-content-center d-flex">
                      <button
                        type="button"
                        className="btn btn-theme text-center"
                        disabled={disable}
                        onClick={handleOnSubmit}
                      >
                        Procced
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
)}

export default ContactForm;