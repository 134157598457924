import React from 'react';
import NavBar from '../common/Navbar';
import pageNotFoundError from '../../assets/imgs/404-error.png';

const NoMatch = (props) => {
    return (
        <div className="container-fluid m-0 p-0">
            <NavBar></NavBar>
            <div className="container-fluid m-0 p-0 unauthorised-container">
                <div className="row h-100 justify-content-center align-items-center">
                    <img src={pageNotFoundError} />
                </div>
            </div>
        </div>
        //<h2 className="margin-auto">You are not authorised for this page. Please contact your admin</h2>
    )
}

export default NoMatch;