import React, { useState, useEffect } from "react";
import Loader from "../../component/Loader";
import ManageService from "../../service/ManageService";
import { ErrorToaster } from "../../component/modal/ErrorModal";
import { SuccessToaster } from "../../component/modal/SuccessModal";
import UseForm from "../../service/FormService";
import $ from "jquery";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const stateSchema = {
  content: { value: "", error: "" },
};

const validationStateSchema = {
  content: {
    required: true,
  },
};

const TermsConditions = (props) => {
  const [loading, setLoading] = useState(true);
  const [errModel, setErrorModal] = useState({ show: false, msg: "" });
  const [successModel, setSuccessModal] = useState({
    show: false,
    msg: "",
  });
  const [intialSchema, setInitionalSchema] = useState(
    $.extend(true, {}, stateSchema)
  );

  useEffect(() => {
    if (props.selectedTab === "terms_conditions" || props.selectedTab === "terms_conditions") {
      getTermsConditionData();
    }
  }, [props]);

  const getTermsConditionData = async () => {
    const response = await ManageService.getTermsConditionData();
    if (response.status === 200) {
      let schema = $.extend(true, {}, stateSchema);
      schema.content.value = response.data.result?.description || "";
      setInitionalSchema(schema);
    }
    setLoading(false);
  };

  const onSubmitForm = (state) => {
    const requestPayload = {
        description: state.content.value,
    };
    ManageService.addTermsConditionData(requestPayload).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          setSuccessModal({ show: true, msg: "Data updated Successfully" });
        }
      },
      (err) => {
        setErrorModal({ show: true, msg: err.data.message });
        setTimeout(() => {
          setErrorModal({ show: false, msg: "" });
        }, 3000);
      }
    );
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    intialSchema,
    validationStateSchema,
    onSubmitForm
  );

  return (
    <div id="category-list-container" className="about-us-container">
      <div className="container-fluid">
        <div className="row p-3 filter-row">
          <div className="col-12">
            <h3>Terms & Conditions</h3>
          </div>
        </div>
      </div>
      <div className="list-card list-view" id="category-list-view">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="content">Content </label>
              <CKEditor
                        data={state.content.value}
                        editor={ClassicEditor}
                        config={{
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "blockQuote",
                            "link",
                            "numberedList",
                            "bulletedList",
                            "insertTable",
                            "|",
                            "alignment",
                            "|",
                            "undo",
                            "redo",
                          ],
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          //console.log( 'on change',{ event, editor, data } );
                          handleOnChange({
                            target: {
                              name: "content",
                              value: data,
                            },
                          });
                        }}
                      />
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.content.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {state.content.error}
                      </small>
            </div>
          </div>
          <div className="col-12">
            <button
              type="button"
              className="btn btn-sm btn-outline-primary"
              disabled={disable}
              onClick={handleOnSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <ErrorToaster
        data={errModel}
        onClose={() => setErrorModal({ show: false, msg: "" })}
      ></ErrorToaster>
      <SuccessToaster
        data={successModel}
        onClose={() => setSuccessModal({ show: false, msg: "" })}
      ></SuccessToaster>
    </div>
  );
};

export default TermsConditions;
