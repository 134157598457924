import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import NoDataFound from '../../common/NoDataFound';
import CandidateService from '../../service/CandidateService';
import Loader from '../../component/Loader';
import ConfirmModal from '../../component/modal/ConfirmModal';
import ErrorModal from '../../component/modal/ErrorModal';
import SuccessModal from '../../component/modal/SuccessModal';
import Moment from 'react-moment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GraduateImg from '../../../assets/imgs/graduate.png';
import CarrerImg from '../../../assets/imgs/working_women.png';
import AddressImg from '../../../assets/imgs/house.png'
import FileViewerModal from '../../component/modal/FileViewerPopup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';

import LocalStorageService from "../../service/LocalstorageService";
const localService = LocalStorageService.getService();

const NoData = (props) => {
    return (
        <div className="content-wrapper">
            <div className="content-container">
                <NoDataFound name={props.name} redirectUrl={props.redirectUrl}></NoDataFound>
            </div>
        </div>
    )
}

const CandidateView = (props) => {
    const { id, userType } = useParams();
    const [loading, setLoading] = useState(true);
    const [candidateData, setCandidateData] = useState({});
    const [errModel, setErrorModal] = useState({ show: false, error: {} })
    const [successModel, setSuccessModal] = useState({ show: false, success: {} })
    const [confirmationObj, setConfirmationObj] = useState({ show: false, msg: '' });
    const [fileViewObj, setFileViewObj] = useState({ show: false, file: {} });


    useEffect(() => {
        getCandidateDetails();
    }, []);


    const getCandidateDetails = async () => {
        CandidateService.getCandidateDetails(id).then((res) => {
            if (res.status === 201 || res.status === 200) {
                setCandidateData(res.data.result)
                setLoading(false);
            }
        }, (err) => {
            setErrorModal({ 'show': true, 'error': err.data })
            setLoading(false);
        })
    }

    const openFileReader = () => {
        setFileViewObj({ show: true, file: candidateData.professional.resume });
    }

    const downloadCv = async (cand) => {
        var a = document.createElement('a');
        a.target = '_blank';
        a.href = localService.getServerIp() + 'candidate/user/custom-cv/' + cand.id + '?token=' + JSON.parse(localStorage.getItem('access_token'));
        a.click();

    }

    const originalCv = async (profile_url) => {
        var a = document.createElement('a');
        a.target = '_blank';
        a.href = profile_url;
        a.click();

    }

    const getfileExtension = (fileName) => {
        const lastDot = fileName.lastIndexOf('.');
        const extension = fileName.substr(lastDot, fileName.length);
        return extension;
    }

    const getfileName = (fileName) => {
        const lastDot = fileName.lastIndexOf('.');
        const name = fileName.substr(0, lastDot);
        return name;
    }

    if (loading) return <Loader />;


    return (
        <div className="content-wrapper p-0" id="condidate-details-container">
            <div className="row summery-section">
                <div className="col-xl-2 col-lg-2 col-md-3 row">
                    <div className="col image-wrapper text-center">
                        {candidateData.personal && candidateData.personal.profilePic ?
                            <img src={candidateData.personal.profilePic.href} className="profile-pic" />
                            : <div className="no-img margin-auto"><span className="margin-auto">No Img Avilable</span></div>}
                    </div>
                </div>
                <div className="col-xl-10 col-lg-10 col-md-9 row">
                    <div className="col-12 row align-items-center my-1">
                        <div className="name col-auto">{`${candidateData.personal.firstName} ${candidateData.personal.middleName || ''} ${candidateData.personal.lastName}`}</div>
                        {candidateData.isArchived ? <span className="badge badge-danger col-auto">Archived</span> : candidateData.isActive ? <span className="badge badge-success col-auto">Active</span> : <span className="badge badge-warning">Inactive</span>}&nbsp;{!candidateData.hasEmailVerified ? <span className="badge badge-danger" title="Email Not Verified"> NOT VERIFIED</span> : <span className="badge badge-primary" title="Email Verified"> VERIFIED</span>}
                        <div className="col"></div>
                        {!candidateData.isArchived && props.permission.indexOf('update') > -1 ? <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/candidate/update/${id}`} className="btn btn-outline-primary">Update</Link> : ''}
                    </div>
                    {candidateData.invitedBy ? <div className="col-12 row align-items-center my-1"><span className="badge badge-primary col-auto" title={candidateData.invitedBy.email}>Profile Added By - {candidateData.invitedBy.firstName+' '+candidateData.invitedBy.lastName}</span></div>: ''}
                    <div className="col-12 row my-1">
                        <p className="col-12 m-0">{candidateData.summary.currentEmployer ? `Working at - ${candidateData.summary.currentEmployer}` : ''}</p>
                    </div>
                    <div className="col-12 row my-1">
                        {candidateData.professional && candidateData.professional.currentLocation ?
                            <div className="col-auto location">
                                <LocationOnIcon></LocationOnIcon>
                                <span className="mx-2">{` 
                                    ${candidateData.professional.currentLocation.town ? candidateData.professional.currentLocation.town : ''} 
                                    ${candidateData.professional.currentLocation.city ? candidateData.professional.currentLocation.city.name : ''},
                                    ${candidateData.professional.currentLocation.state ? candidateData.professional.currentLocation.state.name : ''},
                                    India`}</span>
                            </div> : ''}
                    </div>
                    <div className="col-12 row my-1">
                        <div className="col-xl-6 col-lg-4 col-md-6 text-truncate">Created On: <span className="text-color-1"><Moment date={candidateData.createdOn} format={"DD MMMM YYYY"}></Moment></span></div>
                        <div className="col-xl-6 col-lg-4 col-md-6 text-truncate">Last Updated On: <span className="text-color-1"><Moment date={candidateData.updatedOn} format={"DD MMMM YYYY"}></Moment></span></div>
                    </div>
                    <div className="col-12 row my-1"></div>
                    <div className="col-12 row my-1">
                        <div className="col-xl-6 col-lg-4 col-md-6 text-truncate">Contact Email:  <span className="text-color-1">{candidateData.personal.email}</span></div>
                        <div className="col-xl-6 col-lg-4 col-md-6 text-truncate">Contact Phone Number: <span className="text-color-1">{candidateData.personal.contactNumber}</span></div>
                    </div>
                    <div className="col-12 row my-1">
                        <div className="col-xl-6 col-lg-4 col-md-6 text-truncate">Date Of Birth: <span className="text-color-1">{candidateData.personal.dateOfBirth ? <Moment date={candidateData.personal.dateOfBirth} format={"DD MMMM YYYY"}></Moment> : 'NA'}</span></div>
                        <div className="col-xl-6 col-lg-4 col-md-6 text-truncate">Total Experience:  <span className="text-color-1">{
                            candidateData?.professional?.experienceInYr || candidateData?.professional?.experienceInMnth ?
                                `${candidateData.professional.experienceInYr || 0} Year ${candidateData.professional.experienceInMnth || 0} Month` : "NA"
                        } </span></div>
                    </div>
                    <div className="col-12 row my-1">
                        <div className="col-xl-6 col-lg-4 col-md-6 text-truncate">Current CTC: <span className="text-color-1"> {
                            candidateData?.professional?.currentCtcInLkh || candidateData?.professional?.currentCtcInThnd ?
                                `${candidateData.professional.currentCtcInLkh || 0} Lakhs ${candidateData.professional.currentCtcInThnd || 0} Thousand` : "NA"}</span></div>
                        <div className="col-xl-6 col-lg-4 col-md-6 text-truncate">Expected CTC: <span className="text-color-1"> {
                            candidateData?.professional?.expectedCtcInLkh || candidateData?.professional?.expectedCtcInThnd ?
                                `${candidateData.professional.expectedCtcInLkh || 0} Lakhs ${candidateData.professional.expectedCtcInThnd || 0} Thousand` : "NA"} </span></div>
                    </div>
                    <div className="col-12 row my-1">
                        <div className="col-xl-6 col-lg-4 col-md-6 text-truncate">Category:  <span className="text-color-1">{candidateData.summary.category ? candidateData.summary.category.name : "NA"} </span></div>
                        <div className="col-xl-6 col-lg-4 col-md-6 text-truncate">Role: <span className="text-color-1"> {candidateData.summary.role ? candidateData.summary.role.name : "NA"}</span></div>
                    </div>
                    <div className="col-12 row my-1"></div>
                    <div className="col-12 row my-1"></div>
                    <div className="col-12 row my-1">
                        <div className="col-xl-12">Profile Summary: {candidateData.summary.text}</div>
                        {/* <div className="col">{candidateData.summary.text} </div> */}
                    </div>
                </div>

            </div>

            <div className="content-section m-3">
                <div className="container py-4">
                    <div className="row align-items-center no-gutters">
                        <div className="card col-12 step-card">
                            <div className="card-header">
                                Personal Details
                            </div>
                            <div className="card-body">
                                <div className="row no-gutters">
                                    <div className="col-12 row no-gutters">
                                        <div className="row col-6">
                                            <div className="col-6 label">Name :</div>
                                            <div className="col-6 value">{`${candidateData.personal.firstName} ${candidateData.personal.middleName || ''} ${candidateData.personal.lastName}`}</div>
                                        </div>
                                        <div className="row col-6">
                                            <div className="col-6 label">DOB :</div>
                                            <div className="col-6 value">{candidateData.personal.dateOfBirth ? <Moment date={candidateData.personal.dateOfBirth} format={"MM-DD-YYYY"}></Moment> : "NA"}</div>
                                        </div>
                                        <div className="row col-6">
                                            <div className="col-6 label">Email :</div>
                                            <div className="col-6 value">{candidateData.personal.email || 'NA'}</div>
                                        </div>
                                        <div className="row col-6">
                                            <div className="col-6 label">Alternate Email :</div>
                                            <div className="col-6 value">{candidateData.personal.alternateEmail || 'NA'} </div>
                                        </div>
                                        <div className="row col-6">
                                            <div className="col-6 label">Contact Number :</div>
                                            <div className="col-6 value">{candidateData.personal.contactNumber || 'NA'} </div>
                                        </div>
                                        <div className="row col-6">
                                            <div className="col-6 label">Alternate Contact Number :</div>
                                            <div className="col-6 value">{candidateData.personal.alternateNumber || 'NA'} </div>
                                        </div>
                                        <div className="row col-6">
                                            <div className="col-6 label">Gender :</div>
                                            <div className="col-6 value">{candidateData.personal.gender || 'NA'} </div>
                                        </div>
                                        <div className="row col-6">
                                            <div className="col-6 label">Marital Status :</div>
                                            <div className="col-6 value">{candidateData.personal.maritalStatus || 'NA'} </div>
                                        </div>
                                        {candidateData.personal.maritalStatus === 'MARRIED' ?
                                            <div className="row col-6">
                                                <div className="col-6 label">Is Spouse Doctor :</div>
                                                <div className="col-6 value">{candidateData.personal.isSpouceDoctrate ? 'Yes' : 'No'} </div>
                                            </div>
                                            : ''}

                                        {candidateData.personal.maritalStatus === 'MARRIED' && candidateData.personal.isSpouceDoctrate ?
                                            <div className="row col-6">
                                                <div className="col-6 label">Spouse Specialization :</div>
                                                <div className="col-6 value">{candidateData.personal.spouceDetail} </div>
                                            </div>
                                            : ''}
                                        <div className="row col-6">
                                            <div className="col-6 label">Spoken Language :</div>
                                            <div className="col-6 value">{candidateData.personal.spokenLanguages || 'NA'} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {candidateData.professional ?
                            <div className="card col-12 step-card">
                                <div className="card-header">
                                    Professional Details
                                </div>
                                <div className="card-body">
                                    <div className="row no-gutters">
                                        <div className="col-12 row no-gutters">
                                            <div className="row col-6">
                                                <div className="col-6 label">Total Experience :</div>
                                                <div className="col-6 value">{
                                                    candidateData?.professional?.experienceInYr || candidateData?.professional?.experienceInMnth ?
                                                        `${candidateData.professional.experienceInYr || 0} Year ${candidateData.professional.experienceInMnth || 0} Month` : "NA"
                                                } </div>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label">Current CTC :</div>
                                                <div className="col-6 value">{
                                                    candidateData?.professional?.currentCtcInLkh || candidateData?.professional?.currentCtcInThnd ?
                                                        `${candidateData.professional.currentCtcInLkh || 0} Lakhs ${candidateData.professional.currentCtcInThnd || 0} Thousand` : "NA"}  </div>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label">Expected CTC :</div>
                                                <div className="col-6 value">{
                                                    candidateData?.professional?.expectedCtcInLkh || candidateData?.professional?.expectedCtcInThnd ?
                                                        `${candidateData.professional.expectedCtcInLkh || 0} Lakhs ${candidateData.professional.expectedCtcInThnd || 0} Thousand` : "NA"}  </div>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label">Preferred Locations :</div>
                                                <div className="col-6 value">{candidateData.professional && candidateData.professional.preferredLocations.length > 0 ?
                                                    candidateData.professional.preferredLocations.map((item, index) => {
                                                        return <span className="badge badge-primary mx-1" key={index}>{item.name}</span>
                                                    }) : "NA"
                                                } </div>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label">Category :</div>
                                                <div className="col-6 value">{candidateData.professional.category ? candidateData.professional.category.name : "NA"} </div>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label">Role :</div>
                                                <div className="col-6 value">{candidateData.professional.role ? candidateData.professional.role.name : "NA"} </div>
                                            </div>
                                            <div className="col-12 row">
                                                <b className="col-12 p-2">Current Address</b>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label"> State:</div>
                                                <div className="col-6 value">{candidateData.professional.currentLocation && candidateData.professional.currentLocation.state ? candidateData.professional.currentLocation.state.name : ''} </div>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label"> City:</div>
                                                <div className="col-6 value">{candidateData.professional.currentLocation && candidateData.professional.currentLocation.city ? candidateData.professional.currentLocation.city.name : ''} </div>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label"> City:</div>
                                                <div className="col-6 value">{candidateData.professional.currentLocation && candidateData.professional.currentLocation.town ? candidateData.professional.currentLocation.town : ''} </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''}

                        {candidateData.qualifications && candidateData.qualifications.length ?
                            <div className="card col-12 step-card">
                                <div className="card-header">
                                    Educational Details
                                </div>
                                <div className="card-body">
                                    <div className="row no-gutters">
                                        <div className="col-7 row no-gutters">
                                            {candidateData.qualifications.map((item, index) => {
                                                return (
                                                    <div className="row col-12 ml-3 py-4 border-grey" key={index}>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">
                                                                <span className="count">{index + 1}</span>
                                                                <span className="float-left">Organization Name :</span>
                                                            </div>
                                                            <div className="col-7 value">{item.organizationName || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">
                                                                <span className="float-left">Qualification :</span>
                                                            </div>
                                                            <div className="col-7 value">{item.qualification.name || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Course :</div>
                                                            <div className="col-7 value">{item.course.name || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Specialization :</div>
                                                            <div className="col-7 value">{item.specialization.name || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Joining Year :</div>
                                                            <div className="col-7 value">{item.joinedOn || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Passing Year :</div>
                                                            <div className="col-7 value">{item.passoutYear || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Currently Pursuing :</div>
                                                            <div className="col-7 value">{item.isCurrentlyPursuing ? 'YES' : 'NO'} </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="col-5 row no-gutters align-items-center">
                                            <div className="margin-auto">
                                                <img src={GraduateImg} height="300"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''}

                        {candidateData.careers && candidateData.careers.length ?
                            <div className="card col-12 step-card">
                                <div className="card-header">
                                    Carrer Details
                                </div>
                                <div className="card-body">
                                    <div className="row no-gutters">
                                        <div className="col-7 row no-gutters">
                                            {candidateData.careers.map((item, index) => {
                                                return (
                                                    <div className="row col-12 ml-3 py-4 border-grey" key={index}>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">
                                                                <span className="count">{index + 1}</span>
                                                                <span className="float-left">Employer :</span>
                                                            </div>
                                                            <div className="col-7 value">{item.employer || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Designation :</div>
                                                            <div className="col-7 value">{item.designation || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Category :</div>
                                                            <div className="col-7 value">{item.category.name || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Role :</div>
                                                            <div className="col-7 value">{item.role.name || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Joined Year :</div>
                                                            <div className="col-7 value">{item.joinedOn || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Last Working Year :</div>
                                                            <div className="col-7 value">{item.seperatedOn || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Currently Working :</div>
                                                            <div className="col-7 value">{item.isCurrentlyWorking ? 'YES' : 'NO'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">CTC :</div>
                                                            <div className="col-7 value">{item.annualCtcInLkh + ' Lakh ' + item.annualCtcInThnd + ' Thousand' || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Job Location:</div>
                                                            <div className="col-7 value">{item.jobLocation ? `${item.jobLocation.town ? item.jobLocation.town + "," : ''} ${item.jobLocation.city.name}, ${item.jobLocation.state.name}` : 'NA'} </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="col-5 row no-gutters align-items-center">
                                            <div className="margin-auto">
                                                <img src={CarrerImg} height="300"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''}

                        {candidateData.addresses && candidateData.addresses.length ?
                            <div className="card col-12 step-card">
                                <div className="card-header">
                                    Address
                                </div>
                                <div className="card-body">
                                    <div className="row no-gutters">
                                        <div className="col-7 row no-gutters">
                                            {candidateData.addresses.map((item, index) => {
                                                return (
                                                    <div className="row col-12 ml-3 py-4 border-grey" key={index}>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">
                                                                <span className="count">{index + 1}</span>
                                                                <span className="float-left">Address Type :</span>
                                                            </div>
                                                            <div className="col-7 value">{item.addressType || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Address Line 1 :</div>
                                                            <div className="col-7 value">{item.lineOne || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Address Line 2 :</div>
                                                            <div className="col-7 value">{item.lineTwo || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Town :</div>
                                                            <div className="col-7 value">{item.town || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">City :</div>
                                                            <div className="col-7 value">{item.city.name || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">State :</div>
                                                            <div className="col-7 value">{item.state.name || 'NA'} </div>
                                                        </div>
                                                        <div className="row col-12">
                                                            <div className="col-5 label">Current Address :</div>
                                                            <div className="col-7 value">{item.isCurrentAddress ? 'YES' : 'NO'} </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="col-5 row no-gutters align-items-center">
                                            <div className="margin-auto">
                                                <img src={AddressImg} height="300"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''}
                        <div className="row no-gutters col-12">
                            <div className="col-9 row no-gutters">
                                <div className="col text-wrap row no-gutters resume p-0">
                                    {candidateData.professional && candidateData.professional.resume ?
                                        <div className="col-12 row no-gutters p-2 align-items-center">
                                            <p className="col-auto extension p-3">{getfileExtension(candidateData.professional.resume.originalName)}</p>&nbsp;
                                            <div className="col-8 row no-gutters p-3 file-name m-0">
                                                <p className="col-12 text-truncate m-0 ">{getfileName(candidateData.professional.resume.originalName)}</p>
                                                <p className="col-12 text-truncate m-0">{candidateData.professional.resume.size}</p>
                                            </div>&nbsp;
                                            <a type="button" className="btn file-view-btn p-3 col-auto" title='View Resume' onClick={openFileReader}>
                                                <VisibilityIcon></VisibilityIcon>
                                            </a>&nbsp;
                                            <a type="button" className="btn file-view-btn p-3 col-auto" title='Download Resume' onClick={() => originalCv(candidateData.professional.resume.href)}>
                                                <GetAppIcon></GetAppIcon>
                                            </a>
                                        </div>
                                        : ""
                                    }
                                </div>
                            </div>
                            <div className="col-3 row align-items-center justify-content-center">
                                <button type="button" className="btn btn-primary custom_cv_btn" onClick={() => downloadCv(candidateData)}>
                                    <p className="m-0"><GetAppIcon></GetAppIcon>&nbsp;&nbsp;&nbsp; Custom Resume</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <ConfirmModal data={confirmationObj} onConfirmAction={doAction} onCancel={closePopup}></ConfirmModal> */}
            <ErrorModal data={errModel} onConfirmAction={() => setErrorModal({ show: false, error: {} })} ></ErrorModal>
            <FileViewerModal data={fileViewObj} onCancel={() => setFileViewObj({ show: false, file: {} })}></FileViewerModal>
        </div>
    )
}

export default CandidateView;