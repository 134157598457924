import React,{useState,useEffect} from 'react';
import Countcard from '../../component/Countcard';
import PersonIcon from '@material-ui/icons/Person';
import BarGraph from '../../component/BarChats';
import DashboardService from '../../service/DashboardService';
import ErrorPopup from '../../component/modal/ErrorModal';

const configData = [{'label':'jobsPosted','color':'#A4A1FB'},{'label':'newsPosted','color':'#56D9FE'},{'label':'candidatesAdded','color':'#5FE3A1'}]

const  StaffOverview = (props) => {
    const [loading,setLoading] = useState(false);
    const [staffData,setStaffData] = useState('');
    const [errModel, setErrorModal] =useState({show:false,err:{}})

    useEffect(() => {
        if(props.visible){
            getOverViewData();
        }
        else{
            setStaffData('');
        }
     }, [props.visible]);

    const getOverViewData = async () => {
        try{
            setLoading(true);
            const response = await DashboardService.getAdminOverviewData('staff')
            if(response.status === 200){
                setStaffData(response.data.result);
                setLoading(false);
            }
        }
        catch(err){
            setLoading(false);
            if(err && err.data){
                setErrorModal({show:true,error : err.data})
            }
            
        }
    }

    return (
        <div id="staff-overview">
            <h3>Staff Overview</h3>
            <div className="container-fluid p-0 mt-4">
                <div className="row no-gutters">
                    <div className="col-3">
                        <Countcard count={staffData ? staffData.counts.total:0} msg={'Total'} name={'total-count'} icon={PersonIcon} />
                    </div>
                    <div className="col-3">
                        <Countcard count={staffData ? staffData.counts.active:0} msg={'Active'} name={'active-count'} icon={PersonIcon} />
                    </div>
                    <div className="col-3">
                        <Countcard count={staffData ? staffData.counts.inactive:0} msg={'Inactive'} name={'inactive-count'} icon={PersonIcon} />
                    </div>
                    <div className="col-3">
                        <Countcard count={staffData ? staffData.counts.new:0} msg={'New'} name={'new-count'} icon={PersonIcon} />
                    </div>
                </div>
            </div>
            <div className="col-12 subvsexpGraph">
                <div className="col-12 graph-header-text header-font">
                    <p className="col-auto">Staff Activity</p>
                </div>
                {props.visible ?  <BarGraph data={staffData.staffActivity} config={configData} /> :''}
            </div>    
            <ErrorPopup data={errModel} onConfirmAction={() => setErrorModal({show:false,error:{}})}></ErrorPopup>
        </div>
    );
}

export default StaffOverview
