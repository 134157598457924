import React, { useState, useEffect, forwardRef, useImperativeHandle, useMemo } from 'react';
import CandidateService from '../../../../service/CandidateService';
import { useParams, Link, useHistory } from 'react-router-dom';
import Autocomplete from 'react-select';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import UseForm from '../../../../service/FormService';
import $ from 'jquery';
import ErrorModal from "../../../../component/modal/ErrorModal"
import Loader from '../../../../component/Loader';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileViewerModal from '../../../../component/modal/FileViewerPopup';


const initialSchema = {
    "experienceInYr": { value: "", error: "" },
    "experienceInMnth": { value: "", error: "" },
    "currentCtcInLkh": { value: "", error: "" },
    "currentCtcInThnd": { value: "", error: "" },
    "expectedCtcInLkh": { value: "", error: "" },
    "expectedCtcInThnd": { value: "", error: "" },
    "resume": { value: "", error: "" },
    "category": { value: "", error: "" },
    "role": { value: "", error: "" },
}

const validationStateSchema = {
    "experienceInYr": { required: true },
    "experienceInMnth": { required: false },
    "currentCtcInLkh": { required: false },
    "currentCtcInThnd": { required: false },
    "expectedCtcInLkh": { required: false },
    "expectedCtcInThnd": { required: false },
    "resume": { required: false },
    "category": { required: true },
    "role": { required: true },

}

const ProfessionalStep = forwardRef((props, ref) => {
    const [stateSchema, setStateSchema] = useState(initialSchema);
    const [isLoading, setIsLoading] = useState(false);
    const [errorObj, setErrorObj] = useState({ show: false, error: {} });
    const [fileViewObj, setFileViewObj] = useState({ show: false, file: {} });
    const [categoryList, setCategoryList] = useState([]);
    const [roleList, setRoleList] = useState([]);


    useEffect(() => {
        getCategoryList();
        if (props.wizard && props.wizard.professional) {
            upDateInitialData();
        }
    }, [])

    const getCategoryList = async () => {
        try {
            const response = await CandidateService.getCategoryList();
            setCategoryList(response.data.result);
        } catch (err) {
            setLoading(false);
        }
    }


    const categoryChanges = (data) => {
        handleOnChange({ target: { name: 'category', value: data ? { id: data.id, name: data.name } : '' } });
        handleOnChange({ target: { name: 'role', value: '' } });

        if (data && data.id)
            getRoleList(data.id)
    }

    const getRoleList = async (categoryId, param) => {
        try {
            const response = await CandidateService.getRoleList(categoryId, param);
            setRoleList(response.data.result);
        } catch (err) {
            console.log(err);
        }
    }
    const roleChanges = (data) => {
        handleOnChange({ target: { name: 'role', value: data ? { id: data.id, name: data.name } : '' } });
    }



    const customFilter = (option, inputValue) => {
        if(option.data.name && inputValue){
            return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        }
        return true;
    }

    const upDateInitialData = () => {
        const data = props.wizard.professional;
        let schema =  {...stateSchema};
        Object.keys(schema).forEach(key => {
            if (data.hasOwnProperty(key)) {
                if (key === 'category' || key === 'role') {
                    schema[key].value = { id: data[key].id, name: data[key].name };
                }
                else {
                    schema[key].value = data[key];
                }

            }
        })
        setStateSchema(schema);

    };

    useImperativeHandle(ref, () => ({
        submit() {
            const requestPayload = { };
            for (let key in state) {
                requestPayload[key] = state[key].value;
            }
            props.updateData(requestPayload, props.stepConfig);

        }

    }));

    const onSubmitForm = (state) => {

        setEditMode(false);
        props.canProceed(true);
        setChanged(true);
    }

    const onFileChange = (e) => {
        setIsLoading(true);
        e.persist();
        e.preventDefault();
        let formData = new FormData();
        formData.append("files", e.target.files[0])
        formData.append("mediaType", "document");
        CandidateService.upload(formData).then((res) => {
            setIsLoading(false);
            handleOnChange({ target: { name: 'resume', value: res.data.result[0] } })
        }, (error) => {
            setIsLoading(false);
            setErrorObj({ 'show': true, 'error': error.data })
        })

    }
    const openFileReader = () => {
        setFileViewObj({ show: true, file: state.resume.value });
    }

    const { state, handleOnChange, handleOnSubmit, disable, setValueWithoutError } = UseForm(stateSchema, validationStateSchema, onSubmitForm);

    const onChangeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        handleOnChange({ target: { name: name, value: value } });
    }

    useEffect(() => {
        validateInput();
    }, [state]);

    const validateInput = () => {
        let isValid = true;
        for (let key in state) {
            if ((validationStateSchema[key].required && !state[key].value) || state[key].error) {
                isValid = false;
            }
        }
        if (isValid) {
            props.canProceed(true);
        } else {
            props.canProceed(false);
        }
    }

    if (isLoading) return <Loader />;

    return (
        <div className="candidate-signup-professional-step">
            <div className="form-wrapper h-100">
                <div className="row no-gutters h-100 w-100">
                    <div className="container-fluid h-100 w-100 p-0">
                        <div className="row no-gutter h-100 w-100">
                            <div className="col">
                                <div className=" candidate-personal-card mt-4">
                                    <div className="cs-header row no-gutters">
                                        <p className="cs-title col "><b>Professional Details </b></p>
                                    </div>
                                    <div className="cs-body">
                                        <div className="row no-gutter">
                                            <div className="form-group col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                                <label className="label-sm">Category <sup className="required">*</sup></label>
                                                <Autocomplete
                                                    name="category"
                                                    placeholder="Select Category"
                                                    defaultOptions
                                                    isClearable={true}
                                                    filterOption={customFilter}
                                                    options={categoryList}
                                                    value={state.category.value ? state.category.value : null}
                                                    getOptionLabel={(option) => {
                                                        return (
                                                            <div className="row autocomplete-item no-gutters">
                                                                <div className="text col p-1 text-truncate">
                                                                    <div>{option.name}</div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                    getOptionValue={(option) => {
                                                        return option.id;
                                                    }}
                                                    onChange={categoryChanges}
                                                />
                                                <small className="form-text  error-text m-0" style={{ visibility: state.category.error ? 'visible' : 'hidden' }}>{state.category.error}</small>
                                            </div>


                                            <div className="form-group col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                                <label className="label-sm">Role <sup className="required">*</sup></label>
                                                <Autocomplete
                                                    name="role"
                                                    placeholder="Select Role"
                                                    defaultOptions
                                                    isClearable={true}
                                                    isDisabled={state.category.value && state.category.value.id ? false : true}
                                                    filterOption={customFilter}
                                                    options={roleList}
                                                    value={state.role.value ? state.role.value : null}
                                                    getOptionLabel={(option) => {
                                                        return (
                                                            <div className="row autocomplete-item no-gutters">
                                                                <div className="text col p-1 text-truncate">
                                                                    <div>{option.name}</div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                    getOptionValue={(option) => {
                                                        return option.id;
                                                    }}
                                                    onChange={roleChanges}
                                                />
                                                <small className="form-text  error-text m-0" style={{ visibility: state.role.error ? 'visible' : 'hidden' }}>{state.role.error}</small>
                                            </div>

                                            <div className="form-group col-12 m-0">
                                                <label className="label-sm">Total Experience(in years) <sup className="required">*</sup></label>
                                                <div className='row no-gutters'>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                                        <input type="number" className="form-control" id="experienceInYr" placeholder="Provide in year(s)" name="experienceInYr" value={state.experienceInYr.value} onChange={handleOnChange} min="0" max="75"/>
                                                        <small className="form-text  error-text m-0" style={{ visibility: state.experienceInYr.error ? 'visible' : 'hidden' }}>{state.experienceInYr.error}</small>
                                                    </div>
                                                    <div className='col-1'></div>
                                                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 m-0">
                                                        <input type="number" className="form-control" id="experienceInMnth" placeholder="Provide in month(s)" name="experienceInMnth" value={state.experienceInMnth.value} onChange={handleOnChange} min="0" max="12"/>
                                                        <small className="form-text  error-text m-0" style={{ visibility: state.experienceInMnth.error ? 'visible' : 'hidden' }}>{state.experienceInMnth.error}</small>
                                                    </div>
                                                </div>
                                                {/* <input type="number" className="form-control" id="experienceInYr" placeholder="Experiance In Year" name="experienceInYr" value={state.experienceInYr.value} onChange={handleOnChange} />
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.experienceInYr.error ? 'visible' : 'hidden' }}>{state.experienceInYr.error}</small> */}
                                            </div>
                                            <div className="form-group col-12 m-0">
                                                <label className="label-sm">Current CTC(in lakhs)</label>
                                                <div className='row no-gutters'>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                                        <input type="number" className="form-control" id="currentCtcInLkh" placeholder="Provide in Lakh(s)" name="currentCtcInLkh" value={state.currentCtcInLkh.value} onChange={handleOnChange} min="0" max="999" />
                                                        <small className="form-text  error-text m-0" style={{ visibility: state.currentCtcInLkh.error ? 'visible' : 'hidden' }}>{state.currentCtcInLkh.error}</small>
                                                    </div>
                                                    <div className="col-1"></div>
                                                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 m-0">
                                                        <input type="number" className="form-control" id="currentCtcInThnd" placeholder="Provide in Thousand(s)" name="currentCtcInThnd" value={state.currentCtcInThnd.value} onChange={handleOnChange} min="0" max="99"/>
                                                        <small className="form-text  error-text m-0" style={{ visibility: state.currentCtcInThnd.error ? 'visible' : 'hidden' }}>{state.currentCtcInThnd.error}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-12 m-0">
                                                <label className="label-sm">Expected CTC(in lakhs)</label>
                                                <div className='row no-gutters'>
                                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 m-0">
                                                        <input type="number" className="form-control" id="expectedCtcInLkh" placeholder="Provide in Lakh(s)" name="expectedCtcInLkh" value={state.expectedCtcInLkh.value} onChange={handleOnChange} min="0" max="999" />
                                                        <small className="form-text  error-text m-0" style={{ visibility: state.expectedCtcInLkh.error ? 'visible' : 'hidden' }}>{state.expectedCtcInLkh.error}</small>
                                                    </div>
                                                    <div className='col-1'></div>
                                                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 m-0">
                                                        <input type="number" className="form-control" id="expectedCtcInThnd" placeholder="Provide in Thousand(s)" name="expectedCtcInThnd" value={state.expectedCtcInThnd.value} onChange={handleOnChange} min="0" max="99" />
                                                        <small className="form-text  error-text m-0" style={{ visibility: state.expectedCtcInThnd.error ? 'visible' : 'hidden' }}>{state.expectedCtcInThnd.error}</small>
                                                    </div>
                                                </div>
                                            </div>




                                            <div className="col-12 my-2"><b>Resume :</b></div>
                                            <div className="form-group m-0 col-lg-6 col-xl-6 col-md-12 col-sm-12 col-xs-12">
                                                <label className="label-sm w-100">Upload Resume </label>
                                                <div className="row no-gutters align-items-center">
                                                    <div className="fileUpload btn btn-secondary cs-upload-1 m-0 col-auto">
                                                        <span>Browse</span>
                                                        <input type="file" className="upload" name="resume" id="resume" onChange={onFileChange} accept=".doc,.docx,.rtf,.pdf" />
                                                    </div>
                                                    <div className="col file-name">
                                                        <div> {state.resume.value ? state.resume.value.originalName : ''} </div>
                                                    </div>
                                                </div>

                                                <small id="logoHelp" className="form-text text-muted">Max size of file is 5MB.</small>
                                                <small className="form-text  error-text m-0" style={{ visibility: state.resume.error ? 'visible' : 'hidden' }}>{state.resume.error}</small>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ErrorModal data={errorObj} onConfirmAction={() => setErrorObj({ show: false, error: {} })}></ErrorModal>
            <FileViewerModal data={fileViewObj} onCancel={() => setFileViewObj({ show: false, file: {} })}></FileViewerModal>
        </div >
    )
})

export default ProfessionalStep;