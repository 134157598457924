import React, { useState, useEffect } from "react";
import UseForm from "../../service/FormService";
import ManageService from "../../service/ManageService";
import $ from "jquery";
import { ErrorToaster } from "../../component/modal/ErrorModal";
import Autocomplete from "react-select";

const stateSchema = {
  categoryId: { value: "", error: "" },
  name: { value: "", error: "" },
};

const validationStateSchema = {
  categoryId: {
    required: true,
  },
  name: {
    required: true,
  },
};

const AddRoles = (props) => {
  const [visible, setVisible] = useState(props.data.show || false);
  const [intialSchema, setInitionalSchema] = useState(
    $.extend(true, {}, stateSchema)
  );
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [categoryData, setCategoryData] = useState({});
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });

  useEffect(() => {
    setVisible(props.data.show);
    if (!props.data.show) {
      setInitionalSchema($.extend(true, {}, stateSchema));
      setErrorObj({ show: false, msg: "" });
      setCategoryList([]);
      setSelectedCategory("");
      setCategoryData({});
    } else {
      setCategoryLoading(true);
      getCategoryList();
      setCategoryLoading(false);
      if (props.data.role && props.data.role.id) {
        setSelectedCategory({
          id: props.data.role.category.id,
          name: props.data.role.category.name,
        });
        let schema = $.extend(true, {}, stateSchema);
        schema.name.value = props.data.role.name;
        schema.categoryId.value = props.data.role.category.id;
        setInitionalSchema(schema);
      }
    }
  }, [props]);

  const getCategoryList = async (param, url) => {
    const response = await ManageService.getCategoryList(param, url);
    if (response.status === 200) {
      if (url) {
        let list = [];
        list = list.concat(categoryList, response.data.result.data);
        response.data.result.data = list;
      }
      setCategoryList(response.data.result.data);
      setCategoryData(response.data);
    }
  };

  const customFilter = (option, inputValue) => {
        if(option.data.name && inputValue){
            return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        }
        return true;
    };

  const categoryChange = (category) => {
    category && category.id
      ? setSelectedCategory(category)
      : setSelectedCategory({});
    handleOnChange({
      target: {
        name: "categoryId",
        value: category && category.id ? category.id : "",
      },
    });
  };

  const paginateCategoryList = (e) => {
    if (categoryData.detail.links && categoryData.detail.links.next) {
      setCategoryLoading(true);
      getCategoryList("", categoryData.detail.links.next);
      setCategoryLoading(false);
    }
  };

  const onCategoryInputChange = (param, actionType, list) => {
    if (actionType.action && actionType.action === "input-change") {
      param ? getCategoryList(param) : getCategoryList();
    } else if (actionType.action === "menu-close" && !selectedCategory.id)
      getCategoryList();
  };

  const hideModal = () => {
    setVisible(false);
    props.onCancel();
  };

  const closeToast = (e) => {
    e.preventDefault();
    setErrorObj({ show: false, msg: "" });
  };

  const onSubmitForm = (state) => {
    const requestPayload = {
      name: state.name.value,
      categoryId: state.categoryId.value,
    };
    console.log("requestPayload", requestPayload);
    if (props.data.role && props.data.role.id) {
      ManageService.updateRole(props.data.role.id, requestPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            props.onConfirmAction(res.data.result);
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    } else {
      ManageService.createRole(requestPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            props.onConfirmAction(res.data.result);
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    }
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    intialSchema,
    validationStateSchema,
    onSubmitForm
  );

  return (
    <div
      className="overlay "
      id="role-model"
      style={{ display: visible ? "block" : "none" }}
    >
      <div className="overlay-content">
        <div className={visible ? " cs-modal showModal" : "cs-modal hideModal"}>
          <div className="header">
            {props.data.role && props.data.role.id ? "Update Role" : "Add Role"}{" "}
          </div>
          <div className="body">
            <div className="role-container">
              <form>
                <div className="row">
                  <div className="form-group col-12 m-0">
                    <label className="label-sm">
                      Category<sup className="required">*</sup>
                    </label>
                    <Autocomplete
                      name="category"
                      placeholder="Select Category"
                      defaultOptions
                      isClearable={true}
                      isDisabled={
                        props.data.role && props.data.role.id ? true : false
                      }
                      options={categoryList}
                      value={selectedCategory.id ? selectedCategory : null}
                      filterOption={customFilter}
                      isLoading={categoryLoading}
                      getOptionLabel={(option) => {
                        return (
                          <div className="autocomplete-list">{option.name}</div>
                        );
                      }}
                      getOptionValue={(option) => {
                        return (
                          <div className="autocomplete-list">{option.id}</div>
                        );
                      }}
                      onChange={categoryChange}
                      onMenuScrollToBottom={paginateCategoryList}
                      onInputChange={onCategoryInputChange}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.categoryId.error
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      {state.categoryId.error}
                    </small>
                  </div>
                  <div className="form-group col-12 m-0">
                    <label className="label-sm">
                      Role Name <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      onChange={handleOnChange}
                      value={state.name.value}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.name.error ? "visible" : "hidden",
                      }}
                    >
                      {state.name.error}
                    </small>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="footer">
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary mr-3"
              onClick={hideModal}
            >
              cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary"
              disabled={disable}
              onClick={handleOnSubmit}
            >
              Procced
            </button>
          </div>
        </div>
      </div>
      <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
    </div>
  );
};

export default AddRoles;
