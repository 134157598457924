import React, { useState, useEffect } from "react";
import UseForm from "../../service/FormService";
import SearchCandidateService from "../../service/SearchCandidateService";
import ClientService from "../../service/ClientService";
import CandidateService from "../../service/CandidateService";
import CategoryRoleService from "../../service/JobService";

import Autocomplete from "react-select";

import { SuccessToaster } from "../../component/modal/SuccessModal";
import { ErrorToaster } from "../../component/modal/ErrorModal";

const stateSchema = {
  jobPost: { value: "", error: "" }
};

const validationStateSchema = {
  jobPost: {
    required: true,
  }
};

const SubmitProfile = (props) => {
  const [visible, setVisible] = useState(props.data.show || false);
  const [successObj, setSuccessObj] = useState({ show: false, msg: "" });
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });
  const [jobsList, setJobsList] = useState([]);

  const [clientsList, setClientsList] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);

  const [locationList, setlocationList] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);

  const [roleList, setRoleList] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    getClients();
    getLocations();
    getRoles();
    // getJobPosts();
    setVisible(props.data.show);
    if (!props.data.show) {
      setErrorObj({ show: false, msg: "" });
    }
  }, [props]);

  const getJobPosts = async () => {
    try {
      const response = await SearchCandidateService.getJobs();
      setJobsList(response.data.result.data);
    } catch (e) {
      //console.log("error",e);
    }
  };

  const filterJobPosts = async (clientIds = null, locations = null, roles = null) => {
    const client_id_str = selectedClients.map(function (val) {
      return val.id;
    }).join(',');
    const location_id_str = selectedLocations.map(function (val) {
      return val.id;
    }).join(',');
    const role_id_str = selectedRoles.map(function (val) {
      return val.id;
    }).join(',');

    try {
      const data = {
        'client_id_str': client_id_str,
        'location_id_str': location_id_str,
        'role_id_str': role_id_str
      }
      const response = await SearchCandidateService.filterJobs(data);
      setJobsList(response.data.result.data);
    } catch (e) {
      setVisible(props.data.show);
      setErrorObj({ show: true, msg: e.data.detail.hint });
    }
  };

  const getClients = async (param = null) => {
    try {
      const response = await ClientService.getClientListLimitedData(param);
      setClientsList(response.data.result.data);
    } catch (e) {
      //console.log("error",e);
    }
  };

  const getLocations = async (param = null) => {
    try {
      const response = await CandidateService.getAllLocationListByPagination(param);
      setlocationList(response.data.result.data);
    } catch (e) {
      // console.log("error", e);
    }
  };

  const getRoles = async (param = null) => {
    try {
      const response = await CategoryRoleService.getAllCategoryRoles(param);
      setRoleList(response.data.result.data);
    } catch (e) {
      // console.log("error", e);
    }
  };

  const hideModal = () => {
    setVisible(false);
    // props.onCancel();
  };

  const closeToast = (e) => {
    e.preventDefault();
    setErrorObj({ show: false, msg: "" });
  };

  const closeSuccessToast = (e) => {
    e.preventDefault();
    setSuccessObj({ show: false, msg: "" });
  };

  const onSubmitForm = (state) => {
    const requestPayload = {
      postingId: state.jobPost.value,
      candidateId: props.data.candidateId,
    };
    SearchCandidateService.submitProfile(requestPayload).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          setSuccessObj({ show: true, msg: "Profile Submitted Successfully" });
          hideModal();
          setTimeout(() => {
            setSuccessObj({ show: false, msg: "" });
          }, 3000);
        }
      },
      (err) => {
        setErrorObj({ show: true, msg: err.data.message });
        setTimeout(() => {
          setErrorObj({ show: false, msg: "" });
        }, 3000);
      }
    );
  };

  const customFilter = (option, inputValue) => {
    if (option.data.name && inputValue) {
      return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    }
    return true;
  };

  const OnClientChanges = (clients) => {
    getClients();
    if (clients && clients.length > 0) {
      setSelectedClients(clients);
      // const locationnames_str = clients.map(function (val) {
      //   return val.name;
      // }).join(',');
      // getClients(locationnames_str);
    }
    else {
      setSelectedClients([]);
    }
  }

  const OnRoleChanges = (roles) => {
    getRoles();
    if (roles && roles.length > 0) {
      setSelectedRoles(roles);
      // const rolenames_str = roles.map(function (val) {
      //   return val.name;
      // }).join(',');
      // getRoles(rolenames_str);
    }
    else {
      setSelectedRoles([]);
    }
  }

  const OnLocationChanges = (locations) => {
    getLocations();
    if (locations && locations.length > 0) {
      setSelectedLocations(locations);
      // const citynames_str = locations.map(function (val) {
      //   return val.name;
      // }).join(',');
      // getLocations(citynames_str);
    }
    else {
      setSelectedLocations([]);
    }
  }

  const onClientInputChange = (param, actionType, list) => {
    if (actionType.action && actionType.action === 'input-change') {
      param ? getClients(param) : getClients();
    }
    else if (actionType.action === 'menu-close' && !selectedLocations.length > 0) {
      getClients();
    }
  }

  const onRoleInputChange = (param, actionType, list) => {
    if (actionType.action && actionType.action === 'input-change') {
      param ? getRoles(param) : getRoles();
    }
    else if (actionType.action === 'menu-close' && !selectedLocations.length > 0) {
      getRoles();
    }
  }

  const onCityInputChange = (param, actionType, list) => {
    if (actionType.action && actionType.action === 'input-change') {
      param ? getLocations(param) : getLocations();
    }
    else if (actionType.action === 'menu-close' && !selectedLocations.length > 0) {
      getLocations();
    }
  }

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    stateSchema,
    validationStateSchema,
    onSubmitForm
  );

  return (
    <div>
      <div
        className="overlay "
        id="category-model"
        style={{ display: visible ? "block" : "none" }}
      >
        <div className="overlay-content">
          <div
            className={visible ? " cs-modal showModal" : "cs-modal hideModal"}
          >
            <div className="body">
              <div className="category-container">
                <form>
                  <div className="row">
                    <div className="form-group col-12 m-2">
                      <label className="label-sm">
                        Select Clients
                      </label>
                      <Autocomplete
                        name="client-selects"
                        placeholder="Search Client"
                        defaultOptions
                        isMulti={true}
                        isClearable={true}
                        options={clientsList}
                        value={selectedClients}
                        filterOption={customFilter}
                        isLoading={locationLoading}
                        getOptionLabel={(option) => {
                          return (
                            <div className="row autocomplete-item no-gutters">
                              <div className="text col p-1 text-truncate">
                                <div>{option.name}</div>
                              </div>
                            </div>
                          );
                        }}
                        getOptionValue={(option) => {
                          return option.id;
                        }}
                        onChange={OnClientChanges}
                        onInputChange={onClientInputChange}
                      />
                    </div>
                    <div className="form-group col-12 m-2">
                      <label className="label-sm">
                        Select Locations
                      </label>
                      <Autocomplete
                        name="location-selects"
                        placeholder="Search Location"
                        defaultOptions
                        isMulti={true}
                        isClearable={true}
                        options={locationList}
                        value={selectedLocations}
                        filterOption={customFilter}
                        isLoading={locationLoading}
                        getOptionLabel={(option) => {
                          return (
                            <div className="row autocomplete-item no-gutters">
                              <div className="text col p-1 text-truncate">
                                <div>{option.name}</div>
                              </div>
                            </div>
                          );
                        }}
                        getOptionValue={(option) => {
                          return option.id;
                        }}
                        onChange={OnLocationChanges}
                        onInputChange={onCityInputChange}
                      />
                    </div>
                    <div className="form-group col-12 m-2">
                      <label className="label-sm">
                        Select Roles
                      </label>
                      <Autocomplete
                        name="role-selects"
                        placeholder="Search Role"
                        defaultOptions
                        isMulti={true}
                        isClearable={true}
                        options={roleList}
                        value={selectedRoles}
                        filterOption={customFilter}
                        isLoading={locationLoading}
                        getOptionLabel={(option) => {
                          return (
                            <div className="row autocomplete-item no-gutters">
                              <div className="text col p-1 text-truncate">
                                <div>{option.name}</div>
                              </div>
                            </div>
                          );
                        }}
                        getOptionValue={(option) => {
                          return option.id;
                        }}
                        onChange={OnRoleChanges}
                        onInputChange={onRoleInputChange}
                      />
                    </div>
                    <div className="form-group col-12 m-2">
                      <label className="label-sm">
                        Posted Active Jobs<sup className="required">*</sup>
                      </label>
                      <select
                        className="form-control"
                        name="jobPost"
                        id="jobPost"
                        onChange={handleOnChange}
                        onClick={filterJobPosts}
                        value={state.jobPost.value}
                      >
                        <option value="">Select Job</option>
                        {jobsList
                          ? jobsList.map((job, index) => {
                            return (
                              <option key={index} value={job.id}>
                                {job.title}
                              </option>
                            );
                          })
                          : ""}
                      </select>
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          display: state.jobPost.error ? "initial" : "none",
                        }}
                      >
                        {state.jobPost.error}
                      </small>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="footer">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary mr-3"
                onClick={hideModal}
              >
                cancel
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-primary"
                disabled={disable}
                onClick={handleOnSubmit}
              >
                Procced
              </button>
            </div>
          </div>
        </div>
      </div>
      <SuccessToaster
        data={successObj}
        onClose={closeSuccessToast}
      ></SuccessToaster>
      <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
    </div>
  );
};

export default SubmitProfile;
