import React, { useEffect, useState } from "react";
import ManageService from "../../../service/ManageService";
import NavBar from "../Navbar";
import Footer from "../Footer";
import { SuccessToaster } from "../../../component/modal/SuccessModal";
import { ErrorToaster } from "../../../component/modal/ErrorModal";

import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import ContactForm from "../Contact/ContactForm";
import CloseIcon from '@material-ui/icons/Close';



const Plans = (props) => {
    const [plansData, setPlansData] = useState([]);
    const [open, setOpen] = useState(false)
    const [planDesc, setPlanDesc] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState({});
    const [showContactModal, setShowContactModal] = useState(false);
    const [errorObj, setErrorObj] = useState({ show: false, msg: "" });
    const [successObj, setSuccessObj] = useState({ show: false, msg: "" });

    useEffect(() => {
        getPlansData();
    }, []);

    const getPlansData = async () => {
        const response = await ManageService.getPlans();
        if (response.status === 200) {
            const data = response.data.result
            setPlansData(data);
        }
    };

    const handleOpen = (desc) => {
        setOpen(true)
        setPlanDesc(desc)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const showPopup = (index) => {
        setSelectedPlan({ ...plansData[index] });
        setShowContactModal(true)
    }

    const onFormSubmit = (data) => {
        if (data.error) {
            setErrorObj({ show: true, msg: data.msg })
        } else {
            setSelectedPlan({});
            setShowContactModal(false);
            setSuccessObj({ show: true, msg: "Your request is submitted successfully, \n we will reach you as soon as possible" })
        }
    }
    const hideContactModal = (data) => {
        setSelectedPlan({});
        setShowContactModal(false);
    }

    return (
        <div id="plan">
            <NavBar />
            <div className="containt-wrapper hero-section">
                <div className="plans-section row no-gutters align-items-center">
                    <div className="col-12">
                        <h4 className="plan-title">Active Plans</h4>
                    </div>
                </div>

            </div>
            <div className="plan-wrapper">
                <div className="row no-gutters justify-content-center plan-list">
                    {plansData && plansData.length ?
                        plansData.map((plan, index) => {
                            return (
                                <div className={`col-4 plan-card row no-gutters${plan.isRecommended ? " recommended" : ''}`} key={plan.id}>
                                    <h5 className="card-title text-center col-12 m-0 text-truncate">{plan.name}</h5>
                                    <p className="card-desc col-12">
                                        <>
                                            {plan.description && plan.description.length > 90 ? plan.description.substring(0, 90) : ""}
                                            {plan.description && plan.description.length > 90 ? '...' : ""}
                                            {(plan.description && plan.description.length > 90) && <span className="view-all" onClick={() => handleOpen(plan.description)}>View All</span>}
                                        </>
                                    </p>
                                    {plan.isRecommended && <div className="recommended-label">Recommended</div>}
                                    <div className="row no-gutters py-2">
                                        <CheckIcon className="mr-2 col-auto list-icon" />
                                        <span className="col list-details">You can add upto {plan.jobPostBalance} job postings.</span>
                                    </div>
                                    {
                                        plan.categories.map(category => {
                                            return (
                                                <div className="row no-gutters py-2" key={category.id}>
                                                    <CheckIcon className="mr-2 col-auto list-icon" />
                                                    <span className="col list-details">You can access upto {category.profileAccessBalance} <b>{category.category.name.toLowerCase()}</b> profiles</span>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="row col-12 no-gutters mt-3 justify-content-center">
                                        <button className="col-auto btn btn-theme text-center" onClick={() => showPopup(index)}>Contact Us</button>
                                    </div>
                                </div>
                            )
                        }) : plansData && plansData.length === 0 ?
                            <h4>No Plans Found</h4> : null
                    }
                </div>
            </div>
            <Footer></Footer>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle id="fpw-title">Description</DialogTitle>
                <DialogContent>
                    <p>{planDesc}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <div
                className="overlay "
                id="equipment-contact-model"
                style={{ display: showContactModal ? "block" : "none" }}
            >
                <div className="overlay-content">
                    <div className={showContactModal ? " cs-modal showModal" : "cs-modal hideModal"}>
                        <div className="content" >
                            <div className="equipment-contact-heaader-section row no-gutters align-items-center">
                                <div className="equipment-contact-header col">Job Provider lead</div>
                                <div className="col-auto">
                                    <CloseIcon className="close-icon" onClick={hideContactModal}></CloseIcon>
                                </div>
                            </div>
                            <ContactForm
                                onFormSubmit={onFormSubmit}
                                service="JOB POSTING SERVICES"
                                isServiceDisabled={true}
                                planId={selectedPlan.id}></ContactForm>
                        </div>
                    </div>
                </div>
            </div>
            <SuccessToaster
                data={successObj}
                onClose={() => {
                    setSuccessObj({ show: false, msg: "" });
                }}
            ></SuccessToaster>
            <ErrorToaster
                data={errorObj}
                onClose={() => {
                    setErrorObj({ show: false, msg: "" });
                }}
            ></ErrorToaster>
        </div >
    );
};
export default Plans;
