import Api from "./Interceptor";
import LocalStorageService from "./LocalstorageService";

const localStorage = LocalStorageService.getService();

const ManageService = (function () {
  const ajax = function (param) {
    return Api(param);
  };

  return {
    getRole: function () {
      let requestObj = { method: "GET", url: "own/role" };
      return ajax(requestObj);
    },
    isLoggedIn: function () {
      return localStorage.getAccessToken() ? true : false;
    },

    getCategoryList: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `/own/category${param ? `?search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getActiveCategoryList: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `/own/category?q=active${param ? `&search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getCategoryDetails: function (id) {
      let requestObj = { method: "GET", url: `/own/category${id}` };
      return ajax(requestObj);
    },
    createCategory: function (data) {
      let requestObj = { method: "POST", url: `/own/category`, data: data };
      return ajax(requestObj);
    },
    updateCategory: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `/own/category/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateCategoryStatus: function (action, data) {
      let requestObj = {
        method: "PUT",
        url: `/own/category/update/bulk?action=${action}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getRoleList: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `/own/category/role${param ? `?search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getRoleDetails: function (id) {
      let requestObj = { method: "GET", url: `/own/category/role/${id}` };
      return ajax(requestObj);
    },
    createRole: function (data) {
      let requestObj = {
        method: "POST",
        url: `/own/category/role`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateRole: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `/own/category/role/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateRoleStatus: function (action, data) {
      let requestObj = {
        method: "PUT",
        url: `/own/category/role/update/bulk?action=${action}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getStateList: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `/own/state${param ? `?search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getStateDetails: function (id) {
      let requestObj = { method: "GET", url: `/own/state/${id}` };
      return ajax(requestObj);
    },
    createState: function (data) {
      let requestObj = { method: "POST", url: `/own/state`, data: data };
      return ajax(requestObj);
    },
    updateState: function (id, data) {
      let requestObj = { method: "PUT", url: `/own/state/${id}`, data: data };
      return ajax(requestObj);
    },
    updateStateStatus: function (action, data) {
      let requestObj = {
        method: "PUT",
        url: `/own/state/update/bulk?action=${action}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getCityList: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `/own/state/city${param ? `?search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getCityDetails: function (id) {
      let requestObj = { method: "GET", url: `/own/state/city/${id}` };
      return ajax(requestObj);
    },
    createCity: function (data) {
      let requestObj = { method: "POST", url: `/own/state/city`, data: data };
      return ajax(requestObj);
    },
    updateCity: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `/own/state/city/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateCityStatus: function (action, data) {
      let requestObj = {
        method: "PUT",
        url: `/own/state/city/update/bulk?action=${action}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getQualificationList: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `/own/qualification${param ? `?search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getQualificationDetails: function (id) {
      let requestObj = { method: "GET", url: `/own/qualification/${id}` };
      return ajax(requestObj);
    },
    createQualification: function (data) {
      let requestObj = {
        method: "POST",
        url: `/own/qualification`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateQualification: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `/own/qualification/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateQualificationStatus: function (action, data) {
      let requestObj = {
        method: "PUT",
        url: `/own/qualification/update/bulk?action=${action}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getCourseList: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `/own/course${param ? `?search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getCourseDetails: function (id) {
      let requestObj = { method: "GET", url: `/own/course/${id}` };
      return ajax(requestObj);
    },
    createCourse: function (data) {
      let requestObj = { method: "POST", url: `/own/course`, data: data };
      return ajax(requestObj);
    },
    updateCourse: function (id, data) {
      let requestObj = { method: "PUT", url: `/own/course/${id}`, data: data };
      return ajax(requestObj);
    },
    updateCourseStatus: function (action, data) {
      let requestObj = {
        method: "PUT",
        url: `/own/course/update/bulk?action=${action}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getSpecializationList: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `/own/specialization${param ? `?search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getSpecializationDetails: function (id) {
      let requestObj = { method: "GET", url: `/own/specialization/${id}` };
      return ajax(requestObj);
    },
    createSpecialization: function (data) {
      let requestObj = {
        method: "POST",
        url: `/own/specialization`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateSpecialization: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `/own/specialization/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateSpecializationStatus: function (action, data) {
      let requestObj = {
        method: "PUT",
        url: `/own/specialization/update/bulk?action=${action}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getStaffRoleList: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `/own/role${param ? `?search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getStaffRoleDetails: function (id) {
      let requestObj = { method: "GET", url: `/own/role/${id}` };
      return ajax(requestObj);
    },
    createStaffRole: function (data) {
      let requestObj = { method: "POST", url: `/own/role`, data: data };
      return ajax(requestObj);
    },
    updateStaffRole: function (id, data) {
      let requestObj = { method: "PUT", url: `/own/role/${id}`, data: data };
      return ajax(requestObj);
    },
    updateStaffRoleStatus: function (action, data) {
      let requestObj = {
        method: "PUT",
        url: `/own/role/update/bulk?action=${action}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getAppList: function () {
      let requestObj = { method: "GET", url: `/own/app` };
      return ajax(requestObj);
    },
    getPlanList: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `own/plan${param ? `?search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getActivePlanList: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `own/plan?search=active`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    createPlan: function (data) {
      let requestObj = { method: "POST", url: `/own/plan`, data: data };
      return ajax(requestObj);
    },
    updatePlan: function (id, data) {
      let requestObj = { method: "PUT", url: `/own/plan/${id}`, data: data };
      return ajax(requestObj);
    },
    updatePlanStatus: function (action, data) {
      let requestObj = {
        method: "PUT",
        url: `/own/plan/update/bulk?action=${action}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getLeadList: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `/webcontent/leads${param ? `?search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    createLead: function (data, key, value) {
      let requestObj = { method: "POST", url: `/webcontent/leads`, data: data };
      if(key){
        requestObj.url = requestObj.url + `?${key}=${value}`;
      }
      return ajax(requestObj);
    },
    getLeadById: function (id) {
      let requestObj = { method: "GET", url: `/webcontent/leads/${id}` };
      return ajax(requestObj);
    },
    updateLead: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `/webcontent/leads/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateLeadStatus: function (action, data) {
      let requestObj = {
        method: "PUT",
        url: `/webcontent/leads/update/bulk?action=${action}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getEquipmentList: function (param, url) {
      let requestObj = {
        method: "GET",
        url: `/webcontent/equipment${param ? `?search=${param}` : ""}`,
      };
      if (url) {
        requestObj.url = url;
      }
      return ajax(requestObj);
    },
    getAllEquipment: function (param) {
      let requestObj = {
        method: "GET",
        url: `/webcontent/equipment?items=all${param ? `&search=${param}` : ""
          }`,
      };
      return ajax(requestObj);
    },
    createEquipment: function (data) {
      let requestObj = {
        method: "POST",
        url: `/webcontent/equipment`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateEquipment: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `/webcontent/equipment/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateEquipmentStatus: function (action, data) {
      let requestObj = {
        method: "PUT",
        url: `/webcontent/equipment/update/bulk?action=${action}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getAboutorContentPageData: function (pageId) {
      let requestObj = {
        method: "GET",
        url: `/webcontent/page?name=${pageId}`,
      };
      return ajax(requestObj);
    },
    addAboutorContentPageData: function (data) {
      let requestObj = {
        method: "POST",
        url: `/webcontent/page`,
        data: data,
      };
      return ajax(requestObj);
    },
    getPlans: function () {
      let requestObj = {
        method: "GET",
        url: `own/plan?items=all`,
      };
      return ajax(requestObj);
    },
    getTermsConditionDataInWebsite: function () {
      let requestObj = {
        method: "GET",
        url: `webcontent/tnc`,
      };
      return ajax(requestObj);
    },
    getTermsConditionData: function () {
      let requestObj = {
        method: "GET",
        url: `own/tnc`,
      };
      return ajax(requestObj);
    },
    addTermsConditionData: function (data) {
      let requestObj = {
        method: "POST",
        url: `own/tnc`,
        data: data
      };
      return ajax(requestObj);
    },
    aceptTermCondition: function () {
      let requestObj = {
        method: "POST",
        url: `auth/accept/tnc`,
      };
      return ajax(requestObj);
    }
  };
})();

export default ManageService;
