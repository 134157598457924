import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import Unauthorised from "../pages/UnAuthorised";
import LocalStorageService from "../service/LocalstorageService";

const LocalService = LocalStorageService.getService();

const checkPageAccess = (route, user) => {
  //console.log("page access",route,user)
  let isUserAuthorised = false;
  let urlUser =
    LocalService.getUserTypeFromPrefix(route.computedMatch.url.split("/")[1]) ||
    "";
  let allowedActions = [];

  if (urlUser === user.data.userType) {
    if (!route.routeData.checkAccess) {
      isUserAuthorised = true;
      allowedActions.concat(["write", "update", "delete", "read"]);
    } else if (user.data.permissions) {
      if (route?.routeData?.parent === "Role Management") {
        user.data.permissions.forEach((item, index) => {
          if (
            (item.name === "Subscription Plan Management" ||
              item.name === "Staff Role Management" ||
              item.name === "Qualification Management" ||
              item.name === "Category Role Management" ||
              item.name === "Location Management" ||
              item.name === "Leads Management" ||
              item.name === "Equipment Management") &&
            item.read
          ) {
            allowedActions.push(item);
            isUserAuthorised = true;
          }
        });
      } else {
        user.data.permissions.forEach((item, index) => {
          if (item.name === route.routeData.parent) {
            isUserAuthorised = item[route.routeData.name] || false;
            item.write ? allowedActions.push("write") : "";
            item.update ? allowedActions.push("update") : "";
            item.delete ? allowedActions.push("delete") : "";
            item.read ? allowedActions.push("read") : "";
          }
        });
      }
    }
  } else {
    isUserAuthorised = false;
  }

  return { isUserAuthorised, allowedActions };
};

function PrivateRoute(props) {
  const Component = props.component;
  const context = useContext(AuthContext);
  const checkAccess = checkPageAccess(props, context);

  //console.log("checkAccess",checkAccess);

  return (
    <Route
      exact
      path={`${props.path}`}
      render={(props) => {
        return context.data && context.data.token ? (
          checkAccess.isUserAuthorised ? (
            <Component permission={checkAccess.allowedActions} {...props} />
          ) : (
            <Redirect to={{ pathname: "/unauthorised" }} />
          )
        ) : (
          <Redirect to={{ pathname: "/home" }} />
        );
      }}
    />
  );
}

export default PrivateRoute;
