import React, { useState, useCallback } from 'react';
import UnChecked from '@material-ui/icons/CheckBoxOutlineBlank';
import Checked from '@material-ui/icons/CheckBox';
import Intermediate from '@material-ui/icons/IndeterminateCheckBox';

const CheckboxV2 = (props) => {
    const { label, value, data, index, name, classes = '', hasError, change } = props;

    const updateCheckBox = (e) => {
        e.preventDefault();
        change(!value, data, index);
    }

    return (
        <div className={`checkbox-container ${classes}`}>
        <label className="checkbox-label m-0">
            <input className="custom-checkbox-input d-none" 
                type="checkbox"
                name={`${name}-cbx-${index}`}  
                defaultValue={value}/>
            <span className="checkbox-custom recangular" onClick={updateCheckBox}>
                {value ? <Checked className="icon"></Checked> : <UnChecked className="icon"></UnChecked>}
            </span>
        </label>
        { label ? <div className="input-title">{label}</div> :''}
    </div>
    );
}

export default CheckboxV2;

