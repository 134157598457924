import { useState, useEffect, useCallback } from 'react';
import $ from 'jquery';

function UseForm(stateSchema, validationSchema = {}, callback) {
  const [state, setState] = useState(stateSchema);
  const [disable, setDisable] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    const disabled = validateSchema();
    setDisable(disabled);
  }, []);

  useEffect(() => {
    setState(stateSchema);
    setDisable(validateSchema());  
  }, [stateSchema]);


  useEffect(() => {
    if(isDirty)
      setDisable(validateState());  
  }, [state, isDirty]);

  const validateSchema = useCallback(() => {
    let hasErrorInState = false
    Object.keys(validationSchema).forEach(key => {
      if(validationSchema[key].required && (stateSchema[key].value === "" || stateSchema[key].value === null)){
        hasErrorInState = true;
      }

      if ( validationSchema[key].validator !== null && typeof validationSchema[key].validator === 'object') {
        if (stateSchema[key].value && !validationSchema[key].validator.regEx.test(stateSchema[key].value)) {
          hasErrorInState = true;
        }
      }
    });

    return hasErrorInState;
  }, [stateSchema, validationSchema]);

  // Used to disable submit button if there's an error in state
  // or the required field in state has no value.
  // Wrapped in useCallback to cached the function to avoid intensive memory leaked
  // in every re-render in component
  const validateState = useCallback(() => {
    //console.log("validateState called")
    const hasErrorInState = Object.keys(validationSchema).some(key => {
      if(validationSchema[key].required && (state[key].value === "" || state[key].value === null)){
        return true;
      }

      if ( validationSchema[key].validator !== null && typeof validationSchema[key].validator === 'object') {
        if (state[key].value && !validationSchema[key].validator.regEx.test(state[key].value)) {
          return true;
        }
      }

      return false;
    });


    return hasErrorInState;
  }, [state, validationSchema]);

  
  const handleOnChange = useCallback(
    event => {
      setIsDirty(true);

      const name = event.target.name;
      const value = event.target.value;

      let error = '';
      if (validationSchema[name].required) {
        if (!value) {
          error = 'This is required field.';
        }
      }

      if ( validationSchema[name].validator !== null && typeof validationSchema[name].validator === 'object') {
        if (value && !validationSchema[name].validator.regEx.test(value)) {
            error = validationSchema[name].validator.error;
        }
      }

      setState(prevState => ({
        ...prevState,
        [name]: { value, error },
      }));
    },
    [validationSchema]
  );

  const handleOnSubmit = useCallback(
    event => {
      event.preventDefault();

      // Make sure that validateState returns false
      // Before calling the submit callback function
      if (!validateState()) {
        callback(state);
      }
    },
    [state]
  );

  const setErrorInField = useCallback(errorObj => {
    let value = state[errorObj.key].value;
    let error = errorObj.errorMsg;
    setState(prevState => ({
      ...prevState,
      [errorObj.key]: { value, error },
    }));
  },[validationSchema])

  const setValueWithoutError = useCallback(valueObj => {
    let value = state[valueObj.key].value;
    let error = valueObj.error || '';
    setState(prevState => ({
      ...prevState,
      [valueObj.key]: { value, error },
    }));
  },[validationSchema])

  return { state, disable, handleOnChange, handleOnSubmit , setErrorInField,setValueWithoutError};
}

export default UseForm;
