import React, { useState, useEffect } from "react";
import UseForm from "../../service/FormService";
import ManageService from "../../service/ManageService";
import $ from "jquery";
import { ErrorToaster } from "../../component/modal/ErrorModal";
import Autocomplete from "react-select";
import DeleteIcon from "@material-ui/icons/Delete";

const AddProfileSchema = {
  categoryId: { value: "", error: "" },
  profileAccessBalance: { value: "", error: "" },
};

const profileValidationStateSchema = {
  categoryId: {
    required: true,
  },
  profileAccessBalance: {
    required: true,
  },
};

const AddProfileCategory = (props) => {
  const [profileData, setProfileData] = useState({});
  const [intialSchema, setInitionalSchema] = useState(
    $.extend(true, {}, AddProfileSchema)
  );
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [categoryData, setCategoryData] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});

  useEffect(() => {
    //console.log("props",props);
    if (props.data && props.data.categoryId) {
      setProfileData(props.data);
      setSelectedCategory(props.data.category);
    }

    setCategoryData(props.categoryLists);
    setCategoryList(
      props.categoryLists && props.categoryLists.result
        ? props.categoryLists.result.data
        : []
    );
    let schema = $.extend(true, {}, AddProfileSchema);
    schema.categoryId.value = props.data.categoryId || "";
    schema.profileAccessBalance.value = props.data.profileAccessBalance || "";
    setInitionalSchema(schema);
  }, [props]);

  const customFilter = (option, inputValue) => {
        if(option.data.name && inputValue){
            return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        }
        return true;
    };

  const categoryChange = (category) => {
    if (category && category.id) {
      setSelectedCategory(category);
      props.fieldChange("category", category, props.index);
      handleOnChange({ target: { name: "categoryId", value: category.id } });
    } else {
      setSelectedCategory({});
      props.fieldChange("category", "", props.index);
      handleOnChange({ target: { name: "categoryId", value: "" } });
    }
  };
  const profileBalanceChange = (e) => {
    let value = e.target.value;
    props.fieldChange("profileBalance", value, props.index);
    handleOnChange({ target: { name: "profileAccessBalance", value: value } });
  };

  const getCategoryList = async (param, url) => {
    const response = await ManageService.getCategoryList(
      param ? `${param},active` : "active",
      url
    );
    if (response.status === 200) {
      if (url) {
        let list = categoryList || [];
        list = list.concat(response.data.result.data);
        response.data.result.data = list;
      }
      setCategoryData(response.data);
      setCategoryList($.extend(true, [], response.data.result.data));
    }
  };
  const onCategoryInputChange = (param, actionType, list) => {
    if (actionType.action && actionType.action === "input-change") {
      setCategoryList([]);
      param ? getCategoryList(param) : getCategoryList();
    } else if (actionType.action === "menu-close" && !selectedCategory.id)
      getCategoryList();
  };

  const paginateCategoryList = (e) => {
    if (categoryData.detail.links && categoryData.detail.links.next) {
      setCategoryLoading(true);
      getCategoryList("", categoryData.detail.links.next);
      setCategoryLoading(false);
    }
  };
  const removeRow = (e) => {
    e.preventDefault();
    props.remove(props.index);
  };

  const onSubmitForm = () => {
    // console.log("on submit");
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    intialSchema,
    profileValidationStateSchema,
    onSubmitForm
  );
  return (
    <div className="add-profile-category row col-12">
      <div className="form-group col-6 m-0">
        <label className="label-sm">
          Category <sup className="required">*</sup>
        </label>
        <Autocomplete
          name="category-select"
          placeholder="Select Category"
          defaultOptions
          isClearable={true}
          options={categoryList}
          value={
            selectedCategory && selectedCategory.id ? selectedCategory : null
          }
          filterOption={customFilter}
          isLoading={categoryLoading}
          getOptionLabel={(option) => {
            return (
              <div className="row autocomplete-item no-gutters">
                <div className="text col p-1 text-truncate">
                  <div>{option.name}</div>
                </div>
              </div>
            );
          }}
          getOptionValue={(option) => {
            return option.id;
          }}
          onChange={categoryChange}
          onMenuScrollToBottom={paginateCategoryList}
          onInputChange={onCategoryInputChange}
        />
        <small
          className="form-text  error-text my-1"
          style={{ visibility: state.categoryId.error ? "visible" : "hidden" }}
        >
          {state.categoryId.error}
        </small>
      </div>
      <div className="form-group col-5 m-0">
        <label className="label-sm">
          Profile Balance <sup className="required">*</sup>
        </label>
        <input
          type="number"
          className="form-control"
          name="profileAccessBalance"
          id="profileAccessBalance"
          onChange={profileBalanceChange}
          value={state.profileAccessBalance.value}
        />
        <small
          className="form-text  error-text my-1"
          style={{
            visibility: state.profileAccessBalance.error ? "visible" : "hidden",
          }}
        >
          {state.profileAccessBalance.error}
        </small>
      </div>
      {props.totalLength != 1 ? (
        <div className="form-group col-1 m-0 remove-btn">
          <DeleteIcon onClick={removeRow}></DeleteIcon>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const stateSchema = {
  name: { value: "", error: "" },
  description: { value: "", error: "" },
  displayOnWeb: { value: false, error: "" },
  isRecommended: { value: false, error: "" },
  isRenewable: { value: false, error: "" },
  jobPostBalance: { value: "", error: "" },
  price: { value: "", error: "" },
  categories: { value: [], error: "" },
};

const validationStateSchema = {
  description: {
    required: true,
  },
  name: {
    required: true,
  },
  displayOnWeb: {
    required: false,
  },
  isRecommended: {
    required: false
  },
  isRenewable: {
    required: false,
  },
  price: {
    required: true,
  },
  jobPostBalance: {
    required: true,
  },
  categories: {
    required: false,
  },
};

const AddPlan = (props) => {
  const [visible, setVisible] = useState(props.data.show || false);
  const [intialSchema, setInitionalSchema] = useState(
    $.extend(true, {}, stateSchema)
  );
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [ListData, setListData] = useState({});
  const [listLoading, setListLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });

  useEffect(() => {
    setVisible(props.data.show);
    if (!props.data.show) {
      setInitionalSchema($.extend(true, {}, stateSchema));
      setSelectedCategories([]);
      setListData({});
      setErrorObj({ show: false, msg: "" });
    } else {
      if (props.data.plan && props.data.plan.id) {
        let schema = $.extend(true, {}, stateSchema);
        schema.name.value = props.data.plan.name;
        schema.description.value = props.data.plan.description;
        schema.displayOnWeb.value = props.data.plan.displayOnWeb;
        schema.isRecommended.value = props.data.plan.isRecommended;
        schema.isRenewable.value = props.data.plan.isRenewable;
        schema.jobPostBalance.value = props.data.plan.jobPostBalance;
        schema.price.value = props.data.plan.price;
        setInitionalSchema(schema);
        const categories = [];
        props.data.plan.categories.forEach((item) => {
          const { category, id, profileAccessBalance } = item;
          categories.push({ category, categoryId: id, profileAccessBalance });
        });
        setSelectedCategories(categories);
        schema.categories.value = categories;
      } else {
        let categories = $.extend(true, [], selectedCategories);
        categories.push({});
        setSelectedCategories(categories);
      }
      setListLoading(true);
      getCategoryList();
      setListLoading(false);
    }
  }, [props]);

  useEffect(() => {
    // console.log("selectedCategories changes", selectedCategories);
    if (props.data.show) {
      handleOnChange({
        target: {
          name: "categories",
          value: $.extend(true, [], selectedCategories),
        },
      });
    }
  }, [selectedCategories]);

  const getCategoryList = async (param, url) => {
    const response = await ManageService.getCategoryList(
      param ? `${param},active` : "active",
      url
    );
    if (response.status === 200) {
      if (url) {
        let list = [];
        list = list.concat(ListData, response.data.result.data);
        response.data.result.data = list;
      }
      setListData(response.data.result.data);
      setCategoryList(response.data);
    }
  };

  const displayOnWebChange = (e) => {
    e.persist();
    const event = {
      target: {
        name: "displayOnWeb",
        value: !document.getElementById("displayOnWeb").checked,
      },
    };
    handleOnChange(event);
  };

  const isRecommendedChange = (e) => {
    e.persist();
    const event = {
      target: {
        name: "isRecommended",
        value: !document.getElementById("isRecommended").checked,
      },
    };
    handleOnChange(event);
  };

  const isPlanRenewableChange = (e) => {
    e.persist();
    const event = {
      target: {
        name: "isRenewable",
        value: !document.getElementById("isRenewable").checked,
      },
    };
    handleOnChange(event);
  };

  const hideModal = () => {
    setVisible(false);
    props.onCancel();
  };

  const closeToast = (e) => {
    e.preventDefault();
    setErrorObj({ show: false, msg: "" });
  };

  const onProfileCategoryChange = (type, data, index) => {
    let plans = $.extend(true, [], selectedCategories);
    if (type === "category") {
      plans[index].categoryId = data.id || "";
      plans[index].category = data.id ? { name: data.name, id: data.id } : "";
    } else {
      plans[index].profileAccessBalance = data || "";
    }
    setSelectedCategories(plans);

    // console.log("onProfileCategoryChange", type, data, index, plans);
  };

  const addCategories = (e) => {
    e.preventDefault();
    let plans = $.extend(true, [], selectedCategories);
    plans.push({});
    setSelectedCategories(plans);
  };

  const removeCategorie = (index) => {
    let plans = $.extend(true, [], selectedCategories);
    plans.splice(index, 1);
    setSelectedCategories(plans);
  };

  const isValidCategories = () => {
    let disabled = false;
    for (let i = 0; i < selectedCategories.length; i++) {
      if (
        !selectedCategories[i].categoryId ||
        !selectedCategories[i].profileAccessBalance
      ) {
        disabled = true;
        break;
      }
    }
    return disabled;
  };

  const onSubmitForm = (state) => {
    const requestPayload = {
      name: state.name.value,
      description: state.description.value,
      displayOnWeb: state.displayOnWeb.value,
      isRecommended: state.isRecommended.value,
      isRenewable: state.isRenewable.value,
      jobPostBalance: state.jobPostBalance.value,
      price: state.price.value,
      categories: [],
    };
    state.categories.value.forEach((item) => {
      requestPayload.categories.push({
        categoryId: item.categoryId,
        profileAccessBalance: item.profileAccessBalance,
      });
    });

    // console.log("on submit data", requestPayload);

    if (props.data.plan && props.data.plan.id) {
      ManageService.updatePlan(props.data.plan.id, requestPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            props.onConfirmAction(res.data.result);
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    } else {
      ManageService.createPlan(requestPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            props.onConfirmAction(res.data.result);
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    }
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    intialSchema,
    validationStateSchema,
    onSubmitForm
  );

  return (
    <div
      className="overlay "
      id="plan-model"
      style={{ display: visible ? "block" : "none" }}
    >
      <div className="overlay-content">
        <div className={visible ? " cs-modal showModal" : "cs-modal hideModal"}>
          <div className="header">
            {props.data.plan && props.data.plan.id ? "Update Plan" : "Add Plan"}{" "}
          </div>
          <div className="body">
            <div className="plan-container">
              <form name="add-plan">
                <div className="row">
                  <div className="form-group col-12 m-0">
                    <label className="label-sm">
                      Plan Name <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      onChange={handleOnChange}
                      value={state.name.value}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.name.error ? "visible" : "hidden",
                      }}
                    >
                      {state.name.error}
                    </small>
                  </div>
                  <div className="form-group col-12 m-0">
                    <label className="label-sm">
                      Description <sup className="required">*</sup>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="description"
                      id="namdescriptione"
                      onChange={handleOnChange}
                      value={state.description.value}
                    ></textarea>
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.description.error
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      {state.description.error}
                    </small>
                  </div>
                  <div className="form-group col-4" title="Enable to show plan on website">
                    <label className="label-sm mr-4 mb-3">
                      Visibility
                    </label>
                    <div className="switch" onClick={displayOnWebChange}>
                      {state.displayOnWeb.value ? (
                        <input
                          type="checkbox"
                          name="displayOnWeb"
                          id="displayOnWeb"
                          value={state.displayOnWeb.value || true}
                          checked={state.displayOnWeb.value || true}
                          onChange={displayOnWebChange}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          name="displayOnWeb"
                          id="displayOnWeb"
                          value={state.displayOnWeb.value || false}
                          checked={state.displayOnWeb.value || false}
                          onChange={displayOnWebChange}
                        />
                      )}
                      <span className="slider round"></span>
                    </div>
                  </div>
                  <div className="form-group col-4" title="Recommended plan will have a label on website">
                    <label className="label-sm mr-4 mb-3">
                      Recommended
                    </label>
                    <div className="switch" onClick={isRecommendedChange}>
                      {state.isRecommended.value ? (
                        <input
                          type="checkbox"
                          name="isRecommended"
                          id="isRecommended"
                          value={state.isRecommended.value || true}
                          checked={state.isRecommended.value || true}
                          onChange={isRecommendedChange}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          name="isRecommended"
                          id="isRecommended"
                          value={state.isRecommended.value || false}
                          checked={state.isRecommended.value || false}
                          onChange={isRecommendedChange}
                        />
                      )}
                      <span className="slider round"></span>
                    </div>
                  </div>
                  <div className="form-group col-4" title="Can this plan be renewed by customer">
                    <label className="label-sm mr-4 mb-3">
                      Renewable
                    </label>
                    <div className="switch" onClick={isPlanRenewableChange}>
                      {state.isRenewable.value ? (
                        <input
                          type="checkbox"
                          name="isRenewable"
                          id="isRenewable"
                          value={state.isRenewable.value || true}
                          checked={state.isRenewable.value || true}
                          onChange={isPlanRenewableChange}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          name="isRenewable"
                          id="isRenewable"
                          value={state.isRenewable.value || false}
                          checked={state.isRenewable.value || false}
                          onChange={isPlanRenewableChange}
                        />
                      )}
                      <span className="slider round"></span>
                    </div>
                  </div>
                  <div className="form-group col-6 m-0">
                    <label className="label-sm">
                      Price <sup className="required">*</sup>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="price"
                      id="price"
                      onChange={handleOnChange}
                      value={state.price.value}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.price.error ? "visible" : "hidden",
                      }}
                    >
                      {state.price.error}
                    </small>
                  </div>
                  <div className="form-group col-6 m-0">
                    <label className="label-sm">
                      Job Post Balance <sup className="required">*</sup>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="jobPostBalance"
                      id="jobPostBalance"
                      onChange={handleOnChange}
                      value={state.jobPostBalance.value}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.jobPostBalance.error
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      {state.jobPostBalance.error}
                    </small>
                  </div>
                  <div className="row col-12 m-0">
                    <div className="card col-12 p-0">
                      <div className="card-header row no-gutters">
                        <h5 className="col">Category and Profile Balance</h5>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={addCategories}
                        >
                          +Add
                        </button>
                      </div>
                      <div className="card-body">
                        {selectedCategories
                          ? selectedCategories.map((category, index) => {
                            return (
                              <AddProfileCategory
                                categoryLists={categoryList}
                                key={index}
                                index={index}
                                totalLength={selectedCategories.length}
                                fieldChange={onProfileCategoryChange}
                                data={category}
                                remove={removeCategorie}
                              ></AddProfileCategory>
                            );
                          })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="footer">
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary mr-3"
              onClick={hideModal}
            >
              cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary"
              disabled={disable || isValidCategories()}
              onClick={handleOnSubmit}
            >
              Procced
            </button>
          </div>
        </div>
      </div>
      <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
    </div>
  );
};

export default AddPlan;
