import React from "react";
import BackupIcon from "@material-ui/icons/Backup";
import { Link } from "react-router-dom";
import UploadResume from '../../../assets/imgs/pose_1.svg';

const UploadResumeSection = () => {
  return (
    <div id="upload-resume">
      <div className="container">
        <div className="row section-header text-center p-4">
          <h2 className="col-md-12">Upload Resume</h2>
        </div>
        <div className="row">
          <div className=" col-md-6 col-lg-6 col-xl-6">
              <img src={UploadResume} className="img-fluid"></img>
          </div>
          <div className="form-section col-md-6 row">
            <div className="m-auto align-items-center">
              <div className="container">
                <div className="row p-4 text-center justify-items-center align-items-center">
                  <h3 className="upload-text col-md-12">
                    Start your career by uploading <br /> awesome resume
                  </h3>
                  <Link className="col-md-12 my-4 mx-auto" to="/can/register">
                    <button className="btn upload-btn">
                      Upload Resume
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadResumeSection;
