import React, { useState, useEffect } from "react";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "../../component/Checkbox";
import { Link } from "react-router-dom";
import ActionListIcon from "@material-ui/icons/MoreVert";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import Loader from "../../component/Loader";
import ManageService from "../../service/ManageService";
import Moment from "react-moment";
import WarningIcon from "@material-ui/icons/Warning";
import UpdateStaffRole from "../../component/modal/AddStaffRole";
import ErrorModal from "../../component/modal/ErrorModal";
import SuccessModal from "../../component/modal/SuccessModal";
import ConfirmModal from "../../component/modal/ConfirmModal";

const StaffRoleLists = (props) => {
  //console.log("prpos StaffRoleLists",props);
  const [rowSelected, setSelectedRow] = useState(false);

  useEffect(() => {
    if (props.rowSelected != "intermediate") {
      setSelectedRow(props.rowSelected);
    }
  }, [props.rowSelected]);

  const onCheckBoxChange = (param) => {
    //console.log(' checkbox param',param);
    setSelectedRow(param);
    props.onRowChange(props.list.id, rowSelected);
  };
  const doAction = (e) => {
    //console.log("doing action");
    e.target.dataset.value === "edit"
      ? props.callAction(e, props.list)
      : props.callAction(e, [props.list.id]);
  };
  return (
    <div className="list col-12">
      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="checbox-content col-auto m-auto">
            <Checkbox
              value={rowSelected}
              classes={"staff-list-checkbox"}
              change={onCheckBoxChange}
            ></Checkbox>
          </div>
          <div
            className={
              props.list.isArchived
                ? "list-content col row no-gutters archived"
                : props.list.isActive
                  ? "list-content col row no-gutters active"
                  : "list-content col row no-gutters inactive"
            }
          >
            <b className="user-name col-3 text-truncate">{props.list.name}</b>
            <div className="col-3 row-text text-truncate">
              <span className="text-label">Created By - </span>
              <span className="text-value">{`${props.list.addedBy ? props.list.addedBy.firstName : ""
                } ${props.list.addedBy ? props.list.addedBy.lastName : ""
                }`}</span>
            </div>
            <div className="row-text col-2 text-truncate">
              <span className="text-label">Created On - </span>
              <span className="text-value">
                {props.list.createdOn ? (
                  <Moment
                    date={props.list.createdOn}
                    format={"DD MMM YYYY"}
                  ></Moment>
                ) : (
                  " NA "
                )}{" "}
              </span>
            </div>
            <div className="col-2 my-auto text-center text-truncate">
              {props.list.isArchived ? (
                <span className="badge badge-danger">Archived</span>
              ) : props.list.isActive ? (
                <span className="badge badge-success">Active</span>
              ) : (
                <span className="badge badge-warning">Inactive</span>
              )}
            </div>
            <div className="action col-2 text-center">
              <div className="btn-group">
                <ActionListIcon
                  className="dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
                <ul className="dropdown-menu">
                  {<li
                    className="dropdown-item spl-link-buttn-css"
                    data-value="edit"
                    onClick={doAction}
                  >
                    Edit Role
                  </li>
                  }
                  {props.list.isActive ? (
                    <li
                      className="dropdown-item spl-link-buttn-css"
                      data-value="deactivate"
                      onClick={doAction}
                    >
                      Deactivate Role
                    </li>
                  ) : (
                    <li
                      className="dropdown-item spl-link-buttn-css"
                      data-value="activate"
                      onClick={doAction}
                    >
                      Activate Role
                    </li>
                  )}
                  {<li
                    className="dropdown-item spl-red-buttn-css"
                    data-value="delete"
                    onClick={doAction}
                  >
                    Delete Permanently
                  </li>
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StaffRoleDetails = (props) => {
  const [loading, setLoading] = useState(true);
  const [staffRoleList, setStaffRoleList] = useState([]);
  const [paginationObj, setPaginationObj] = useState({});
  const [listLoading, setListLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [confirmationObj, setConfirmationObj] = useState({
    show: false,
    msg: "",
  });
  const [currentAction, setCurrentAction] = useState("");
  const [errModel, setErrorModal] = useState({ show: false, error: {} });
  const [successModel, setSuccessModal] = useState({
    show: false,
    success: {},
  });
  const [showUpdatePopup, setShowUpdatePopup] = useState({
    show: false,
    staffRole: {},
  });
  const thresholdHeight = 40;

  useEffect(() => {
    if (props.selectedTab === "staff_role") {
      setSelectedRow([]);
      setSelectAll(false);
      setCurrentAction("");
      getStaffRoleList();
    }
  }, [props]);

  const getStaffRoleList = async (param, url) => {
    const response = await ManageService.getStaffRoleList(param, url);
    if (response.status === 200) {
      if (url) {
        let list = [];
        list = list.concat(staffRoleList.data, response.data.result.data);
        response.data.result.data = list;
      }
      setStaffRoleList(response.data.result);
      setPaginationObj(response.data.detail);
      setLoading(false);
      setListLoading(false);
    } else {
      setLoading(false);
      setListLoading(false);
    }
  };

  const handleScroll = (e) => {
    e.persist();
    e.preventDefault();
    const scrollableHeight = e.target.scrollHeight - e.target.clientHeight;
    if (!paginationObj.links.next || listLoading) {
      return;
    }
    if (scrollableHeight - e.target.scrollTop < thresholdHeight) {
      setListLoading(true);
      getStaffRoleList("", paginationObj.links.next);
    }
  };

  const selectedRowChange = (id) => {
    let selectedList = selectedRow;
    const ocuuranceId = selectedList.indexOf(id);
    ocuuranceId > -1
      ? selectedList.splice(ocuuranceId, 1)
      : selectedList.push(id);
    setSelectedRow(selectedList);
    changeStateOfCheckbox();
  };

  const changeStateOfCheckbox = () => {
    let status = "";
    const len = selectedRow.length;
    if (len === 0) {
      status = false;
    } else if (len === staffRoleList.data.length) {
      status = true;
    } else {
      status = "intermediate";
    }
    setSelectAll(status);
  };

  const onCheckBoxChange = (param) => {
    const selectedAllItem = [];
    if (param === true) {
      staffRoleList.data.forEach((item) => {
        selectedAllItem.push(item.id);
      });
    }
    setSelectedRow(selectedAllItem);
    setSelectAll(param);
  };

  const closePopup = () => {
    setConfirmationObj({ show: false, msg: "" });
    setCurrentAction("");
  };

  const performAction = (e, list) => {
    e.persist();
    e.preventDefault();
    const action = e.target.dataset.value;
    if (action === "edit") {
      addStaffRole(list);
    } else {
      setCurrentAction({ action: action, data: list ? list : selectedRow });
      setConfirmationObj({
        show: true,
        msg: `You want to ${action} this Role?`,
      });
    }
  };

  const doAction = async () => {
    setLoading(true);
    setConfirmationObj({ show: false, msg: "" });

    try {
      const res = await ManageService.updateStaffRoleStatus(
        currentAction.action,
        { roleIds: currentAction.data }
      );
      setCurrentAction({});
      setLoading(false);
      //console.log("success res", res);
      if (res.status === 200) {
        setSuccessModal({ show: true, success: { msg: res.data.result } });
        setSelectAll(false);
        getStaffRoleList();
      }
    } catch (err) {
      //console.log(err);
      setLoading(false);
      if (err && err.data) {
        setErrorModal({ show: true, error: err.data });
      }
    }
  };

  const onSearch = (e) => {
    e.persist();
    e.preventDefault();
    e.stopPropagation();
    const value = e.target.value;
    if ((value && value.length > 2) || !value) {
      getStaffRoleList(value);
    }
  };
  const onCancelCatePopup = (data) => {
    ////console.log("onCancelSubscription called",data);
    setShowUpdatePopup({ show: false, staffRole: {} });
  };

  const addStaffRole = (data) => {
    //console.log("add catedgory called");
    setShowUpdatePopup({ show: true, staffRole: data ? data : {} });
  };

  const afterAddCategory = (data) => {
    onCancelCatePopup();
    getStaffRoleList();
  };

  if (loading) return <Loader />;

  //console.log("Category Details is called");
  return (
    <div id="category-list-container" className="manage-list-container">
      <div className="container-fluid">
        <div className="row p-3 filter-row">
          <div className="col-3">
            <h3>Staff Role</h3>
          </div>
          <div className="col">
            <div className="form-group m-0">
              <div className="d-flex justify-content-center">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="What are you looking for?"
                  onChange={onSearch}
                />
                <SearchIcon className="search-icon"></SearchIcon>
              </div>
            </div>
          </div>
          <div className="col-1"></div>
          <div className="col-2">
            <button
              className="btn btn-primary btn-padding add-staff-btn"
              onClick={addStaffRole}
            >
              {" "}
              +Add{" "}
            </button>
          </div>
        </div>

        <div className="list-card list-view" id="category-list-view">
          <div className="row">
            <div className="list-container container-fluid">
              <div className="row col-12">
                <div className="list border-0 m-0 col-12">
                  <div className="container-fluid">
                    <div className="row pb-2 no-gutters">
                      <div className="checbox-content col-auto m-auto">
                        <Checkbox
                          value={selectAll}
                          classes={"staff-list-checkbox"}
                          change={onCheckBoxChange}
                          label={"Select all"}
                        ></Checkbox>
                      </div>
                      <div className="col row no-gutters">
                        <div className="col"></div>
                        <div className="col-2">
                          {paginationObj.totalCount && staffRoleList.data ? (
                            <span className="pagination">
                              {staffRoleList.data.length} of{" "}
                              {paginationObj.totalCount} Roles{" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-2 text-right p-0">
                          <div
                            className="dropdown"
                            style={{
                              visibility:
                                selectAll === false ? "hidden" : "visible",
                            }}
                          >
                            <button
                              className="btn btn-secondary btn-sm dropdown-toggle"
                              type="button"
                              id="action-staff-dropdown"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Actions
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="action-staff-dropdown"
                              onClick={performAction}
                            >
                              <p
                                className="dropdown-item m-0"
                                data-value="archive"
                              >
                                Archive
                              </p>
                              <p
                                className="dropdown-item m-0"
                                data-value="unarchive"
                              >
                                Unarchive
                              </p>
                              <p
                                className="dropdown-item m-0"
                                data-value="activate"
                              >
                                Activate
                              </p>
                              <p
                                className="dropdown-item m-0"
                                data-value="deactivate"
                              >
                                Deactivate
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="container-fluid list-container scrollable-list "
              onScroll={handleScroll}
            >
              <div className="row col-12">
                {staffRoleList.data.map((item) => {
                  return (
                    <StaffRoleLists
                      list={item}
                      key={item.id}
                      onRowChange={selectedRowChange}
                      rowSelected={selectAll}
                      callAction={performAction}
                    ></StaffRoleLists>
                  );
                })}
                {listLoading ? (
                  <div className="List col-12">
                    <div
                      className="spinner-border text-primary table-loader"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {!listLoading &&
                  staffRoleList.data &&
                  staffRoleList.data.length === 0 &&
                  paginationObj.totalCount > 0 ? (
                  <div className="no-option-avialble w-100 m-3">
                    <div className="margin-auto text-center">
                      <WarningIcon></WarningIcon>
                      <p>No Result Found </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {!listLoading && paginationObj.totalCount === 0 ? (
                  <div className="no-option-avialble w-100 m-3">
                    <div className="margin-auto text-center">
                      <WarningIcon></WarningIcon>
                      <p>No Staff role added yet! </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        data={confirmationObj}
        onConfirmAction={doAction}
        onCancel={closePopup}
      ></ConfirmModal>
      <ErrorModal
        data={errModel}
        onConfirmAction={() => setErrorModal({ show: false, error: {} })}
      ></ErrorModal>
      <SuccessModal
        data={successModel}
        onConfirmAction={() => setSuccessModal({ show: false, success: {} })}
      ></SuccessModal>
      <UpdateStaffRole
        data={showUpdatePopup}
        onConfirmAction={afterAddCategory}
        onCancel={onCancelCatePopup}
      ></UpdateStaffRole>
    </div>
  );
};

export default StaffRoleDetails;
