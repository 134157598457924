import React, { useState, useEffect } from "react";
import NoDataFound from "../../common/NoDataFound";
import CandidateService from "../../service/SearchCandidateService";
import Loader from "../../component/Loader";
import ConfirmModal from "../../component/modal/ConfirmModal";
import { CandidateListView } from "./List";
import ErrorModal from "../../component/modal/ErrorModal";
import SuccessModal from "../../component/modal/SuccessModal";
import SearchIcon from "@material-ui/icons/Search";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UseForm from "../../service/FormService";
import Autocomplete from "react-select";
import { SuccessToaster } from "../../component/modal/SuccessModal";
import { ErrorToaster } from "../../component/modal/ErrorModal";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import Switch from "@material-ui/core/Switch";

const NoData = (props) => {
  return (
    <div className="content-wrapper">
      <div className="content-container">
        <NoDataFound
          name={props.name}
          redirectUrl={props.redirectUrl}
          hideButton={props.hideButton}
        ></NoDataFound>
      </div>
    </div>
  );
};

const Main = (props) => {
  const [loading, setLoading] = useState(true);
  const [candidateList, setCandidateList] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [confirmationObj, setConfirmationObj] = useState({
    show: false,
    msg: "",
  });
  const [paginationObj, setPaginationObj] = useState({});
  const [listLoading, setListLoading] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [errModel, setErrorModal] = useState({ show: false, error: {} });
  const [successModel, setSuccessModal] = useState({
    show: false,
    success: {},
  });
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const thresholdHeight = 40;

  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [qualificationList, setQualificationList] = useState([]);
  const [selectedQual, setSelectedQual] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [speciList, setSpeciList] = useState([]);
  const [selectedSpeci, setSelectedSpeci] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [successObj, setSuccessObj] = useState({ show: false, success: {} });
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });
  const location = useLocation();
  const [expand, setExpand] = useState(false);
  const [isStrict, setIsStrict] = useState(false);
  const [town, setTown] = useState("");
  const [isCtcNegotiable, setIsCtcNegotiable] = useState(false);
  const [maximumCtc, setMaximumCtc] = useState("");
  const [minimumExperience, setMinimumExperience] = useState("");
  const [maximumExperience, setMaximumExperience] = useState("");
  const [applyLoader, setApplyLoader] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState(null);
  useEffect(() => {
    getCandidateList();
    if (location && location.state && location.state.id) {
      getCategory(location.state.id);
    } else {
      getCategory();
    }
    getQualification();
    getStateList();
    setLoading(false);
  }, []);

  useEffect(() => {
    getCandidateList();
  }, [searchValue, filterValue, advancedFilter]);

  const getCandidateList = async (url) => {
    const response = await CandidateService.getCandidateList(
      searchValue,
      filterValue,
      url,
      advancedFilter,
      isStrict
    );
    if (response.status === 200) {
      if (url) {
        let list = [];
        list = list.concat(candidateList.data, response.data.result.data);
        response.data.result.data = list;
      }
      setCandidateList(response.data.result);
      setPaginationObj(response.data.detail);
      setLoading(false);
      setListLoading(false);
      setApplyLoader(false);
    } else {
      setLoading(false);
      setListLoading(false);
      setApplyLoader(false);
    }
  };

  const handleScroll = (e) => {
    e.persist();
    e.preventDefault();
    const scrollableHeight = e.target.scrollHeight - e.target.clientHeight;
    if (!paginationObj.links.next || listLoading) {
      return;
    }
    if (scrollableHeight - e.target.scrollTop < thresholdHeight) {
      setListLoading(true);
      getCandidateList(paginationObj.links.next);
    }
  };

  const selectedRowChange = (id) => {
    let selectedList = selectedRow;
    const ocuuranceId = selectedList.indexOf(id);
    ocuuranceId > -1
      ? selectedList.splice(ocuuranceId, 1)
      : selectedList.push(id);
    setSelectedRow(selectedList);
  };

  const closePopup = () => {
    setConfirmationObj({ show: false, msg: "" });
    setCurrentAction("");
  };

  const performAction = (e, list) => {
    e.persist();
    e.preventDefault();
    const action = e.target.dataset.value;
    setCurrentAction({ action: action, data: list ? list : selectedRow });
    setConfirmationObj({
      show: true,
      msg: `You want to ${action} this candidate?`,
    });
  };

  const doAction = async () => {
    setLoading(true);
    setConfirmationObj({ show: false, msg: "" });

    try {
      const res = await CandidateService.updateCandidateStatus(
        currentAction.action,
        { userIds: currentAction.data }
      );
      setCurrentAction({});
      setLoading(false);
      if (res.status === 200) {
        setSuccessModal({ show: true, success: { msg: res.data.result } });
        getCandidateList();
      }
    } catch (err) {
      setLoading(false);
      if (err && err.data) {
        setErrorModal({ show: true, error: err.data });
      }
    }
  };

  const onSearch = (e) => {
    e.persist();
    e.preventDefault();
    e.stopPropagation();
    const value = e.target.value;
    if ((value && value.length > 2) || !value) {
      setSearchValue(value);
    }
  };

  const onFilterChange = (event) => {
    const text = event.target.value;
    setFilterValue(text);
  };

  const getCityList = async (selectedItem, param, url) => {
    try {
      const response = await CandidateService.getCitiesList(
        selectedItem.id,
        param,
        url
      );
      const item = { label: selectedItem.name, options: response.data.result };
      const list = $.extend(true, [], cityList);
      list.push(item);
      setCityList(list);
    } catch (e) {
      //console.log("error",e);
    }
  };

  const getStateList = async () => {
    try {
      const response = await CandidateService.getStateList();
      setStateList(response.data.result);
    } catch (e) {
      //console.log("error",e);
    }
  };

  const getSpecialization = async (selectedItem) => {
    try {
      const response = await CandidateService.getSpecialization(
        selectedItem.id
      );
      const item = { label: selectedItem.name, options: response.data.result };
      const list = $.extend(true, [], speciList);
      list.push(item);
      setSpeciList(list);
    } catch (e) {
      //console.log("error",e);
    }
  };

  const getCourses = async (selectedItem, param, url) => {
    try {
      const response = await CandidateService.getCourse(
        selectedItem.id,
        param,
        url
      );
      const item = { label: selectedItem.name, options: response.data.result };
      const list = $.extend(true, [], courseList);
      list.push(item);
      setCourseList(list);
    } catch (e) { }
  };

  const getQualification = async (param, url) => {
    try {
      const response = await CandidateService.getQualification(param, url);
      setQualificationList(response.data.result);
    } catch (e) { }
  };
  const getCategory = async (clientId, param, url) => {
    try {
      const response = await CandidateService.getCategoryByClient(
        clientId,
        param,
        url
      );
      setCategoryList(response.data.result);
      setSelectedCategory([]);
    } catch (e) { }
  };

  const getRoles = async (selectedItem, param, url) => {
    try {
      const response = await CandidateService.getRoles(
        selectedItem.id,
        param,
        url
      );
      const item = { label: selectedItem.name, options: response.data.result };
      const list = $.extend(true, [], roleList);
      list.push(item);
      setRoleList(list);
    } catch (e) { }
  };

  const categoryChanges = (categories) => {
    setSelectedRole([]);
    if (categories && categories.length > 0) {
      setSelectedCategory(categories);
      updateRolesList(categories, selectedCategory);
    } else {
      setSelectedCategory([]);
    }
  };

  const updateRolesList = (newList, previousList) => {
    const newListLength = newList ? newList.length : 0;
    const previousListLen = previousList ? previousList.length : 0;
    let action = newListLength > previousListLen ? "add" : "remove";
    newList = newList || [];
    previousList = previousList || [];
    let item = "";
    if (action === "remove") {
      previousList.forEach((prevItem) => {
        let matched = false;
        newList.forEach((newItem) => {
          if (newItem.id === prevItem.id) {
            matched = true;
          }
        });
        if (!matched) {
          item = prevItem;
        }
      });
    } else {
      item = newList[newList.length - 1];
    }
    action === "add" ? getRoles(item) : updateRoleList(item);
  };

  const updateRoleList = (item) => {
    let roleList = $.extend(true, [], roleList);
    roleList.forEach((role, index) => {
      if (role.label === item.name) {
        roleList.splice(index, 1);
      }
    });
    setRoleList(roleList);
    setSelectedRole([]);
  };

  const roleChanges = (role) => {
    role && role.length > 0 ? setSelectedRole(role) : setSelectedRole([]);
  };

  const qualificationChanges = (qualification) => {
    setSelectedCourse([]);
    setSelectedSpeci([]);
    if (qualification && qualification.length > 0) {
      setSelectedQual(qualification);
      updateCoursesList(qualification, selectedQual);
    } else {
      setSelectedQual([]);
    }
  };

  const updateCoursesList = (newList, previousList) => {
    const newListLength = newList ? newList.length : 0;
    const previousListLen = previousList ? previousList.length : 0;
    let action = newListLength > previousListLen ? "add" : "remove";
    newList = newList || [];
    previousList = previousList || [];
    let item = "";
    if (action === "remove") {
      previousList.forEach((prevItem) => {
        let matched = false;
        newList.forEach((newItem) => {
          if (newItem.id === prevItem.id) {
            matched = true;
          }
        });
        if (!matched) {
          item = prevItem;
        }
      });
    } else {
      item = newList[newList.length - 1];
    }
    action === "add" ? getCourses(item) : updateCourseList(item);
  };

  const updateCourseList = (item) => {
    let courseList = $.extend(true, [], courseList);
    courseList.forEach((course, index) => {
      if (course.label === item.name) {
        courseList.splice(index, 1);
      }
    });
    setCourseList(courseList);
    setSelectedCourse([]);
  };

  const courceChanges = (courses) => {
    setSelectedSpeci([]);
    updateSpecilaisationList(courses, selectedCourse);
    if (courses && courses.length > 0) {
      setSelectedCourse(courses);
    } else {
      setSelectedCourse([]);
    }
  };

  const speciChanges = (speci) => {
    speci && speci.length > 0 ? setSelectedSpeci(speci) : setSelectedSpeci([]);
  };

  const updateSpecilaisationList = (newList, previousList) => {
    const newListLength = newList ? newList.length : 0;
    const previousListLen = previousList ? previousList.length : 0;
    let action = newListLength > previousListLen ? "add" : "remove";
    newList = newList || [];
    previousList = previousList || [];
    let item = "";
    if (action === "remove") {
      previousList.forEach((prevItem) => {
        let matched = false;
        newList.forEach((newItem) => {
          if (newItem.id === prevItem.id) {
            matched = true;
          }
        });
        if (!matched) {
          item = prevItem;
        }
      });
    } else {
      item = newList[newList.length - 1];
    }
    action === "add" ? getSpecialization(item) : updateSpeciList(item);
  };

  const updateSpeciList = (item) => {
    let specList = $.extend(true, [], speciList);
    speciList.forEach((spec, index) => {
      if (spec.label === item.name) {
        specList.splice(index, 1);
      }
    });
    setSpeciList(specList);
    setSelectedSpeci([]);
  };

  const onStateChange = (state) => {
    setSelectedCity([]);
    if (state && state.length > 0) {
      setSelectedState(state);
      updateCitiesList(state, selectedState);
    } else {
      setSelectedState([]);
    }
  };

  const updateCitiesList = (newList, previousList) => {
    const newListLength = newList ? newList.length : 0;
    const previousListLen = previousList ? previousList.length : 0;
    let action = newListLength > previousListLen ? "add" : "remove";
    newList = newList || [];
    previousList = previousList || [];
    let item = "";
    if (action === "remove") {
      previousList.forEach((prevItem) => {
        let matched = false;
        newList.forEach((newItem) => {
          if (newItem.id === prevItem.id) {
            matched = true;
          }
        });
        if (!matched) {
          item = prevItem;
        }
      });
    } else {
      item = newList[newList.length - 1];
    }
    action === "add" ? getCityList(item) : updateCityList(item);
  };

  const updateCityList = (item) => {
    let cityList = $.extend(true, [], cityList);
    cityList.forEach((city, index) => {
      if (city.label === item.name) {
        cityList.splice(index, 1);
      }
    });
    setCityList(cityList);
    setSelectedCity([]);
  };

  const onCityChange = (city) => {
    city && city.length > 0 ? setSelectedCity(city) : setSelectedCity([]);
  };

  const isNegotiableChange = (e) => {
    setIsCtcNegotiable(!document.getElementById("isCtcNegotiable").checked);
  };

  const customFilter = (option, inputValue) => {
    if (option.data.name && inputValue) {
      return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    }
    return true;
  };

  const onModeChange = (event) => {
    setIsStrict(event.target.checked);
  };

  const onSubmitForm = () => {
    setApplyLoader(true);
    const requestPayload = {
      categories: selectedCategory.map((cat) => cat.id),
      roles: selectedRole.map((role) => role.id),
      qualifications: selectedQual.map((qual) => qual.id),
      courses: selectedCourse.map((course) => course.id),
      specializations: selectedSpeci.map((speci) => speci.id),
      jobLocations: {
        states: selectedState.map((state) => state.id),
        cities: selectedCity.map((city) => city.id),
        towns: town ? town.split(",") : [],
      },
      isCtcNegotiable: isCtcNegotiable,
      maximumCtc: maximumCtc,
      minimumExperience: minimumExperience,
      maximumExperience: maximumExperience,
    };
    setAdvancedFilter({ ...requestPayload });
    //getCandidateList();
    // CandidateService.filterCandidate(requestPayload, isStrict).then(
    //   (res) => {
    //     if (res.status === 201 || res.status === 200) {
    //       setApplyLoader(false);
    //       setExpand(false);
    //       setCandidateList({ data: res.data.result.data });
    //     }
    //   },
    //   (err) => {
    //     setApplyLoader(false);
    //     setErrorObj({ show: true, msg: err.data.message });
    //   }
    // );
  };

  const closeAccordian = () => {
    setExpand(false)
  }

  if (loading) return <Loader />;

  return (
    <div className="content-wrapper" id="candidate-container">
      <Accordion
        className="mb-2"
        expanded={expand}
        onChange={() => {
          setExpand(!expand);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="col-12 jd-header-section">
            <div className="name-section row no-gutters">
              <p className="col-auto name m-0">Advanced Filter</p>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="container h-100 w-100">
            <div className="row align-items-center h-100 w-100">
              <div className="form-group col-12 m-0 text-right">
                <label className="label-sm">Casual</label>
                <Switch
                  checked={isStrict}
                  onChange={onModeChange}
                  color="secondary"
                  name="isStrict"
                />
                <label className="label-sm">Strict</label>
              </div>
              <form>
                <div className="row">
                  <div className="form-group col-12 mb-4">
                    <div className="card">
                      <div className="card-header">Category Detail</div>
                      <div className="card-body row">
                        <div className="form-group col-6 m-0">
                          <label className="label-sm">Category</label>
                          <Autocomplete
                            name="category-select"
                            placeholder="Select Category"
                            defaultOptions
                            isClearable={true}
                            isMulti={true}
                            options={categoryList}
                            filterOption={customFilter}
                            value={selectedCategory}
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={categoryChanges}
                          />
                        </div>
                        <div className="form-group col-6 m-0">
                          <label className="label-sm">Roles</label>
                          <Autocomplete
                            name="roles-select"
                            placeholder="Select Roles"
                            defaultOptions
                            isClearable={true}
                            isMulti={true}
                            options={roleList}
                            filterOption={customFilter}
                            isDisabled={
                              selectedCategory && selectedCategory.length > 0
                                ? false
                                : true
                            }
                            value={selectedRole}
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={roleChanges}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-6 mb-4">
                    <div className="card">
                      <div className="card-header">Qualification Detail</div>
                      <div className="card-body">
                        <div className="form-group m-0">
                          <label className="label-sm">Qualification</label>
                          <Autocomplete
                            name="qualification-select"
                            placeholder="Select Qualification"
                            defaultOptions
                            isClearable={true}
                            isMulti={true}
                            options={qualificationList}
                            filterOption={customFilter}
                            value={selectedQual}
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={qualificationChanges}
                          />
                        </div>
                        <div className="form-group m-0">
                          <label className="label-sm">Course</label>
                          <Autocomplete
                            name="course-select"
                            placeholder="Select Course"
                            defaultOptions
                            isMulti={true}
                            isClearable={true}
                            filterOption={customFilter}
                            options={courseList}
                            isDisabled={
                              selectedQual && selectedQual.length > 0
                                ? false
                                : true
                            }
                            value={selectedCourse}
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={courceChanges}
                          />
                        </div>
                        <div className="form-group m-0">
                          <label className="label-sm">Specialization</label>
                          <Autocomplete
                            name="Speci-select"
                            placeholder="Select Specialization"
                            defaultOptions
                            isMulti={true}
                            isClearable={true}
                            filterOption={customFilter}
                            options={speciList}
                            isDisabled={
                              selectedCourse && selectedCourse.length > 0
                                ? false
                                : true
                            }
                            value={selectedSpeci}
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={speciChanges}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-6 mb-4">
                    <div className="card">
                      <div className="card-header">Location Detail</div>
                      <div className="card-body">
                        <div className="form-group m-0">
                          <label className="label-sm">State</label>
                          <Autocomplete
                            name="state-select"
                            placeholder="Select State"
                            defaultOptions
                            isClearable={true}
                            isMulti={true}
                            options={stateList}
                            filterOption={customFilter}
                            value={selectedState}
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={onStateChange}
                          />
                        </div>
                        <div className="form-group m-0">
                          <label className="label-sm">City</label>
                          <Autocomplete
                            name="city-select"
                            placeholder="Select City"
                            defaultOptions
                            isClearable={true}
                            isMulti={true}
                            options={cityList}
                            filterOption={customFilter}
                            value={selectedCity}
                            isDisabled={
                              selectedState && selectedState.length > 0
                                ? false
                                : true
                            }
                            getOptionLabel={(option) => {
                              return (
                                <div className="row autocomplete-item no-gutters">
                                  <div className="text col p-1 text-truncate">
                                    <div>{option.name}</div>
                                  </div>
                                </div>
                              );
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={onCityChange}
                          />
                        </div>
                        <div className="form-group m-0">
                          <label className="label-sm">Town</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="For multiple search give comma seperated values"
                            id="town"
                            name="town"
                            value={town}
                            onChange={(event) => {
                              setTown(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-12 mb-4">
                    <div className="card">
                      <div className="card-header">Experience & Salary</div>
                      <div className="card-body">
                        <div className="row">
                          <div className="form-group col-6 mt-4">
                            <label className="label-sm mb-3 mr-4">
                              Salary is negotiable
                            </label>
                            <div
                              className="switch"
                              onClick={isNegotiableChange}
                            >
                              {isCtcNegotiable ? (
                                <input
                                  type="checkbox"
                                  name="isCtcNegotiable"
                                  id="isCtcNegotiable"
                                  checked
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  name="isCtcNegotiable"
                                  id="isCtcNegotiable"
                                />
                              )}
                              <span className="slider round"></span>
                            </div>
                          </div>
                          <div className="form-group col-6 m-0">
                            <label className="label-sm">Maximum Salary</label>
                            <input
                              type="number"
                              className="form-control"
                              name="maximumCtc"
                              id="maximumCtc"
                              onChange={(event) => {
                                setMaximumCtc(event.target.value);
                              }}
                              value={maximumCtc}
                              disabled={isCtcNegotiable}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-6 m-0">
                            <label className="label-sm">
                              Minimum Experience
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="minimumExperience"
                              id="minimumExperience"
                              onChange={(event) => {
                                setMinimumExperience(event.target.value);
                              }}
                              value={minimumExperience}
                            />
                          </div>
                          <div className="form-group col-6 m-0">
                            <label className="label-sm">
                              Maximum Experience
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="maximumExperience"
                              id="maximumExperience"
                              onChange={(event) => {
                                setMaximumExperience(event.target.value);
                              }}
                              value={maximumExperience}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 action-btn text-center">
                    <button
                      type="button"
                      className="btn  btn-outline-secondary login-btn mr-3"
                      onClick={closeAccordian}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn  btn-primary login-btn"
                      onClick={onSubmitForm}
                      disabled={applyLoader}
                    >
                      {applyLoader ?
                        <div class="spinner-border-1_3 white-color" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        : 'Apply'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <div className="list-card list-view" id="candidate-list-view">
        <ul className="list-container m-0">
          <li className="list border-0 m-0">
            <div className="container-fluid">
              <div
                className="row pb-3 filter-row"
                style={{ alignItems: "center" }}
              >
                <div className="col-3">
                  <h5>Matched Profiles</h5>
                </div>
                <div className="col-4">
                  <div className="form-group m-0">
                    <div className="d-flex justify-content-center">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search Here"
                        onChange={onSearch}
                      />
                      <SearchIcon className="search-icon"></SearchIcon>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <FormControl variant="outlined" className="w-100">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Order By
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={filterValue}
                      onChange={(event) => onFilterChange(event)}
                      label="Tenure"
                    >
                      <MenuItem value="active">Last Active On</MenuItem>
                      <MenuItem value="relevance">Relevance</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-2 text-center p-0">
                  {paginationObj.totalCount && candidateList.data ? (
                    <span>
                      {candidateList.data.length} of {paginationObj.totalCount}{" "}
                      Users{" "}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul className="list-container search-cand-list" onScroll={handleScroll}>
          <div className="container">
            <div className="row">
              {candidateList.data && candidateList.data.length ? (
                candidateList.data.map((item) => {
                  return (
                    <CandidateListView
                      list={item}
                      key={item.id}
                      onRowChange={selectedRowChange}
                      callAction={performAction}
                    >
                      {" "}
                    </CandidateListView>
                  );
                })
              ) : (
                <h4 className="text-center no-cand">No Candidates Found</h4>
              )}
              {listLoading ? (
                <li className="List text-center">
                  <div
                    className="spinner-border text-primary table-loader"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </li>
              ) : (
                ""
              )}
            </div>
          </div>
        </ul>
      </div>
      <SuccessToaster
        data={successObj}
        onClose={() => setSuccessObj({ show: false, success: {} })}
      ></SuccessToaster>
      <ErrorToaster
        data={errorObj}
        onClose={() => setErrorObj({ show: false, msg: "" })}
      ></ErrorToaster>
      <ConfirmModal
        data={confirmationObj}
        onConfirmAction={doAction}
        onCancel={closePopup}
      ></ConfirmModal>
      <ErrorModal
        data={errModel}
        onConfirmAction={() => setErrorModal({ show: false, error: {} })}
      ></ErrorModal>
      <SuccessModal
        data={successModel}
        onConfirmAction={() => setSuccessModal({ show: false, success: {} })}
      ></SuccessModal>
    </div>
  );
};

export default Main;
