import React from "react";
import ReactDOM from "react-dom";
import "./style/main.scss";
import App from "./app/App";

ReactDOM.render(<App />, document.getElementById("root"));

// // Check if HMR interface is enabled
// if (module.hot) {
//   // Accept hot update
//   console.log("HMR are enabled");
//   module.hot.accept();
// }
