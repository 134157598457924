import React, { useState, useEffect } from "react";
import UseForm from "../../service/FormService";
import ManageService from "../../service/ManageService";
import CandidateService from "../../service/CandidateService";
import Checkbox from "../../component/Checkbox";
import Autocomplete from "react-select";
import $ from "jquery";
import { ErrorToaster } from "../../component/modal/ErrorModal";

const emailRegx =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const stateSchema = {
  organizationName: { value: "", error: "" },
  personName: { value: "", error: "" },
  designation: { value: "", error: "" },
  contactNumber: { value: "", error: "" },
  contactEmail: { value: "", error: "" },
  service: { value: "", error: "" },
  state: { value: "", error: "" },
  city: { value: "", error: "" },
  address: { value: "", error: "" },
  query: { value: "", error: "" },
  isQueryResolved: { value: false, error: "" },
  note: { value: "", error: "" },
};

const validationStateSchema = {
  organizationName: {
    required: true,
  },
  personName: {
    required: true,
  },
  designation: {
    required: true,
  },
  contactNumber: {
    required: true,
    validator: {
      regEx: /^([0-9]{10})$/,
      error: "Invalid phone number format.",
    },
  },
  contactEmail: {
    required: false,
    validator: {
      regEx: emailRegx,
      error: "Invalid Email",
    },
  },
  service: {
    required: true,
  },
  state: {
    required: true,
  },
  city: {
    required: true,
  },
  address: {
    required: true,
  },
  query: {
    required: true,
  },
  isQueryResolved: {
    required: false,
  },
  note: {
    required: false,
  },
};

const AddLead = (props) => {
  const [visible, setVisible] = useState(props.data.show || false);
  const [intialSchema, setInitionalSchema] = useState(
    $.extend(true, {}, stateSchema)
  );
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });
  const [queryResolved, setQueryResolved] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setVisible(props.data.show);
    if (!props.data.show) {
      setInitionalSchema($.extend(true, {}, stateSchema));
      setErrorObj({ show: false, msg: "" });
    } else {
      getStateList();
      setIsUpdating(props.data.lead && props.data.lead.id ? true : false);
      if (props.data.lead && props.data.lead.id) {
        getLeadById();
      }
    }
  }, [props]);

  const updateInitialData = (lead) => {
    let schema = $.extend(true, {}, stateSchema);
    schema.organizationName.value = lead.organizationName;
    schema.personName.value = lead.personName;
    schema.designation.value = lead.designation;
    schema.contactNumber.value = lead.contactNumber;
    schema.contactEmail.value = lead.contactEmail;
    schema.service.value = lead.service;
    schema.state.value = lead.state;
    schema.city.value = lead.city;
    schema.address.value = lead.address;
    schema.query.value = lead.query;
    schema.isQueryResolved.value = lead.isQueryResolved;
    setInitionalSchema(schema);
    setQueryResolved(lead.isQueryResolved);
  };

  const getLeadById = async () => {
    try {
      const response = await ManageService.getLeadById(props.data.lead.id);
      console.log(response.data.result);
      updateInitialData(response.data.result);
    } catch (err) {
      //setLoading(false);
    }
  };

  const getCityList = async (stateId) => {
    try {
      const response = await CandidateService.getCityList(stateId);
      setCityList(response.data.result);
    } catch (err) {
      //setLoading(false);
    }
  };
  const getStateList = async () => {
    try {
      const response = await CandidateService.getStateList("active");
      setStateList(response.data.result);
    } catch (err) {
      //setLoading(false);
    }
  };

  const stateChanges = (data) => {
    handleOnChange({
      target: {
        name: "state",
        value: data ? { id: data.id, name: data.name } : "",
      },
    });
    handleOnChange({ target: { name: "city", value: "" } });
    if (data && data.id) getCityList(data.id);
  };

  const cityChanges = (data) => {
    handleOnChange({
      target: {
        name: "city",
        value: data ? { id: data.id, name: data.name } : "",
      },
    });
  };

  const customFilter = (option, inputValue) => {
        if(option.data.name && inputValue){
            return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        }
        return true;
    };

  const hideModal = () => {
    setVisible(false);
    props.onCancel();
  };
  const closeToast = (e) => {
    e.preventDefault();
    setErrorObj({ show: false, msg: "" });
  };

  const isQueryChangeHndler = (data) => {
    handleOnChange({
      target: { name: "isQueryResolved", value: data },
    });
    setQueryResolved(data);
  };

  const onSubmitForm = (state) => {
    const requestPayload = {
      organizationName: state.organizationName.value,
      personName: state.personName.value,
      designation: state.designation.value,
      contactNumber: state.contactNumber.value,
      contactEmail: state.contactEmail.value,
      service: state.service.value,
      state: state.state.value.id,
      city: state.city.value.id,
      address: state.address.value,
      query: state.query.value,
    };
    state.note.value ? (requestPayload.note = state.note.value) : "";

    if (isUpdating) {
      requestPayload.isQueryResolved = state.isQueryResolved.value;
      ManageService.updateLead(props.data.lead.id, requestPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            props.onConfirmAction(res.data.result);
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    } else {
      ManageService.createLead(requestPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            props.onConfirmAction(res.data.result);
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    }
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    intialSchema,
    validationStateSchema,
    onSubmitForm
  );

  return (
    <div
      className="overlay "
      id="lead-model"
      style={{ display: visible ? "block" : "none" }}
    >
      <div className="overlay-content">
        <div className={visible ? " cs-modal showModal" : "cs-modal hideModal"}>
          <div className="header">
            {props.data.lead && props.data.lead.id ? "Update lead" : "Add lead"}
          </div>
          <div className="body">
            <div className="lead-container">
              <form name="add-lead">
                <div className="row">
                  <div className="form-group col-6 m-0">
                    <label className="label-sm">
                      Organization  Name <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="organizationName"
                      id="organizationName"
                      onChange={handleOnChange}
                      value={state.organizationName.value}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.organizationName.error
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      {state.organizationName.error}
                    </small>
                  </div>
                  <div className="form-group col-6 m-0">
                    <label className="label-sm">
                      Person Name <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="personName"
                      id="personName"
                      onChange={handleOnChange}
                      value={state.personName.value}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.personName.error
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      {state.personName.error}
                    </small>
                  </div>
                  <div className="form-group col-6 m-0">
                    <label className="label-sm">
                      Designation <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="designation"
                      id="designation"
                      onChange={handleOnChange}
                      value={state.designation.value}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.designation.error
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      {state.designation.error}
                    </small>
                  </div>
                  <div className="form-group col-6 m-0">
                    <label className="label-sm">
                      Contact Number <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="contactNumber"
                      id="contactNumber"
                      onChange={handleOnChange}
                      value={state.contactNumber.value}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.contactNumber.error
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      {state.contactNumber.error}
                    </small>
                  </div>

                  <div className="form-group col-6 m-0">
                    <label className="label-sm">
                      Contact Email <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="contactEmail"
                      id="contactEmail"
                      onChange={handleOnChange}
                      value={state.contactEmail.value}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.contactEmail.error
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      {state.contactEmail.error}
                    </small>
                  </div>
                  <div className="form-group col-6 m-0">
                    <label className="label-sm">
                      Service <sup className="required">*</sup>
                    </label>
                    <select
                      type="text"
                      className="form-control"
                      name="service"
                      id="service"
                      onChange={handleOnChange}
                      value={state.service.value}
                    >
                      <option value="">Select Service</option>
                      <option value="JOB POSTING SERVICES">
                        JOB POSTING SERVICES
                      </option>
                      <option value="STAFFING SERVICES">
                        STAFFING SERVICES
                      </option>
                      <option value="EQUIPMENT PURCHASE">
                        EQUIPMENT PURCHASE
                      </option>
                    </select>
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.service.error ? "visible" : "hidden",
                      }}
                    >
                      {state.service.error}
                    </small>
                  </div>
                  <div className="form-group col-6 m-0">
                    <label className="label-sm">
                      State <sup className="required">*</sup>
                    </label>
                    <Autocomplete
                      name="State"
                      placeholder="Select State"
                      defaultOptions
                      isClearable={true}
                      filterOption={customFilter}
                      options={stateList}
                      value={state.state.value ? state.state.value : null}
                      getOptionLabel={(option) => {
                        return (
                          <div className="row autocomplete-item no-gutters">
                            <div className="text col p-1 text-truncate">
                              <div>{option.name}</div>
                            </div>
                          </div>
                        );
                      }}
                      getOptionValue={(option) => {
                        return option.id;
                      }}
                      onChange={stateChanges}
                    />
                    <small
                      className="form-text  error-text m-0"
                      style={{
                        visibility: state.state.error ? "visible" : "hidden",
                      }}
                    >
                      {state.state.error}
                    </small>
                  </div>
                  <div className="form-group col-6 m-0">
                    <label className="label-sm">
                      City <sup className="required">*</sup>
                    </label>
                    <Autocomplete
                      name="city"
                      placeholder="Select City"
                      defaultOptions
                      isClearable={true}
                      isDisabled={
                        state.state.value && state.state.value.id ? false : true
                      }
                      filterOption={customFilter}
                      options={cityList}
                      value={state.city.value ? state.city.value : null}
                      getOptionLabel={(option) => {
                        return (
                          <div className="row autocomplete-item no-gutters">
                            <div className="text col p-1 text-truncate">
                              <div>{option.name}</div>
                            </div>
                          </div>
                        );
                      }}
                      getOptionValue={(option) => {
                        return option.id;
                      }}
                      onChange={cityChanges}
                    />
                    <small
                      className="form-text  error-text m-0"
                      style={{
                        visibility: state.city.error ? "visible" : "hidden",
                      }}
                    >
                      {state.city.error}
                    </small>
                  </div>
                  <div className="form-group col-6 m-0">
                    <label className="label-sm">
                      Address <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      id="address"
                      onChange={handleOnChange}
                      value={state.address.value}
                    />
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.address.error ? "visible" : "hidden",
                      }}
                    >
                      {state.address.error}
                    </small>
                  </div>
                  {isUpdating && (
                    <div className="form-group col-6 m-0 align-items-center d-flex">
                      <label className="label-sm mr-4 ">
                        is Query Resolved
                      </label>
                      <div
                        className="switch"
                        onClick={() => isQueryChangeHndler(!queryResolved)}
                      >
                        {queryResolved ? (
                          <input
                            type="checkbox"
                            name="isHotJobTrue"
                            id="isHotJob"
                            value={queryResolved}
                            checked={queryResolved}
                            onChange={() => {}}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            name="isHotJobFalse"
                            id="isHotJob"
                            value={queryResolved}
                            checked={queryResolved}
                            onChange={() => {}}
                          />
                        )}
                        <span className="slider round"></span>
                      </div>
                    </div>
                  )}
                  {state.isQueryResolved.value && (
                    <div className="form-group col-12 m-0">
                      <label className="label-sm">
                        Resolve Note <sup className="required">*</sup>
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="note"
                        id="note"
                        onChange={handleOnChange}
                        value={state.note.value}
                      ></textarea>
                      <small
                        className="form-text  error-text my-1"
                        style={{
                          visibility: state.note.error ? "visible" : "hidden",
                        }}
                      >
                        {state.note.error}
                      </small>
                    </div>
                  )}
                  <div className="form-group col-12 m-0">
                    <label className="label-sm">
                      Query <sup className="required">*</sup>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="query"
                      id="query"
                      onChange={handleOnChange}
                      value={state.query.value}
                    ></textarea>
                    <small
                      className="form-text  error-text my-1"
                      style={{
                        visibility: state.query.error ? "visible" : "hidden",
                      }}
                    >
                      {state.query.error}
                    </small>
                  </div>
                </div>
              </form>
            </div>
            <div className="footer">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary mr-3"
                onClick={hideModal}
              >
                cancel
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-primary"
                disabled={disable}
                onClick={handleOnSubmit}
              >
                Procced
              </button>
            </div>
          </div>
        </div>
      </div>
      <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
    </div>
  );
};

export default AddLead;
