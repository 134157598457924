import React from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import Carousel from "react-multi-carousel";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Slider from "react-slick";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import BusinessIcon from '@material-ui/icons/Business';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <ArrowBackIosIcon style={{ fontSize: '18px' }} />
    </div>
  );
}

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon style={{ fontSize: '18px' }} />
    </div>
  );
}

const VideoCard = ({ data: videoDetails }) => {
  const description = videoDetails.video.originalName.split('.')[0] || "";
  return (
    <div className="video-card">
      <div className="video-card-body">
        <div className="video-section">
          <div className="section d-flex justify-content-center embed-responsive embed-responsive-16by9">
            <video className="embed-responsive-item" controls>
              <source src={videoDetails.video.href} type="video/mp4"></source>
            </video>
          </div>
        </div>
        <div className="video-content">
          <PlayCircleFilledIcon className="video-icon"></PlayCircleFilledIcon>
          <div className="container-fluid pt-3 px-0">
            <div className="row no-gutters">
              <div className="col-12 row no-gutters video-client-section">
                <div className="col-auto">
                  <BusinessIcon className="client-icon"></BusinessIcon>
                </div>
                <div className="col text-truncate client-label">{videoDetails.client}</div>
              </div>
              <div className="col-12 video-client-description">
                {description.length > 86 ? description.subString(0, 86) + '...' : description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


const VideoSection = ({ data: videoList }) => {
  const settings = {
    className: "center",
    infinite: true,
    speed: 500,
    rows: 1,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    dots: true,
    arrows: false,
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "transparent",
          padding: "10px",
          bottom: "-50px"
        }}
      >
        <ul className="custom-dots-list" style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div
        style={{
          width: "13px",
          height: "13px",
          border: "2px solid #8860D0",
          borderRadius: "50%"
        }}
      >
      </div>
    ),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 910, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 615, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div id="videos-section" className="py-4">
      <div className="container py-4">
        <div className="row section-header text-center py-4">
          <h2 className="col-md-12">Videos</h2>
          <p className="col-md-12">
            Latest video from our client.
          </p>
        </div>
        <Slider {...settings}>
          {videoList?.length && videoList.map((video, index) => {
            return (
              <VideoCard data={video} key={index}></VideoCard>
            )
          })}
        </Slider>
      </div>
    </div>
  );
};

export default VideoSection;
