import React, { useState, useEffect } from "react";
import HomePageService from "../../service/HomePageService";
import CheckboxV2 from "../CheckboxV2";
import { Grid, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

const SelectLocation = (props) => {
    const [visible, setVisible] = useState(props.data.show || false);
    const [dropdownList, setDropDownList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [listLoading, setListLoading] = useState(false);
    const windowWidth = window.innerWidth;
    const [rowCount, setRowCount] = useState(0);
    const [columnCount, setColumnCount] = useState( windowWidth >= 768 ? 2 : 1)

    const cache = new CellMeasurerCache({
        defaultWidth: 250,
        fixedWidth: true,
        defaultHeight: 30
    });

    useEffect(() => {
        setVisible(props.data.show);
        if (!props.data.show) {
            setDropDownList([]);
            setSelectedList([]);
        } else {
            setListLoading(true);
            setSelectedList([...props.data.location]);
        }
    }, [props]);

    useEffect(() => {
        if (visible) {
            getLocationList();
        }
    }, [selectedList])

    const getLocationList = async () => {
        const response = await HomePageService.getSelectedLocation(selectedList, true);
        if (response.status === 200) {
            const list = [];
            response.data.result.checked.forEach((item) => {
                list.push({ label: item, checked: true })
            })
            response.data.result.uncheckedVals.forEach((item) => {
                list.push({ label: item, checked: false })
            })
            setRowCount(windowWidth >= 768  ? Math.floor(list.length / 2) : list.length);
            setDropDownList([...list]);
            setListLoading(false);
        }
    };

    const hideModal = () => {
        setVisible(false);
        props.onCancel();
    };

    const onSubmitForm = () => {
        if(props.onConfirmAction){
            const selectedList = [];
            dropdownList.forEach(item => {
                if(item.checked){
                    selectedList.push(item.label);
                }
            })
            props.onConfirmAction(selectedList);
        }
    };

    const onLocationSelectionChange = (event, location) => {
        const locations = [...dropdownList];
        locations.forEach(loc => {
        if(loc.label === location){
            loc.checked = event;
        }
        })
        setDropDownList([...locations]);
    }

    const rowRenderer = ({
        columnIndex, key, parent, rowIndex, style
      }) => {
        const index = windowWidth >= 768 ? ((2 * rowIndex) +  columnIndex): rowIndex;
        return (
        <CellMeasurer
            key={key}
            cache={cache}
            parent={parent}
            columnIndex={columnIndex}
            rowIndex={rowIndex}>
                {({measure, registerChild}) => (
                    <div className=" d-flex py-1 pl-4" ref={registerChild} key={key} style={style}>
                        <CheckboxV2 value={dropdownList[index].checked}
                            classes="select-popup-checkbox"
                            name={`location-filter-${index}`}
                            size={16}
                            change={(event) => onLocationSelectionChange(event, dropdownList[index].label)}>
                        </CheckboxV2>
                        <label className="select-popup-label pl-2" >{dropdownList[index].label}</label>
                    </div>
                )}
        </CellMeasurer>
           
        );
      }

    return (
        <div
            className="overlay "
            id="select-location-model"
            style={{ display: visible ? "block" : "none" }}
        >
            <div className="overlay-content">
                <div className={visible ? " cs-modal showModal" : "cs-modal hideModal"}>
                    <div className="header">
                        Location Filter
                    </div>
                    <div className="body">
                        <div className="select-location-model-container">
                            {
                                listLoading ? 
                                (
                                    <div className='list-loader-wrapper  position-absolute w-100'>
                                        <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                                            <div className='font-weight-bold loader-text'>Loading Data </div>
                                            <div className="spinner-grow spinner-grow-sm-loader" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <div className="spinner-grow spinner-grow-sm-loader" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            <div className="spinner-grow spinner-grow-sm-loader" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                ):(
                                    <div className="row align-items-center">
                                        {dropdownList && dropdownList.length ? 
                                        <AutoSizer disableHeight>
                                            {({width}) => (
                                                <Grid
                                                    width={width} 
                                                    height={300}
                                                    columnCount={columnCount}
                                                    rowCount={rowCount}
                                                    deferredMeasurementCache={cache}
                                                    columnWidth={cache.columnWidth}
                                                    rowHeight={cache.rowHeight}
                                                    cellRenderer={rowRenderer} />
                                            )}
                                        </AutoSizer>
                                        :'' }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="footer">
                        <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary mr-3"
                            onClick={hideModal}
                        >
                            cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-sm btn-outline-primary"
                            onClick={onSubmitForm}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectLocation;
