import React, {useState, useEffect} from "react";
import ReactHtmlParser from "react-html-parser";
import ManageService from "../../service/ManageService";
import Loader from "../../component/Loader";

const TermsConnditions = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getTermsAndCondition();
    },[]);

    const getTermsAndCondition = async () => {
        try{
            const response = await ManageService.getTermsConditionDataInWebsite();
            if (response.status === 200) {
                setData(response.data.result?.description || '');
            }
            setLoading(false);
        }catch(e){
            console.log(e);
            setLoading(false);
        }

    }

    if (loading) return <Loader />;

    return (
        <div id="terms-and-condition">
            <div className="d-flex p-4">
                {/* <label className="text-center ">Terms and Conditions</label> */}
                <p className="py-4" style={{"word-break": "keep-all","text-align": "justify"}}>{ReactHtmlParser(data)}</p>
            </div>
        </div>
    );

}

export default TermsConnditions;