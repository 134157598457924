import React,{useState} from 'react';

const ConfirmModal = (props) => {
    const [visible,setVisible] = useState(props.data.show);

    const hideModal = () =>{
        setVisible(false);
        props.onCancel();
    }
    const onConfirm =() =>{
        props.onConfirmAction(props.action)
    }

    return (
        <div className="overlay" style={{'display':props.data.show ?'block':'none'}}>
            <div className="overlay-content">
                <div className={props.data.show ? " cs-modal showModal":"cs-modal hideModal"} >
                    <div className="header">Are you sure?</div>
                    <div className="body">
                        <p className="body-text">{props.data.msg}</p>
                        <p className="text-muted info-msg">If you want to proceed then click on proceed button and for cancel this action click on cancel button</p>
                    </div>
                    <div className="footer">
                        <button type="button" className="btn btn-sm btn-outline-secondary mr-3" onClick={hideModal}>cancel</button>
                        <button type="button" className="btn btn-sm btn-outline-primary" onClick={onConfirm}>Proceed</button>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default ConfirmModal;