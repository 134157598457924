import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import LocalStorageService from "./../../service/LocalstorageService";
import CategoryDetails from "./CategoryDetails";
import RoleDetails from "./RoleDetails";
import StateList from "./StateDetails";
import CityList from "./CityDetails";
import QualificationList from "./QualificationDetails";
import CourseList from "./CourseDetails";
import SpecList from "./SpecificationDetails";
import StaffRole from "./StaffRole";
import Plans from "./Plans";
import Leads from "./Leads";
import Equipments from "./Equipments";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import TermsConditions from "./TermsConditions";

const localStorage = LocalStorageService.getService();

const TabList = {
  "Subscription Plan Management": [{ name: "Plans", value: "plans" }],
  "Qualification Management": [
    { name: "Qualification", value: "qualification" },
    { name: "Course", value: "course" },
    { name: "Specialization", value: "specialization" },
  ],
  "Category Role Management": [
    { name: "Category", value: "category" },
    { name: "Role", value: "role" },
  ],
  "Staff Role Management": [{ name: "Staff Role", value: "staff_role" }],
  "Location Management": [
    { name: "State", value: "state" },
    { name: "City", value: "city" },
  ],
  "Leads Management": [{ name: "Leads", value: "leads" }],
  "Equipment Management": [{ name: "Equipments", value: "equipments" }],
  "Content Management": [
    { name: "About US", value: "about_us" },
    { name: "Contact US", value: "contact_us" },
    { name: "Terms & Conditions", value: "terms_conditions" },
  ],
};

const Main = (props) => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const dropdownRef = useRef(null);
  const userType = localStorage.getUserType();

  //console.log("props in manage",props);

  useEffect(() => {
    onRoleChange("", props.permission[0].name);
  }, []);

  const onRoleChange = (e, role) => {
    const newRole = role || e.currentTarget.dataset.role;
    e ? e.persist() : "";
    //console.log("onRoleChange",newRole,TabList[newRole][0].value);
    setSelectedRole(newRole);
    setSelectedTab(TabList[newRole][0].value);
    dropdownRef.current.innerText = newRole;
  };

  const onTabChange = (e) => {
    e.persist();
    //console.log(e,e.currentTarget.dataset);
    setSelectedTab(e.currentTarget.dataset.tabname);
  };
  return (
    <div className="content-wrapper m-0 p-0">
      <nav className="cs-navbar">
        <div className="cs-nav-content" id="manage-section">
          <div className="cs-navbar-nav ">
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle role-selection"
                type="button"
                id="role-dropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                ref={dropdownRef}
              >
                Select Role
              </button>
              <ul className="dropdown-menu" aria-labelledby="role-dropdown">
                {props?.permission.map((item, index) => {
                  return (
                    <li
                      className={`dropdown-item ${item.name === selectedRole ? "selected" : ""
                        }`}
                      key={index}
                      onClick={onRoleChange}
                      data-role={item.name}
                    >
                      {item.name}
                    </li>
                  );
                })}
                {userType === "Owner" && (
                  <li
                    className={`dropdown-item ${"Content Management" === selectedRole ? "selected" : ""
                      }`}
                    onClick={onRoleChange}
                    data-role="Content Management"
                  >
                    Content Management
                  </li>
                )}
              </ul>
            </div>
            {selectedRole === "Category Role Management" ? (
              <div
                className="btn-group"
                role="group"
                aria-label="Category Role Management"
              >
                <button
                  type="button"
                  className={`btn btn-custom ${"category" === selectedTab ? "active" : ""
                    }`}
                  data-tabname="category"
                  onClick={onTabChange}
                >
                  Category
                </button>
                <button
                  type="button"
                  className={`btn btn-custom ${"role" === selectedTab ? "active" : ""
                    }`}
                  data-tabname="role"
                  onClick={onTabChange}
                >
                  Role
                </button>
              </div>
            ) : (
              ""
            )}
            {selectedRole === "Location Management" ? (
              <div
                className="btn-group"
                role="group"
                aria-label="Location Management"
              >
                <button
                  type="button"
                  className={`btn btn-custom ${"state" === selectedTab ? "active" : ""
                    }`}
                  data-tabname="state"
                  onClick={onTabChange}
                >
                  State
                </button>
                <button
                  type="button"
                  className={`btn btn-custom ${"city" === selectedTab ? "active" : ""
                    }`}
                  data-tabname="city"
                  onClick={onTabChange}
                >
                  City
                </button>
              </div>
            ) : (
              ""
            )}
            {selectedRole === "Qualification Management" ? (
              <div
                className="btn-group"
                role="group"
                aria-label="Qualification Management"
              >
                <button
                  type="button"
                  className={`btn btn-custom ${"qualification" === selectedTab ? "active" : ""
                    }`}
                  data-tabname="qualification"
                  onClick={onTabChange}
                >
                  Qualification
                </button>
                <button
                  type="button"
                  className={`btn btn-custom ${"course" === selectedTab ? "active" : ""
                    }`}
                  data-tabname="course"
                  onClick={onTabChange}
                >
                  Course
                </button>
                <button
                  type="button"
                  className={`btn btn-custom ${"specialization" === selectedTab ? "active" : ""
                    }`}
                  data-tabname="specialization"
                  onClick={onTabChange}
                >
                  Specialization
                </button>
              </div>
            ) : (
              ""
            )}
            {selectedRole === "Staff Role Management" ? (
              <div
                className="btn-group"
                role="group"
                aria-label="Staff Role Management"
              >
                <button
                  type="button"
                  className={`btn btn-custom ${"staff_role" === selectedTab ? "active" : ""
                    }`}
                  data-tabname="staff_role"
                  onClick={onTabChange}
                >
                  Staff Role
                </button>
              </div>
            ) : (
              ""
            )}
            {selectedRole === "Subscription Plan Management" ? (
              <div
                className="btn-group"
                role="group"
                aria-label="Subscription Plan Management"
              >
                <button
                  type="button"
                  className={`btn btn-custom ${"plans" === selectedTab ? "active" : ""
                    }`}
                  data-tabname="plans"
                  onClick={onTabChange}
                >
                  Plans
                </button>
              </div>
            ) : (
              ""
            )}
            {userType === "Owner" && selectedRole === "Content Management" ? (
              <div
                className="btn-group"
                role="group"
                aria-label="Content Management"
              >
                <button
                  type="button"
                  className={`btn btn-custom ${"about_us" === selectedTab ? "active" : ""
                    }`}
                  data-tabname="about_us"
                  onClick={onTabChange}
                >
                  About Us
                </button>
                <button
                  type="button"
                  className={`btn btn-custom ${"contact_us" === selectedTab ? "active" : ""
                    }`}
                  data-tabname="contact_us"
                  onClick={onTabChange}
                >
                  Contact US
                </button>
                <button
                  type="button"
                  className={`btn btn-custom ${"terms_conditions" === selectedTab ? "active" : ""
                    }`}
                  data-tabname="terms_conditions"
                  onClick={onTabChange}
                >
                  Terms & Conditions
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </nav>

      <div
        className="cs-nav-tabs"
        style={{ display: selectedTab === "category" ? "block" : "none" }}
      >
        <CategoryDetails selectedTab={selectedTab}></CategoryDetails>
      </div>
      <div
        className="cs-nav-tabs"
        style={{ display: selectedTab === "role" ? "block" : "none" }}
      >
        <RoleDetails selectedTab={selectedTab}></RoleDetails>
      </div>
      <div
        className="cs-nav-tabs"
        style={{ display: selectedTab === "state" ? "block" : "none" }}
      >
        <StateList selectedTab={selectedTab}></StateList>
      </div>
      <div
        className="cs-nav-tabs"
        style={{ display: selectedTab === "city" ? "block" : "none" }}
      >
        <CityList selectedTab={selectedTab}></CityList>
      </div>
      <div
        className="cs-nav-tabs"
        style={{ display: selectedTab === "qualification" ? "block" : "none" }}
      >
        <QualificationList selectedTab={selectedTab}></QualificationList>
      </div>
      <div
        className="cs-nav-tabs"
        style={{ display: selectedTab === "course" ? "block" : "none" }}
      >
        <CourseList selectedTab={selectedTab}></CourseList>
      </div>
      <div
        className="cs-nav-tabs"
        style={{ display: selectedTab === "specialization" ? "block" : "none" }}
      >
        <SpecList selectedTab={selectedTab}></SpecList>
      </div>
      <div
        className="cs-nav-tabs"
        style={{ display: selectedTab === "staff_role" ? "block" : "none" }}
      >
        <StaffRole selectedTab={selectedTab}></StaffRole>
      </div>
      <div
        className="cs-nav-tabs"
        style={{ display: selectedTab === "plans" ? "block" : "none" }}
      >
        <Plans selectedTab={selectedTab}></Plans>
      </div>
      <div
        className="cs-nav-tabs"
        style={{ display: selectedTab === "leads" ? "block" : "none" }}
      >
        <Leads selectedTab={selectedTab}></Leads>
      </div>
      <div
        className="cs-nav-tabs"
        style={{ display: selectedTab === "equipments" ? "block" : "none" }}
      >
        <Equipments selectedTab={selectedTab}></Equipments>
      </div>
      <div
        className="cs-nav-tabs"
        style={{ display: selectedTab === "contact_us" ? "block" : "none" }}
      >
        <ContactUs selectedTab={selectedTab}></ContactUs>
      </div>
      <div
        className="cs-nav-tabs"
        style={{ display: selectedTab === "about_us" ? "block" : "none" }}
      >
        <AboutUs selectedTab={selectedTab}></AboutUs>
      </div>
      <div
        className="cs-nav-tabs"
        style={{ display: selectedTab === "terms_conditions" ? "block" : "none" }}
      >
        <TermsConditions selectedTab={selectedTab}></TermsConditions>
      </div>
    </div>
  );
};

export default Main;
