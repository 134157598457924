import React, { useState, useEffect } from "react";
import UseForm from "../../service/FormService";
import ManageService from "../../service/ManageService";
import $ from "jquery";
import { ErrorToaster } from "../../component/modal/ErrorModal";
import Checkbox from "../../component/Checkbox";

const stateSchema = {
  name: { value: "", error: "" },
};

const validationStateSchema = {
  name: {
    required: true,
  },
};

const AddStaffRole = (props) => {
  const [visible, setVisible] = useState(props.data.show || false);
  const [appList, setAppList] = useState([]);
  const [intialSchema, setInitionalSchema] = useState(
    $.extend(true, {}, stateSchema)
  );
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });
  //const [permissionsList,setPermissionsList] = useState([])

  useEffect(() => {
    //console.log("props",props.data)
    setVisible(props.data.show);
    setAppList([]);
    if (!props.data.show) {
      setInitionalSchema($.extend(true, {}, stateSchema));
      setErrorObj({ show: false, msg: "" });
    } else {
      props.data.staffRole.id
        ? getRoleDetails(props.data.staffRole.id)
        : getAppList();
    }
  }, [props]);

  useEffect(() => {
    // console.log("appList changes", appList);
  }, [appList]);

  const getRoleDetails = async (id) => {
    try {
      const list = [];
      const res = await ManageService.getStaffRoleDetails(id);
      setInitionalSchema({ name: { value: res.data.result.name, error: "" } });
      res?.data?.result?.permissions.forEach((permission) => {
        if (!permission.isGeneric) list.push(permission);
      });
      setAppList(list);
    } catch (e) {
      setErrorObj({ show: true, msg: e.data.message });
      setTimeout(() => {
        setErrorObj({ show: false, msg: "" });
      }, 3000);
    }
  };

  const getAppList = async () => {
    try {
      const res = await ManageService.getAppList();
      const list = [];
      const updateFormat = {
        read: false,
        write: false,
        update: false,
        delete: false,
      };
      res.data.result.forEach((app) => {
        if (!app.isGeneric)
          list.push({ ...updateFormat, ...{ name: app.name, id: app.id } });
      });
      setAppList(list);
    } catch (e) {
      setErrorObj({ show: true, msg: e.data.message });
      setTimeout(() => {
        setErrorObj({ show: false, msg: "" });
      }, 3000);
    }
  };
  const hideModal = () => {
    setVisible(false);
    props.onCancel();
  };

  const closeToast = (e) => {
    e.preventDefault();
    setErrorObj({ show: false, msg: "" });
  };
  const onCheckBoxChange = (param, props) => {
    let list = [...appList];
    list[props.customIndex][props.customKey] = param;
    setAppList(list);
  };
  const getAppPermisionList = () => {
    return [...appList];
  };

  const onSubmitForm = (state) => {
    const requestPayload = {
      name: state.name.value,
    };
    const list = [];
    const appPermisionList = $.extend(true, [], getAppPermisionList());
    appPermisionList.forEach((app) => {
      list.push({
        appId: app.id,
        read: app.read,
        write: app.write,
        update: app.update,
        delete: app.delete,
      });
    });
    requestPayload.permissions = list;
    if (props.data.staffRole && props.data.staffRole.id) {
      ManageService.updateStaffRole(
        props.data.staffRole.id,
        requestPayload
      ).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            props.onConfirmAction(res.data.result);
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    } else {
      ManageService.createStaffRole(requestPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            props.onConfirmAction(res.data.result);
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    }
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    intialSchema,
    validationStateSchema,
    onSubmitForm
  );

  return (
    <div
      className="overlay "
      id="staffRole-model"
      style={{ display: visible ? "block" : "none" }}
    >
      <div className="overlay-content">
        <div className={visible ? " cs-modal showModal" : "cs-modal hideModal"}>
          <div className="header">
            {props.data.staffRole && props.data.staffRole.id
              ? "Update Role"
              : "Add Role"}{" "}
          </div>
          <div className="body">
            <div className="staffRole-container">
              <form>
                <div className="row">
                  <div className="form-group col-12 m-0">
                    <label className="label-sm">
                      Role Name <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      onChange={handleOnChange}
                      value={state.name.value}
                    />
                    <small
                      className="form-text  error-text m-0"
                      style={{
                        visibility: state.name.error ? "visible" : "hidden",
                      }}
                    >
                      {state.name.error}
                    </small>
                  </div>
                </div>
                <div className="row">
                  <p className="col-12 app-permision-header">
                    App Permision for this role
                  </p>
                </div>
                <div id="app-list">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">App Name</th>
                        <th scope="col">Read</th>
                        <th scope="col">Write</th>
                        <th scope="col">Update</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {appList.map((app, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{app.name}</th>
                            <td>
                              <Checkbox
                                value={app.read}
                                classes={"staff-list-checkbox"}
                                customIndex={index}
                                customKey="read"
                                change={onCheckBoxChange}
                              ></Checkbox>
                            </td>
                            <td>
                              <Checkbox
                                value={app.write}
                                classes={"staff-list-checkbox"}
                                customIndex={index}
                                customKey="write"
                                change={onCheckBoxChange}
                              ></Checkbox>
                            </td>
                            <td>
                              <Checkbox
                                value={app.update}
                                classes={"staff-list-checkbox"}
                                customIndex={index}
                                customKey="update"
                                change={onCheckBoxChange}
                              ></Checkbox>
                            </td>
                            <td>
                              <Checkbox
                                value={app.delete}
                                classes={"staff-list-checkbox"}
                                customIndex={index}
                                customKey="delete"
                                change={onCheckBoxChange}
                              ></Checkbox>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>
          <div className="footer">
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary mr-3"
              onClick={hideModal}
            >
              cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary"
              disabled={disable}
              onClick={handleOnSubmit}
            >
              Procced
            </button>
          </div>
        </div>
      </div>
      <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
    </div>
  );
};

export default AddStaffRole;
