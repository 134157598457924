import React ,{useState} from 'react';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AuthService from '../../service/AuthService';
import {useHistory} from 'react-router-dom';
import ClientOverView from './Client';
import StaffOverView from './Staff';
import CandidateOverView from './Candidate';

const data = [
    {name: '1', subscription: 4000, expiration: 2400, amt: 2400},
    {name: '2', subscription: 3000, expiration: 1398, amt: 2210},
    {name: '3', subscription: 2000, expiration: 9800, amt: 2290},
    {name: '11', subscription: 4000, expiration: 2400, amt: 2400},
    {name: '12', subscription: 3000, expiration: 1398, amt: 2210},
    {name: '13', subscription: 2000, expiration: 9800, amt: 2290},
    {name: '21', subscription: 4000, expiration: 2400, amt: 2400},
    {name: '22', subscription: 3000, expiration: 1398, amt: 2210},
    {name: '23', subscription: 2000, expiration: 9800, amt: 2290},

];

const Staffdata = [
    {
      name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
    },
    {
      name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    },
    {
      name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    },
    {
      name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    },
    {
      name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    },
    {
      name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    },
    {
      name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },
  ];

const isLoggedIn = () =>{

    const history = useHistory();
    if(!AuthService.isLoggedIn()){
            history.push('/adm/login');
    }
}

const AdminDashboard = () => {
    const [selectedTab,setSelectedTab] = useState('Client');
    isLoggedIn();

    const onTabChange = (e) => {
        e.persist();
        setSelectedTab(e.currentTarget.dataset.tab);
    }
    return (
        <div className="content-wrapper">
            <div className="dashboard-container">
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <a className={`nav-item nav-link ${selectedTab === 'Client' ? 'active':''} `} id="nav-client-tab" data-tab="Client" onClick={onTabChange}> <BusinessIcon /> Client</a>
                        <a className={`nav-item nav-link ${selectedTab === 'Candidate' ? 'active':''} `} id="nav-candidate-tab" data-toggle="tab" data-tab="Candidate" onClick={onTabChange}> <PersonOutlineIcon /> Candidate</a>
                        <a className={`nav-item nav-link ${selectedTab === 'Staff' ? 'active':''} `} id="nav-staff-tab" data-toggle="tab" data-tab="Staff" onClick={onTabChange}><PersonIcon /> Staff</a>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className={`tab-pane ${selectedTab === 'Client' ? 'show active':'hide'} fade`} id="nav-client">
                       <ClientOverView data={data} visible={selectedTab === 'Client' ? true:false}></ClientOverView>   
                    </div>
                    <div className={`tab-pane ${selectedTab === 'Candidate' ? 'show active':'hide'} fade`} id="nav-candidate">
                       <CandidateOverView data={data} visible={selectedTab === 'Candidate' ? true:false}></CandidateOverView>   
                    </div>
                    <div className={`tab-pane ${selectedTab === 'Staff' ? 'show active':'hide'} fade`} id="nav-staff">
                       <StaffOverView data={Staffdata} visible={selectedTab === 'Staff' ? true:false}></StaffOverView>   
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default AdminDashboard;