import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import NoDataFound from "../../common/NoDataFound";
import Moment from 'react-moment';
import StaffService from "../../service/StaffService";
import Loader from "../../component/Loader";
import StaffIcon from "@material-ui/icons/Person";
import { StaffCardView, StaffListView } from "./List";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "../../component/Checkbox";
import ConfirmModal from "../../component/modal/ConfirmModal";
import ErrorModal from "../../component/modal/ErrorModal";
import SuccessModal from "../../component/modal/SuccessModal";
import WarningIcon from "@material-ui/icons/Warning";
import TableComponent from '../../common/tableComponent';
import LocalService from '../../service/LocalstorageService';
import ActionListIcon from '@material-ui/icons/MoreVert';


const NoData = (props) => {
  return (
    <div className="content-wrapper">
      <div className="content-container">
        <NoDataFound
          name={props.name}
          redirectUrl={props.redirectUrl}
        ></NoDataFound>
      </div>
    </div>
  );
};

const ColourList = [
  "#FA5181",
  "#54BBFE",
  "#41E122",
  "#FE8B57",
  "#713BDB",
  "#34BE19",
  "#3B86FF",
  "#594d6d",
  "#f0854f",
  "#713BDB",
];

const Main = () => {
  const { userType } = useParams();
  const [loading, setLoading] = useState(true);
  const [staffList, setStaffList] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [confirmationObj, setConfirmationObj] = useState({
    show: false,
    msg: "",
  });
  const [paginationObj, setPaginationObj] = useState({});
  const [currentAction, setCurrentAction] = useState("");
  const [errModel, setErrorModal] = useState({ show: false, error: {} });
  const [successModel, setSuccessModal] = useState({
    show: false,
    success: {},
  });
  const permissions = LocalService.getPermision().filter(perm => perm.name === 'Staff Management') || {};
  const perPageItem = 10;
  const [tableLoader, setTableLoader] = useState(false);
  const [tableError, setTableError] = useState(false);

  // Table config file
  const columns = [
    {
      label: "Display Name", value: "name", sortable: true,
      render: (value, index, list) => {
        return (
          <div className='d-flex align-items-center'>
            {
              list.profilePic && list.profilePic.href ?
                <img className="image-30" src={list.profilePic.href}></img> :
                <div className="no-image-28">{list.firstName.substr(0, 1)}</div>
            }
            <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/staff/view/${list.id}`} className="d-flex align-items-center">{`${list.firstName} ${list.middleName || ''} ${list.lastName}`} </Link>
          </div>
        )
      }

    },
    {
      label: "Email Address", value: "email", sortable: false,
      render: (value, index, list) => {
        return list.email || "NA"
      }
    },
    {
      label: "Phone No.", value: "contactNumber", sortable: false,
      render: (value, index, list) => {
        return list.contactNumber || "NA"
      }
    },
    {
      label: "Staff Role", value: "role", sortable: false,
      render: (value, index, list) => {
        return (
          <span className="badge badge-pill badge-info" title={list.role ? list.role.name : 'NA'}>
            <span className="text-truncate">{list.role ? list.role.name : 'NA'}</span>
          </span>
        )
      }
    },
    {
      label: "Status", value: "status", sortable: false,
      render: (value, index, list) => {
        return (
          list.isArchived ? <span className="badge badge-danger">Archived</span> : list.isAccessBlocked ? <span className="badge badge-warning">Inactive</span> : <span className="badge badge-success">Active</span>
        )
      }
    },
    {
      label: "Created On", value: "createdOn", sortable: false,
      render: (value, index, list) => {
        return <Moment date={list.createdOn} format={"DD MMM YYYY"}></Moment>
      }
    },
    {
      label: "Actions", value: "action", sortable: false,
      render: (value, index, list) => {
        return (
          getTableActionList(list)
        )
      }
    }
  ];

  const getTableActionList = (data) => {
    return (
      <div className="btn-group action">
        <ActionListIcon className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
        <ul className="dropdown-menu">
          <li className="dropdown-item"><Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/staff/view/${data.id}`}>View Profile</Link></li>
          {permissions[0]['update'] ? <li className="dropdown-item"><Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/staff/edit/${data.id}`}>Edit Profile</Link></li> : ''}
          {permissions[0]['update'] && !data.isAccessBlocked ? <li className="dropdown-item spl-link-buttn-css" data-value="deactivate" onClick={() => performAction("deactivate", [data.id])}>Deactivate Profile</li> : ''}
          {permissions[0]['update'] && data.isAccessBlocked ? <li className="dropdown-item spl-link-buttn-css" data-value="activate" onClick={() => performAction("activate", [data.id])}>Activate Profile</li> : ''}
          {permissions[0]['delete'] ? <li className="dropdown-item spl-red-buttn-css" data-value="delete" onClick={() => performAction("delete", [data.id])}>Delete Profile Permanently</li> : ''}
        </ul>
      </div>
    )
  }

  useEffect(() => {
    getStaffList();
  }, []);

  const getStaffList = async (search = "", offset = 10, page = 1, sortBy) => {
    try {
      const response = await StaffService.getStaffList(search, offset, page, sortBy);
      if (response.status === 200) {
        setStaffList(response.data.result);
        setPaginationObj(response.data.detail);
        setLoading(false);
        setTableLoader(false);
        setTableError(false);
      }
    } catch (e) {
      setStaffList([]);
      setPaginationObj({});
      setLoading(false);
      setTableLoader(false);
      setTableError(true);
    }
  };

  const searchBy = (searchParam) => {
    let tableConfig = { ...config, search: searchParam };
    getTableData(tableConfig.search, tableConfig.perPageItem, tableConfig.page, tableConfig.sortBy);
  }

  const getTableData = (search, offset, page, sortBy) => {
    setTableLoader(true);
    let tableConfig = { ...config, search, "perPageItem": offset, page, sortBy };
    setConfig(tableConfig);
    getStaffList(search, offset, page, sortBy);
  }

  const onRowSelectionChange = (list) => {
    setSelectedRow(list ? [...list]: null);
  }

  const performAction = (action, list) => {
    setCurrentAction({ action: action, data: list});
    setConfirmationObj({ show: true, msg: `You want to ${action.toUpperCase()} this staff?` });
  }

  let tableConfig = {
    pagination: true,
    showSelectRow: true,
    perPageItem: perPageItem,
    getTableData: getTableData,
    onRowSelection: onRowSelectionChange,
    onActionButton: performAction,
    actionList: ['edit', "delete", "activate", "deactivate"],
    search: "",
    sortBy: { name: "", type: "" },
    page: 1
  }

  const [config , setConfig] = useState({...tableConfig});


  const closePopup = () => {
    setConfirmationObj({ show: false, msg: "" });
    setCurrentAction("");
  };

  const doAction = async () => {
    setLoading(true);
    setConfirmationObj({ show: false, msg: "" });

    try {
      const res = await StaffService.updateStaffStatus(currentAction.action, {
        userIds: currentAction.data ? currentAction.data : selectedRow,
      });
      setCurrentAction({});
      setLoading(false);
      if (res.status === 200) {
        setTableLoader(true)
        getStaffList(config.search, config.perPageItem, config.page, config.sort);
        setSuccessModal({ show: true, success: { msg: res.data.result } });
      }
    } catch (err) {
      setLoading(false);
      if (err && err.data) {
        setErrorModal({ show: true, error: err.data });
      }
    }
  };

  if (loading) return <Loader />;

  if (staffList.totalStaff === 0) {
    return (
      <NoData name={"Staff"} redirectUrl={`/${userType}/staff/add`}></NoData>
    );
  }

  return (
    <div className="content-wrapper p-0" id="staff-list-container">
      <div className="container-fluid">
        <div className="row px-2 pt-2">
          <div className="col-12 d-flex count-summery p-0">
            <div className="col-4 px-0">
              <div className="card-counter primary" onClick={() => searchBy("")}>
                <StaffIcon></StaffIcon>
                <span className="count-numbers">{staffList.totalStaff}</span>
                <span className="count-name">Total Staffs</span>
              </div>
            </div>
            <div className="col-4 px-0">
              <div className="card-counter success" onClick={() => searchBy("Active")}>
                <StaffIcon></StaffIcon>
                <span className="count-numbers">{staffList.activeStaff}</span>
                <span className="count-name">Active Staffs</span>
              </div>
            </div>
            <div className="col-4 px-0">
              <div className="card-counter warning" onClick={() => searchBy("Inactive")}>
                <StaffIcon></StaffIcon>
                <span className="count-numbers">{staffList.inactiveStaff}</span>
                <span className="count-name">Inactive Staffs</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2 filter-row align-items-center">
          <div className="col-xl-2 col-lg-3 col-md-4 px-2">
            <h5 className="m-0">Staff List</h5>
          </div>
          <div className="col">
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4  px-2">
            {permissions[0]['write'] ? <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/staff/add`}>
              <button className="btn btn-primary btn-padding add-btn"> + Add Staff</button>
            </Link> : ''}
          </div>
        </div>
        <div className="list-card list-view" id="staff-list-view">
          <div className='list-container'>
            <TableComponent
              loading={tableLoader}
              error={tableError}
              columns={columns}
              data={staffList.data || []}
              config={config}
              paginationObj={paginationObj}>
            </TableComponent>
          </div>

        </div>
      </div>

      <ConfirmModal
        data={confirmationObj}
        onConfirmAction={doAction}
        onCancel={closePopup}
      ></ConfirmModal>
      <ErrorModal
        data={errModel}
        onConfirmAction={() => setErrorModal({ show: false, error: {} })}
      ></ErrorModal>
      <SuccessModal
        data={successModel}
        onConfirmAction={() => setSuccessModal({ show: false, success: {} })}
      ></SuccessModal>
    </div>
  );
};

export default Main;
