import React, { useState, useEffect } from "react";
import Countcard from "../component/Countcard";
import AuthService from "../service/AuthService";
import { useHistory, Link, useLocation } from "react-router-dom";
import WorkIcon from "@material-ui/icons/Work";
import PersonIcon from "@material-ui/icons/Person";
import NotificationsIcon from "@material-ui/icons/Notifications";
import StaffLogo from "../../assets/imgs/male-avatar.png";
import ClientService from "../service/ClientService";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import PublishIcon from "@material-ui/icons/Publish";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Moment from "react-moment";
import Loader from "../component/Loader";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import userImg from "./../../assets/imgs/male-avatar.png";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { SuccessToaster } from "../component/modal/SuccessModal";
import ErrorModal from "../component/modal/ErrorModal";
import WarningIcon from "@material-ui/icons/Warning";
import ProfileCompletionModal from "../component/modal/ProfileCompletionModal";

const isLoggedIn = () => {
  const history = useHistory();
  if (!AuthService.isLoggedIn()) {
    history.push("/cli/login");
  }
};

const percentage = 66;

const ClientDashboard = () => {
  const [dashboardCounts, setDashboardCounts] = useState(null);
  const [candidatesList, setCandidatesList] = useState([]);
  const [userDetail, setUserDetail] = useState(null);
  const [activityLog, setActivityLog] = useState([]);
  const [logChart, setLogChart] = useState([]);
  const [jobListTemplate, setJobListTemplate] = useState([]);
  const [candListTemplate, setCandListTemplate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jobsList, setJobsList] = useState([]);
  const [profilePercentage, setProfilePercentage] = useState(null);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [errorObj, setErrorObj] = useState({ show: false, error: {} });
  const [successObj, setSuccessObj] = useState({ show: false, success: {} });
  const [profileCompletionObj, setProfileCompletionObj] = useState({ show: false, error: {} });
  const location = useLocation();
  const userPrifix = location.pathname.split("/")[1];
  const [subscriptions, setSubscriptions] = useState([]);
  const [circularProgress, setCircularProgress] = useState([]);

  isLoggedIn();

  useEffect(() => {
    getCounts();
    getUserActivity();
    getJobs();
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUserDetail(user);
    }
  }, []);

  useEffect(() => {
    let candTemp = candidatesList.map((cand, i) => {
      return (
        <div className="job-list col-12 py-1 client-job-list" key={"cand" + i}>
          <div className="container-fluid px-0 candid_client_dash_bg">
            <div className="list-content col-12 row no-gutters align-items-center px-0">
              <div className="col-auto">
                <img
                  className="user-image"
                  src={
                    cand.candidate.personal.profilePic
                      ? cand.candidate.personal.profilePic.href
                      : userImg
                  }
                />
              </div>

              <div className="user-name col text-truncate px-3">
                <div>
                  <b className="cand_name">
                    {cand.candidate.personal.firstName +
                      " " +
                      cand.candidate.personal.lastName}
                  </b>
                  <span className="pl-2 cand_role">
                    |{" "}
                    {cand.candidate.summary.category
                      ? cand.candidate.summary.category.name
                      : "NA"}
                  </span>
                  <span className="pl-2 cand_role">
                    |{" "}
                    {cand.candidate.summary.role
                      ? cand.candidate.summary.role.name
                      : "NA"}
                  </span>
                  <span className="text-truncate date-right">
                    <span className="fs-14">Last Updated On : </span>
                    <b>
                      <span className="f-sm">
                        {getLastUpdated(cand.candidate.lastUpdated)} ago
                      </span>
                    </b>
                  </span>
                </div>
                <p className="m-0 cand_exp">
                  {cand.candidate.summary.text
                    ? cand.candidate.summary.text
                    : "NA"}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    });
    setCandListTemplate(candTemp);
  }, [candidatesList]);

  const getLastUpdated = (date) => {
    console.log(date);
    var today = new Date();
    var date = new Date(date);
    var seconds = Math.floor((today - date) / 1000);
    var interval;
    var lastUpdOn;
    if (Math.floor(seconds / 31536000) >= 1) {
      interval = Math.floor(seconds / 31536000);
      if (interval == 1) {
        lastUpdOn = interval + " year";
      } else {
        lastUpdOn = interval + " years";
      }
    } else if (Math.floor(seconds / 2592000) >= 1) {
      interval = Math.floor(seconds / 2592000);
      if (interval == 1) {
        lastUpdOn = interval + " month";
      } else {
        lastUpdOn = interval + " months";
      }
    } else if (Math.floor(seconds / 86400) >= 1) {
      interval = Math.floor(seconds / 86400);
      if (interval == 1) {
        lastUpdOn = interval + " day";
      } else {
        lastUpdOn = interval + " days";
      }
    } else if (Math.floor(seconds / 3600) >= 1) {
      interval = Math.floor(seconds / 3600);
      if (interval == 1) {
        lastUpdOn = interval + " hour";
      } else {
        lastUpdOn = interval + " hours";
      }
    } else if (Math.floor(seconds / 60) >= 1) {
      interval = Math.floor(seconds / 60);
      if (interval == 1) {
        lastUpdOn = interval + " minute";
      } else {
        lastUpdOn = interval + " minutes";
      }
    } else if (Math.floor(seconds) >= 1) {
      interval = Math.floor(seconds);
      if (interval == 1) {
        lastUpdOn = interval + " second";
      } else {
        lastUpdOn = interval + " seconds";
      }
    }
    return lastUpdOn;
  };

  const getCounts = async () => {
    setLoading(true);
    const response = await ClientService.getDashboardCounts();
    if (response.status === 200) {
      if (response["data"]["result"]["profileCompletion"] < 100) {
        setProfileCompletionObj({ show: true, error: { 'message': "Your Profile is only " + response["data"]["result"]["profileCompletion"] + "% completed till now." } });
      }
      setDashboardCounts(response["data"]["result"]["counts"]);
      setCandidatesList(response["data"]["result"]["candidates"]);
      setProfilePercentage(response["data"]["result"]["profileCompletion"]);
      setSubscriptions(response["data"]["result"]["activeSubscription"]);
      getSubscriptionTemplate(response["data"]["result"]["activeSubscription"]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getSubscriptionTemplate = (sub) => {
    if (sub.length) {
      let circularProgress = sub.map((sub, ind) => {
        return (
          <div className="col-6 text-center" key={"sub" + ind}>
            <div className="row plans_bg">
              <div className="col-6">
                <CircularProgressbar
                  value={sub.daysPercUtilised}
                  text={`${sub.daysPercUtilised}%`}
                  title={"Days Utilised"}
                />
                <p className="pt-2">
                  <b>{sub.plan.name}</b>
                </p>
              </div>
              <div className="col-6 text-left">
                <div className="sub-details">
                  <p>
                    <span className="fs-14">Started On : </span>{" "}
                    <b className="red-color">{sub.activateOn}</b>
                  </p>
                  <p>
                    <span className="fs-14">Expiry : </span>{" "}
                    <b className="red-color">{sub.expireOn}</b>
                  </p>
                  {/* <p><span className="fs-14">Days Left : </span> <b className="green-color">{sub.daysLeft}</b></p> */}
                  {/* <p><span className="fs-14">Job Post Balance Left : </span> <b className="green-color">{sub.jobPostBalanceLeft}</b></p> */}
                  <div className="row pl-3">
                    <div className="progress p-0 col-8 sub-progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: sub.jobPostBalanceLeft + "%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div className="col-4">
                      <b className="green-color job-post-left">
                        {sub.jobPostBalanceLeft}%
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
      setCircularProgress(circularProgress);
    }
  };

  const getUserActivity = async () => {
    setLoading(true);
    const response = await ClientService.getUserActivityLog();
    if (response.status === 200) {
      setActivityLog(response["data"]["result"]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getJobs = async () => {
    setLoading(true);
    const response = await ClientService.getJobPosts();
    if (response.status === 200) {
      setJobsList(response["data"]["result"]["data"]);
      setLoading(false);
      let jobs = response["data"]["result"]["data"].map((job, i) => {
        return (
          <li
            className={[
              "job-list col-12 py-1",
              job.isHotJob ? "isHotJob" : null,
            ].join(" ")}
            key={"job" + i}
          >
            <Link to={`/${userPrifix}/job-post/view/${job.id}`}>
              <div className="container-fluid px-0">
                <div className="list-content col-12 row no-gutters align-items-center px-0">
                  <div className="col-auto pl-2">
                    <img className="user-image" src={StaffLogo} />
                  </div>

                  <div className="user-name col text-truncate px-3">
                    {/* <span className="fs-14 red-color">Title : </span>  */}
                    <b>{`${job.title}`}</b>
                    <p className="m-0">
                      <span className="fs-14 red-color">Role : </span>
                      <span className="f-sm">{`${job.role}`}</span>
                    </p>
                  </div>
                  <div className="col d-block text-truncate">
                    <span className="fs-14 green-color">Category : </span>
                    <span className="f-sm">
                      <b>{`${job.category}`}</b>
                    </span>
                  </div>
                  <div className="col-2 d-block text-truncate">
                    <span className="f-sm">
                      <LocationOnIcon className="job-loc-icon" />
                      {`${job.jobLocation.town}`}
                    </span>
                  </div>
                  <div className="col d-block text-truncate">
                    <span className="fs-14">Posted On : </span>
                    <b>
                      <span className="f-sm">
                        <Moment format="YYYY-MM-DD">{job.createdOn}</Moment>
                      </span>
                    </b>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        );
      });
      setJobListTemplate(jobs);
    } else {
      setLoading(false);
    }
  };

  const changePassword = async () => {
    setLoading(true);
    let data = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
      shouldLogout: false,
    };

    ClientService.changePassword(data).then(
      (response) => {
        setChangePasswordModal(false);
        setLoading(false);
        setSuccessObj({ show: true, msg: "Password Changed Successfully" });
      },
      (err) => {
        setLoading(false);
        setChangePasswordModal(false);
        setErrorObj({ show: true, error: err.data });
      }
    );
  };

  const closePopup = () => {
    setSuccessObj({ show: false, msg: "" });
  };

  if (loading) return <Loader />;

  return (
    <div>
      <div id="staff-dashboard" className="content-wrapper client-dashboard">
        <div className="dashboard-container">
          <h3>Dashboard</h3>
          <div className="container-fluid p-0 mt-4">
            {dashboardCounts ? (
              <div className="row px-2">
                <div className="count-width">
                  <Countcard
                    count={dashboardCounts.postings}
                    msg={"Requirements"}
                    name={"total-count"}
                    icon={WorkIcon}
                  />
                </div>
                <div className="count-width">
                  <Countcard
                    count={dashboardCounts.availableJobPostBalance}
                    msg={"Job Post Balance"}
                    name={"inactive-count"}
                    icon={AccountBalanceWalletIcon}
                  />
                </div>
                <div className="count-width">
                  <Countcard
                    count={dashboardCounts.profileSubmissions}
                    msg={"Submissions"}
                    name={"active-count"}
                    icon={PublishIcon}
                  />
                </div>
                <div className="count-width">
                  <Countcard
                    count={dashboardCounts.availableProfileBalance}
                    msg={"Profile Balance"}
                    name={"new-count"}
                    icon={PersonIcon}
                  />
                </div>
                <div className="count-width">
                  <Countcard
                    count={dashboardCounts.activeSubscriptions}
                    msg={"Subscriptions"}
                    name={"inactive-count"}
                    icon={AttachMoneyIcon}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="row py-4 h-500">
            <div className="col-7">
              <div className="card count-card staff-dashboard-profile h-100">
                <div className="card-body">
                  <div className="row">
                    <div className="col-auto header-text">
                      <h6>Profile</h6>
                    </div>
                    <div className="col"></div>
                    <div className="col-auto">
                      Last updated on{" "}
                      {userDetail ? (
                        <b className="fs-14">
                          <Moment format="YYYY/MM/DD hh:mm">
                            {userDetail.createdOn}
                          </Moment>
                        </b>
                      ) : null}
                    </div>
                  </div>
                  <div className="row position-relative">
                    <div className="img-container col-12">
                      <div className="margin-auto">
                        <img
                          className="profile-img"
                          src={
                            userDetail.profilePic
                              ? userDetail.profilePic.href
                              : userImg
                          }
                        ></img>
                        <p className="name text-center">
                          {userDetail
                            ? `${userDetail.firstName} ${userDetail.lastName}`
                            : null}
                        </p>
                      </div>
                    </div>
                    <div className="progression-container row col-12">
                      <div className="col-2"></div>
                      <div className="progress col-8 p-0">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: profilePercentage + "%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {profilePercentage}%
                        </div>
                      </div>
                      <div className="col-2"></div>
                      <div className="col-2"></div>
                      <div className="col-8 row progression-text mx-0 px-0 py-2">
                        <div className="col-auto text p-0">
                          {profilePercentage}% profile completed
                        </div>
                        <div className="col"></div>
                        <div className="col-auto text p-0">Finish Profile</div>
                      </div>
                      <div className="col-2"></div>
                    </div>
                  </div>
                  <div className="row my-4">
                    <div className="col"></div>
                    <div className="col-auto">
                      <Link to={`/${userPrifix}/settings`}>
                        <button type="button" className="btn btn-primary">
                          View Full profile
                        </button>
                      </Link>
                      <button
                        type="button"
                        className="btn btn-warning"
                        style={{ marginLeft: "10px" }}
                        onClick={() => setChangePasswordModal(true)}
                      >
                        Change Password
                      </button>
                    </div>
                    <div className="col"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5 pl-0">
              <div className="card count-card staff-dashboard-profile h-100">
                <div className="card-body">
                  <div className="row">
                    <div className="col-auto header-text">
                      <h6>Your Activity Log</h6>
                    </div>
                    <div className="col"></div>
                    <div className="col-auto color-grey fs-14">Know More</div>
                  </div>
                  <div className="row position-relative log-timeline-container">
                    <div className="container">
                      <div className="timeline">
                        {activityLog?.length &&
                          activityLog.map((item, index) => {
                            return (
                              <div key={index}>
                                <div className="timeline-month">
                                  <Moment
                                    date={new Date(item.date)}
                                    format={"DD MMMM YYYY"}
                                  ></Moment>
                                  <span>3 Entries</span>
                                </div>
                                {item.messages.map((msg, index) => {
                                  return (
                                    <div
                                      className="timeline-section"
                                      key={index}
                                    >
                                      <div className="row m-2">
                                        <div className="col-auto time">
                                          <Moment
                                            date={msg.createdOn}
                                            format={"HH:mm"}
                                          ></Moment>
                                        </div>
                                        {msg.message.toLowerCase().includes('applied for job') ? <div className="col job-appliied-log-highlighter" title={msg.detailedMessage}>{msg.message}</div> : msg.message.toLowerCase().includes('you logged in') || msg.message.toLowerCase().includes('you have been logged out') ? <div className="col signin-appliied-log-highlighter" title={msg.detailedMessage}>{msg.message}</div> : <div title={msg.detailedMessage}>{msg.message}</div>}
                                        {/* <div
                                          className="col"
                                          title={msg.detailedMessage}
                                        >
                                          {msg.message}
                                        </div> */}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-2 h-500">
            <div className="col-12">
              <div className="card count-card job-posted-container h-100">
                <div className="card-body">
                  <div className="row pb-1">
                    <div className="col-auto header-text">
                      <h6>Recent Job Posted</h6>
                    </div>
                    <div className="col"></div>
                    <div className="col-auto color-grey ">Know More</div>
                  </div>
                  <div className="row job-posted-lists no-gutters client-job-list">
                    {jobListTemplate}
                    {jobListTemplate && !jobListTemplate.length ? (
                      <div className="no-option-avialble w-100 m-3">
                        <div className="margin-auto text-center">
                          <WarningIcon></WarningIcon>
                          <p>No Jobs Posted yet! </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row py-2 h-500">
            <div className="col-12 pb-4">
              <div className="card count-card staff-dashboard-candidate h-100">
                <div className="card-body">
                  <div className="row">
                    <div className="col-auto header-text">
                      <h6>Candidates Added</h6>
                    </div>
                  </div>
                  <div className="row staff-candidate-lists">
                    {candListTemplate}
                    {candListTemplate && !candListTemplate.length ? (
                      <div className="no-option-avialble w-100 m-3">
                        <div className="margin-auto text-center">
                          <WarningIcon></WarningIcon>
                          <p>No Candidates added yet! </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 pb-4">
              <div className="card count-card staff-dashboard-news h-100">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 header-text pb-4">
                      <h6>Subscription Details</h6>
                    </div>
                    {circularProgress}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        onClose={() => {
          setChangePasswordModal(false);
        }}
        open={changePasswordModal}
        className="client-chng-pass"
      >
        <Grid container>
          <Grid item sm={12} align="center">
            <h5 className="text-center mt-3">New Password</h5>
            <hr className=""></hr>
            <form autoComplete="off">
              <TextField
                type="password"
                className="mt-3 width100"
                label="Current Password"
                variant="outlined"
                onChange={(event) => setCurrentPassword(event.target.value)}
              />
              <br></br>
              <TextField
                type="password"
                className="mt-3 width100"
                label="New Password"
                variant="outlined"
                onChange={(event) => setNewPassword(event.target.value)}
              />
              <br></br>
              <TextField
                type="password"
                className="mt-3 width100"
                label="Confirm Password"
                variant="outlined"
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
              <div className="text-center my-4">
                <Button
                  variant="contained"
                  className="mx-2"
                  onClick={() => setChangePasswordModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={changePassword}
                >
                  Submit
                </Button>
              </div>
            </form>
          </Grid>
        </Grid>
      </Dialog>

      <SuccessToaster
        data={successObj}
        onClose={() => setSuccessObj({ show: false, success: {} })}
      ></SuccessToaster>
      <ErrorModal
        data={errorObj}
        onConfirmAction={() => setErrorObj({ show: false, error: {} })}
      ></ErrorModal>
      <ProfileCompletionModal
        data={profileCompletionObj}
        onConfirmAction={() => setProfileCompletionObj({ show: false, error: {} })}
      ></ProfileCompletionModal>
    </div>
  );
};

export default ClientDashboard;
