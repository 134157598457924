import React, { useState, useEffect } from "react";
import useDynamicRefs from "use-dynamic-refs";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import CloseIcon from "@material-ui/icons/Close";
import LocalStorageService from "../../service/LocalstorageService";
import $ from "jquery";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
//import FileViewer from 'react-file-viewer';

const ShowDocViewerModal = (props) => {
  const [visible, setVisible] = useState(props.data.show);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [scale, seScale] = useState(1);
  const [getRef, setRef] = useDynamicRefs();
  const BASE_URL = LocalStorageService.getServerIp();

  useEffect(() => {
    if (props.data.show) {
      setCurrentPage(1);
      setTotalPage(1);
    }
  }, [props]);

  // useEffect(() => {
  //     window.addEventListener('scroll', listenScrollEvent);

  //     return () =>
  //       window.removeEventListener('scroll', listenScrollEvent);
  // }, []);

  const listenScrollEvent = (event) => {
    console.log("on scroll event", event);
  };

  const updatePage = (page) => {
    const targetPage = getRef(page);
    targetPage.current.ref.scrollIntoView();
  };
  const onDocLoadSuccess = (pageData) => {
    //console.log(pageData);
    const { numPages = 1 } = pageData;
    setTotalPage(numPages);
  };

  const goToPrevPage = () => {
    const newPage = currentPage - 1;
    updatePage(newPage);
    setCurrentPage(newPage);
  };

  const goToNextPage = () => {
    const newPage = currentPage + 1;
    updatePage(newPage);
    setCurrentPage(newPage);
  };

  const hideModal = () => {
    setVisible(false);
    props.onCancel();
  };

  return (
    <div
      id="file-viwer-modal"
      className="overlay"
      style={{ display: props.data.show ? "block" : "none" }}
    >
      <div
        className={
          props.data.show ? " file-modal showModal" : "file-modal hideModal"
        }
      >
        {props.data.file.id ? (
          <>
            <nav className="file-nav row align-items-center">
              <div className="file-name col-auto text-truncate">
                {props.data.file.originalName}
              </div>
              <div className="col text-center">
                {currentPage}/{totalPage}
              </div>
              <button
                className="icon-btn col-auto"
                onClick={goToPrevPage}
                disabled={currentPage === 1 ? true : false}
              >
                <NavigateBeforeIcon></NavigateBeforeIcon>
              </button>
              <button
                className="icon-btn col-auto"
                onClick={goToNextPage}
                disabled={currentPage === totalPage ? true : false}
              >
                <NavigateNextIcon></NavigateNextIcon>
              </button>
              <button className="icon-btn col-auto" onClick={hideModal}>
                <CloseIcon></CloseIcon>
              </button>
            </nav>
            <div className="file-body">
              <div id="file-content" className="container">
                <Document
                  file={`${props.data.file.href}`}
                  onLoadSuccess={onDocLoadSuccess}
                >
                  {Array.apply(null, Array(totalPage)).map((page, index) => {
                    return (
                      <Page
                        scale={scale}
                        pageNumber={index + 1}
                        wrap={false}
                        key={index}
                        ref={setRef(index + 1)}
                      />
                    );
                  })}
                </Document>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {/* <div className="footer">
                    <button type="button" className="btn-sm btn-outline-secondary mr-3" onClick={hideModal}>cancel</button>
                </div> */}
      </div>
    </div>
  );
};
export default ShowDocViewerModal;
