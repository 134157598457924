import Api from './Interceptor';
import LocalStorageService from './LocalstorageService';

const localStorage = LocalStorageService.getService();

const SettingsService  = (function(){
    const ajax = function(param){
        return Api(param)
    }

    return {
        getRole : function(){
            let requestObj = {method :'GET', url : 'own/role'}
            return ajax(requestObj);
        },
        isLoggedIn : function(){
           return localStorage.getAccessToken() ? true : false;
        },
        getProfile : function(){
            let requestObj = {method:'GET',url : '/user/profile'};
            return ajax(requestObj);
        },
        updateProfile : function(data){
            let requestObj = {method:'PUT',url:'/user/profile',data:data};
            return ajax(requestObj);
        },
        updatePassword : function(data){
            let requestObj = {method:'PUT',url:'/user/profile',data:data};
            return ajax(requestObj);
        },
        upload : function(data){
            let requestObj = {method:'POST',url:`file/upload`,data:data, headers:{'content-type':'multipart/form-data'}};
            return ajax(requestObj);
        },
    }
})()

export default SettingsService;