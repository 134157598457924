import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import JobService from '../../service/JobService';
import Loader from '../../component/Loader';
import ConfirmModal from '../../component/modal/ConfirmModal';
import ErrorModal from '../../component/modal/ErrorModal';
import SuccessModal from '../../component/modal/SuccessModal';
import TableComponent from '../../common/candidateTableComponent';
import LocalService from '../../service/LocalstorageService';
import ActionListIcon from '@material-ui/icons/MoreVert';
import Moment from 'react-moment';
import WorkIcon from "@material-ui/icons/Work";
import VisibilityIcon from '@material-ui/icons/Visibility';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

const Main = (props) => {
    const { userType } = useParams();
    const [loading, setLoading] = useState(true);
    const [jobList, setJobList] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [confirmationObj, setConfirmationObj] = useState({ show: false, msg: '' });
    const [paginationObj, setPaginationObj] = useState({});
    const [currentAction, setCurrentAction] = useState('');
    const [errModel, setErrorModal] = useState({ show: false, error: {} })
    const [successModel, setSuccessModal] = useState({ show: false, success: {} })
    const [hotJobOnly, setHotJobOnly] = useState(false);
    // const thresholdHeight = 40;
    const perPageItem = 10;
    const [tableLoader, setTableLoader] = useState(false);
    const [tableError, setTableError] = useState(false);
    const clientId = props ? props.clientId || null : null;

    const [filterValue, setFilterValue] = useState('All Jobs');

    const columns = [
        {
            label: "Job Title", value: "name", sortable: true,
            render: (value, index, list) => {
                return (
                    <div className='d-flex align-items-center'>
                        <div className="no-image-28">{list.title.substr(0, 1)}</div>
                        <Link target={"_blank"} rel={"noopener noreferrer"} to={`/can/cand-jobs/view/${list.id}`}>{list.title} </Link>
                    </div>
                )
            }

        },
        {
            label: "Company Name", value: "client", sortable: false,
            render: (value, index, list) => {
                return (
                    <div className='d-flex align-items-center'>
                        {
                            list.client.logo && list.client.logo.href ?
                                <img className="image-30" src={list.client.logo.href}></img> :
                                <div className="no-image-28">{list.client.name.substr(0, 1)}</div>
                        }
                        <div className="d-flex align-items-center">{list.client.name} </div>
                    </div>
                )
            }
        },
        {
            label: "Location", value: "location", sortable: true,
            render: (value, index, list) => {
                return list.jobLocation.city || "NA"
            }
        },
        {
            label: "Hot Job", value: "hot_job", sortable: false,
            render: (value, index, list) => {
                return (
                    list.isHotJob ? <span className="badge" title='Hot Job'><DoneIcon></DoneIcon></span> : <span className="badge"><CloseIcon></CloseIcon></span>
                )
            }
        },
        {
            label: "Application Status", value: "application_status", sortable: false,
            render: (value, index, list) => {
                if (list.submission && !list.submission.isRejected) {
                    return (
                        <div className='ml-4'>
                            <div title={`Submitted On ${list.submission.submittedOn.substr(0, 10)}`}>
                                {list.submission.submissionType}
                                {list.submission.profileViewedOn ? <label className='ml-1' title={`Last Viewed On ${list.submission.profileViewedOn.substr(0, 10)}`}><VisibilityIcon></VisibilityIcon></label> : ''}
                            </div>
                        </div>
                    )
                } else if (list.submission && list.submission.isRejected) {
                    return (
                        <div className='ml-4'>
                            <div title={`Rejected On ${list.submission.rejectedOn.substr(0, 10)}`}>
                                Rejected
                                {list.submission.profileViewedOn ? <label className='ml-1' title={`Last Viewed On ${list.submission.profileViewedOn.substr(0, 10)}`}><VisibilityIcon></VisibilityIcon></label> : ''}
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className='ml-4'>
                            <div title='Not Applied Yet'>
                                Not Applied
                            </div>
                        </div>
                    )
                }

                // return (
                //     list.submission ? <span className="badge badge-success" title={`${<Moment date={list.submission.submittedOn} format={"DD MMM YYYY"}></Moment>}`}>list.submission.submissionType</span> : <span className="badge"><CloseIcon></CloseIcon></span>
                //     // list.submission ? <span className="badge badge-success" title='Hot Job'><DoneIcon></DoneIcon></span> : <span className="badge"><CloseIcon></CloseIcon></span>
                // )
                // return list.role || 'NA'
            }
        },
        {
            label: "Role", value: "role", sortable: false,
            render: (value, index, list) => {
                return list.role || 'NA'
            }
        },
        {
            label: "Posted On", value: "postOn", sortable: false,
            render: (value, index, list) => {
                return <Moment date={list.postOn} format={"DD MMM YYYY"}></Moment>
            }
        }
    ];

    useEffect(() => {
        getJobList();
    }, []);

    useEffect(() => {
        getJobList();
    }, [hotJobOnly])

    const getJobList = async (search = "", offset = 10, page = 1, sortBy) => {
        try {
            const response = await JobService.getJobList(search, offset, page, sortBy, hotJobOnly, clientId);
            if (response.status === 200) {
                setJobList(response.data.result);
                setPaginationObj(response.data.detail);
                setLoading(false);
                setTableLoader(false);
                setTableError(false);
            }
        }
        catch (e) {
            setLoading(false);
            setTableLoader(false);
            setTableError(true);
            setJobList([]);
            setPaginationObj({});
        }
    }

    const searchBy = (searchParam) => {
        let tableConfig = { ...config, search: searchParam };
        getTableData(tableConfig.search, tableConfig.perPageItem, tableConfig.page, tableConfig.sortBy);
    }

    const getTableData = (search, offset, page, sortBy) => {
        setTableLoader(true);
        let tableConfig = { ...config, search, "perPageItem": offset, page, sortBy };
        setConfig(tableConfig);
        getJobList(search, offset, page, sortBy);
    }

    const onRowSelectionChange = (list) => {
        setSelectedRow(list ? [...list] : null);
    }

    const performAction = (action, list) => {
        setCurrentAction({ action: action, data: list });
        setConfirmationObj({ show: true, msg: `You want to ${action.toUpperCase()} this Job?` });
    }

    const onFilterChange = async (event) => {
        setFilterValue(event.target.value)
        setLoading(true);
        try {
            const res = await JobService.getJobList(event.target.value, perPageItem, 1, '');
            if (res.status === 200) {
                setJobList(res.data.result);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            if (err && err.data) {
                setErrorModal({ show: true, error: err.data })
            }
        }
    }

    let tableConfig = {
        pagination: true,
        showSelectRow: true,
        perPageItem: perPageItem,
        getTableData: getTableData,
        onRowSelection: onRowSelectionChange,
        onActionButton: performAction,
        onFilterChange: onFilterChange,
        filterValue: filterValue,
        actionList: [],
        searchBy: searchBy,
        search: "",
        sortBy: { name: "", type: "" },
        page: 1
    }

    const [config, setConfig] = useState({ ...tableConfig });

    const closePopup = () => {
        setConfirmationObj({ show: false, msg: '' });
        setCurrentAction("");
    }

    const doAction = async () => {
        setLoading(true);
        setConfirmationObj({ show: false, msg: '' });

        try {
            const res = await JobService.updateJobStatus(currentAction.action, { jobPostIds: currentAction.data ? currentAction.data : selectedRow });
            setCurrentAction({});
            setLoading(false);
            if (res.status === 200) {
                setTableLoader(true);
                getJobList(config.search, config.perPageItem, config.page, config.sort);
                setSuccessModal({ show: true, success: { msg: res.data.result } });
            }
        } catch (err) {
            setLoading(false);
            if (err && err.data) {
                setErrorModal({ show: true, error: err.data })
            }
        }
    }


    if (loading) return <Loader />;

    return (
        <div className="content-wrapper p-0" id="jobs-list-container">
            <div className="container-fluid">
                <div className="row px-2 pt-2" style={{ display: props.clientId && props.clientId ? 'none' : '' }}>
                    <div className="col-12 d-flex count-summery p-0">
                        <div className="col-3 px-0">
                            <div className="card-counter warning">
                                <WorkIcon></WorkIcon>
                                <span className="count-numbers">{jobList.totalMatched}</span>
                                <span className="count-name">Matched</span>
                            </div>
                        </div>
                        <div className="col-3 px-0">
                            <div className="card-counter primary">
                                <WorkIcon></WorkIcon>
                                <span className="count-numbers">{jobList.totalApplied}</span>
                                <span className="count-name">Applied</span>
                            </div>
                        </div>
                        <div className="col-3 px-0">
                            <div className="card-counter primary">
                                <VisibilityIcon></VisibilityIcon>
                                <span className="count-numbers">{jobList.profileViewedCount}</span>
                                <span className="count-name">Profile Viewed</span>
                            </div>
                        </div>
                        <div className="col-3 px-0">
                            <div className="card-counter success">
                                <DoneIcon></DoneIcon>
                                <span className="count-numbers">{jobList.totalShortlisted}</span>
                                <span className="count-name">Shortlisted</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-2 filter-row align-items-center">
                    <div className="col-xl-2 col-lg-3 col-md-4 px-2">
                        <h5 className="m-0">Jobs List</h5>
                    </div>
                    <div className="col">
                    </div>
                    <div className="col-auto">
                        <label className="my-0 mx-2">Hot jobs</label>
                        <div className="switch" onClick={() => setHotJobOnly(!hotJobOnly)}>
                            {
                                hotJobOnly ?
                                    <input type="checkbox" name="isHotJobTrue" id="isHotJob" value={hotJobOnly} checked={hotJobOnly} onChange={() => { }} /> :
                                    <input type="checkbox" name="isHotJobFalse" id="isHotJob" value={hotJobOnly} checked={hotJobOnly} onChange={() => { }} />
                            }
                            <span className="slider round"></span>
                        </div>
                    </div>
                    {/* <div className="col-auto px-2">
                        {permissions[0]['write'] ? <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/job-post/add`}>
                            <button className="btn btn-primary btn-padding add-btn"> +Add</button>
                        </Link> : ''}
                    </div> */}
                </div>
                <div className="list-card list-view" id="job-list-view">
                    <div className='list-container'>
                        <TableComponent
                            loading={tableLoader}
                            error={tableError}
                            columns={columns}
                            data={jobList.data || []}
                            config={config}
                            paginationObj={paginationObj}>
                        </TableComponent>
                    </div>

                </div>
            </div>
            <ConfirmModal data={confirmationObj} onConfirmAction={doAction} onCancel={closePopup}></ConfirmModal>
            <ErrorModal data={errModel} onConfirmAction={() => setErrorModal({ show: false, error: {} })} ></ErrorModal>
            <SuccessModal data={successModel} onConfirmAction={() => setSuccessModal({ show: false, success: {} })}></SuccessModal>
        </div>
    )
}

export default Main;