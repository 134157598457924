import React, { useState, useEffect, useContext ,forwardRef, useImperativeHandle} from "react";
import Validation from "../../../../service/ValidationService";
import LocalStorageService from "../../../../service/LocalstorageService";
import UseForm from '../../../../service/FormService';
import CandidateService from "../../../../service/CandidateService";


const LocalService = LocalStorageService.getService();
const EmailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const initialSchema = {
    "firstName": { value: "", error: "" },
    "middleName": { value: "", error: "" },
    "lastName": { value: "", error: "" },
    "email": { value: "", error: "" },
    "contactNumber": { value: "", error: "" },
    "gender": { value: "", error: "" },
}

const validationStateSchema = {
    firstName: { required: true},
    lastName: { required: true },
    middleName: { required: false },
    email: { 
        required: true,
        validator: {
            regEx: EmailRegx,
            error: "Invalid Email format.",
        }, 
    },
    contactNumber: { 
        required: true,
        validator: {
            regEx: /^([0-9]{10})$/,
            error: "Invalid phone number format.",
        },
    },
    gender: { required: true },
};

const PersonalStep = forwardRef((props,ref) => {
    const [stateSchema, setStateSchema] = useState(initialSchema);
    const [errorObj, setErrorObj] = useState({ show: false, error: {} });
    const [isChanged, setChanged] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [contactNumberError, setContactNumberError] = useState("");

    useEffect(() => {
        if (props.wizard && props.wizard.personal) {
            upDateInitialData();
        }
    },[]);

    const upDateInitialData = () => {
        const data = props.wizard.personal;
        let schema = {...stateSchema};
        Object.keys(schema).forEach(key => {
            if (data.hasOwnProperty(key)) {
                schema[key].value =  data[key];
            }
        })
        setStateSchema(schema);
    };

    const onEmailChange = (ev) => {
        const email = ev.target.value;
        const event = { target: { "name": 'email', 'value': email } }
        if(email.match(EmailRegx)){
            setEmailError("");
            validateEmail(email, event);
        }else{
            handleOnChange(event);
        }
    }

    const onContactNumberChange = (ev) => {
        const contactNo = ev.target.value;
        const event = { target: { "name": 'contactNumber', 'value': contactNo } }
        if(contactNo.match(/^([0-9]{10})$/)){
            setEmailError("");
            validateContactNumber(contactNo, event);
        }else{
            handleOnChange(event);
        }
    }

    const validateEmail = async (email, event) => {
        setEmailError("Please wait while we are validating this email...")
        try{
            const res = await CandidateService.checkEmailExist(email);
            if(res.status === 200){
                if(res.data.result === "Email Already Exists!"){
                    setEmailError("Email is already in use.")
                    
                }else{
                    setEmailError("");
                }
                handleOnChange(event);
            }
        }catch(e){
            setEmailError("");
            handleOnChange(event);
        }
    }

    const validateContactNumber = async (contactNumber, event) => {
        setContactNumberError("Please wait while we are validating this Phone Number...")
        try{
            const res = await CandidateService.checkContactNumberExist(contactNumber);
            if(res.status === 200){
                if(res.data.result === "Contact Number Already Exists!"){
                    setContactNumberError("Contact Number is already in use.")
                }else{
                    setContactNumberError("");
                }
                handleOnChange(event);
            }
        }catch(e){
            setContactNumberError("");
            handleOnChange(event);
        }
    }

    const onSubmitForm = (state) => {
        setEditMode(false);
        props.canProceed(true);
        setChanged(true);
    }

    useImperativeHandle(ref, () => ({
        submit() {
            const personal = {};
            for(let key in state){
                personal[key] = state[key].value === "" ? null : state[key].value;
            }
            props.updateData(personal,props.stepConfig)
        }
    }));


    const { state, handleOnChange, handleOnSubmit, disable, setValueWithoutError } = UseForm(stateSchema, validationStateSchema, onSubmitForm);
    
    useEffect(() => {
        validateInput();
    },[state]);

    const validateInput = () => {
        let isValid = true;
        for(let key in state){
            if((validationStateSchema[key].required && !state[key].value) || state[key].error){
                isValid = false; 
            }
        }
        if(isValid && !emailError && !contactNumberError){
            props.canProceed(true);
        }else{
            props.canProceed(false);
        }
    }

    return(
        <div className="candidate-signup-personal-step">
            <div className="form-wrapper">
                <div className="candidate-personal-card mt-4">
                    <div className=" row no-gutters">
                        <p className=" col"><b>Personal Details</b></p>
                    </div>
                    <div className="cs-body">
                        <div className="row no-gutter">
                            <div className="form-group col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 m-0">
                                <label className="label-sm">First Name <sup className="required">*</sup></label>
                                <input type="text" className="form-control" id="firstName" placeholder="First Name" name="firstName" value={state.firstName.value} onChange={handleOnChange} />
                                <small className="form-text  error-text m-0" style={{ visibility: state.firstName.error ? 'visible' : 'hidden' }}>{state.firstName.error}</small>
                            </div>
                            <div className="form-group col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 m-0">
                                <label className="label-sm">Middle Name</label>
                                <input type="text" className="form-control " placeholder="Middle Name" id="middleName" name="middleName" value={state.middleName.value} onChange={handleOnChange} />
                                <small className="form-text  error-text m-0" style={{ visibility: state.middleName.error ? 'visible' : 'hidden' }}>{state.middleName.error}</small>
                            </div>
                            <div className="form-group col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 m-0">
                                <label className="label-sm">Last Name <sup className="required">*</sup></label>
                                <input type="text" className="form-control " placeholder="Last Name" id="lastName" name="lastName" value={state.lastName.value} onChange={handleOnChange} />
                                <small className="form-text  error-text m-0" style={{ visibility: state.lastName.error ? 'visible' : 'hidden' }}>{state.lastName.error}</small>
                            </div>

                            <div className="form-group col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 m-0">
                                <label className="label-sm">Email ID  <sup className="required">*</sup></label>
                                <input type="text" className="form-control " placeholder="name@email.com" id="email" name="email" value={state.email.value} onChange={onEmailChange} />
                                <small className="form-text  error-text m-0" style={{ visibility: state.email.error ? 'visible' : 'hidden' }}>{state.email.error}</small>
                                {!state.email.error && emailError && <small className="form-text  error-text m-0">{emailError}</small>}

                            </div>
                            <div className="form-group col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 m-0">
                                <label className="label-sm">Contact Number  <sup className="required">*</sup></label>
                                <input type="number" className="form-control " placeholder="Phone Number" id="contactNumber" name="contactNumber" value={state.contactNumber.value} onChange={onContactNumberChange} />
                                <small className="form-text  error-text m-0" style={{ visibility: state.contactNumber.error ? 'visible' : 'hidden' }}>{state.contactNumber.error}</small>
                                {!state.contactNumber.error && contactNumberError && <small className="form-text  error-text m-0">{contactNumberError}</small>}
                            </div>
                            <div className="form-group col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 m-0">
                                <label className="label-sm">Gender </label>
                                <select className="form-control" id="gender" name="gender" value={state.gender.value} onChange={handleOnChange}>
                                    <option value="">Select Gender</option>
                                    <option value="MALE">MALE</option>
                                    <option value="FEMALE">FEMALE</option>
                                    <option value="prefer not to say">PREFER NOT TO SAY</option>
                                </select>
                                <small className="form-text  error-text m-0" style={{ visibility: state.gender.error ? 'visible' : 'hidden' }}>{state.gender.error}</small>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>    
    )
})

export default PersonalStep;