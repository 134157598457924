import React from 'react';
import { Link, useParams } from 'react-router-dom';
import userLogo from '../../../assets/imgs/male-avatar.png';

export const CandidateListView = (props) => {
    const { userType } = useParams();

    const getLastUpdated = (date) => {
        var today = new Date()
        var date = new Date(date)
        var seconds = Math.floor((today - date) / 1000)
        var interval;
        var lastUpdOn;
        if (Math.floor(seconds / 31536000) >= 1) {
            interval = Math.floor(seconds / 31536000)
            if (interval == 1) {
                lastUpdOn = interval + " year"
            }
            else {
                lastUpdOn = interval + " years"
            }
        }
        else if (Math.floor(seconds / 2592000) >= 1) {
            interval = Math.floor(seconds / 2592000)
            if (interval == 1) {
                lastUpdOn = interval + " month"
            }
            else {
                lastUpdOn = interval + " months"
            }
        }
        else if (Math.floor(seconds / 86400) >= 1) {
            interval = Math.floor(seconds / 86400)
            if (interval == 1) {
                lastUpdOn = interval + " day"
            }
            else {
                lastUpdOn = interval + " days"
            }
        }
        else if (Math.floor(seconds / 3600) >= 1) {
            interval = Math.floor(seconds / 3600)
            if (interval == 1) {
                lastUpdOn = interval + " hour"
            }
            else {
                lastUpdOn = interval + " hours"
            }
        }
        else if (Math.floor(seconds / 60) >= 1) {
            interval = Math.floor(seconds / 60)
            if (interval == 1) {
                lastUpdOn = interval + " minute"
            }
            else {
                lastUpdOn = interval + " minutes"
            }
        }
        else if (Math.floor(seconds) >= 1) {
            interval = Math.floor(seconds)
            if (interval == 1) {
                lastUpdOn = interval + " second"
            }
            else {
                lastUpdOn = interval + " seconds"
            }
        }
        return lastUpdOn
    }

    return (
        <div className="col-lg-6 mb-3 d-flex align-items-stretch">
            <div className="card">
                <div className="card-header">
                    <Link
                        target={"_blank"} rel={"noopener noreferrer"}
                        to={`/${userType}/search-candidate/view/${props.list.id}`}>
                        <b className="cand_name">{`${props.list.personal.firstName} ${props.list.personal.middleName || ''} ${props.list.personal.lastName}`}</b>
                    </Link>
                    {!props.list.summary.role || !props.list.summary.category ? <span className="pl-2"><span className="badge badge-danger" title="Role and Category Should be Assigned"> INCOMPLETE </span></span> : ''}
                </div>
                <div className="card-body">
                    <h6 className="card-subtitle mb-2 text-muted">Category - {props.list.summary.category ? `${props.list.summary.category.name}` : 'NA'}</h6>
                    <h6 className="card-subtitle mb-2 text-muted" style={{ marginTop: '0.8rem' }}>Role - {props.list.summary.role ? `${props.list.summary.role.name}` : 'NA'}</h6>
                    <h6 className="card-subtitle mb-2 text-muted" style={{ marginTop: '0.8rem' }}>Experience - {props.list.experienceInYr ? props.list.experienceInYr + `${props.list.experienceInMnth ? '.' + props.list.experienceInMnth + ' Yrs' : ' Yrs'}` : ' NA '}</h6>
                    <p className="card-text" style={{ marginTop: '0.8rem', border: '1px dotted', padding: '0.4rem', fontFamily: 'auto' }}>{props.list.summary.text}</p>
                </div >
                <div className="card-footer">
                    <small className="text-muted" title='Profile Last Updated On'>Last Updated : {props.list.lastUpdated ? getLastUpdated(props.list.lastUpdated) + ' ago' : 'NA'}</small>
                    {props.list.lastViewedOn ?
                        <small className="text-light bg-dark float-right" title='Viewed By Client'> Viewed On : <span>{getLastUpdated(props.list.lastViewedOn)} ago </span></small>: ''
                    }
                </div>
                {/* <div className="card-footer">
                    <small className="text-muted">Last Updated : {props.list.lastUpdated ? getLastUpdated(props.list.lastUpdated) + ' ago' : 'NA'}</small>
                </div> */}
            </div >
        </div>
    )

    // return (
    //     <li className="list mb-1">
    //         <div className="container-fluid candid-list">
    //             <div className="row no-gutters main_div">
    //                 <div className={props.list.isArchived ? 'list-content col row no-gutters archived' : props.list.isActive ? 'list-content col row no-gutters active' : 'list-content col row no-gutters inactive'}>
    //                     {
    //                         props.list.personal.profilePic ?
    //                             <img className="user-image col-1 " src={props.list.personal.profilePic.href}></img> :
    //                             <img className="user-image col-1 " src={userLogo}></img>
    //                     }
    //                     <div className="cand-name col-11 text-truncate">
    //                         <Link target={"_blank"} to={`/${userType}/search-candidate/view/${props.list.id}`}>
    //                             <b className="cand_name">{`${props.list.personal.firstName} ${props.list.personal.middleName || ''} ${props.list.personal.lastName}`}</b>
    //                         </Link>
    //                         <span className="pl-2 cand_role">|  {props.list.summary.category ? <b> {props.list.summary.category.name} </b> : ' NA '}</span>
    //                         <span className="pl-2 cand_role">|  {props.list.summary.role ? <b> {props.list.summary.role.name} </b> : ' NA '}</span>
    //                         {props.list.submittedBy ?
    //                             <span className="pl-2">
    //                                 |  <span className="badge badge-primary" title="Submitted By">{props.list.submittedBy}</span>
    //                             </span> :
    //                             null
    //                         }
    //                         {props.list.lastViewedOn ?
    //                             <span className="pl-2 last_updated">
    //                                 <span className="key">Last Viewed On :</span>
    //                                 <span>{getLastUpdated(props.list.lastViewedOn)} ago</span>
    //                             </span> :
    //                             <span className="pl-2 last_updated">
    //                                 <span className="key">Last Updated On :</span>
    //                                 <span>{getLastUpdated(props.list.lastUpdated)} ago</span>
    //                             </span>
    //                         }

    //                         <p className="m-0 cand_exp"><span className="text-label" title={props.list.summary ? props.list.summary.text : null}>{props.list.summary ? props.list.summary.text : '-'}</span></p>
    //                     </div>
    //                     {/* <span className="row-text col-2 text-truncate"> {props.list.email}</span>
    //                     <div className="col-2 row-text text-truncate">
    //                         <span className="text-label">Role - </span>
    //                         <span className="text-value">{props.list.role ? <b> {props.list.role.name} </b> : ' NA '}</span>
    //                     </div>
    //                     <div className="col-2 row-text text-truncate">
    //                         <span className="text-label">Experience - </span>
    //                         <span className="text-value">{props.list.totalExperience ? props.list.totalExperience : ' NA '}</span>
    //                     </div> */}
    //                 </div>
    //             </div>
    //         </div>
    //     </li>
    // )
}

const List = (props) => {
    return (
        <div></div>

    );
}

export default List;