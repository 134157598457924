import React from "react";
import Slider from "react-slick";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <ArrowBackIosIcon style={{ fontSize: '18px' }} />
    </div>
  );
}

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon style={{ fontSize: '18px' }} />
    </div>
  );
}

const MultipleRows = ({ data: categoryList }) => {
  const settings = {
    className: "center",
    infinite: true,
    speed: 500,
    rows: 1,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    dots: true,
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "transparent",
          padding: "10px",
          bottom: "-50px"
        }}
      >
        <ul className="custom-dots-list" style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div
        style={{
          width: "13px",
          height: "13px",
          border: "2px solid #8860D0",
          borderRadius: "50%"
        }}
      >
      </div>
    ),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 980, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 560, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div id="employer-category-list">
      <div className="container">
        <div className="row section-header text-center p-4">
          <h2 className="col-md-12">Find Applicants based on roles</h2>
          {/* <p className="col-md-12">Click on the roles below to see the list of applicants.</p> */}
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="col-10">
            <Slider {...settings}>
              {categoryList.map((category, index) => {
                return (
                  <div className="category-card d-flex" key={index}>
                    <div className="row no-gutters align-items-center">
                      <div className="col-2">
                        <div className="category-logo">{category.name[0]}</div>
                      </div>
                      <div className="col-10">
                        <div className="row no-gutters align-items-center">
                          <div className="col-12 text-truncate category-name py-1 px-2">{category.name}</div>
                          {/* <div className="col-12 text-truncate category-count px-2">{category.jobsCount || 'Upcoming '} {category.jobsCount ?  '+Jobs' : ''}</div> */}
                          <div className="col-12 text-truncate category-count px-2">{category.profiles}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultipleRows;