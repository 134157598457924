import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthService from "../../../service/AuthService";
import LocalStorageService from "../../../service/LocalstorageService";
import LoginImage from "../../../../assets/imgs/candidate-signup-WB.png";
import { AuthContext } from "../../../component/AuthContext";
import PersonalStep from "./WizardSteps/personal";
import QualificationStep from "./WizardSteps/qualification";
import LocationStep from "./WizardSteps/location";
import ProfessionalStep from "./WizardSteps/professional";
import BaseWizard from "../../../component/Wizard";
import whiteLogo from "../../../../assets/imgs/logo-white-transparent.png";
import DefaultLogo from "../../../../assets/imgs/new_logo-transparent.png";

const LocalService = LocalStorageService.getService();

const stepList = [
  {displayName:"", name: 'personal', type: PersonalStep},
  {displayName:"", name: 'professional', type: ProfessionalStep },
  {displayName:"", name: 'qualification', type: QualificationStep},
  {displayName:"", name: 'location', type: LocationStep},
]


const Signup = () => {
  const [userData,setUserData] = useState({})
  const history = useHistory();
  const context = useContext(AuthContext);
  const wizardConfig = {
    name : 'Sign Up',
    stepCount: 4, 
    steps : stepList, 
    isProgression:true, 
    nextLabel : "Next",
    submitLabel: "Sign Up",
    showCancel: false
  }

  useEffect(() => {
    const isUserLogin = LocalService.getAccessToken();
    isUserLogin
      ? history.push(`/${LocalService.getRoutePrefix()}/dashboard`)
      : "";
  }, []);


  return (
    <div className="container-fluid p-0 m-0">
      <div className="form-wrapper login-page-container" id="signup-page-container">
        <div className="row vh-100 w-100 m-0">
          <div className="col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12">
            <div className="containers w-100">
              <div className="row w-100 no-gutters">
                <div className="col-1 col-sm-none col-xs-none col-md-block col-lg-block col-xl-block"></div>
                <div className="col-11 col-xs-12 col-sm-12">
                  <div className="row vh-100 no-gutters">
                    <div className="col-12">
                        <div className="logo-section text-center">
                          <Link to="/home">
                            <img src={DefaultLogo} height="100"/>
                            </Link>
                        </div>
                        {/* <div className="logo-section text-center d-xs-block d-sm-block d-md-none d-lg-none d-xl-none">
                          <Link to="/home">
                            <img src={whiteLogo} />
                            </Link>
                        </div> */}
                    </div>
                    <div className="col-12">
                        <div className="d-flex align-items-center" id="signup-wizard-container">
                            <BaseWizard config={wizardConfig} data={userData}></BaseWizard>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5 d-xl-block d-lg-block d-md-block d-xs-none d-sm-none p-0">
            <img src={LoginImage} className="login-bg-img"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
