import React, { useState } from "react";
import ErrorIcon from "@material-ui/icons/Error";

export const ErrorToaster = (props) => {
  const onClose = (e) => {
    props.onClose(e);
  };
  return (
    <div
      className={`toast fade error-toast ${
        props.data && props.data.show ? "show" : "hide"
      }`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="toast-header">
        <ErrorIcon></ErrorIcon>
        <strong className="mr-auto">Error</strong>
        <button
          type="button"
          className="ml-2 mb-1 close"
          data-dismiss="toast"
          aria-label="Close"
          onClick={onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="toast-body">{props.data.msg}</div>
    </div>
  );
};

const ErrorModal = (props) => {
  const [visible, setVisible] = useState(props.data.show);

  const hideModal = () => {
    setVisible(false);
    props.onCancel();
  };
  const onConfirm = () => {
    props.onConfirmAction(props.action);
  };

  return (
    <div
      className={props.data.show ? "overlay showModal" : "overlay hideModal"}
    >
      <div className="overlay-content">
        <div className="cs-modal" id="error-popup">
          <div className="header">
            <div className="margin-auto">
              <ErrorIcon></ErrorIcon> Error{" "}
              {props.data.error && props.data.error.detail
                ? props.data.error.detail.appCode
                : ""}
            </div>
          </div>
          <div className="body">
            <p className="body-text">
              {props.data.error
                ? props.data.error.message || props.data.error.result
                : ""}
            </p>
            <p className="text-muted info-msg text-center">
              Your request got failed because of above mention reason, Please
              try again!
            </p>
          </div>
          <div className="footer">
            {/* <button type="button" className="btn-sm btn-outline-secondary mr-3" onClick={hideModal}>cancel</button> */}
            <button
              type="button"
              className="btn btn-sm btn-outline-danger"
              onClick={onConfirm}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
