import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Validation from "../../../service/ValidationService";
import AuthService from "../../../service/AuthService";
import LocalStorageService from "../../../service/LocalstorageService";
import { SuccessToaster } from "../../../component/modal/SuccessModal";
import { ErrorToaster } from "../../../component/modal/ErrorModal";
import LoginImage from "../../../../assets/imgs/candidate-new-signup-WB.png";
import MobileBackgroundImage from "../../../../assets/imgs/login3.png";
import fogotPassword from "../../../../assets/imgs/Activate-Account.png";
import whiteLogo from "../../../../assets/imgs/logo-white-transparent.png";
import DefaultLogo from "../../../../assets/imgs/new_logo-transparent.png";
import HomeIcon from '@material-ui/icons/Home';

const LocalService = LocalStorageService.getService();
const ValidationService = Validation.getService();

const validationSchema = {
  password: { dname: "password", min: 6, type: "string", required: true },
  confirmPassword: {
    dname: "confirmPassword",
    min: 6,
    type: "string",
    required: true,
  },
};

const ActivateAccount = () => {
  const { code } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [successObj, setSuccessObj] = useState({ show: false, success: {} });
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });
  const history = useHistory();

  useEffect(() => {
    const isUserLogin = LocalService.getAccessToken();
    isUserLogin
      ? history.push(`/${LocalService.getRoutePrefix()}/dashboard`)
      : "";
  }, []);

  const onBlur = (e) => {
    e.persist();
    e.preventDefault();
    const { name, value } = e.target;
    const validation = ValidationService.checkValidField(
      name,
      value,
      validationSchema
    );

    if (name === "password") {
      setPassword(validation.valid ? value : "");
      setPasswordError(validation.msg || "");
    } else if (name === "confirmPassword") {
      setConfirmPassword(validation.valid ? value : "");
      setConfirmPasswordError(validation.msg || "");
    }
  };
  const closeToast = (e) => {
    e.preventDefault();
    setErrorObj({ show: false, msg: "" });
  };

  const onSubmit = async (e) => {
    e.persist();
    e.preventDefault();
    const error = passwordError || confirmPasswordError ? true : false;
    if (!error) {
      try {
        const response = await AuthService.activateAccount(
          { password: password, confirmPassword: confirmPassword },
          code
        );
        if (response.status === 200) {
          setSuccessObj({ show: true, msg: "Account Activated Successfully" });
          history.push(`/${LocalService.getRoutePrefix()}/login`);
        }
      } catch (err) {
        console.log("Error", err);
        setErrorObj({ show: true, msg: err.data.message });
      }
    }
  };

  return (
    <div className="container-fluid p-0 m-0">
      <Link to="/home" className="goToHome l-10 d-lg-block d-xl-block d-md-none d-sm-none d-xs-none">
        <HomeIcon />
        <span>Home</span>
      </Link>
      <div className="form-wrapper login-page-container">
        <div className="row vh-100 w-100 m-0">
          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 login-form-container">
            <div className="container vh-100 w-100">
              <div className="row align-items-center vh-100 w-100 login-form-section">
                <Link to="/home" className="goToHome d-md-block d-sm-block d-xs-block d-lg-none d-xl-none">
                  <HomeIcon />
                  <span>Home</span>
                </Link>
                <div className="col-md-2 col-lg-2 col-xl-2 d-sm-none d-xs-none d-md-block d-lg-block d-xl-block"></div>
                <div className="col-md-8 col-lg-8 col-xl-8 col-sm-12 col-xs-12">
                  <div className="logo-section text-center d-flex justify-content-center mb-4">
                    <img src={whiteLogo} height={"80px"} className="d-md-flex d-sm-flex d-xs-flex d-lg-none d-xl-none"/>
                    <img src={DefaultLogo} height={"100px"} className="d-md-none d-sm-none d-xs-none d-lg-block d-xl-block"/>
                  </div>
                  <div className="form-header text-center">
                    <span className="text-msg ">
                      {window.location.pathname.includes("activate-account")
                        ? "Activate-Account"
                        : window.location.pathname.includes("set-password")
                          ? "Set-Password"
                          : null}
                    </span>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="row">
                      <div className="form-group col-sm-12">
                        <label className="label-sm">Password</label>
                        <input
                          type="password"
                          className="form-control "
                          id="password"
                          placeholder="****"
                          name="password"
                          defaultValue={password}
                          onBlur={onBlur}
                        />
                        {passwordError ? (
                          <small className="form-text  error-text">
                            {passwordError}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group col-sm-12">
                        <label className="label-sm">Confirm Password</label>
                        <input
                          type="password"
                          className="form-control "
                          id="confirmPassword"
                          placeholder="*****"
                          name="confirmPassword"
                          defaultValue={confirmPassword}
                          onBlur={onBlur}
                        />
                        {confirmPasswordError ? (
                          <small className="form-text  error-text">
                            {confirmPasswordError}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="action-btn text-center">
                      <button
                        type="button"
                        className="btn  btn-primary"
                        onClick={onSubmit}
                      >
                        Activate
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-md-2 col-lg-2 col-xl-2 d-sm-none d-xs-none d-md-block d-lg-block d-xl-block"></div>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 p-0 parent_div">
            <img src={MobileBackgroundImage} className="login-bg-img d-lg-none d-xl-none d-md-block d-sm-block d-xs-block"></img>
            <img src={LoginImage} className="login-bg-img d-lg-block d-xl-block d-md-none d-sm-none d-xs-none"></img>
            <img src={fogotPassword} className="forgot-bg-img d-lg-block d-xl-block d-md-none d-sm-none d-xs-none"></img>
          </div>
        </div>
        <SuccessToaster
          data={successObj}
          onClose={() => setSuccessObj({ show: false, success: {} })}
        ></SuccessToaster>
        <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
      </div>
    </div>
  );
};

export default ActivateAccount;
