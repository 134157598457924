import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Main from '../JobPostManagement/Main';

const ClientJobs = (props) => {
    const {id, userType } = useParams();
    return (
        <div id="client-jobs-container">
            <Main clientId={id}></Main>
        </div>
    )
};


export default ClientJobs;