import React from 'react';


const Countcard = (props) => {
    const Component = props.icon;
    return (
        <div className="card count-card mx-2">
            <div className="card-body row no-gutters py-3 px-2">
                <h5 className="card-title col-3 col-md-5 col-lg-4 ">
                    <div className={"icon-round-container text-center " + props.name } >
                        <Component></Component>
                    </div>
                </h5>
                <div className="card-text col-9 col-md-7 col-lg-8">
                    <p className="count m-0 pl-2">{props.count || 0}</p>
                    <p className="status m-0 pl-2">{props.msg}</p>
                </div>
            </div>
        </div>
    )
}

export default Countcard;