import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import JobService from '../../service/JobPostService';
import Loader from '../../component/Loader';
import ConfirmModal from '../../component/modal/ConfirmModal';
import ErrorModal from '../../component/modal/ErrorModal';
import SuccessModal from '../../component/modal/SuccessModal';
import Moment from 'react-moment';
import ReactHtmlParser from 'react-html-parser';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CandidatesList from './Candidates/Main';

const ClientView = () => {
    const { id, userType } = useParams();
    const [jobData, setJobData] = useState({});
    const [loading, setLoading] = useState(true);
    const [confirmationObj, setConfirmationObj] = useState({ show: false, msg: '' });
    const [errModel, setErrorModal] = useState({ show: false, error: {} });
    const [successModel, setSuccessModal] = useState({ show: false, success: {} })
    const [currentAction, setCurrentAction] = useState('');
    const [expand, setExpand] = useState(true)
    const history = useHistory();

    useEffect(() => {
        getJobData();
    }, []);

    const getJobData = async () => {
        try {
            const response = await JobService.getJobDetails(id);
            if (response.status === 200) {
                setJobData(response.data.result);
                localStorage.setItem('jobPostId', response.data.result.id)
                localStorage.setItem('jobPostTitle', response.data.result.title)
                //console.log("Job data",response.data.result);
                setLoading(false);
            }
        }
        catch (err) {
            setLoading(false);
            history.push(`/${userType}/job-post`)
        }

    }
    const closePopup = () => {
        setConfirmationObj({ show: false, msg: '' });
        setCurrentAction("");
    }

    const performAction = (e) => {
        //console.log("action",e);
        e.persist()
        e.preventDefault();
        const action = e.target.dataset.value;
        if(action){
            setCurrentAction({ action: action, data: [id] });
            setConfirmationObj({ show: true, msg: `You want to ${action} this job?` });
        }
        
    }

    const doAction = async () => {
        setLoading(true);
        setConfirmationObj({ show: false, msg: '' });

        try {
            const res = await JobService.updateJobStatus(currentAction.action, { jobPostIds: currentAction.data });
            setCurrentAction({});
            setLoading(false);
            //console.log("success res", res);
            if (res.status === 200) {
                setSuccessModal({ show: true, success: { msg: res.data.result } });
                getJobData();
            }
        } catch (err) {
            //console.log(err);
            setLoading(false);
            if (err && err.data) {
                setErrorModal({ show: true, error: err.data })
            }
        }
    }


    if (loading) return <Loader />;

    return (
        <div id="jobs-view">
            <div className="row cs-breadcrumb no-gutters w-100">
                <ol className="breadcrumb w-100">
                    <li className="breadcrumb-item active">
                        <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/job-post`}>Jobs</Link>
                    </li>
                    <li className="breadcrumb-item">{jobData.title}</li>
                </ol>
            </div>
            <div className="content-wrapper pt-0" >
                <Accordion className="mb-2" expanded={!expand} onChange={() => { setExpand(!expand) }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="col-12 jd-header-section">
                            <div className="name-section row no-gutters">
                                <p className="col-auto name m-0">{jobData.title}</p>
                                <p className="col-auto mb-0 ml-4 mt-2">
                                    {jobData.isArchived ? <span className="badge badge-danger">Archived</span> : jobData.isActive ? <span className="badge badge-success">Active</span> : <span className="badge badge-warning">Inactive</span>}
                                </p>
                                <div className="col d-flex flex-row-reverse staff-detail-action ">
                                    {jobData.isArchived ? "" : <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/job-post/edit/${id}`} className="btn btn-primary">Edit Posting</Link>}
                                    {
                                        jobData.isActive ?
                                            <button type="button" className="btn btn-warning" data-value="deactivate" onClick={performAction}>Deactivate Posting</button> :
                                            <button type="button" className="btn btn-warning" data-value="activate" onClick={performAction} >Activate Posting</button>
                                    }
                                    <button type="button" className="btn btn-danger" data-value="delete" onClick={performAction}>Delete Permanently</button>
                                </div>
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="detail-section row no-gutters spl-dotted-border-div-css">
                            <div className="col-6 item-detail pb-0">
                                <span className="name">Client Name:</span>
                                <span className="value">{jobData.client.name}</span>
                            </div>
                            {jobData.addedBy ? (
                                <div className="col-6 item-detail pb-0">
                                    <span className="name">Created By :</span>
                                    <span className="value" title={jobData.addedBy.email}>{jobData.addedBy.firstName + ' ' + jobData.addedBy.lastName}</span>
                                </div>
                            ) : ('')}
                            <div className="col-6 item-detail pb-0">
                                <span className="name">Created On :</span>
                                <span className="value" title='Posting Created On'><Moment date={jobData.createdOn} format={"DD MMM YYYY"}></Moment></span>
                            </div>
                            <div className="col-6 item-detail pb-0">
                                <span className="name" title='Available For Candidates to apply for this job posting from this date'>Active From :</span>
                                <span className="value" title='Available For Candidates to apply for this job posting from this date'><Moment date={jobData.postOn} format={"DD MMM YYYY"}></Moment></span>
                            </div>
                            <div className="col-6 item-detail pb-0">
                                <span className="name">Preferred Experience Range:</span>
                                <span className="value">{jobData.isExperienceNegotiable ? 'Negotiable' : jobData.minimumExperience + ' - ' + jobData.maximumExperience + ' Years'}</span>
                            </div>
                            <div className="col-6 item-detail pb-0">
                                <span className="name">Maximum CTC Provided :</span>
                                <span className="value">{jobData.isCtcNegotiable ? 'Negotiable' : jobData.maximumCtc + ' LPA'}</span>
                            </div>
                            <div className="col-6 item-detail pb-0">
                                <span className="name">Job Location : </span>
                                <span className="value">{jobData.jobLocation.town}, {jobData.jobLocation.city.name}, {jobData.jobLocation.state.name}</span>
                            </div>
                            <div className="col-6 item-detail pb-0">
                                <span className="name">Is a Hot Job :</span>
                                <span className="value">{jobData.isHotJob ? 'Yes' : 'No'}</span>
                            </div>
                            <div className="col-6 item-detail pb-0">
                                <span className="name">Job Category Name:</span>
                                <span className="value">{jobData.category.name}</span>
                            </div>
                            <div className="col-6 item-detail pb-0">
                                <span className="name">Job Role Name:</span>
                                <span className="value">{jobData.role.name}</span>
                            </div>
                            <div className="col-12 item-detail pb-0 row no-gutters">
                            </div>
                            <div className="col-12 item-detail pb-0 row no-gutters">
                            </div>
                            <div className="col-12 item-detail pb-0 row no-gutters">
                                <span className="name">Required Qualifications :</span>
                                <span className="value col">{jobData.qualifications && jobData.qualifications.map(item => <span className="badge badge-primary text-truncate mr-2" key={item.id} title={item.name}>{item.name}</span>)}</span>
                            </div>
                            <div className="col-12 item-detail pb-0 row no-gutters">
                                <span className="name col-auto ">Required Courses :</span>
                                <span className="value col">{jobData.courses && jobData.courses.map(item => <span className="badge badge-info text-truncate mr-2" key={item.id} title={item.name}>{item.name}</span>)}</span>
                            </div>
                            <div className="col-12 item-detail pb-0 row no-gutters">
                                <span className="name col-auto">Required Specializations :</span>
                                <span className="value col">{jobData.specializations && jobData.specializations.map(item => <span className="badge badge-secondary text-truncate mr-2" key={item.id} title={item.name}>{item.name}</span>)}</span>
                            </div>
                            <div className="col-12 item-detail pb-0 row no-gutters">
                            </div>
                            <div className="col-12 item-detail pb-0 row no-gutters">
                            </div>
                            <div className="col-12 item-detail description-container">
                                <p className="name w-100">Job Description: </p>
                                <div className="description w-100">{ReactHtmlParser(jobData.description)}</div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <CandidatesList />
            </div>
            <ConfirmModal data={confirmationObj} onConfirmAction={doAction} onCancel={closePopup}></ConfirmModal>
            <ErrorModal data={errModel} onConfirmAction={() => setErrorModal({ show: false, error: {} })} ></ErrorModal>
            <SuccessModal data={successModel} onConfirmAction={() => setSuccessModal({ show: false, success: {} })}></SuccessModal>
        </div>
    )
}

export default ClientView;
