import Api from "./Interceptor";
import LocalStorageService from "./LocalstorageService";

const localStorage = LocalStorageService.getService();

const NewsService = (function () {
  const ajax = function (param) {
    return Api(param);
  };
  const generateParams = function (param) {
    if (!param) return "";
    let params = "";

    Object.keys(param).forEach((key) => {
      params = `${params}${params ? "&" : "?"}${key}=${param[key]}`;
    });
    return params;
  };

  return {
    getRole: function () {
      let requestObj = { method: "GET", url: "own/role" };
      return ajax(requestObj);
    },
    isLoggedIn: function () {
      return localStorage.getAccessToken() ? true : false;
    },
    addNews: function (data) {
      let requestObj = { method: "POST", url: "/webcontent/news", data: data };
      return ajax(requestObj);
    },
    getNewsList: function (search, offset, page, sortby) {
      const requestObj = {
        method: "GET",
        url: '/webcontent/news'
      };
      let paramSting = "";
      if(search){
        paramSting = paramSting + `search=${search}`
      }
      if(offset){
          paramSting = (paramSting ? paramSting + '&': paramSting) + `limit=${offset}`
      }
      if(page){
          paramSting = (paramSting ? paramSting + '&': paramSting) + `page=${page}` 
      }
      if(sortby){
          paramSting = (paramSting ? paramSting + '&': paramSting) + `sort=${sortby.type}&sortby=${sortby.name}` 
      }

      if(paramSting){
          requestObj.url = requestObj.url + "?" + paramSting;
      }
      return ajax(requestObj);
    },
    getNewsDetails: function (id) {
      let requestObj = { method: "GET", url: `/webcontent/news/${id}` };
      return ajax(requestObj);
    },
    updateNews: function (id, data) {
      let requestObj = {
        method: "PUT",
        url: `webcontent/news/${id}`,
        data: data,
      };
      return ajax(requestObj);
    },
    updateNewsStatus: function (action, data) {
      let requestObj = {
        method: "PUT",
        url: `/webcontent/news/update/bulk?action=${action}`,
        data: data,
      };
      return ajax(requestObj);
    },
    getNewsListPublic: function (id) {
      let requestObj = { method: "GET", url: `/webcontent/news/${id}` };
      return ajax(requestObj);
    },
  };
})();

export default NewsService;
