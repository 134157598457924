import React, { useState, useEffect } from "react";
import HomePageService from "../../service/HomePageService";
import CheckboxV2 from "../CheckboxV2";

const SelectRole = (props) => {
    const [visible, setVisible] = useState(props.data.show || false);
    const [dropdownList, setDropDownList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [listLoading, setListLoading] = useState(false);

    useEffect(() => {
        setVisible(props.data.show);
        if (!props.data.show) {
            setDropDownList([]);
            setSelectedList([]);
        } else {
            setListLoading(true);
            setSelectedList([...props.data.role]);
        }
    }, [props]);

    useEffect(() => {
        if (visible) {
            getRoleList();
        }
    }, [selectedList])

    const getRoleList = async () => {
        const response = await HomePageService.getSelectedCategoryRole(selectedList, true);
        if (response.status === 200) {
            const list = [];
            response.data.result.checked.forEach((item) => {
                list.push({ label: item, checked: true })
            })
            response.data.result.uncheckedVals.forEach((item) => {
                list.push({ label: item, checked: false })
            })
            setDropDownList([...list]);
            setListLoading(false);
        }
    };

    const hideModal = () => {
        setVisible(false);
        props.onCancel();
    };

    const onSubmitForm = () => {
        if(props.onConfirmAction){
            const selectedList = [];
            dropdownList.forEach(item => {
                if(item.checked){
                    selectedList.push(item.label);
                }
            })
            props.onConfirmAction(selectedList);
        }
    };

    const onRoleSelectionChange = (event, role) => {
        const roles = [...dropdownList];
        roles.forEach(list => {
        if(list.label === role){
            list.checked = event;
        }
        })
        setDropDownList([...roles]);
    }

    return (
        <div
            className="overlay "
            id="select-location-model"
            style={{ display: visible ? "block" : "none" }}
        >
            <div className="overlay-content">
                <div className={visible ? " cs-modal showModal" : "cs-modal hideModal"}>
                    <div className="header">
                        Role Filter
                    </div>
                    <div className="body">
                        <div className="select-location-model-container">
                            <div className="row align-items-center">
                                {dropdownList && dropdownList.length ? dropdownList.map((item, index) => {
                                    return(
                                        <div className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 d-flex py-1" key={index}>
                                            <CheckboxV2 value={item.checked}
                                                classes="select-popup-checkbox"
                                                name={`location-filter-${index}`}
                                                size={16}
                                                change={(event) => onRoleSelectionChange(event, item.label)}>
                                            </CheckboxV2>
                                            <label className="select-popup-label pl-2" >{item.label}</label>
                                        </div>
                                    )
                                }):'' }
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary mr-3"
                            onClick={hideModal}
                        >
                            cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-sm btn-outline-primary"
                            onClick={onSubmitForm}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectRole;
