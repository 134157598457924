import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AuthService from "../service/AuthService";
import SettingsService from "../service/SettingsService";
import Loader from "../component/Loader";
import userLogo from "../../assets/imgs/male-avatar.png";
import UseForm from "../service/FormService";
import DatePicker from "react-datepicker";
import ErrorModal from "../component/modal/ErrorModal";
import $ from "jquery";
import { SuccessToaster } from "../component/modal/SuccessModal";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ClientService from "../service/ClientService";
import moment from "moment";

const isLoggedIn = () => {
  const history = useHistory();
  if (!AuthService.isLoggedIn()) {
    history.push("/client/login");
  }
};

const initialSchema = {
  firstName: { value: "", error: "" },
  middleName: { value: "", error: "" },
  lastName: { value: "", error: "" },
  gender: { value: false, error: "" },
  maritalStatus: { value: "", error: "" },
  alternateEmail: { value: "", error: "" },
  contactNumber: { value: "", error: "" },
  alternateNumber: { value: "", error: "" },
  spokenLanguages: { value: "", error: "" },
  nationality: { value: "INDIAN", error: "" },
  isSpouceDoctrate: { value: "", error: "" },
  dateOfBirth: { value: "", error: "" },
  profilePic: { value: "", error: "" },
  createdOn: { value: "", error: "" },
  userType: { value: "", error: "" },
};

const validationStateSchema = {
  firstName: { required: true },
  middleName: { required: false },
  lastName: { required: true },
  gender: { required: true },
  maritalStatus: { required: true },
  alternateEmail: { required: false },
  contactNumber: { required: true },
  alternateNumber: { required: false },
  spokenLanguages: { required: true },
  nationality: { required: false },
  isSpouceDoctrate: { required: false },
  dateOfBirth: { required: true },
  profilePic: { required: false },
  // createdOn: { required: false },
};

const Settings = (props) => {
  isLoggedIn();

  const [profileData, setProfileData] = useState([]);
  const [stateSchema, setStateSchema] = useState(initialSchema);
  const [editMode, setEditMode] = useState(false);
  const [spouseDoctrate, setSpouseDoctrate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorObj, setErrorObj] = useState({ show: false, error: {} });
  const [successObj, setSuccessObj] = useState({ show: false, success: {} });
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    SettingsService.getProfile().then(
      (res) => {
        if (res.status === 201 || res.status === 200) {
          setProfileData(res.data.result);
          const data = res.data.result;
          let schema = $.extend(true, {}, stateSchema);
          Object.keys(schema).forEach((key) => {
            if (data.hasOwnProperty(key)) {
              schema[key].value =
                key === "dateOfBirth" ? new Date(data[key]) : data[key] || "";
            }
          });
          setStateSchema(schema);
          setIsLoading(false);
        }
      },
      (err) => {
        setErrorModal({ show: true, error: err.data });
        setIsLoading(false);
      }
    );
  };

  const editData = () => {
    setEditMode(true);
  };

  const spouseDoctrateChange = (data) => {
    handleOnChange({
      target: { name: "isSpouceDoctrate", value: data ? data : "" },
    });
    setSpouseDoctrate(data);
  };

  const dobChange = (date) => {
    const event = { target: { name: "dateOfBirth", value: date } };
    handleOnChange(event);
  };

  const onFileChange = (e) => {
    setIsLoading(true);
    e.persist();
    e.preventDefault();
    // console.log("file changes", e);
    let formData = new FormData();
    formData.append("files", e.target.files[0]);
    formData.append("mediaType", "image");
    SettingsService.upload(formData).then(
      (res) => {
        // console.log(res);
        setIsLoading(false);
        handleOnChange({
          target: { name: "profilePic", value: res.data.result[0] },
        });
      },
      (error) => {
        // console.log(error);
        setIsLoading(false);
      }
    );
  };

  const submit = () => {
    const requestPayload = {};
    Object.keys(state).forEach((key) => {
      if (key === "dateOfBirth") {
        requestPayload[key] = getDateFormat(state[key].value) || null;
      } else if (key === "profilePic") {
        requestPayload[key] = state[key].value ? state[key].value : null;
      } else {
        requestPayload[key] = state[key].value || null;
      }
    });
    SettingsService.updateProfile(requestPayload).then(
      (res) => {
        if (res.status === 201 || res.status === 200) {
          setEditMode(false);
          setSuccessObj({ show: true, msg: "Profile Updated Successfully" });
          getProfileData();
        }
      },
      (err) => {
        setErrorObj({ show: true, error: err.data });
      }
    );
  };

  const getDateFormat = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const getLastUpdated = (date) => {
    var today = new Date()
    var date = new Date(date)
    var seconds = Math.floor((today - date) / 1000)
    var interval;
    var lastUpdOn;
    if (Math.floor(seconds / 31536000) >= 1) {
      interval = Math.floor(seconds / 31536000)
      if (interval == 1) {
        lastUpdOn = interval + " year"
      }
      else {
        lastUpdOn = interval + " years"
      }
    }
    else if (Math.floor(seconds / 2592000) >= 1) {
      interval = Math.floor(seconds / 2592000)
      if (interval == 1) {
        lastUpdOn = interval + " month"
      }
      else {
        lastUpdOn = interval + " months"
      }
    }
    else if (Math.floor(seconds / 86400) >= 1) {
      interval = Math.floor(seconds / 86400)
      if (interval == 1) {
        lastUpdOn = interval + " day"
      }
      else {
        lastUpdOn = interval + " days"
      }
    }
    else if (Math.floor(seconds / 3600) >= 1) {
      interval = Math.floor(seconds / 3600)
      if (interval == 1) {
        lastUpdOn = interval + " hour"
      }
      else {
        lastUpdOn = interval + " hours"
      }
    }
    else if (Math.floor(seconds / 60) >= 1) {
      interval = Math.floor(seconds / 60)
      if (interval == 1) {
        lastUpdOn = interval + " minute"
      }
      else {
        lastUpdOn = interval + " minutes"
      }
    }
    else if (Math.floor(seconds) >= 1) {
      interval = Math.floor(seconds)
      if (interval == 1) {
        lastUpdOn = interval + " second"
      }
      else {
        lastUpdOn = interval + " seconds"
      }
    }
    return lastUpdOn
  }

  const closePopup = () => {
    setSuccessObj({ show: false, msg: "" });
  };

  const changePassword = async () => {
    setIsLoading(true);
    let data = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
      shouldLogout: false,
    };

    ClientService.changePassword(data).then(
      (response) => {
        setChangePasswordModal(false);
        setSuccessObj({ show: true, msg: "Password Updated Successfully" });
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        setChangePasswordModal(false);
        setErrorModal({ show: true, error: err.data });
      }
    );
  };

  const { state, handleOnChange, disable } = UseForm(
    stateSchema,
    validationStateSchema
  );

  if (isLoading) return <Loader />;

  let passDialog = (
    <Dialog
      onClose={() => {
        setChangePasswordModal(false);
      }}
      open={changePasswordModal}
      className="client-chng-pass"
    >
      <Grid container>
        <Grid item sm={12} align="center">
          <h5 className="text-center mt-3">New Password</h5>
          <hr className=""></hr>
          <form autoComplete="off">
            <TextField
              type="password"
              className="mt-3 width100"
              label="Current Password"
              variant="outlined"
              onChange={(event) => setCurrentPassword(event.target.value)}
            />
            <br></br>
            <TextField
              type="password"
              className="mt-3 width100"
              label="New Password"
              variant="outlined"
              onChange={(event) => setNewPassword(event.target.value)}
            />
            <br></br>
            <TextField
              type="password"
              className="mt-3 width100"
              label="Confirm Password"
              variant="outlined"
              onChange={(event) => setConfirmPassword(event.target.value)}
            />
            <div className="text-center my-4">
              <Button
                variant="contained"
                className="mx-2"
                onClick={() => setChangePasswordModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={changePassword}
              >
                Submit
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </Dialog>
  );

  return (
    <div className="content-wrapper p-0 ">
        <div className="row"  style={{ 'overflowY': 'scroll', height: 'calc(100vh - 70px)' }}>
      <div className="col-4">
        <div className="row no-gutter p-4 details-section">
          <div className="form-group m-0 col-12 text-center">
            <label className="label-sm w-100">&nbsp;</label>
            <div className="row no-gutters align-items-center" style={{ backgroundColor: '#fbfbfb' }}>
              <div className="col file-name align-center">
                {editMode ? (<button
                  className="fileUpload btn btn-secondary cs-upload-1 m-0 col-auto"
                  disabled={!editMode}
                >
                  <span>Browse</span>
                  <input
                    type="file"
                    className="upload"
                    name="logo"
                    id="logo.value"
                    onChange={onFileChange}
                    accept="image/*"
                    disabled={!editMode}
                  />
                </button>) : ''}
                {state.profilePic.value && state.profilePic.value.id ? (
                  <img src={state.profilePic.value.href} style={{ height: "100%", width: "100%", borderRadius: "10px" }} title={state.profilePic.value.originalName}></img>
                ) : (
                  ""
                )}
              </div>
            </div>
            <small
              className="form-text  error-text m-0"
              style={{
                visibility: state.profilePic.error ? "visible" : "hidden",
              }}
            >
              {state.profilePic.error}
            </small>
            <div className="row no-gutters align-items-center" style={{ backgroundColor: '#fbfbfb', color: '#8f8f4d' }}>
              <label className="label-lg w-100" style={{ fontWeight: 'bold', fontSize: '25px' }}>{state.firstName.value + ' ' + state.lastName.value}</label>
              <label className="label-lg w-100" style={{ fontWeight: 'bold' }}>{state.userType.value} Account</label>
              <label className="label-lg w-100" style={{ fontWeight: 'bold' }} title={getDateFormat(state.createdOn.value)}>Joined {getLastUpdated(state.createdOn.value)} ago</label>
            </div>
          </div>
          <div className="form-group p-4 m-0 col-12 text-center">
            {editMode ? (
              <button
                className="btn btn-success col-12"
                onClick={submit}
                disabled={disable ? true : false}
              >
                Save Changes
              </button>
            ) : (
              <button className="btn btn-primary col-12" onClick={editData}>
                Update Profile
              </button>
            )}

            <button
              className="btn btn-warning col-12"
              onClick={() => setChangePasswordModal(true)}
              style={{ marginTop: '10px' }}
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
      <div className="col-8">
        <div className="row no-gutter p-4 details-section">
          <label className="label-sm">
            <b>Name & Date of Birth</b>
          </label>
          <div className="row no-gutter p-4 details-section" style={{ backgroundColor: '#f8f8fa', borderRadius: "10px", marginRight: "2px" }}>
            <div className="form-group col-6 m-0">
              <label className="label-sm">
                First Name <sup className="required">*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="First Name"
                name="firstName"
                value={state.firstName.value}
                disabled={!editMode}
                onChange={handleOnChange}
              />
              <small
                className="form-text  error-text m-0"
                style={{ visibility: state.firstName.error ? "visible" : "hidden" }}
              >
                {state.firstName.error}
              </small>
            </div>
            <div className="form-group col-6 m-0">
              <label className="label-sm">Middle Name</label>
              <input
                type="text"
                className="form-control "
                placeholder="Middle Name"
                id="middleName"
                name="middleName"
                value={state.middleName.value}
                disabled={!editMode}
                onChange={handleOnChange}
              />
              <small
                className="form-text  error-text m-0"
                style={{
                  visibility: state.middleName.error ? "visible" : "hidden",
                }}
              >
                {state.middleName.error}
              </small>
            </div>
            <div className="form-group col-6 m-0">
              <label className="label-sm">
                Last Name <sup className="required">*</sup>
              </label>
              <input
                type="text"
                className="form-control "
                placeholder="Last Name"
                id="lastName"
                name="lastName"
                value={state.lastName.value}
                disabled={!editMode}
                onChange={handleOnChange}
              />
              <small
                className="form-text  error-text m-0"
                style={{ visibility: state.lastName.error ? "visible" : "hidden" }}
              >
                {state.lastName.error}
              </small>
            </div>
            <div className="form-group col-6 m-0">
              <label className="label-sm">
                Date of Birth <sup className="required">*</sup>
              </label>
              <DatePicker
                className="form-control w-100"
                id="dateOfBirth"
                name="dateOfBirth"
                maxDate={new Date()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={dobChange}
                selected={state.dateOfBirth.value}
                disabled={!editMode}
                autoComplete="off"
              ></DatePicker>
              <small
                className="form-text  error-text m-0"
                style={{
                  visibility: state.dateOfBirth.error ? "visible" : "hidden",
                }}
              >
                {state.dateOfBirth.error}
              </small>
            </div>
          </div>
          <label className="label-sm" style={{ marginTop: '0.3rem' }}>
            <b>Contact Details</b>
          </label>
          <div className="row no-gutter p-4 details-section" style={{ backgroundColor: '#f8f8fa', borderRadius: "10px", marginRight: "2px" }}>
            <div className="form-group col-6 m-0">
              <label className="label-sm"> Primary Email <sup className="required">*</sup></label>
              <input
                type="text"
                className="form-control"
                placeholder="name@email.com"
                id="alternateEmail"
                name="alternateEmail"
                value={profileData.email}
                disabled
              />
              <small
                className="form-text  error-text m-0"
                style={{
                  visibility: state.alternateEmail.error ? "visible" : "hidden",
                }}
              >
                {state.alternateEmail.error}
              </small>
            </div>
            <div className="form-group col-6 m-0">
              <label className="label-sm"> Alternate Email </label>
              <input
                type="text"
                className="form-control"
                placeholder="name@email.com"
                id="alternateEmail"
                name="alternateEmail"
                value={state.alternateEmail.value}
                disabled={!editMode}
                onChange={handleOnChange}
              />
              <small
                className="form-text  error-text m-0"
                style={{
                  visibility: state.alternateEmail.error ? "visible" : "hidden",
                }}
              >
                {state.alternateEmail.error}
              </small>
            </div>
            <div className="form-group col-6 m-0">
              <label className="label-sm">
                Primary Number <sup className="required">*</sup>
              </label>
              <input
                type="number"
                className="form-control "
                placeholder="Phone Number"
                id="contactNumber"
                name="contactNumber"
                value={state.contactNumber.value}
                disabled={!editMode}
                onChange={handleOnChange}
              />
              <small
                className="form-text  error-text m-0"
                style={{
                  visibility: state.contactNumber.error ? "visible" : "hidden",
                }}
              >
                {state.contactNumber.error}
              </small>
            </div>
            <div className="form-group col-6 m-0">
              <label className="label-sm"> Alternate Number</label>
              <input
                type="number"
                className="form-control"
                placeholder="Phone number"
                id="alternateNumber"
                name="alternateNumber"
                value={state.alternateNumber.value}
                disabled={!editMode}
                onChange={handleOnChange}
              />
              <small
                className="form-text  error-text m-0"
                style={{
                  visibility: state.alternateNumber.error ? "visible" : "hidden",
                }}
              >
                {state.alternateNumber.error}
              </small>
            </div>
          </div>
          <label className="label-sm" style={{ marginTop: '0.3rem' }}>
            <b>Other Details</b>
          </label>
          <div className="row no-gutter p-4 details-section" style={{ backgroundColor: '#f8f8fa', borderRadius: "10px", marginRight: "2px" }}>
            <div className="form-group col-6 m-0">
              <label className="label-sm">
                Gender <sup className="required">*</sup>
              </label>
              <select
                className="form-control"
                id="gender"
                name="gender"
                value={state.gender.value}
                disabled={!editMode}
                onChange={handleOnChange}
              >
                <option value="">Select Gender</option>
                <option value="MALE">MALE</option>
                <option value="FEMALE">FEMALE</option>
                <option value="OTHER">OTHER</option>
              </select>
              <small
                className="form-text  error-text m-0"
                style={{ visibility: state.gender.error ? "visible" : "hidden" }}
              >
                {state.gender.error}
              </small>
            </div>
            <div className="form-group col-6 m-0">
              <label className="label-sm">
                {" "}
                Marital Status <sup className="required">*</sup>
              </label>
              <select
                className="form-control"
                id="maritalStatus"
                name="maritalStatus"
                value={state.maritalStatus.value}
                disabled={!editMode}
                onChange={handleOnChange}
              >
                <option value="">Select Marital Status</option>
                <option value="SINGLE">SINGLE</option>
                <option value="MARRIED">MARRIED</option>
                <option value="DIVOSED">DIVORSED</option>
              </select>
              <small
                className="form-text  error-text m-0"
                style={{
                  visibility: state.maritalStatus.error ? "visible" : "hidden",
                }}
              >
                {state.maritalStatus.error}
              </small>
            </div>
            {state.maritalStatus.value === "MARRIED" ? (
              <div className="form-group col-6 m-0">
                <div className="margin-top-auto highest-qualification ml-3">
                  <label className="label-sm mr-4 ">Is Spouse Doctor</label>
                  <div
                    className="switch"
                    onClick={() => spouseDoctrateChange(!spouseDoctrate)}
                  >
                    {spouseDoctrate ? (
                      <input
                        type="checkbox"
                        name="isHotJobTrue"
                        id="isHotJob"
                        value={spouseDoctrate}
                        checked={spouseDoctrate.value}
                        onChange={() => { }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        name="isHotJobFalse"
                        id="isHotJob"
                        value={spouseDoctrate}
                        checked={spouseDoctrate.value}
                        onChange={() => { }}
                      />
                    )}
                    <span className="slider round"></span>
                  </div>
                  <small
                    className="form-text  error-text m-0"
                    style={{
                      visibility: state.isSpouceDoctrate.error
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    {state.isSpouceDoctrate.error}
                  </small>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="form-group col-6 m-0">
              <label className="label-sm">
                Language Spoken <sup className="required">*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Eg: English, Hindi"
                id="spokenLanguages"
                name="spokenLanguages"
                value={state.spokenLanguages.value}
                disabled={!editMode}
                onChange={handleOnChange}
              />
              <small
                className="form-text  error-text m-0"
                style={{
                  visibility: state.spokenLanguages.error ? "visible" : "hidden",
                }}
              >
                {state.spokenLanguages.error}
              </small>
            </div>
            <div className="form-group col-6 m-0">
              <label className="label-sm"> Nationality </label>
              <select
                className="form-control"
                id="nationality"
                name="nationality"
                value={state.nationality.value}
                disabled={!editMode}
                onChange={handleOnChange}
              >
                <option value="">Select Nationality</option>
                <option value="Indian">Indian</option>
              </select>
              <small
                className="form-text  error-text m-0"
                style={{
                  visibility: state.nationality.error ? "visible" : "hidden",
                }}
              >
                {state.nationality.error}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SuccessToaster
        data={successObj}
        onClose={() => setSuccessObj({ show: false, success: {} })}
      ></SuccessToaster>
      <ErrorModal
        data={errorObj}
        onConfirmAction={() => setErrorObj({ show: false, error: {} })}
      ></ErrorModal>
      {passDialog}
    </div>
  )

};

export default Settings;
