import axios from "axios";
import React from "react";
import LocalStorageService from "./LocalstorageService";
import { Redirect } from "react-router-dom";

// LocalstorageService
const localStorageService = LocalStorageService.getService();
const baseURL = LocalStorageService.getServerIp();

const APIService = (function (React) {
  let instance;

  function getInstance() {
    if (!instance) {
      instance = axios.create({
        baseURL: baseURL,
      });

      // Add a request interceptor
      instance.interceptors.request.use(
        (config) => {
          const token = localStorageService.getAccessToken();
          config.headers["content-type"]
            ? (config.headers["content-type"] = "application/json")
            : "";
          if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          Promise.reject(error.response);
        }
      );

      //Add a response interceptor

      instance.interceptors.response.use(
        (response) => {
          return response;
        },
        function (error) {
          if (error.response && error.response.status === 401) {
            if (
              error.response.data.detail.appCode === "MDLW100" ||
              error.response.data.detail.appCode === "MDLW101" ||
              error.response.data.detail.appCode === "MDLW104" ||
              error.response.data.detail.appCode === "MDLW401"
            ) {
              localStorageService.clearToken();
              window.location.href = "/home";
            }
          }
          return Promise.reject(error.response);
        }
      );

      return instance;
    }
    return instance;
  }

  return getInstance();
})(React);

export default APIService;
