import React, { useEffect, useState, useMemo, useRef } from 'react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
// import CheckboxV2 from '../component/CheckboxV2';
import { useDebouncedCallback } from 'use-debounce';
import IconButton from '@material-ui/core/IconButton';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from 'react-select';
import CandidateService from '../service/CandidateService';
import $ from 'jquery';

const CandidateTableComponent = ({ columns, data, config = {}, paginationObj, loading, error }) => {
    const [listItem, setListItem] = useState(data);
    const [selectedList, setSelectedList] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [search, setSearch] = useState(config.search || "");
    const perPageItem = config.perPageItem || 10;
    paginationObj.currentPage = +paginationObj.currentPage;
    const selectedKey = config.selectedRowKey ? config.selectedRowKey : 'id';
    const countStarting = paginationObj ? ((paginationObj.currentPage - 1) * paginationObj.perPageCount) + 1 : 'NA';
    const countLast = paginationObj && data ? countStarting + data.length - 1 : 'NA';
    const totalPageCount = paginationObj ? Math.ceil(paginationObj.totalCount / paginationObj.perPageCount) : 0;
    // const [filterValue, setFilterValue] = useState('All Jobs');

    const [cityList, setCityList] = useState([]);
    const [cityData, setCityData] = useState({});
    const [selectedCity, setSelectedCity] = useState([]);
    const [cityLoading, setCityLoading] = useState(false);

    const sort = config.sortBy || { name: "", type: "" }
    useEffect(() => {
        setListItem(data);
    }, [data])

    useEffect(() => {
        getAllCityList();
    }, []);

    const onSelectAll = (value, lists, index) => {
        const rowList = [];
        const tempList = [...listItem];

        tempList.forEach((list) => {
            list.selected = value;
            if (value) {
                rowList.push(list[selectedKey]);
            }
        })

        setSelectAll(value);
        setSelectedList(rowList);
        setListItem(tempList);

        if (config.onRowSelection) {
            config.onRowSelection(rowList)
        }
    }

    const perPageItemChange = (e) => {
        if (e.currentTarget && e.currentTarget.value) {
            onSelectAll(false);
            config.getTableData(search, e.currentTarget.value, 1, sort.name ? sort : null)
        }
    }

    const goToPage = (page) => {
        onSelectAll(false);
        config.getTableData(search, perPageItem, page, sort.name ? sort : null);
    }

    const debounce = useDebouncedCallback((value) => {
        setSearch(value)
        onSelectAll(false);
        config.getTableData(value, perPageItem, 1, sort.name ? sort : null);
    }, 1000)

    const doSort = (column) => {
        if (column.value && column.value === sort.name) {
            sort.type = !sort.type ? 'asc' : sort.type === 'asc' ? 'dsc' : '';
            sort.name = !sort.type ? "" : column.value;
        } else {
            sort.type = 'asc';
            sort.name = column.value;
        }
        onSelectAll(false);
        config.getTableData(search, perPageItem, 1, sort.name ? sort : null);
    }

    const getAllCityList = async (param, url) => {
        try {
            setCityLoading(true)
            const response = await CandidateService.getAllLocationListByPagination(param, url);
            if (url) {
                let tempDataArr = cityList;
                tempDataArr = tempDataArr.concat(response.data.result.data);
                response.data.result.data = tempDataArr;
            }
            setCityData(response.data)
            setCityList($.extend(true, [], response.data.result.data));
            setCityLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    const onCityInputChange = (param, actionType, list) => {
        if (actionType.action && actionType.action === 'input-change') {
            setCityList([]);
            param ? getAllCityList(param) : getAllCityList();
        }
        else if (actionType.action === 'menu-close' && !selectedCity.length > 0)
            getAllCityList()
    }

    const cityChange = (cities) => {
        if (cities && cities.length > 0) {
            setSelectedCity(cities);
            const citynames_str = cities.map(function (val) {
                return val.name;
            }).join(',');
            config.searchBy(citynames_str, perPageItem, 1, sort.name ? sort : null)
        }
        else {
            setSelectedCity([]);
            config.searchBy('', perPageItem, 1, sort.name ? sort : null)
        }
    }

    const paginateCityList = (e) => {
        if (cityData.detail.links && cityData.detail.links.next) {
            setCityLoading(true)
            getAllCityList("", cityData.detail.links.next);
            setCityLoading(false)
        }
    }

    const customFilter = (option, inputValue) => {
        if (option.data.name && inputValue) {
            return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        }
        return true;
    }

    return (
        <div className='cs-table-container'>
            <div className="cs-header-section">
                <div className="row no-gutters px-2 align-items-center">

                    <div className="col d-flex align-items-center">
                        <input type="text" className='table-search-input' placeholder='Search Here' defaultValue={search} onChange={(e) => debounce(e.currentTarget.value)}></input>
                    </div>
                    <div className="col-2 d-flex align-items-center">
                        <FormControl variant="outlined" className="w-100">
                            <InputLabel id="demo-simple-select-outlined-label"> View </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={config.filterValue}
                                onChange={config.onFilterChange}
                                label="Filter"
                            >
                                <MenuItem value="All Jobs">All Jobs</MenuItem>
                                <MenuItem value="suggested">Suggested Jobs</MenuItem>
                                <MenuItem value="applied">Applied Jobs</MenuItem>
                                <MenuItem value="shortlisted">Shortlisted Jobs</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                        {/* <label className="label-sm">Search Location</label> */}
                        <Autocomplete
                            name="preferred-locations"
                            className="col"
                            placeholder="Search Location"
                            defaultOptions
                            isMulti={true}
                            isClearable={true}
                            options={cityList}
                            value={selectedCity && selectedCity.length > 0 ?
                                selectedCity.map(city => {
                                    return { name: city.name, id: city.id }
                                }) : null}
                            filterOption={customFilter}
                            isLoading={cityLoading}
                            getOptionLabel={(option) => {
                                return (
                                    <div className="text col p-1 text-truncate">
                                        <div>{option.name}</div>
                                    </div>
                                );
                            }}
                            getOptionValue={(option) => {
                                return option.id;
                            }}
                            onClick={getAllCityList}
                            onChange={cityChange}
                            onMenuScrollToBottom={paginateCityList}
                            onInputChange={onCityInputChange}
                        />
                        {/* <small className="form-text  error-text m-0" style={{ visibility: state.preferredLocations.error ? 'visible' : 'hidden' }}>{state.preferredLocations.error}</small> */}
                    </div>
                </div>
            </div>
            <div className={`cs-table-wrapper ${loading || error ? 'overflow-hidden' : ''}`}>
                {loading &&
                    <div className='table-loader-wrapper  position-absolute w-100 h-100 '>
                        <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                            <div className='font-weight-bold loader-text'>Loading Data </div>
                            <div className="spinner-grow spinner-grow-sm-loader" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="spinner-grow spinner-grow-sm-loader" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="spinner-grow spinner-grow-sm-loader" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                }
                {!loading && error &&
                    <div className='table-loader-wrapper  position-absolute w-100 h-100 '>
                        <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                            <div className='font-weight-bold loader-text'>Something went wrong, Please try again! </div>
                        </div>
                    </div>
                }
                {!loading && !error && listItem.length === 0 &&
                    <div className='table-loader-wrapper  position-absolute w-100 h-100 top-0 left-0'>
                        <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                            <div className='font-weight-bold loader-text'>No Data Available!!</div>
                        </div>
                    </div>
                }
                <table className="cs-list-table">
                    <thead className='cs-thead'>
                        <tr className='cs-row'>
                            {columns && columns.map((column, index) => {
                                return (
                                    <th className='cs-column' key={index}>
                                        <div className="cs-column-wrapper d-flex">
                                            <span className='column-label'>{column.label}</span>
                                            {column.sortable ?
                                                <div className="sort-icon-wrapper">
                                                    <IconButton aria-label="sort"
                                                        className={sort.name === column.value ? 'active-sort' : ''}
                                                        size="small" onClick={() => doSort(column)}>
                                                        {sort.name === column.value && sort.type === 'dsc' ? <ArrowDownwardIcon fontSize="inherit" /> :
                                                            <ArrowUpwardIcon fontSize="inherit" />}
                                                    </IconButton>
                                                </div> : ""
                                            }
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody className='cs-tbody'>
                        {(!loading && !error) && listItem.map((list, index) => {
                            return (
                                <tr className='cs-row' key={'row-' + index}>

                                    {columns.map((col, key) => {
                                        return (
                                            <td className='cs-cell' key={'cell-' + key}>
                                                {col.render ? col.render(list[col['value']], index, list) : list[col['value']]}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className='row cs-table-pagination'>
                <div className="col-auto align-items-center">
                    <select id="showPerPage" className="per-page-select" defaultValue={perPageItem} onChange={perPageItemChange}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    <label className="per-page-label">Per Page</label>
                </div>
                <div className='col'></div>
                <div className="col-auto pagination-wrapper align-items-center d-flex">
                    {paginationObj && <div className="count-wrapper">
                        {countStarting}-{countLast} out of {paginationObj.totalCount}
                    </div>}
                    <div className='pagination'>
                        <button className="pagination-btn first-page"
                            disabled={paginationObj.currentPage === 1}
                            onClick={() => { goToPage(1) }}><FirstPageIcon></FirstPageIcon></button>
                        <button className="pagination-btn previous-page"
                            disabled={paginationObj.currentPage === 1}
                            onClick={() => { goToPage(1) }}><NavigateBeforeIcon></NavigateBeforeIcon></button>
                        <button className="pagination-btn next-page"
                            disabled={paginationObj.currentPage === totalPageCount}
                            onClick={() => { goToPage(paginationObj.currentPage + 1) }}><NavigateNextIcon></NavigateNextIcon></button>
                        <button className="pagination-btn last-page"
                            disabled={paginationObj.currentPage === totalPageCount}
                            onClick={() => { goToPage(totalPageCount) }}><LastPageIcon></LastPageIcon></button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CandidateTableComponent;