import React, { useState, useEffect } from 'react';
import ActionListIcon from '@material-ui/icons/MoreVert';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import Checkbox from '../../component/Checkbox';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';

export const CandidateListView = (props) => {
    const { userType } = useParams();
    //console.log("client list",props);
    const [rowSelected, setSelectedRow] = useState(false);

    useEffect(() => {
        if (props.rowSelected != 'intermediate') {
            setSelectedRow(props.rowSelected);
        }
    }, [props.rowSelected]);

    const onCheckBoxChange = (param) => {
        //console.log(' checkbox param',param);
        setSelectedRow(param);
        props.onRowChange(props.list.id, rowSelected);
    }
    const doAction = (e) => {
        //console.log("doing action");
        props.callAction(e, [props.list.id]);
    }

    return (
        <li className="list">
            <div className="container-fluid">
                <div className="row no-gutters">
                    <div className="checbox-content col-auto m-auto">
                        <Checkbox value={rowSelected} classes={'staff-list-checkbox'} change={onCheckBoxChange}></Checkbox>
                    </div>
                    <div className={props.list.isArchived ? 'list-content col row no-gutters archived' : props.list.isActive ? 'list-content col row no-gutters active' : 'list-content col row no-gutters inactive'}>
                        {
                            props.list.personal.profilePic ?
                                <img className="user-image col-1 " src={props.list.personal.profilePic.href}></img> :
                                <div className="user-no-image col-1"><div className="margin-auto">No Image</div></div>

                        }

                        <b className="user-name col-2 text-truncate"><Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/candidate/view/${props.list.id}`} >{`${props.list.personal.firstName} ${props.list.personal.middleName || ''} ${props.list.personal.lastName}`} </Link></b>
                        <span className="row-text col-2 text-truncate"> {props.list.email}</span>
                        <div className="col-2 row-text text-truncate">
                            <span className="text-label">Role - </span>
                            <span className="text-value">{props.list.role ? props.list.role.name : ' NA '}</span>
                        </div>
                        <div className="col-2 row-text text-truncate">
                            <span className="text-label">Experience - </span>
                            <span className="text-value">{`${props.list.experienceInYr || 0} yr ${props.list.experienceInMnth || 0} mn`}</span>
                        </div>
                        <div className="col-1 m-auto d-block text-truncate">
                            {props.list.isArchived ? <span className="badge badge-danger">Archived</span> : props.list.isActive ? <span className="badge badge-success">Active</span> : <span className="badge badge-warning">Inactive</span>}
                        </div>
                        <div className="action col-1">
                            <div className="btn-group action">
                                <ActionListIcon className="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                <ul className="dropdown-menu">
                                    <li className="dropdown-item"><Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/candidate/view/${props.list.id}`}>View </Link></li>
                                    {props.permission.indexOf('update') > -1 ? <li className="dropdown-item"><Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/candidate/update/${props.list.id}`}>Edit </Link></li> : ''}
                                    {props.permission.indexOf('update') > -1 && props.list.isArchived ? <li className="dropdown-item" data-value="unarchive" onClick={doAction}>Unarchive</li> : ''}
                                    {props.permission.indexOf('delete') > -1 && !props.list.isArchived ? <li className="dropdown-item" data-value="archive" onClick={doAction}>Archive</li> : ''}
                                    {props.permission.indexOf('update') > -1 && !props.list.isArchived && props.list.isActive ? <li className="dropdown-item" data-value="deactivate" onClick={doAction}>Deactivate</li> : ''}
                                    {props.permission.indexOf('update') > -1 && !props.list.isArchived && !props.list.isActive ? <li className="dropdown-item" data-value="activate" onClick={doAction}>Activate</li> : ''}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

const List = (props) => {
    return (
        <div></div>

    );
}

export default List;