import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import NoDataFound from '../../common/NoDataFound';
import CandidateService from '../../service/SearchCandidateService';
import Loader from '../../component/Loader';
import ConfirmModal from '../../component/modal/ConfirmModal';
import ErrorModal from '../../component/modal/ErrorModal';
import SuccessModal from '../../component/modal/SuccessModal';
import Moment from 'react-moment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DoneIcon from '@material-ui/icons/Done';
import GraduateImg from '../../../assets/imgs/graduate.png';
import CarrerImg from '../../../assets/imgs/working_women.png';
import AddressImg from '../../../assets/imgs/house.png'
import FileViewerModal from '../../component/modal/FileViewerPopup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import userLogo from '../../../assets/imgs/male-avatar.png';
import SubmitProfile from '../../component/modal/SubmitProfile';
import GetAppIcon from '@material-ui/icons/GetApp';
import UnAuthorised from '../UnAuthorised';
import LocalStorageService from "../../service/LocalstorageService";
const localService = LocalStorageService.getService();

const NoData = (props) => {
    return (
        <div className="content-wrapper">
            <div className="content-container">
                <NoDataFound name={props.name} redirectUrl={props.redirectUrl}></NoDataFound>
            </div>
        </div>
    )
}

const CandidateView = (props) => {
    const { id, userType } = useParams();
    const [loading, setLoading] = useState(true);
    const [candidateData, setCandidateData] = useState({});
    const [errModel, setErrorModal] = useState({ show: false, error: {} })
    const [successModel, setSuccessModal] = useState({ show: false, success: {} })
    const [confirmationObj, setConfirmationObj] = useState({ show: false, msg: '' });
    const [currentAction, setCurrentAction] = useState('');
    const [fileViewObj, setFileViewObj] = useState({ show: false, file: {} });
    const jobPostId = localStorage.getItem('jobPostId')
    const jobPostTitle = localStorage.getItem('jobPostTitle')
    const [submProfObj, setSubmProfObj] = useState({ show: false, candidateId: null });
    const [unauthorised, setUnauthorised] = useState(false);

    useEffect(() => {
        getCandidateDetails();
    }, []);


    const getCandidateDetails = async () => {
        CandidateService.getCandidateDetails(id).then((res) => {
            if (res.status === 201 || res.status === 200) {
                setCandidateData(res.data.result)
                setLoading(false);
            }
        }, (err) => {
            setErrorModal({ 'show': true, 'error': err.data })
            setLoading(false);
            if (err.status === 401) {
                setErrorModal({ 'show': true, 'error': err.data })
                setLoading(false);
                setUnauthorised(true);
                setTimeout(() => {
                    history.push(props.location.state.prevPath)
                }, 2000)
                // history.push(`/${localService.getRoutePrefix(userType)}/dashboard`);
            }
        })
    }

    const getLastUpdated = (date) => {
        var today = new Date()
        var date = new Date(date)
        var seconds = Math.floor((today - date) / 1000)
        var interval;
        var lastUpdOn;
        if (Math.floor(seconds / 31536000) >= 1) {
            interval = Math.floor(seconds / 31536000)
            if (interval == 1) {
                lastUpdOn = interval + " year"
            }
            else {
                lastUpdOn = interval + " years"
            }
        }
        else if (Math.floor(seconds / 2592000) >= 1) {
            interval = Math.floor(seconds / 2592000)
            if (interval == 1) {
                lastUpdOn = interval + " month"
            }
            else {
                lastUpdOn = interval + " months"
            }
        }
        else if (Math.floor(seconds / 86400) >= 1) {
            interval = Math.floor(seconds / 86400)
            if (interval == 1) {
                lastUpdOn = interval + " day"
            }
            else {
                lastUpdOn = interval + " days"
            }
        }
        else if (Math.floor(seconds / 3600) >= 1) {
            interval = Math.floor(seconds / 3600)
            if (interval == 1) {
                lastUpdOn = interval + " hour"
            }
            else {
                lastUpdOn = interval + " hours"
            }
        }
        else if (Math.floor(seconds / 60) >= 1) {
            interval = Math.floor(seconds / 60)
            if (interval == 1) {
                lastUpdOn = interval + " minute"
            }
            else {
                lastUpdOn = interval + " minutes"
            }
        }
        else if (Math.floor(seconds) >= 1) {
            interval = Math.floor(seconds)
            if (interval == 1) {
                lastUpdOn = interval + " second"
            }
            else {
                lastUpdOn = interval + " seconds"
            }
        }
        return lastUpdOn
    }

    const openFileReader = () => {
        console.log("file reader function called");
        setFileViewObj({ show: true, file: candidateData.professional.resume });
    }

    const closePopup = () => {
        setConfirmationObj({ show: false, msg: '' });
        setCurrentAction("");
    }

    const closeSubmProfModal = () => {
        setSubmProfObj({ show: false, candidateId: '' })
    }

    const performAction = (e) => {
        //console.log("action",e);
        e.persist()
        e.preventDefault();
        const action = e.target.dataset.value;
        setCurrentAction({
            action: action,
            data: {
                "postingId": jobPostId,
                "candidateId": candidateData.id
            }
        });
        setConfirmationObj({ show: true, msg: `You want to ${action} this candidate?` });
    }

    const doAction = async () => {
        setLoading(true);
        setConfirmationObj({ show: false, msg: '' });

        try {
            if (currentAction.action === 'select') {
                const res = await CandidateService.selectCandidate(currentAction.data);
            }
            else {
                const res = await CandidateService.rejectCandidate(id);
            }
            setCurrentAction({});
            setLoading(false);
            //console.log("success res", res);
            if (res.status === 200) {
                setSuccessModal({ show: true, msg: res.data.result });
                getCandidateDetails();
            }
        } catch (err) {
            //console.log(err);
            setLoading(false);
            if (err && err.data) {
                setErrorModal({ show: true, error: err.data })
            }
        }
    }

    const downloadCv = async (cand) => {
        var a = document.createElement('a');
        a.target = '_blank';
        a.href = localService.getServerIp() + 'candidate/user/custom-cv/' + cand.id + '?token=' + JSON.parse(localStorage.getItem('access_token'));
        a.click();

    }

    const originalCv = async (profile_url) => {
        var a = document.createElement('a');
        a.target = '_blank';
        a.href = profile_url;
        a.click();
    }

    const getfileExtension = (fileName) => {
        const lastDot = fileName.lastIndexOf('.');
        const extension = fileName.substr(lastDot, fileName.length);
        return extension;
    }

    const getfileName = (fileName) => {
        const lastDot = fileName.lastIndexOf('.');
        const name = fileName.substr(0, lastDot);
        return name;
    }

    if (loading) return <Loader />;


    return (
        <div className="content-wrapper p-0" id="condidate-details-container">
            { unauthorised ? <UnAuthorised hideNavbar="true"></UnAuthorised> : 
            <>
                <div className="row cs-breadcrumb no-gutters w-100">
                    <ol className="breadcrumb w-100 mb-0">
                        <li className="breadcrumb-item active">
                            <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/search-candidate`}>Search Applicants</Link>
                        </li>
                        <li className="breadcrumb-item">
                            {`${candidateData?.personal?.firstName} ${candidateData?.personal?.middleName || ''} ${candidateData?.personal?.lastName}`}
                        </li>
                    </ol>
                </div>
                <div className="row summary-section">
                    <div className="col-xl-2 col-lg-2 col-md-3 row">
                        <div className="col image-wrapper text-center">
                            {candidateData.personal && candidateData.personal.profilePic ?
                                <img src={candidateData.personal.profilePic.href} className="profile-pic" /> :
                                <img className="profile-pic" src={userLogo}></img>
                            }
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-10 col-md-9 row">
                        <div className="col-12 row align-items-center my-1">
                            <div className="name col-auto">{`${candidateData?.personal?.firstName} ${candidateData?.personal?.middleName || ''} ${candidateData?.personal?.lastName}`}</div>
                            {candidateData.isActive ? (
                                <span className="badge badge-success col-auto" title="Account Status">Active</span>
                            ) : (
                                <span className="badge badge-warning" title="Account Status">Inactive</span>
                            )}
                            &nbsp;&nbsp;{!candidateData.hasEmailVerified ? <span className="badge badge-danger" title="Email Not Verified"> NOT VERIFIED</span> : <span className="badge badge-primary" title="Email Verified"> VERIFIED</span>}
                            <div className="col"></div>
                            <button type="button" className="btn btn-primary" data-value="select" onClick={() => { setSubmProfObj({ show: true, candidateId: id }) }}><DoneIcon></DoneIcon>&nbsp;<b>{userType=='cli' ? 'Select Profile':'Suggest this Profile'}</b></button>
                        </div>
                        <div className="col-12 row my-1">
                            <p className="col-12 m-0" title='Current Organization and Profile Update Info'>
                                {candidateData?.summary?.currentEmployer ? `Working at - ${candidateData.summary.currentEmployer}` : ''}
                                &nbsp;&nbsp;<b>|</b>&nbsp;{candidateData.updatedOn ? <span className="badge badge-secondary" title="Last Updated On" style={{ color: '#f7f761' }}> Last Updated : {getLastUpdated(candidateData.updatedOn)} ago</span> : 'NA'}
                            </p>
                        </div>
                        <div className="col-12 row my-1">
                            {candidateData.professional && candidateData.professional.currentLocation ?
                                <div className="col-auto location">
                                    <LocationOnIcon></LocationOnIcon>
                                    <span className="mx-2">{`${candidateData.professional.currentLocation
                                        .town ? candidateData.professional.currentLocation
                                        .town : ''
                                        } 
                                        ${candidateData.professional.currentLocation
                                            .city
                                            ? candidateData.professional
                                                .currentLocation.city.name
                                            : ""
                                        },
                                        ${candidateData.professional.currentLocation
                                            .state
                                            ? candidateData.professional
                                                .currentLocation.state.name
                                            : ""
                                        },
                                        India`}</span>
                                </div> : ''}
                        </div>
                        <div className="col-12 row my-1">
                            <div className="col-xl-6 col-lg-6 col-md-6 text-truncate">
                                Contact Email:{" "}
                                <span className="text-color-2">
                                    {candidateData?.personal?.email}
                                </span>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 text-truncate">
                                Contact Phone Number:{" "}
                                <span className="text-color-2">
                                    {candidateData?.personal?.contactNumber}
                                </span>
                            </div>
                        </div>
                        <div className="col-12 row my-1">
                            <div className="col-xl-6 col-lg-6 col-md-6 text-truncate">
                                Date Of Birth:{" "}
                                <span className="text-color-2">
                                    {candidateData?.personal?.dateOfBirth ?
                                        <Moment
                                            date={candidateData?.personal?.dateOfBirth}
                                            format={"DD MMMM YYYY"}
                                        ></Moment>
                                    : 'NA' }
                                </span>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 text-truncate">
                                Total Experience:{" "}
                                <span className="text-color-2">
                                    {
                                        candidateData?.professional?.experienceInYr || candidateData?.professional?.experienceInMnth ?
                                            `${candidateData.professional.experienceInYr || 0} Year ${candidateData.professional.experienceInMnth || 0} Month` : "NA"
                                    }{" "}
                                </span>
                            </div>
                        </div>
                        <div className="col-12 row my-1">
                            <div className="col-xl-6 col-lg-6 col-md-6 text-truncate">
                                Current CTC:{" "}
                                <span className="text-color-2">
                                    {" "}
                                    {
                                        candidateData?.professional?.currentCtcInLkh || candidateData?.professional?.currentCtcInThnd ?
                                            `${candidateData.professional.currentCtcInLkh || 0} Lakhs ${candidateData.professional.currentCtcInThnd || 0} Thousand` : "NA"}
                                </span>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 text-truncate">
                                Expected CTC:
                                <span className="text-color-2">
                                    {" "}
                                    {
                                        candidateData?.professional?.expectedCtcInLkh || candidateData?.professional?.expectedCtcInThnd ?
                                            `${candidateData.professional.expectedCtcInLkh || 0} Lakhs ${candidateData.professional.expectedCtcInThnd || 0} Thousand` : "NA"}{" "}
                                </span>
                            </div>
                        </div>
                        <div className="col-12 row my-1">
                            <div className="col-xl-6 col-lg-6 col-md-6 text-truncate">
                                Category:{" "}
                                <span className="text-color-2">
                                    {candidateData?.summary?.category
                                        ? candidateData.summary.category.name
                                        : "NA"}{" "}
                                </span>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 text-truncate">
                                Role:{" "}
                                <span className="text-color-2">
                                    {" "}
                                    {candidateData?.summary?.role
                                        ? candidateData.summary.role.name
                                        : "NA"}
                                </span>
                            </div>
                        </div>
                        <div className="col-12 row my-1">
                            <div className="col-auto">Profile Summary: </div>
                            <div className="col text-color-2">{candidateData?.summary?.text} </div>
                        </div>
                        {/* <div className="col-12 row my-1">
                            <div className="col-xl-4 col-lg-4 col-md-6 text-truncate">Emailss:  <span className="text-color-2">{candidateData.personal.email}</span></div>
                            <div className="col-xl-4 col-lg-4 col-md-6 text-truncate">Contact Number: <span className="text-color-2">{candidateData.personal.contactNumber}</span></div>
                            <div className="col-xl-4 col-lg-4 col-md-6 text-truncate">DOB: <span className="text-color-2"><Moment date={candidateData.personal.dateOfBirth} format={"DD MMMM YYYY"}></Moment></span></div>
                        </div>
                        <div className="col-12 row my-1">
                            <div className="col-xl-4 col-lg-4 col-md-6 text-truncate">Experience:  <span className="text-color-2">{
                                candidateData?.professional?.experienceInYr || candidateData?.professional?.experienceInMnth ?
                                    `${candidateData.professional.experienceInYr || 0} Year ${candidateData.professional.experienceInMnth || 0} Month` : "NA"
                            } </span></div>
                            <div className="col-xl-4 col-lg-4 col-md-6 text-truncate">Current CTC: <span className="text-color-2"> {
                                candidateData?.professional?.currentCtcInLkh || candidateData?.professional?.currentCtcInThnd ?
                                    `${candidateData.professional.currentCtcInLkh || 0} Lakhs ${candidateData.professional.currentCtcInThnd || 0} Thousand` : "NA"}</span></div>
                            <div className="col-xl-4 col-lg-4 col-md-6 text-truncate">Expected CTC:<span className="text-color-2"> {
                                candidateData?.professional?.expectedCtcInLkh || candidateData?.professional?.expectedCtcInThnd ?
                                    `${candidateData.professional.expectedCtcInLkh || 0} Lakhs ${candidateData.professional.expectedCtcInThnd || 0} Thousand` : "NA"}  </span></div>
                        </div>
                        <div className="col-12 row my-1">
                            <div className="col-xl-4 col-lg-6 col-md-6 text-truncate">Category:  <span className="text-color-2">{candidateData.summary.category ? candidateData.summary.category.name : "NA"} </span></div>
                            <div className="col-xl-4 col-lg-6 col-md-6 text-truncate">Role: <span className="text-color-2"> {candidateData.summary.role ? candidateData.summary.role.name : "NA"}</span></div>
                        </div>
                        <div className="col-12 row my-1">
                            <div className="col-auto">Summary: </div>
                            <div className="col">{candidateData.summary.text} </div>
                        </div> */}
                    </div>

                </div>
                <div className="cont-section m-3">
                    <div className="container py-4">
                        <div className="row align-items-center no-gutters">
                            {candidateData.personal ? 
                            <div className="card col-12 step-card">
                                <div className="card-header">
                                    Personal Details
                                </div>
                                <div className="card-body">
                                    <div className="row no-gutters">
                                        <div className="col-12 row no-gutters">
                                            <div className="row col-6">
                                                <div className="col-6 label">Name :</div>
                                                <div className="col-6 value">{`${candidateData.personal.firstName} ${candidateData.personal.middleName || ''} ${candidateData.personal.lastName}`}</div>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label">DOB :</div>
                                                <div className="col-6 value">{candidateData.personal.dateOfBirth ? <Moment date={candidateData.personal.dateOfBirth} format={"MM-DD-YYYY"}></Moment> : "NA"}</div>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label">Email :</div>
                                                <div className="col-6 value">{candidateData.personal.email}</div>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label">Alternate Email :</div>
                                                <div className="col-6 value">{candidateData.personal.alternateEmail} </div>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label">Contact Number :</div>
                                                <div className="col-6 value">{candidateData.personal.contactNumber} </div>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label">Alternate Contact Number :</div>
                                                <div className="col-6 value">{candidateData.personal.alternateNumber} </div>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label">Gender :</div>
                                                <div className="col-6 value">{candidateData.personal.gender} </div>
                                            </div>
                                            <div className="row col-6">
                                                <div className="col-6 label">Marital Status :</div>
                                                <div className="col-6 value">{candidateData.personal.maritalStatus} </div>
                                            </div>
                                            {candidateData.personal.maritalStatus === 'MARRIED' ?
                                                <div className="row col-6">
                                                    <div className="col-6 label">Is Spouse Doctor :</div>
                                                    <div className="col-6 value">{candidateData.personal.isSpouceDoctrate ? 'Yes' : 'No'} </div>
                                                </div>
                                                : ''}

                                            {candidateData.personal.maritalStatus === 'MARRIED' && candidateData.personal.isSpouceDoctrate ?
                                                <div className="row col-6">
                                                    <div className="col-6 label">Spouse Specialization :</div>
                                                    <div className="col-6 value">{candidateData.personal.spouceDetail} </div>
                                                </div>
                                                : ''}
                                            <div className="row col-6">
                                                <div className="col-6 label">Spoken Language :</div>
                                                <div className="col-6 value">{candidateData.personal.spokenLanguages} </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>:''}

                            {candidateData.professional ?
                                <div className="card col-12 step-card">
                                    <div className="card-header">
                                        Professional Details
                                    </div>
                                    <div className="card-body">
                                        <div className="row no-gutters">
                                            <div className="col-12 row no-gutters">
                                                <div className="row col-6">
                                                    <div className="col-6 label">Total Experience :</div>
                                                    <div className="col-6 value">{
                                                        candidateData?.professional?.experienceInYr || candidateData?.professional?.experienceInMnth ?
                                                            `${candidateData.professional.experienceInYr || 0} Year ${candidateData.professional.experienceInMnth || 0} Month` : "NA"
                                                    } </div>
                                                </div>
                                                <div className="row col-6">
                                                    <div className="col-6 label">Current CTC :</div>
                                                    <div className="col-6 value">{
                                                        candidateData?.professional?.currentCtcInLkh || candidateData?.professional?.currentCtcInThnd ?
                                                            `${candidateData.professional.currentCtcInLkh || 0} Lakhs ${candidateData.professional.currentCtcInThnd || 0} Thousand` : "NA"} </div>
                                                </div>
                                                <div className="row col-6">
                                                    <div className="col-6 label">Expected CTC :</div>
                                                    <div className="col-6 value">{
                                                        candidateData.professional.expectedCtcInLkh || candidateData.professional.expectedCtcInThnd ?
                                                            `${candidateData.professional.expectedCtcInLkh || 0} Lakhs ${candidateData.professional.expectedCtcInThnd || 0} Thousand` : "NA"}  </div>
                                                </div>
                                                <div className="row col-6">
                                                    <div className="col-6 label">Preferred Locations :</div>
                                                    <div className="col-6 value">{candidateData.professional && candidateData.professional.preferredLocations.length > 0 ?
                                                        candidateData.professional.preferredLocations.map((item, index) => {
                                                            return <span className="badge badge-primary mx-1" key={index}>{item.name}</span>
                                                        }) : "NA"
                                                    } </div>
                                                </div>
                                                <div className="row col-6">
                                                    <div className="col-6 label">Category :</div>
                                                    <div className="col-6 value">{candidateData.professional.category ? candidateData.professional.category.name : "NA"} </div>
                                                </div>
                                                <div className="row col-6">
                                                    <div className="col-6 label">Role :</div>
                                                    <div className="col-6 value">{candidateData.professional.role ? candidateData.professional.role.name : "NA"} </div>
                                                </div>
                                                <div className="col-12 row">
                                                    <b className="col-12 p-2">Current Address</b>
                                                </div>
                                                <div className="row col-6">
                                                    <div className="col-6 label"> State:</div>
                                                    <div className="col-6 value">{candidateData.professional.currentLocation && candidateData.professional.currentLocation.state ? candidateData.professional.currentLocation.state.name : ''} </div>
                                                </div>
                                                <div className="row col-6">
                                                    <div className="col-6 label"> City:</div>
                                                    <div className="col-6 value">{candidateData.professional.currentLocation && candidateData.professional.currentLocation.city ? candidateData.professional.currentLocation.city.name : ''} </div>
                                                </div>
                                                <div className="row col-6">
                                                    <div className="col-6 label"> City:</div>
                                                    <div className="col-6 value">{candidateData.professional.currentLocation && candidateData.professional.currentLocation.town ? candidateData.professional.currentLocation.town : ''} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}

                            {candidateData.qualifications && candidateData.qualifications.length ?
                                <div className="card col-12 step-card">
                                    <div className="card-header">
                                        Educational Details
                                    </div>
                                    <div className="card-body">
                                        <div className="row no-gutters">
                                            <div className="col-7 row no-gutters">
                                                {candidateData.qualifications.map((item, index) => {
                                                    return (
                                                        <div className="row col-12 ml-3 py-4 border-grey" key={index}>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">
                                                                    <span className="count">{index + 1}</span>
                                                                    <span className="float-left">Qualification :</span>
                                                                </div>
                                                                <div className="col-7 value">{item.qualification.name || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Course :</div>
                                                                <div className="col-7 value">{item.course.name || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Specialization :</div>
                                                                <div className="col-7 value">{item.specialization.name || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Joining Year :</div>
                                                                <div className="col-7 value">{item.joinedOn || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Passing Year :</div>
                                                                <div className="col-7 value">{item.passoutYear || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Currently Pursuing :</div>
                                                                <div className="col-7 value">{item.isCurrentlyPursuing ? 'YES' : 'NO'} </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="col-5 row no-gutters align-items-center">
                                                <div className="margin-auto">
                                                    <img src={GraduateImg} height="300"></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}

                            {candidateData.careers && candidateData.careers.length ?
                                <div className="card col-12 step-card">
                                    <div className="card-header">
                                        Carrer Details
                                    </div>
                                    <div className="card-body">
                                        <div className="row no-gutters">
                                            <div className="col-7 row no-gutters">
                                                {candidateData?.careers.map((item, index) => {
                                                    return (
                                                        <div className="row col-12 ml-3 py-4 border-grey" key={index}>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">
                                                                    <span className="count">{index + 1}</span>
                                                                    <span className="float-left">Employer :</span>
                                                                </div>
                                                                <div className="col-7 value">{item.employer || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Designation :</div>
                                                                <div className="col-7 value">{item.designation || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Category :</div>
                                                                <div className="col-7 value">{item.category.name || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Role :</div>
                                                                <div className="col-7 value">{item.role.name || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Joined Year :</div>
                                                                <div className="col-7 value">{item.joinedOn || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Last Working Year :</div>
                                                                <div className="col-7 value">{item.seperatedOn || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Currently Working :</div>
                                                                <div className="col-7 value">{item.isCurrentlyWorking ? 'YES' : 'NO'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">CTC (In Lakhs):</div>
                                                                <div className="col-7 value">{item.annualCtc || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Job Location:</div>
                                                                <div className="col-7 value">{item.jobLocation ? `${item.jobLocation.town ? item.jobLocation.town + "," : ''} ${item.jobLocation.city.name}, ${item.jobLocation.state.name}` : 'NA'} </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="col-5 row no-gutters align-items-center">
                                                <div className="margin-auto">
                                                    <img src={CarrerImg} height="300"></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}

                            {candidateData.addresses && candidateData.addresses.length ?
                                <div className="card col-12 step-card">
                                    <div className="card-header">
                                        Address
                                    </div>
                                    <div className="card-body">
                                        <div className="row no-gutters">
                                            <div className="col-7 row no-gutters">
                                                {candidateData.addresses.map((item, index) => {
                                                    return (
                                                        <div className="row col-12 ml-3 py-4 border-grey" key={index}>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">
                                                                    <span className="count">{index + 1}</span>
                                                                    <span className="float-left">Address Type :</span>
                                                                </div>
                                                                <div className="col-7 value">{item.addressType || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Address Line 1 :</div>
                                                                <div className="col-7 value">{item.lineOne || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Address Line 2 :</div>
                                                                <div className="col-7 value">{item.lineTwo || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Town :</div>
                                                                <div className="col-7 value">{item.town || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">City :</div>
                                                                <div className="col-7 value">{item.city.name || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">State :</div>
                                                                <div className="col-7 value">{item.state.name || 'NA'} </div>
                                                            </div>
                                                            <div className="row col-12">
                                                                <div className="col-5 label">Current Address :</div>
                                                                <div className="col-7 value">{item.isCurrentAddress ? 'YES' : 'NO'} </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="col-5 row no-gutters align-items-center">
                                                <div className="margin-auto">
                                                    <img src={AddressImg} height="300"></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                            <div className="row no-gutters col-12">
                                <div className="col-9 row no-gutters">
                                    <div className="col text-wrap row no-gutters resume p-0">
                                        {candidateData.professional && candidateData.professional.resume ?
                                            <div className="col-12 row no-gutters p-2 align-items-center">
                                                <p className="col-auto extension p-3">{getfileExtension(candidateData.professional.resume.originalName)}</p>&nbsp;
                                                <div className="col-8 row no-gutters p-3 file-name m-0">
                                                    <p className="col-12 text-truncate m-0 ">{getfileName(candidateData.professional.resume.originalName)}</p>
                                                    <p className="col-12 text-truncate m-0">{candidateData.professional.resume.size}</p>
                                                </div>&nbsp;
                                                <a type="button" className="btn file-view-btn p-3 col-auto" title='View Resume' onClick={openFileReader}>
                                                    <VisibilityIcon></VisibilityIcon>
                                                </a>&nbsp;
                                                <a type="button" className="btn file-view-btn p-3 col-auto" title='Download Resume' onClick={() => originalCv(candidateData.professional.resume.href)}>
                                                    <GetAppIcon></GetAppIcon>
                                                </a>
                                            </div>
                                            : ""
                                        }
                                    </div>
                                </div>
                                <div className="col-3 row align-items-center justify-content-center">
                                    <button type="button" className="btn btn-primary custom_cv_btn" onClick={() => downloadCv(candidateData)}>
                                        <p className="m-0"><GetAppIcon></GetAppIcon>&nbsp;&nbsp;&nbsp; Custom Resume</p>
                                    </button>
                                </div>
                            </div>
                            {/* <div className="row no-gutters col-12">
                                <div className="col-9  row no-gutters p-2">
                                    <div className="col text-wrap row no-gutters resume p-0">
                                        {candidateData.professional && candidateData.professional.resume ?
                                            <div className="col-12 row no-gutters p-2 align-items-center">
                                                <p className="col-auto extension p-3">{getfileExtension(candidateData.professional.resume.originalName)}</p>
                                                <div className="col-8 row no-gutters p-3 file-name m-0">
                                                    <p className="col-12 text-truncate m-0 ">{getfileName(candidateData.professional.resume.originalName)}</p>
                                                    <p className="col-12 text-truncate m-0">{candidateData.professional.resume.size}</p>
                                                </div>
                                                <a type="button" className="btn file-view-btn p-3 col-auto" onClick={openFileReader}>
                                                    <VisibilityIcon></VisibilityIcon>
                                                </a>
                                            </div>
                                            : ""
                                        }
                                    </div>
                                </div>
                                <div className="col-3 p-2 row align-items-center justify-content-center">
                                    <button type="button" className="btn btn-info custom_cv_btn p-2" onClick={() => downloadCv(candidateData)}>
                                        <p className="m-0">Download Custom CV <GetAppIcon /></p>
                                    </button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </>
            }
            <SubmitProfile data={submProfObj} onCancel={closeSubmProfModal}></SubmitProfile>
            <ConfirmModal data={confirmationObj} onConfirmAction={doAction} onCancel={closePopup}></ConfirmModal>
            <ErrorModal data={errModel} onConfirmAction={() => setErrorModal({ show: false, error: {} })} ></ErrorModal>
            <FileViewerModal data={fileViewObj} onCancel={() => setFileViewObj({ show: false, file: {} })}></FileViewerModal>
        </div>
    )
}

export default CandidateView;