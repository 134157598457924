import React, { useState, useEffect } from "react";
import UseForm from "../../service/FormService";
import ManageService from "../../service/ManageService";
import $ from "jquery";
import { ErrorToaster } from "../../component/modal/ErrorModal";

const stateSchema = {
  name: { value: "", error: "" },
};

const validationStateSchema = {
  name: {
    required: true,
  },
};

const AddQualification = (props) => {
  const [visible, setVisible] = useState(props.data.show || false);
  const [intialSchema, setInitionalSchema] = useState(
    $.extend(true, {}, stateSchema)
  );
  const [errorObj, setErrorObj] = useState({ show: false, msg: "" });

  useEffect(() => {
    setVisible(props.data.show);
    if (!props.data.show) {
      setInitionalSchema($.extend(true, {}, stateSchema));
      setErrorObj({ show: false, msg: "" });
    } else {
      let schema = $.extend(true, {}, stateSchema);
      schema.name.value = props.data.qualification.name;
      props.data.qualification && props.data.qualification.id
        ? setInitionalSchema(schema)
        : "";
    }
  }, [props]);

  const hideModal = () => {
    setVisible(false);
    props.onCancel();
  };

  const closeToast = (e) => {
    e.preventDefault();
    setErrorObj({ show: false, msg: "" });
  };

  const onSubmitForm = (state) => {
    const requestPayload = {
      name: state.name.value,
    };
    //console.log("requestPayload",requestPayload);
    if (props.data.qualification && props.data.qualification.id) {
      ManageService.updateQualification(
        props.data.qualification.id,
        requestPayload
      ).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            props.onConfirmAction(res.data.result);
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    } else {
      ManageService.createQualification(requestPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            props.onConfirmAction(res.data.result);
          }
        },
        (err) => {
          setErrorObj({ show: true, msg: err.data.message });
          setTimeout(() => {
            setErrorObj({ show: false, msg: "" });
          }, 3000);
        }
      );
    }
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    intialSchema,
    validationStateSchema,
    onSubmitForm
  );

  return (
    <div
      className="overlay "
      id="qualification-model"
      style={{ display: visible ? "block" : "none" }}
    >
      <div className="overlay-content">
        <div className={visible ? " cs-modal showModal" : "cs-modal hideModal"}>
          <div className="header">
            {props.data.qualification && props.data.qualification.id
              ? "Update Qualification"
              : "Add Qualification"}{" "}
          </div>
          <div className="body">
            <div className="qualification-container">
              <form>
                <div className="row">
                  <div className="form-group col-12 m-0">
                    <label className="label-sm">
                      Qualification Name <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      onChange={handleOnChange}
                      value={state.name.value}
                    />
                    <small
                      className="form-text  error-text m-0"
                      style={{
                        visibility: state.name.error ? "visible" : "hidden",
                      }}
                    >
                      {state.name.error}
                    </small>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="footer">
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary mr-3"
              onClick={hideModal}
            >
              cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary"
              disabled={disable}
              onClick={handleOnSubmit}
            >
              Procced
            </button>
          </div>
        </div>
      </div>
      <ErrorToaster data={errorObj} onClose={closeToast}></ErrorToaster>
    </div>
  );
};

export default AddQualification;
