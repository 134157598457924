import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import QueryImage from "../../../../assets/imgs/query.png";


const QueryComponent = (props) => {
  return (
    <div id="employer-query">
    <div className="container">
      <div className="row section-header text-center p-4">
        <h2 className="col-md-12">Any Queries?</h2>
      </div>
      <div className="row">
        <div className=" col-md-6 col-lg-6 col-xl-6">
            <img src={QueryImage} className="img-fluid"></img>
        </div>
        <div className="form-section col-md-6 row">
          <div className="m-auto align-items-center">
            <div className="container">
              <div className="row p-4 text-center justify-items-center align-items-center">
                <h3 className="query-text col-md-12">
                For more information & queries <br/> Contact us  by cliking below!
                </h3>
                <Link className="col-md-12 my-4 mx-auto" to="/contact">
                  <button className="btn query-btn">
                    Contact Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default QueryComponent;