import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import UseForm from "../../service/FormService";
import StaffService from "../../service/StaffService";
import ErrorModal from "../../component/modal/ErrorModal";
import $ from "jquery";
import Loader from "../../component/Loader";

const emailRegx =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const initSchema = {
  firstName: { value: "", error: "" },
  lastName: { value: "", error: "" },
  email: { value: "", error: "" },
  contactNumber: { value: "", error: "" },
  gender: { value: "", error: "" },
  role: { value: "", error: "" },
  profilePic: { value: "", error: "" },
};

const validationStateSchema = {
  firstName: {
    required: true,
    validator: {
      regEx: /^([a-zA-Z]{3,})+$/,
      error: "Invalid first name format.",
    },
  },
  lastName: {
    required: true,
    validator: {
      regEx: /^([a-zA-Z]{3,})+$/,
      error: "Invalid last name format.",
    },
  },
  email: {
    required: true,
    validator: {
      regEx: emailRegx,
      error: "Invalid email Id format.",
    },
  },
  contactNumber: {
    required: true,
    validator: {
      regEx: /^([0-9]{10})$/,
      error: "Invalid phone number format.",
    },
  },
  gender: {
    required: true,
  },
  role: {
    required: true,
  },
  profilePic: {
    required: false,
  },
};

const resetObj = { value: true, error: "" };
const StaffEdit = () => {
  const { id, userType } = useParams();
  const history = useHistory();
  const [stateSchema, setStateSchema] = useState(initSchema);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [staffData, setStaffData] = useState({});
  const [profilePicValid, setprofilePicValid] = useState(resetObj);
  const [errModel, setErrorModal] = useState({ show: false, error: {} });

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    try {
      let staffDataRes = await StaffService.getStaffDetails(id);
      setStaffData(staffDataRes.data.result);
      let roleRes = await StaffService.getRole();
      setRoles(roleRes.data.result);
      updateSchema(staffDataRes.data.result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err && err.data) {
        setErrorModal({ show: true, error: err.data });
      }
    }
  };

  function onSubmitForm(state) {
    setLoading(true);
    const requestPayload = {
      firstName: state.firstName.value,
      lastName: state.lastName.value,
      email: state.email.value,
      roleId: state.role.value,
      gender: state.gender.value,
      contactNumber: state.contactNumber.value,
      profilePic: state.profilePic.value ? state.profilePic.value : null,
    };
    StaffService.updateStaffDetails(id, requestPayload).then(
      (res) => {
        //console.log('edit staff ',res);
        setLoading(false);
        if (res.status === 200) {
          history.goBack();
        }
      },
      (err) => {
        setLoading(false);
        if (err && err.data) {
          setErrorModal({ show: true, error: err.data });
        }
        //console.log('edit staff err',err);
      }
    );
  }

  const uploadFiles = async (obj) => {
    let formData = new FormData();
    formData.append("files", obj);
    formData.append("mediaType", "image");
    let response = "";
    try {
      response = await StaffService.uploadFile(formData);
    } catch (err) {
      if (err || err.data) {
        setErrorModal({ show: true, error: err.data || err });
      }
    }
    return response ? response.data.result[0] : "";
  };
  const deleteFile = async (id) => {
    try {
      await StaffService.deleteFile(id);
    } catch (err) {
      if (err || err.data) {
        setErrorModal({ show: true, error: err.data || err });
      }
    }
  };

  const onFileChange = async (e) => {
    e.persist();
    e.preventDefault();
    const file =
      e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
    const name = e.target.name;
    if (file) {
      const valid = file.size < 2 * 1024 * 1024 ? true : false;
      if (valid) {
        if (state.profilePic.value && state.profilePic.value.id) {
          await deleteFile(state.profilePic.value.id);
        }
        const res = await uploadFiles(file);
        setprofilePicValid({ value: true, error: "" });

        const event = { target: { name: "profilePic", value: res } };
        handleOnChange(event);
      } else {
        setprofilePicValid({
          value: false,
          error: "Maximum file size should not greater than 2MB",
        });
        const event = { target: { name: "profilePic", value: "" } };
        handleOnChange(event);
      }
    }
  };

  const updateSchema = (data) => {
    const schema = $.extend(true, {}, stateSchema);
    Object.keys(schema).forEach((key) => {
      if (data.hasOwnProperty(key) && data[key]) {
        if (key === "role") {
          schema[key].value = data[key].id;
        } else {
          schema[key].value = data[key];
        }
      }
    });
    setStateSchema(schema);
    //console.log("stateSchema",stateSchema)
  };

  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    stateSchema,
    validationStateSchema,
    onSubmitForm
  );

  if (loading) return <Loader />;
  return (
    <div className="content-wrapper" id="add-staff">
      <div className="content-container">
        <div className="form-wrapper">
          <div className="row h-100 w-100">
            <div className="container h-100 w-100">
              <div className="row align-items-center h-100 w-100">
                <div className="col-2"></div>
                <div className="col-8">
                  <div className="form-header text-center">
                    <span className="text-msg">Edit Staff</span>
                  </div>
                  <form onSubmit={handleOnSubmit}>
                    <div className="row">
                      <div className="form-group col-6 m-0">
                        <label className="label-sm">
                          First Name<sup className="required">*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          placeholder="First Name"
                          name="firstName"
                          value={state.firstName.value}
                          onChange={handleOnChange}
                        />
                        <small
                          className="form-text  error-text m-0"
                          style={{
                            visibility: state.firstName.error
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          {state.firstName.error}
                        </small>
                      </div>
                      <div className="form-group col-6 m-0">
                        <label className="label-sm">
                          Last Name<sup className="required">*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Last Name"
                          id="lastName"
                          name="lastName"
                          value={state.lastName.value}
                          onChange={handleOnChange}
                        />
                        <small
                          className="form-text  error-text m-0"
                          style={{
                            visibility: state.lastName.error
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          {state.lastName.error}
                        </small>
                      </div>
                    </div>
                    <div className="form-group m-0">
                      <label className="label-sm">
                        Email ID<sup className="required">*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="name@email.com"
                        id="email"
                        name="email"
                        value={state.email.value}
                        onChange={handleOnChange}
                      />
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.email.error ? "visible" : "hidden",
                        }}
                      >
                        {state.email.error}
                      </small>
                    </div>
                    <div className="form-group m-0">
                      <label className="label-sm">
                        Phone Number<sup className="required">*</sup>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="phone number"
                        id="contactNumber"
                        name="contactNumber"
                        value={state.contactNumber.value}
                        onChange={handleOnChange}
                      />
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.contactNumber.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {state.contactNumber.error}
                      </small>
                    </div>
                    <div className="form-group m-0">
                      <label className="label-sm">
                        Gender<sup className="required">*</sup>
                      </label>
                      <select
                        className="form-control"
                        name="gender"
                        id="gender"
                        onChange={handleOnChange}
                        value={state.gender.value}
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="prefer not to say">
                          Prefer Not To Say
                        </option>
                      </select>
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.gender.error ? "visible" : "hidden",
                        }}
                      >
                        {state.gender.error}
                      </small>
                    </div>
                    <div className="form-group m-0">
                      <label className="label-sm">
                        Role<sup className="required">*</sup>
                      </label>
                      <select
                        className="form-control"
                        name="role"
                        id="role"
                        onChange={handleOnChange}
                        value={state.role.value}
                      >
                        <option value="">Select Role</option>

                        {roles.map((role, index) => {
                          return (
                            <option key={index} value={role.id}>
                              {role.name}
                            </option>
                          );
                        })}
                      </select>
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.role.error ? "visible" : "hidden",
                        }}
                      >
                        {state.role.error}
                      </small>
                    </div>
                    <div className="form-group m-0">
                      <label className="label-sm w-100">Profile Pic</label>
                      <div className="row no-gutters">
                        <div className="fileUpload btn btn-secondary cs-upload-1 m-0 col-auto">
                          <span>Browse</span>
                          <input
                            type="file"
                            className="upload"
                            name="profilePic"
                            id="profilePic"
                            onChange={onFileChange}
                          />
                        </div>
                        <div className="col file-name">
                          <span className="margin-top-auto">
                            {state.profilePic.value
                              ? state.profilePic.value.originalName
                              : ""}
                          </span>
                        </div>
                      </div>

                      <small id="logoHelp" className="form-text text-muted">
                        Max size of file is 2MB.
                      </small>
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: profilePicValid.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {profilePicValid.error}
                      </small>
                    </div>
                    {/* <span>{JSON.stringify(roles)}</span> */}
                    <div className="action-btn text-center">
                      <Link
                        to={`/${userType}/staff`}
                        type="button"
                        className="btn  btn-outline-secondary login-btn mr-3"
                      >
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn  btn-primary login-btn"
                        disabled={disable}
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        data={errModel}
        onConfirmAction={() => setErrorModal({ show: false, error: {} })}
      ></ErrorModal>
    </div>
  );
};

export default StaffEdit;
