import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LocationOn from "@material-ui/icons/LocationOn";
import SearchIcon from "@material-ui/icons/Search";
import HeroImage from "../../../../assets/imgs/pose_4.png";
import { useDebouncedCallback } from 'use-debounce';
import HomePageService from "../../../service/HomePageService";
import Autocomplete from 'react-select';


const HeroSection = (props) => {
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const [role, setRole] = useState("");
  const [skillsList, setSkillsList] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillsLoading, setSkillsLoading] = useState(false);

  const getLocationList = async (param) => {
    try {
      const response = await HomePageService.getLocation(`searchStr=${param || ''}`);
      setLocationList(response.data.result);
      setLocationLoading(false);
    } catch (error) {
      setLocationLoading(false);
    }
  }

  const getSkillsList = async (param) => {
    try {
      const response = await HomePageService.getSkill(`searchStr=${param || ''}`);
      setSkillsList(response.data.result);
      setSkillsLoading(false);
    } catch (error) {
      setSkillsLoading(false);
    }
  }

  const skillsChange = ($event) => {
    setSelectedSkills($event);
  }


  const onSkillsDebounce = useDebouncedCallback((param, actionType) => {
    console.log(param, actionType);
    setSkillsLoading(true);
    getSkillsList(param);
  }, 500)

  const locationChange = ($event) => {
    setSelectedLocation($event);
  }

  const onLocationDebounce = useDebouncedCallback((param, actionType) => {
    setLocationLoading(true);
    getLocationList(param)
  }, 500);

  const customFilter = (option, inputValue) => {
    if (option.data.name && inputValue) {
      return option.data.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    }
    return true;
  };

  const goToSearchPage = () => {
    history.push("/candidates/public/list", { skills: selectedSkills.join(','), locations: selectedLocation.join(',') });
  };

  return (
    <div id="employer-hero-section" className="row no-gutters">
      <div className="col-12 py-4 row no-gutters">
        <div className="col-lg-9 col-xl-9 col-md-9 col-sm-12 col-xs-12 pt-4">
          <div className="col-12 row">
            <div className="col-lg-3 col-xl-3  d-lg-block d-xl-block d-xs-none d-sm-none d-md-none"></div>
            <h2 className="hero-title text-left col-lg-8 col-xl-8 col-xs-12 col-md-12 col-sm-12">
              Hiring is Simpler, <br /> Smarter & Faster with <br /> DoctorJobbs.com
            </h2>
            <div className="col-lg-1 col-xl-1 d-lg-block d-xl-block d-xs-none d-sm-none d-md-none"></div>
          </div>
          <div className="employee-form-section col-12 row">
            <div className="col-lg-3 col-xl-3 d-lg-block d-xl-block d-xs-none d-sm-none d-md-none"></div>
            <div className="col-lg-8 col-xl-8 col-xs-12 col-md-12 col-sm-12 row no-gutters align-items-center">
              <div className="input-wrapper col-12">
                <div className="input-group ">
                  <div className="input-group-prepend align-items-center">
                    <SearchIcon></SearchIcon>
                  </div>
                  <Autocomplete
                    name="skills"
                    placeholder="Search by Role"
                    defaultOptions
                    className="employee-skill-state"
                    classNamePrefix="employee-skill"
                    isMulti={true}
                    isClearable={true}
                    filterOption={customFilter}
                    options={skillsList}
                    getOptionLabel={(option) => {
                      return (
                        <div className="row autocomplete-item no-gutters">
                          <div className="text col p-1 text-truncate">
                            <div>{option}</div>
                          </div>
                        </div>
                      );
                    }}
                    getOptionValue={(option) => {
                      return option;
                    }}
                    onInputChange={onSkillsDebounce}
                    onChange={skillsChange}
                  />
                </div>
              </div>
              <div className="input-wrapper col-12 mt-4">
                <div className="input-group">
                  <div className="input-group-prepend align-items-center">
                    <LocationOn></LocationOn>
                  </div>
                  <Autocomplete
                    name="employee-locations"
                    placeholder="Search by Location"
                    className="employee-location-state"
                    classNamePrefix="employee-location"
                    defaultOptions
                    isMulti={true}
                    isClearable={true}
                    options={locationList}
                    filterOption={customFilter}
                    isLoading={locationLoading}
                    getOptionLabel={(option) => {
                      return (
                        <div className="text col p-1 text-truncate">
                          <div>{option}</div>
                        </div>
                      );
                    }}
                    getOptionValue={(option) => {
                      return option;
                    }}
                    onChange={locationChange}
                    onInputChange={onLocationDebounce}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-xl-1 d-lg-block d-xl-block d-xs-none d-sm-none d-md-none"></div>
          </div>
          <div className="col-12 row action-section">
            <div className="col-lg-3 col-xl-3 d-lg-block d-xl-block d-xs-none d-sm-none d-md-none"></div>
            <div className="col-lg-8 col-xl-8 text-center col-xs-12 col-md-12 col-sm-12">
              <button className="action-btn" onClick={goToSearchPage}> <SearchIcon></SearchIcon> Search</button>
            </div>
            <div className="col-lg-1 col-xl-1 d-lg-block d-xl-block d-xs-none d-sm-none d-md-none"></div>
          </div>
        </div>
        <div className="col-lg-3 col-xl-3 col-md-3  d-lg-block d-xl-block d-md-block  d-sm-none d-xs-none">
          <img className="img-fluid hero-image" src={HeroImage}></img>
        </div>
      </div>

    </div>
  );
};

export default HeroSection;
