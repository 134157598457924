import React from "react";
import GradeIcon from "@material-ui/icons/Grade";
import UserLogo from "../../../component/UserLogo";
import Carousel from "react-multi-carousel";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ReviewSection = (props) => {
  return (
    <div id="review-section">
      <div className="container">
        <div className="row section-header text-center p-4">
          <h2 className="col-md-12">We are trusted by more than</h2>
          <h2 className="col-md-12">thousand companies.</h2>
        </div>

        <div className="row p-4 section-content">
          <div className="container">
            <Carousel
              responsive={responsive}
              containerClass="carousel-container row no-gutters col-12"
              itemAriaLabel="company list"
              showDots={true}
              arrows={false}
              itemClass="d-flex align-items-center justify-content-center"
              swipeable={false}
              draggable={false}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              transitionDuration={500}
              removeArrowOnDeviceType={["tablet", "mobile"]}
            >
              {
                props.data.map((review, index) => {
                  return (

                    <div className="col-12 py-4 row no-gutters align-items-center" key={index}>
                      <p className="review-text col-12">
                        {review?.authorMessage}
                      </p>
                      <div className="col-12 my-2 row author-section align-items-center">
                        <div className="col"></div>
                        <div className="row col-auto">
                          <div className="author-detail col-auto">
                            {review?.authorProfilePic?.href ?
                              <img className="author-image" src={review?.authorProfilePic?.href}></img> :
                              <div className="author-no-image"></div>
                            }
                          </div>
                          <div className="col-auto my-3 p-0">
                            <div className="col author-name p-0">{review.authorName}</div>
                            <div className="col author-designation p-0">{review.authorDesignation}</div>
                          </div>
                        </div>
                        <div className="col"></div>
                      </div>
                    </div>
                  );
                })
              }
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReviewSection;
