import React, { useState, useEffect, forwardRef, useImperativeHandle, useMemo } from 'react';
import CandidateService from '../../../service/CandidateService';
import { useParams, Link, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Moment from 'react-moment';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import UseForm from '../../../service/FormService';
import $ from 'jquery';
import ErrorModal from "../../../component/modal/ErrorModal"
import Loader from '../../../component/Loader';
import moment from 'moment';


const initialSchema = {
    "firstName": { value: "", error: "" },
    "middleName": { value: "", error: "" },
    "lastName": { value: "", error: "" },
    "gender": { value: false, error: "" },
    "maritalStatus": { value: "", error: "" },
    "email": { value: "", error: "" },
    "alternateEmail": { value: "", error: "" },
    "contactNumber": { value: "", error: "" },
    "alternateNumber": { value: "", error: "" },
    "spokenLanguages": { value: "", error: "" },
    "nationality": { value: "INDIAN", error: "" },
    "isSpouceDoctrate": { value: "", error: "" },
    "dateOfBirth": { value: "", error: "" },
    "profilePic": { value: "", error: "" },
    "spouceDetail": { value: "", error: "" }
}

const validationStateSchema = {
    "firstName": { required: true },
    "middleName": { required: false },
    "lastName": { required: true },
    "gender": { required: true },
    "maritalStatus": { required: true },
    "email": { required: true },
    "alternateEmail": { required: false },
    "contactNumber": { required: true },
    "alternateNumber": { required: false },
    "spokenLanguages": { required: false },
    "nationality": { required: false },
    "isSpouceDoctrate": { required: false },
    "dateOfBirth": { required: false },
    "profilePic": { required: false },
    "spouceDetail": { required: false }
}

const EmailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PersonalStep = forwardRef((props, ref) => {
    const { id, userType } = useParams();
    const [stateSchema, setStateSchema] = useState(initialSchema);
    const [editMode, setEditMode] = useState(false);
    const [spouseDoctrate, setSpouseDoctrate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorObj, setErrorObj] = useState({ show: false, error: {} });
    const [isChanged, setChanged] = useState(false);
    const [emailError, setEmailError] = useState("");
    
    useEffect(() => {
        if (props.wizard && props.wizard.personal) {
            upDateInitialData();
        }
        props.canProceed(true);
    }, [])

    const editData = () => {
        setEditMode(true);
        props.canProceed(false);
    }

    const upDateInitialData = () => {
        const data = props.wizard.personal;
        let schema = $.extend(true, {}, stateSchema);
        Object.keys(schema).forEach(key => {
            if (data.hasOwnProperty(key)) {
                schema[key].value = key === 'dateOfBirth' && data[key] ? new Date(data[key]) : data[key] ? data[key] : '';
            }
        })
        setSpouseDoctrate(schema.isSpouceDoctrate.value);
        setStateSchema(schema);

    };

    useImperativeHandle(ref, () => ({
        submit() {
            if (isChanged) {
                const requestPayload = {};
                Object.keys(state).forEach(key => {
                    if (key === 'dateOfBirth') {
                        requestPayload[key] = getDateFormat(state[key].value) || null;
                    }
                    else if (key === 'profilePic') {
                        requestPayload[key] = state[key].value ? state[key].value.id : null;
                    } else {
                        requestPayload[key] = state[key].value || null;
                    }
                })
                CandidateService.updatePersonalDetails(id, requestPayload).then((res) => {
                    if (res.status === 201 || res.status === 200) {
                        props.updateData(res.data.result.personal, props.stepConfig);
                    }
                }, (err) => {
                    setErrorObj({ 'show': true, 'error': err.data })
                })
            }
            else {
                props.updateData(props.wizard.personal, props.stepConfig);
            }
        }

    }));

    const getDateFormat = (date) => {
        return moment(date).format('YYYY-MM-DD');
    };

    const spouseDoctrateChange = (data) => {
        handleOnChange({ target: { name: "isSpouceDoctrate", value: data ? data : '' } });
        setSpouseDoctrate(data)

    }

    const validateEmail = async (email, event) => {
        try{
            const res = await CandidateService.checkEmailExist(email);
            if(res.status === 200){
                handleOnChange(event);
            }
        }catch(e){
            setEmailError("Email is already in use.")
        }
       
    }

    const onEmailChange = (email) => {
        const event = { target: { "name": 'email', 'value': email } }
        if(email.match(EmailRegx)){
            validateEmail(email, event);
        }else{
            handleOnChange(event);
        }
    }

    const dobChange = (date) => {
        const event = { target: { "name": 'dateOfBirth', 'value': date } }
        handleOnChange(event);
    }

    const onSubmitForm = (state) => {
        setEditMode(false);
        props.canProceed(true);
        setChanged(true);
    }

    const onFileChange = (e) => {
        setIsLoading(true);
        e.persist();
        e.preventDefault();
        console.log("file changes", e);
        let formData = new FormData();
        formData.append("files", e.target.files[0])
        formData.append("mediaType", "image");
        CandidateService.upload(formData).then((res) => {
            console.log(res)
            setIsLoading(false);
            handleOnChange({ target: { name: 'profilePic', value: res.data.result[0] } })
        }, (error) => {
            console.log(error)
            setIsLoading(false);
        })

    }

    const { state, handleOnChange, handleOnSubmit, disable, setValueWithoutError } = UseForm(stateSchema, validationStateSchema, onSubmitForm);
    if (isLoading) return <Loader />;

    return (
        <div className="qualification-form">
            <div className="form-wrapper h-100">
                <div className="row no-gutters h-100 w-100">
                    <div className="container-fluid h-100 w-100 p-0">
                        <div className="row no-gutter h-100 w-100">
                            <div className="col-10">
                                <div className="card candidate-personal-card mt-4">
                                    <div className="card-header row no-gutters">
                                        <span className="card-title col">Personal Details</span>
                                        {editMode ?
                                            <div className="col-auto">
                                                <button className="icon-btn margin-auto btn-success" disabled={disable ? true : false} onClick={handleOnSubmit} title="Click to Save">
                                                    <DoneIcon className="save-icon"></DoneIcon>
                                                </button>
                                            </div> :
                                            <div className="col-auto">
                                                <div className="icon-btn margin-auto btn-primary" onClick={editData} title="Click to Edit">
                                                    <EditIcon className="edit-icon" ></EditIcon>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="card-body">
                                        {editMode ?

                                            <div className="row no-gutter">
                                                <div className="form-group col-4 m-0">
                                                    <label className="label-sm">First Name <sup className="required">*</sup></label>
                                                    <input type="text" className="form-control" id="firstName" placeholder="First Name" name="firstName" value={state.firstName.value} onChange={handleOnChange} />
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.firstName.error ? 'visible' : 'hidden' }}>{state.firstName.error}</small>
                                                </div>
                                                <div className="form-group col-4 m-0">
                                                    <label className="label-sm">Middle Name</label>
                                                    <input type="text" className="form-control " placeholder="Middle Name" id="middleName" name="middleName" value={state.middleName.value} onChange={handleOnChange} />
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.middleName.error ? 'visible' : 'hidden' }}>{state.middleName.error}</small>
                                                </div>
                                                <div className="form-group col-4 m-0">
                                                    <label className="label-sm">Last Name <sup className="required">*</sup></label>
                                                    <input type="text" className="form-control " placeholder="Last Name" id="lastName" name="lastName" value={state.lastName.value} onChange={handleOnChange} />
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.lastName.error ? 'visible' : 'hidden' }}>{state.lastName.error}</small>
                                                </div>

                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm">Email ID  <sup className="required">*</sup></label>
                                                    <input type="text" className="form-control " placeholder="name@email.com" id="email" name="email" value={state.email.value} onChange={onEmailChange} />
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.email.error ? 'visible' : 'hidden' }}>{state.email.error}</small>
                                                    {!state.email.error && emailError && <small className="form-text  error-text m-0">{emailError}</small>}
                                                </div>
                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm"> Alternate Email ID </label>
                                                    <input type="text" className="form-control" placeholder="name@email.com" id="alternateEmail" name="alternateEmail" value={state.alternateEmail.value} onChange={handleOnChange} />
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.alternateEmail.error ? 'visible' : 'hidden' }}>{state.alternateEmail.error}</small>
                                                </div>

                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm">Contact Number  <sup className="required">*</sup></label>
                                                    <input type="text" className="form-control " placeholder="Phone Number" id="contactNumber" name="contactNumber" value={state.contactNumber.value} onChange={handleOnChange} />
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.contactNumber.error ? 'visible' : 'hidden' }}>{state.contactNumber.error}</small>
                                                </div>
                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm"> Alternate Contact Number </label>
                                                    <input type="number" className="form-control" placeholder="Phone number" id="alternateNumber" name="alternateNumber" value={state.alternateNumber.value} onChange={handleOnChange} />
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.alternateNumber.error ? 'visible' : 'hidden' }}>{state.alternateNumber.error}</small>
                                                </div>

                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm">Gender </label>
                                                    <select className="form-control" id="gender" name="gender" value={state.gender.value} onChange={handleOnChange}>
                                                        <option value="">Select Gender</option>
                                                        <option value="MALE">MALE</option>
                                                        <option value="FEMALE">FEMALE</option>
                                                        <option value="prefer not to say">PREFER NOT TO SAY</option>
                                                    </select>
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.gender.error ? 'visible' : 'hidden' }}>{state.gender.error}</small>
                                                </div>
                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm"> Marital Status  </label>
                                                    <select className="form-control" id="maritalStatus" name="maritalStatus" value={state.maritalStatus.value} onChange={handleOnChange}>
                                                        <option value="">Select Marital Status</option>
                                                        <option value="SINGLE">SINGLE</option>
                                                        <option value="MARRIED">MARRIED</option>
                                                        <option value="DIVORCED">DIVORCED</option>
                                                    </select>
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.maritalStatus.error ? 'visible' : 'hidden' }}>{state.maritalStatus.error}</small>
                                                </div>
                                                {state.maritalStatus.value === 'MARRIED' ?
                                                    <div className="form-group col-6 m-0">
                                                        <div className="margin-top-auto highest-qualification ml-3">
                                                            <label className="label-sm mr-4 ">Is Spouse Doctor</label>
                                                            <div className="switch" onClick={() => spouseDoctrateChange(!spouseDoctrate)}>
                                                                {
                                                                    spouseDoctrate ?
                                                                        <input type="checkbox" name="isHotJobTrue" id="isHotJob" value={spouseDoctrate} checked={spouseDoctrate} onChange={() => { }} /> :
                                                                        <input type="checkbox" name="isHotJobFalse" id="isHotJob" value={spouseDoctrate} checked={spouseDoctrate} onChange={() => { }} />
                                                                }
                                                                <span className="slider round"></span>
                                                            </div>
                                                            <small className="form-text  error-text m-0" style={{ visibility: state.isSpouceDoctrate.error ? 'visible' : 'hidden' }}>{state.isSpouceDoctrate.error}</small>
                                                        </div>
                                                    </div>
                                                    : ''}
                                                {state.maritalStatus.value === 'MARRIED' && spouseDoctrate ?
                                                    <div className="form-group col-6 m-0">
                                                        <label className="label-sm">Spouse Specialization</label>
                                                        <input type="text" className="form-control" placeholder="Eg - Surgeon" id="spouceDetail" name="spouceDetail" value={state.spouceDetail.value} onChange={handleOnChange} />
                                                        <small className="form-text  error-text m-0" style={{ visibility: state.spouceDetail.error ? 'visible' : 'hidden' }}>{state.spouceDetail.error}</small>
                                                    </div> : ''
                                                }
                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm">Language Spoken</label>
                                                    <input type="text" className="form-control" placeholder="Eg: English, Hindi" id="spokenLanguages" name="spokenLanguages" value={state.spokenLanguages.value} onChange={handleOnChange} />
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.spokenLanguages.error ? 'visible' : 'hidden' }}>{state.spokenLanguages.error}</small>
                                                </div>
                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm"> Nationality  </label>
                                                    <select className="form-control" id="nationality" name="nationality" value={state.nationality.value} onChange={handleOnChange} disabled={state.nationality.value ? true : false}>
                                                        <option value="">Select Nationality</option>
                                                        <option value="Indian">Indian</option>
                                                    </select>
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.nationality.error ? 'visible' : 'hidden' }}>{state.nationality.error}</small>
                                                </div>
                                                <div className="form-group col-6 m-0">
                                                    <label className="label-sm">Date of Birth</label>
                                                    <DatePicker className="form-control w-100" id="dateOfBirth" name="dateOfBirth"
                                                        maxDate={new Date()} showMonthDropdown showYearDropdown dropdownMode="select"
                                                        onChange={dobChange} selected={state.dateOfBirth.value} autoComplete="off"></DatePicker>
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.dateOfBirth.error ? 'visible' : 'hidden' }}>{state.dateOfBirth.error}</small>
                                                </div>
                                                <div className="form-group m-0 col-6">
                                                    <label className="label-sm w-100">Profile Pic</label>
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="fileUpload btn btn-secondary cs-upload-1 m-0 col-auto">
                                                            <span>Browse</span>
                                                            <input type="file" className="upload" name="logo" id="logo" onChange={onFileChange} accept="image/*" />
                                                        </div>
                                                        <div className="col file-name">
                                                            {state.profilePic.value && state.profilePic.value.id ? <img src={state.profilePic.value.href} height="80"></img> : ''}
                                                            <div> {state.profilePic.value ? state.profilePic.value.originalName : ''} </div>
                                                        </div>
                                                    </div>

                                                    <small id="logoHelp" className="form-text text-muted">Max size of file is 2MB.</small>
                                                    <small className="form-text  error-text m-0" style={{ visibility: state.profilePic.error ? 'visible' : 'hidden' }}>{state.profilePic.error}</small>

                                                </div>
                                            </div>
                                            :
                                            <div className="row">
                                                <div className="col-6  row p-2">
                                                    <div className="col-5 text-wrap fw-500">Profile Pic</div>
                                                    <div className="col-7 text-wrap">
                                                        {state.profilePic.value && state.profilePic.value.id ?
                                                            <img src={state.profilePic.value.href} height="80"></img>
                                                            : "NA"
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-6  row p-2">
                                                    <div className="col-5 text-wrap fw-500">Name</div>
                                                    <div className="col-7 text-wrap">{`${state.firstName.value || ''} ${state.middleName.value || ''} ${state.lastName.value || ''}`}</div>
                                                </div>
                                                <div className="col-6  row p-2">
                                                    <div className="col-5 text-wrap fw-500">Email</div>
                                                    <div className="col-7 ttext-wrap">{state.email.value}</div>
                                                </div>
                                                <div className="col-6  row p-2">
                                                    <div className="col-5 text-wrap fw-500">Alternate Email</div>
                                                    <div className="col-7 text-wrap">{state.alternateEmail.value || "NA"}</div>
                                                </div>
                                                <div className="col-6 row p-2">
                                                    <div className="col-5 text-wrap fw-500">Contact Number</div>
                                                    <div className="col-7 text-wrap">{state.contactNumber.value}</div>
                                                </div>
                                                <div className="col-6 row p-2">
                                                    <div className="col-5 text-wrap fw-500">Alternate Contact Number</div>
                                                    <div className="col-7 text-wrap">{state.alternateNumber.value || "NA"}</div>
                                                </div>
                                                <div className="col-6 row p-2">
                                                    <div className="col-5 text-wrap fw-500">Date of Birth</div>
                                                    <div className="col-7 text-wrap">
                                                        {state.dateOfBirth.value ? <Moment date={state.dateOfBirth.value} format={"MM-DD-YYYY"}></Moment> : "NA"}

                                                    </div>
                                                </div>
                                                <div className="col-6 row p-2">
                                                    <div className="col-5 text-wrap fw-500">Gender</div>
                                                    <div className="col-7 text-wrap">{state.gender.value || 'NA'}</div>
                                                </div>
                                                <div className="col-6  row p-2">
                                                    <div className="col-5 text-wrap fw-500">Marital Status</div>
                                                    <div className="col-7 text-wrap">{state.maritalStatus.value || 'NA'}</div>
                                                </div>
                                                {state.maritalStatus.value === 'MARRIED' ?
                                                    <div className="col-6  row p-2">
                                                        <div className="col-5 text-wrap fw-500">Is Spouse Doctor</div>
                                                        <div className="col-7 text-wrap">{state.isSpouceDoctrate.value ? "YES" : "NO"}</div>
                                                    </div> : ''
                                                }
                                                {state.maritalStatus.value === 'MARRIED' && state.isSpouceDoctrate.value ?
                                                    <div className="col-6  row p-2">
                                                        <div className="col-5 text-wrap fw-500">Spouse Specialization</div>
                                                        <div className="col-7 text-wrap">{state.spouceDetail.value || 'NA'}</div>
                                                    </div> : ''
                                                }
                                                <div className="col-6  row p-2">
                                                    <div className="col-5 text-wrap fw-500">Nationality</div>
                                                    <div className="col-7 text-wrap">{state.nationality.value || 'NA'}</div>
                                                </div>
                                                <div className="col-6  row p-2">
                                                    <div className="col-5 text-wrap fw-500">Language Spoken</div>
                                                    <div className="col-7 text-wrap">{state.spokenLanguages.value || 'NA'}</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ErrorModal data={errorObj} onConfirmAction={() => setErrorObj({ show: false, error: {} })}></ErrorModal>
        </div>
    )
})

export default PersonalStep;