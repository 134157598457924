import React from "react";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import CopyrightIcon from "@material-ui/icons/Copyright";
import YoutubeIcon from "@material-ui/icons/YouTube";

const Footer = () => {
  return (
    <div id="footer-section">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-xl-3  col-lg-3 col-md-12 col-sm-12 col-xs-12  py-3 we-are-section">
            <div className="col-md-12 column-header">
              <h5>WHO WE ARE ?</h5>
            </div>
            <p className="col-md-12">
              DoctorJobbs.com is the ONLY specialized job platform for Doctor’s and Healthcare professionals in India, Connecting talent with rewarding opportunities in the Healthcare sector, Join us today and elevate your career.
            </p>
          </div>
          <div className="col-xl-3  col-lg-3 col-md-6 col-sm-6 col-xs-6 py-3 quick-links">
            <div className="col-md-12 column-header">
              <h5>Quick Links</h5>
            </div>
            <div className="row px-3">
              <a href="/home" className="links col-md-12">
                Home
              </a>
              <a href="/about" className="links col-md-12">
                About Us
              </a>
              <a href="/equipments" className="links col-md-12">
                Equipment/Devices
              </a>
              <a href="/contact" className="links col-md-12">
                Contact Us
              </a>
              <a href="/termsconditions" className="links col-md-12">
                Terms & Conditions
              </a>
            </div>
          </div>
          <div className="col-xl-3  col-lg-3 col-md-6 col-sm-6 col-xs-6 py-3 social-media">
            <div className="col-md-12 column-header">
              <h5>Social Media</h5>
            </div>
            <div className="row px-3">
              <a
                href="https://www.facebook.com/profile.php?id=100092255538802"
                target="_blank"
                className="links col-md-12"
              >
                Facebook <FacebookIcon></FacebookIcon>
              </a>
              <a
                href="https://www.linkedin.com/in/doctor-jobbs-76831b209/"
                target="_blank"
                className="links col-md-12"
              >
                LinkedIn <LinkedInIcon></LinkedInIcon>
              </a>
              <a
                href="https://twitter.com/DoctorJobbs"
                target="_blank"
                className="links col-md-12"
              >
                Twitter <TwitterIcon></TwitterIcon>
              </a>
              <a
                href="https://www.instagram.com/doctorjobbs/"
                target="_blank"
                className="links col-md-12"
              >
                Instagram <InstagramIcon></InstagramIcon>
              </a>
              <a
                href="https://www.youtube.com/@doctorjobbs"
                target="_blank"
                className="links col-md-12"
              >
                Youtube <YoutubeIcon></YoutubeIcon>
              </a>
            </div>
          </div>
          <div className="col-xl-3  col-lg-3 col-md-12 col-sm-12 col-xs-12 py-3 address">
            <div className="col-md-12 column-header">
              <h5>Address</h5>
            </div>
            <div className="col-md-12">
              <address>
                <b>DoctorJobbs.com</b>
                <br />
                Hitech City,Hyderabad <br />
                Telangana <br />
                500081
                <br />
                <br />
                IT Park, Whitefield <br />
                Bangalore <br />
                560066
                <br />
                <br />
                Ph No : +91-87925 25867
              </address>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-2 footer-bottom">
        <div className="row no-gutters py-2 justify-content-center align-items-center copyright-section">
          <div className="col-md-6 text-center copyright-text">
            <CopyrightIcon></CopyrightIcon> 2024 Copyright DoctorJOBBS.com
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
