import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

class BarCharts extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="bar-chart-container">
        <ResponsiveContainer>
          <BarChart
            width={400}
            height={200}
            data={this.props.data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {this.props &&
              this.props.config.map((config, index) => {
                return (
                  <Bar
                    key={index}
                    dataKey={config.label}
                    fill={config.color}
                    barSize={15}
                  />
                );
              })}
            {/* <Bar  dataKey="active" fill="#A4A1FB" />
                <Bar  dataKey="inactive" fill="#56D9FE" /> */}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default BarCharts;
