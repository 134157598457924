import React, { useEffect, useState, useMemo, useRef } from 'react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import CheckboxV2 from '../component/CheckboxV2';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useDebouncedCallback } from 'use-debounce';
import IconButton from '@material-ui/core/IconButton';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';


const TableCompponent = ({ columns, data, config = {}, paginationObj, loading, error }) => {
    const [listItem, setListItem] = useState(data);
    const [selectedList, setSelectedList] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [search, setSearch] = useState(config.search || "");
    const perPageItem = config.perPageItem || 10;
    paginationObj.currentPage = +paginationObj.currentPage;
    const selectedKey = config.selectedRowKey ? config.selectedRowKey : 'id';
    const countStarting = paginationObj ? ((paginationObj.currentPage - 1) * paginationObj.perPageCount) + 1 : 'NA';
    const countLast = paginationObj && data ? countStarting + data.length - 1 : 'NA';
    const totalPageCount = paginationObj ? Math.ceil(paginationObj.totalCount / paginationObj.perPageCount) : 0;

    const sort = config.sortBy || { name: "", type: "" }
    useEffect(() => {
        setListItem(data);
    }, [data])

    const onRowSelection = (value, list, index) => {
        const rowList = [...selectedList];
        const tempList = [...listItem];
        const alreadyPresent = rowList.indexOf(list[selectedKey]);
        if (alreadyPresent > -1) {
            tempList[index].selected = false;
            rowList.splice(alreadyPresent, 1);
        } else {
            tempList[index].selected = true;
            rowList.push(list[selectedKey])
        }
        if (config.onRowSelection) {
            config.onRowSelection(rowList)
        }
        rowList.length === tempList.length ? setSelectAll(true) : setSelectAll(false);
        setSelectedList(rowList);
        setListItem(tempList);

    }

    const onSelectAll = (value, lists, index) => {
        const rowList = [];
        const tempList = [...listItem];

        tempList.forEach((list) => {
            list.selected = value;
            if (value) {
                rowList.push(list[selectedKey]);
            }
        })

        setSelectAll(value);
        setSelectedList(rowList);
        setListItem(tempList);

        if (config.onRowSelection) {
            config.onRowSelection(rowList)
        }
    }

    const perPageItemChange = (e) => {
        if (e.currentTarget && e.currentTarget.value) {
            onSelectAll(false);
            config.getTableData(search, e.currentTarget.value, 1, sort.name ? sort : null)
        }
    }

    const goToPage = (page) => {
        onSelectAll(false);
        config.getTableData(search, perPageItem, page, sort.name ? sort : null);
    }

    const debounce = useDebouncedCallback((value) => {
        setSearch(value)
        onSelectAll(false);
        config.getTableData(value, perPageItem, 1, sort.name ? sort : null);
    }, 1000)

    const doSort = (column) => {
        if (column.value && column.value === sort.name) {
            sort.type = !sort.type ? 'asc' : sort.type === 'asc' ? 'dsc' : '';
            sort.name = !sort.type ? "" : column.value;
        } else {
            sort.type = 'asc';
            sort.name = column.value;
        }
        onSelectAll(false);
        config.getTableData(search, perPageItem, 1, sort.name ? sort : null);
    }

    const onAction = (type) => {
        config.onActionButton(type);
    }

    return (
        <div className='cs-table-container'>
            <div className="cs-header-section">
                <div className="row no-gutters px-2 align-items-center">
                    {config.showSelectRow && <div className='col-auto select-all-checkbox d-flex align-items-center'>
                        <CheckboxV2 value={selectAll}
                            classes="table-checkbox"
                            name="client-all"
                            data="All"
                            change={onSelectAll}>
                        </CheckboxV2>
                        {selectedList.length > 0 && <p className='m-0 mx-2'>{selectedList.length} Selected</p>}
                    </div>}
                    <div className="col d-flex align-items-center">
                        <input type="text" className='table-search-input' placeholder='search' defaultValue={search} onChange={(e) => debounce(e.currentTarget.value)}></input>
                    </div>

                    <div className="col-auto">
                        {config.actionList.indexOf('delete') > -1 && <button
                            className='table-btn align-items-center d-flex spl-danger-buttn-css'
                            onClick={() => { onAction('delete') }}
                            disabled={selectedList.length === 0}>
                            <DeleteIcon></DeleteIcon> Delete Permanently
                        </button>
                        }
                    </div>
                    <div className="col-auto">
                        {config.actionList.indexOf('activate') > -1 && <button
                            className='table-btn align-items-center d-flex'
                            onClick={() => { onAction('activate') }}
                            disabled={selectedList.length === 0}>
                            <CheckCircleIcon></CheckCircleIcon> Activate
                        </button>
                        }
                    </div>
                    <div className="col-auto">
                        {config.actionList.indexOf('deactivate') > -1 && <button
                            className='table-btn align-items-center d-flex'
                            onClick={() => { onAction('deactivate') }}
                            disabled={selectedList.length === 0}>
                            <CancelIcon></CancelIcon> Deactivate
                        </button>
                        }
                    </div>
                </div>
            </div>
            <div className={`cs-table-wrapper ${loading || error ? 'overflow-hidden' : ''}`}>
                {loading &&
                    <div className='table-loader-wrapper  position-absolute w-100 h-100 '>
                        <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                            <div className='font-weight-bold loader-text'>Loading Data </div>
                            <div className="spinner-grow spinner-grow-sm-loader" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="spinner-grow spinner-grow-sm-loader" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="spinner-grow spinner-grow-sm-loader" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                }
                {!loading && error &&
                    <div className='table-loader-wrapper  position-absolute w-100 h-100 '>
                        <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                            <div className='font-weight-bold loader-text'>Something went wrong, Please try again! </div>
                        </div>
                    </div>
                }
                {!loading && !error && listItem.length === 0 &&
                    <div className='table-loader-wrapper  position-absolute w-100 h-100 top-0 left-0'>
                        <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                            <div className='font-weight-bold loader-text'>No Data Available!!</div>
                        </div>
                    </div>
                }
                <table className="cs-list-table">
                    <thead className='cs-thead'>
                        <tr className='cs-row sticky-row'>
                            {config.showSelectRow && <th className='cs-column'></th>}
                            {columns && columns.map((column, index) => {
                                return (
                                    <th className='cs-column' key={index}>
                                        <div className="cs-column-wrapper d-flex">
                                            <span className='column-label'>{column.label}</span>
                                            {column.sortable ?
                                                <div className="sort-icon-wrapper">
                                                    <IconButton aria-label="sort"
                                                        className={sort.name === column.value ? 'active-sort' : ''}
                                                        size="small" onClick={() => doSort(column)}>
                                                        {sort.name === column.value && sort.type === 'dsc' ? <ArrowDownwardIcon fontSize="inherit" /> :
                                                            <ArrowUpwardIcon fontSize="inherit" />}
                                                    </IconButton>
                                                </div> : ""
                                            }
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody className='cs-tbody'>
                        {(!loading && !error) && listItem.map((list, index) => {
                            return (
                                <tr className='cs-row' key={'row-' + index}>
                                    {config.showSelectRow && <td className='cs-cell'>
                                        <div className="checkbox-content">
                                            <CheckboxV2 value={list.selected}
                                                classes="table-checkbox"
                                                name="client"
                                                data={list}
                                                index={index}
                                                size={18}
                                                change={onRowSelection}>
                                            </CheckboxV2>
                                        </div>
                                    </td>}
                                    {columns.map((col, key) => {
                                        return (
                                            <td className='cs-cell' key={'cell-' + key}>
                                                {col.render ? col.render(list[col['value']], index, list) : list[col['value']]}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className='row cs-table-pagination'>
                <div className="col-auto align-items-center">
                    <select id="showPerPage" className="per-page-select" defaultValue={perPageItem} onChange={perPageItemChange}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    <label className="per-page-label">Per Page</label>
                </div>
                <div className='col'></div>
                <div className="col-auto pagination-wrapper align-items-center d-flex">
                    {paginationObj && <div className="count-wrapper">
                        {countStarting}-{countLast} out of {paginationObj.totalCount}
                    </div>}
                    <div className='pagination'>
                        <button className="pagination-btn first-page"
                            disabled={paginationObj.currentPage === 1}
                            onClick={() => { goToPage(1) }}><FirstPageIcon></FirstPageIcon></button>
                        <button className="pagination-btn previous-page"
                            disabled={paginationObj.currentPage === 1}
                            onClick={() => { goToPage(1) }}><NavigateBeforeIcon></NavigateBeforeIcon></button>
                        <button className="pagination-btn next-page"
                            disabled={paginationObj.currentPage === totalPageCount}
                            onClick={() => { goToPage(paginationObj.currentPage + 1) }}><NavigateNextIcon></NavigateNextIcon></button>
                        <button className="pagination-btn last-page"
                            disabled={paginationObj.currentPage === totalPageCount}
                            onClick={() => { goToPage(totalPageCount) }}><LastPageIcon></LastPageIcon></button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TableCompponent;