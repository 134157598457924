import React,{useState,useEffect} from 'react';
import Countcard from '../../component/Countcard';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AreaGraph from '../../component/Areagraph';
import DashboardService from '../../service/DashboardService';
import ErrorPopup from '../../component/modal/ErrorModal';

const configData = [{'label':'active','color':'#41E122'},{'label':'inactive','color':'#54BBFE'}]


const  CandidateOverview = (props) => {
    const [loading,setLoading] = useState(false);
    const [candidateData,setCandidateData] = useState('');
    const [errModel, setErrorModal] =useState({show:false,err:{}})

    useEffect(() => {
        if(props.visible){
            getOverViewData();
        }
        else{
            setCandidateData('');
        }
     }, [props.visible]);

    const getOverViewData = async () => {
        try{
            setLoading(true);
            const response = await DashboardService.getAdminOverviewData('candidate')
            if(response.status === 200){
                setCandidateData(response.data.result);
                setLoading(false);
            }
        }
        catch(err){
            setLoading(false);
            if(err && err.data){
                setErrorModal({show:true,error : err.data})
            }
            
        }
    }

    return (
        <div id="Candidate-overview">
            <h3>Candidate Overview</h3>
            <div className="container-fluid p-0 mt-4">
                <div className="row no-gutters">
                    <div className="col-3">
                        <Countcard count={candidateData ? candidateData.counts.total:0} msg={"Total"} name={"total-count"} icon={PersonOutlineIcon} />
                    </div>
                    <div className="col-3">
                        <Countcard count={candidateData ? candidateData.counts.active:0} msg={"Active"} name={"active-count"} icon={PersonOutlineIcon} />
                    </div>
                    <div className="col-3">
                        <Countcard count={candidateData ? candidateData.counts.inactive:0} msg={"Inactive"} name={"inactive-count"} icon={PersonOutlineIcon} />
                    </div>
                    <div className="col-3">
                        <Countcard count={candidateData ? candidateData.counts.new:0} msg={"New"} name={"new-count"} icon={PersonOutlineIcon} />
                    </div>
                </div>
            </div>
            <div className="col-12 subvsexpGraph">
                    <div className="col-12 graph-header-text header-font">
                        <p className="col-auto">Candidates Active Vs Inactive</p>
                    </div>
                {props.visible ?  <AreaGraph data={candidateData.activeInactive} config={configData} /> :''}
            </div>    
            <ErrorPopup data={errModel} onConfirmAction={() => setErrorModal({show:false,error:{}})}></ErrorPopup>
        </div>
    );
}

export default CandidateOverview
