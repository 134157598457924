import React from 'react';
import {AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,Legend} from 'recharts';

class Areagraph extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="area-graph-container">
                <ResponsiveContainer>
                    <AreaChart width={730} height={250} data={this.props.data}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                        <defs>
                            {this.props.config && this.props.config.map((config,index) => {
                                return (
                                    <linearGradient key={index} id={`${config.label}`} x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor={config.color} stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor={config.color} stopOpacity={0}/>
                                    </linearGradient>
                                )
                            })}
                            {/* <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#54D8FF" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#54D8FF" stopOpacity={0}/>
                            </linearGradient> */}
                        </defs>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend verticalAlign="bottom" iconSize={12} iconType="circle" align="left"/>
                        {this.props.config && this.props.config.map((config,index) => {
                            return (
                                <Area key={index} type="monotone" dataKey={config.label} stroke={config.color} fillOpacity={1} fill={`url(#${config.label})`} />
                            )
                        })}
                        
                        {/* <Area type="monotone" dataKey={this.props.secondLabel} stroke="#54D8FF" fillOpacity={1} fill="url(#colorPv)" /> */}
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        )
    }
}

export default Areagraph;