import React, { useEffect, useState } from "react";
import UseForm from "../../service/FormService";
import NewsService from "../../service/NewsService";
import { Link, useHistory, useParams } from "react-router-dom";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import ErrorModal from "../../component/modal/ErrorModal";
import Loader from "../../component/Loader";
import moment from "moment";

const stateSchema = {
  heading: { value: "", error: "" },
  source: { value: "", error: "" },
  content: { value: "", error: "" },
  standard: { value: "", error: "" },
  postOn: { value: new Date(), error: "" },
  expireOn: { value: new Date(), error: "" },
};

const validationStateSchema = {
  heading: {
    required: true,
  },
  source: {
    required: false,
  },
  content: {
    required: true,
  },
  standard: {
    required: true,
  },
  postOn: {
    required: true,
  },
  expireOn: {
    required: true,
  },
};

const StaffCreate = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [errorObj, setErrorObj] = useState({ show: false, error: {} });
  const { userType } = useParams();

  const getDateFormat = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  function onSubmitForm(state) {
    let requestPayload = {};
    Object.keys(state).forEach((key) => {
      if (key === "postOn" || key === "expireOn") {
        requestPayload[key] = getDateFormat(new Date(state[key].value));
      } else {
        requestPayload[key] = state[key].value || null;
      }
    });
    NewsService.addNews(requestPayload).then(
      (res) => {
        //console.log('add news ',res);
        if (res.status === 201 || res.status === 200) {
          history.push(`/${userType}/news`);
        }
      },
      (err) => {
        console.log("add staff err", err);
        setErrorObj({ show: true, error: err.data });
      }
    );
  }
  const { state, handleOnChange, handleOnSubmit, disable } = UseForm(
    stateSchema,
    validationStateSchema,
    onSubmitForm
  );

  if (loading) return <Loader />;
  return (
    <div className="content-wrapper" id="add-staff">
      <div className="content-container">
        <div className="form-wrapper">
          <div className="row h-100 w-100">
            <div className="container h-100 w-100">
              <div className="row align-items-center h-100 w-100">
                <div className="col-2"></div>
                <div className="col-8">
                  <div className="form-header text-center">
                    <span className="text-msg">Add News</span>
                  </div>
                  <form onSubmit={handleOnSubmit}>
                    <div className="row">
                      <div className="form-group col-6 m-0">
                        <label className="label-sm">News Heading</label>
                        <input
                          type="text"
                          className="form-control"
                          id="heading"
                          placeholder="News Heading"
                          name="heading"
                          value={state.heading.value}
                          onChange={handleOnChange}
                        />
                        <small
                          className="form-text  error-text m-0"
                          style={{
                            visibility: state.heading.error
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          {state.heading.error}
                        </small>
                      </div>
                      <div className="form-group col-6 m-0">
                        <label className="label-sm">Source</label>
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Ex: http://www.sourcelink.com/link"
                          id="source"
                          name="source"
                          value={state.source.value}
                          onChange={handleOnChange}
                        />
                        <small
                          className="form-text  error-text m-0"
                          style={{
                            visibility: state.source.error
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          {state.source.error}
                        </small>
                      </div>
                    </div>
                    <div className="form-group m-0">
                      <label className="label-sm">Description</label>
                      <CKEditor
                        data={state.content.value}
                        editor={ClassicEditor}
                        config={{
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "blockQuote",
                            "link",
                            "numberedList",
                            "bulletedList",
                            "insertTable",
                            "|",
                            "alignment",
                            "|",
                            "undo",
                            "redo",
                          ],
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          handleOnChange({
                            target: { name: "content", value: data },
                          });
                        }}
                      />
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.content.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {state.content.error}
                      </small>
                    </div>
                    <div className="form-group m-0">
                      <label className="label-sm">Standard</label>
                      <select
                        className="form-control"
                        name="standard"
                        id="standard"
                        onChange={handleOnChange}
                        value={state.standard.value}
                      >
                        <option value="">Select Standard</option>
                        <option value="NATIONAL">NATIONAL</option>
                        <option value="INTERNATIONAL">INTERNATIONAL</option>
                      </select>
                      <small
                        className="form-text  error-text m-0"
                        style={{
                          visibility: state.standard.error
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        {state.standard.error}
                      </small>
                    </div>
                    <div className="row">
                      <div className="form-group m-0 col-6">
                        <label className="label-sm w-100">Post On</label>

                        <DatePicker
                          className="form-control w-100"
                          id="postOn"
                          name="postOn"
                          minDate={new Date()}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          onChange={(data) => {
                            handleOnChange({
                              target: { name: "postOn", value: data },
                            });
                          }}
                          selected={state.postOn.value}
                          autoComplete="off"
                        ></DatePicker>
                        <small
                          className="form-text  error-text m-0"
                          style={{
                            visibility: state.postOn.error
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          {state.postOn.error}
                        </small>
                      </div>

                      <div className="form-group m-0 col-6">
                        <label className="label-sm w-100">Expire On</label>

                        <DatePicker
                          className="form-control w-100"
                          id="expireOn"
                          name="expireOn"
                          minDate={new Date()}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          onChange={(data) => {
                            handleOnChange({
                              target: { name: "expireOn", value: data },
                            });
                          }}
                          selected={state.expireOn.value}
                          autoComplete="off"
                        ></DatePicker>
                        <small
                          className="form-text  error-text m-0"
                          style={{
                            visibility: state.expireOn.error
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          {state.expireOn.error}
                        </small>
                      </div>
                    </div>

                    <div className="action-btn text-center">
                      <Link
                        to={`/${userType}/news`}
                        type="button"
                        className="btn  btn-outline-secondary login-btn mr-3"
                      >
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn  btn-primary login-btn"
                        disabled={disable}
                      >
                        Add News
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        data={errorObj}
        onConfirmAction={() => setErrorObj({ show: false, error: {} })}
      ></ErrorModal>
    </div>
  );
};

export default StaffCreate;
