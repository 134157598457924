import Api from './Interceptor';
import LocalStorageService from './LocalstorageService';

const localStorage = LocalStorageService.getService();

const StaffService  = (function(){
    const ajax = function(param){
        return Api(param)
    }

    return {
        getRole : function(){
            let requestObj = {method :'GET', url : 'own/role?items=all&search=active'}
            return ajax(requestObj);
        },
        isLoggedIn : function(){
           return localStorage.getAccessToken() ? true : false;
        },
        addStaff : function(data){
            let requestObj = {method:'POST',url : '/staff/user',data:data};
            return ajax(requestObj);
        },
        getStaffList : function (search, offset, page, sortby) {
            const requestObj = {
              method: "GET",
              url: '/staff/user',
            };
            let paramSting = "";
            if(search){
              paramSting = paramSting + `search=${search}`
            }
            if(offset){
                paramSting = (paramSting ? paramSting + '&': paramSting) + `limit=${offset}`
            }
            if(page){
                paramSting = (paramSting ? paramSting + '&': paramSting) + `page=${page}` 
            }
            if(sortby){
                paramSting = (paramSting ? paramSting + '&': paramSting) + `sort=${sortby.type}&sortby=${sortby.name}` 
            }
      
            if(paramSting){
                requestObj.url = requestObj.url + "?" + paramSting;
            }
            return ajax(requestObj);
        },
        getStaffDetails : function(id){
            let requestObj = {method:'GET',url:`staff/user/${id}`};
            return ajax(requestObj);
        },
        updateStaffDetails : function(id,data){
            let requestObj = {method:'PUT',url:`staff/user/${id}`,data:data};
            return ajax(requestObj);
        },
        updateStaffStatus : function(action,data){
            let requestObj = {method:'PUT',url:`/staff/user/update/bulk?action=${action}`,data:data}
            return ajax(requestObj);
        },
        uploadFile : function(data){
            let requestObj = {method:'POST',url:`file/upload`,data:data, headers:{'content-type':'multipart/form-data'}};
            return ajax(requestObj);
        },
        deleteFile:function(id){
            let requestObj = {method:'DELETE',url:`file/upload/${id}`};
            return ajax(requestObj);
        },
        getDashboardData:function(){
            let requestObj = {method:'GET',url:'user/dashboard'};
            return ajax(requestObj);
        },
        getStaffActivity:function(){
            let requestObj = {method:'GET',url:'user/activity'};
            return ajax(requestObj);
        },
        getUserActivity:function(id){
            let requestObj = {method:'GET',url:`user/activity?userId=${id}`};
            return ajax(requestObj);
        }
    }
})()

export default StaffService;