import React, { useEffect, useState } from "react";
import ManageService from "../../../service/ManageService";
import NavBar from "../Navbar";
import Footer from "../Footer";

import HeroImage from "../../../../assets/imgs/about-us-heaader.png";
import MissionImage from "../../../../assets/imgs/about-us-body.png";
import HtmlParser from "react-html-parser";

const AboutUs = (props) => {
  const [errModel, setErrorModal] = useState({ show: false, msg: "" });
  const [successModel, setSuccessModal] = useState({ show: false, msg: "" });
  const [aboutData, setAboutData] = useState({});

  useEffect(() => {
    let isMounted = true;
    if (isMounted) getAboutUsData();
    return () => { isMounted = false };
  }, []);

  const getAboutUsData = async () => {
    const response = await ManageService.getAboutorContentPageData("about-us");
    if (response.status === 200) {
      setAboutData(response.data?.result?.content || {});
    }
  };

  return (
    <div className="container-fluid m-0 p-0" id="about-us">
      <NavBar />
      <div className="containt-wrapper">
        <div className="hero-section align-items-center row no-gutters">
          <div className="text-container col-lg-8 col-xl-8 col-md-6 col-sm-12 col-xs-12 d-flex flex-column">
            <h1 className="title-1">WE HELP HEALTHCARE  <br></br>TO GROW </h1>
            <h1 className="title-2">EXPONENTIALLY</h1>
            <h1 className="title-1">EVERY YEAR </h1>
          </div>
          <div className="img-container col-lg-4 col-xl-4 col-md-6 d-sm-none d-xs-none d-lg-block d-xl-block d-md-flex">
            <img className="hero-image" src={HeroImage}></img>
          </div>
        </div>
        <div className="aboutus-section">
          <div className="container">
            <h3 className="aboutus-header text-center my-4">ABOUT US</h3>
            <div className="aboutus-para p-4">{HtmlParser(aboutData.aboutus)}</div>
          </div>
        </div>
        <div className="mission-section my-4">
          <div className="container-fluid">
            <h3 className="mission-header text-center my-4">OUR MISSION</h3>
            <div className="row align-items-center no-gutters mission-content">
              <img src={MissionImage} className="mission-image col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12 d-xs-none"></img>
              <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-xs-12 row align-items-center no-gutters">
                <div className="perfection-text text-block  col-xl-10 col-lg-10 col-md-8 col-sm-9 col-xs-12 offset-md-1">
                  <div className="header-text">{aboutData.missionHeader4}</div>
                  <div className="content">{aboutData.missionText4}</div>
                  <div className="right-trangle"></div>
                </div>
                <div className="co-operation-text text-block col-xl-10 col-lg-10 col-md-8 col-sm-9 col-xs-12 offset-md-1">
                  <div className="header-text">{aboutData.missionHeader3}</div>
                  <div className="content">{aboutData.missionText3}</div>
                  <div className="right-trangle"></div>
                </div>
                <div className="trustworthy-text text-block  col-xl-10 col-lg-10 col-md-8 col-sm-9 col-xs-12 offset-md-1">
                  <div className="header-text">{aboutData.missionHeader2}</div>
                  <div className="content">{aboutData.missionText2}</div>
                  <div className="right-trangle"></div>
                </div>
                <div className="commitment-text text-block  col-xl-10 col-lg-10 col-md-8 col-sm-9 col-xs-12 offset-md-1">
                  <div className="header-text">{aboutData.missionHeader1}</div>
                  <div className="content">{aboutData.missionText1}</div>
                  <div className="right-trangle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};
export default AboutUs;
