import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import ClientService from "../../service/ClientService";
import Loader from "../../component/Loader";
import ConfirmModal from "../../component/modal/ConfirmModal";
import ErrorModal from "../../component/modal/ErrorModal";
import SuccessModal from "../../component/modal/SuccessModal";
import Moment from "react-moment";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import EventIcon from "@material-ui/icons/Event";
import RoomIcon from "@material-ui/icons/Room";
import JobsService from "../../service/JobService";
import SearchIcon from "@material-ui/icons/Search";
import { JobListView } from "../jobs/List";
import AddSubscriptionModal from "../../component/modal/AddSubscription";
import WarningIcon from "@material-ui/icons/Warning";
import { SuccessToaster } from "../../component/modal/SuccessModal";
import LocalService from "../../service/LocalstorageService";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonIcon from "@material-ui/icons/Person";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CancelIcon from "@material-ui/icons/Cancel";

import TextField from "@material-ui/core/TextField";
import Slider from "react-slick";
import HtmlParser from "react-html-parser";
import Mail from "@material-ui/icons/Mail";

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 2,
};

const ActivityList = (props) => {
  return (
    <div className="activity-date-section">
      <p>
        <Moment
          date={new Date(props.data.date)}
          format={" MMMM DD YYYY"}
        ></Moment>
      </p>
      {props.data.messages &&
        props.data.messages.map((item, index) => {
          return (
            <div className="card activity-list-card ml-4" key={index}>
              <div className="card-body p-2">
                <div className="card-title row no-gutters mb-2">
                  <p className="col m-0">{item.message}</p>
                  <div className="col-auto">
                    <Moment date={item.createdOn} format={"hh:mm A"}></Moment>
                  </div>
                </div>

                <p className="card-text">{item.detailedMessage}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const ClientView = (props) => {
  const { id, userType } = useParams();
  const [clientData, setClientData] = useState({});
  const [subsCategoryList, setSubsCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmationObj, setConfirmationObj] = useState({
    show: false,
    msg: "",
  });
  const [errModel, setErrorModal] = useState({ show: false, error: {} });
  const [successModel, setSuccessModal] = useState({
    show: false,
    success: {},
  });
  const [currentAction, setCurrentAction] = useState("");
  const [hotJobOnly, setHotJobOnly] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [jobsPaginationObj, setJobsPaginationObj] = useState({});
  const [currentJobAction, setCurrentJobAction] = useState("");
  const [showSubsPopup, setShowSubsPopup] = useState({
    show: false,
    client: {},
  });
  const [successObj, setSuccessObj] = useState({ show: false, msg: "" });
  const [activeIndex, setActiveIndex] = useState(0);
  const history = useHistory();
  const permissions = LocalService.getPermision().filter(
    (perm) => perm.name === "Client Management"
  );
  const [open, setOpen] = React.useState(false);
  const [activityData, setActivityData] = useState([]);
  const [openAddUser, setOpenAddUser] = React.useState(false);
  openAddReview;
  const [openAddReview, setOpenAddReview] = React.useState(false);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [emailId, setEmailId] = React.useState(null);
  const [authorName, setAuthorName] = React.useState(null);
  const [authorDesignation, setAuthorDesignation] = React.useState(null);
  const [authorProfilePic, setAuthorProfilePic] = React.useState([]);
  const [authorMessage, setAuthorMessage] = React.useState(null);
  const [listReviews, setListReviews] = React.useState([]);
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isEditReview, setIsEditReview] = useState(false);
  const [selectedReviewId, setSelectedReviewId] = useState(null);

  useEffect(() => {
    getClientData();
    getReviews();
  }, []);

  useEffect(() => {
    if (clientData.id) {
      getJobsData();
    }
  }, [clientData]);

  useEffect(() => {
    if (clientData.id) {
      getJobsData();
    }
  }, [hotJobOnly]);

  const getLastUpdated = (date) => {
    var today = new Date();
    var date = new Date(date);
    var seconds = Math.floor((today - date) / 1000);
    var interval;
    var lastUpdOn;
    if (Math.floor(seconds / 31536000) >= 1) {
      interval = Math.floor(seconds / 31536000);
      if (interval == 1) {
        lastUpdOn = interval + " year";
      } else {
        lastUpdOn = interval + " years";
      }
    } else if (Math.floor(seconds / 2592000) >= 1) {
      interval = Math.floor(seconds / 2592000);
      if (interval == 1) {
        lastUpdOn = interval + " month";
      } else {
        lastUpdOn = interval + " months";
      }
    } else if (Math.floor(seconds / 86400) >= 1) {
      interval = Math.floor(seconds / 86400);
      if (interval == 1) {
        lastUpdOn = interval + " day";
      } else {
        lastUpdOn = interval + " days";
      }
    } else if (Math.floor(seconds / 3600) >= 1) {
      interval = Math.floor(seconds / 3600);
      if (interval == 1) {
        lastUpdOn = interval + " hour";
      } else {
        lastUpdOn = interval + " hours";
      }
    } else if (Math.floor(seconds / 60) >= 1) {
      interval = Math.floor(seconds / 60);
      if (interval == 1) {
        lastUpdOn = interval + " minute";
      } else {
        lastUpdOn = interval + " minutes";
      }
    } else if (Math.floor(seconds) >= 1) {
      interval = Math.floor(seconds);
      if (interval == 1) {
        lastUpdOn = interval + " second";
      } else {
        lastUpdOn = interval + " seconds";
      }
    }
    return lastUpdOn;
  };

  const openClientActivity = (userId) => {
    setOpen(true);
    getActivityData(userId);
  };

  const getReviews = async () => {
    try {
      const response = await ClientService.getReviews(id);
      if (response.status === 200) {
        setListReviews(response["data"]["result"]["data"]);
      }
    } catch (err) { }
  };

  const validateForm = () => {
    if (
      firstName &&
      firstName.trim().length &&
      lastName &&
      lastName.trim().length &&
      emailId &&
      emailId.trim().length
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onFileChange = (e) => {
    e.persist();
    e.preventDefault();
    let formData = new FormData();
    formData.append("files", e.target.files[0]);
    formData.append("mediaType", "image");
    ClientService.upload(formData).then(
      (res) => {
        setAuthorProfilePic(res["data"]["result"]);
        // handleOnChange({target:{name:'profilePic',value:res.data.result[0]}})
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleCloseAddUser = async () => {
    setOpenAddUser(false);
    let data = {
      email: emailId,
      firstName: firstName,
      lastName: lastName,
      clientId: id,
    };
    try {
      const response = await ClientService.addClientUser(data);
      if (response.status === 201) {
        setSuccessModal({ show: true, success: { msg: response.data.result } });
        location.reload();
      }
    } catch (err) {
      setLoading(false);
      if (err && err.data) {
        setErrorModal({ show: true, error: err.data });
      }
    }
  };

  const handleCloseAddReview = async () => {
    setLoading(true);
    if (isEditReview) {
      let data = {
        clientId: id,
        authorName: authorName,
        authorProfilePic: authorProfilePic.length
          ? authorProfilePic[0].id
          : null,
        authorDesignation: authorDesignation,
        authorMessage: authorMessage,
        shouldDisplay: shouldDisplay,
        isLocked: isLocked,
        isValidated: isValidated,
      };
      try {
        const response = await ClientService.updateReview(
          selectedReviewId,
          data
        );
        if (response.status === 200) {
          getReviews();
          setOpenAddReview(false);
          setAuthorName("");
          setAuthorProfilePic([]);
          setAuthorDesignation("");
          setAuthorMessage("");
          setShouldDisplay(false);
          setIsLocked(false);
          setIsValidated(false);
          setLoading(false);
          setSuccessModal({ show: true, success: { msg: "Review updated" } });
        }
      } catch (err) {
        setLoading(false);
        if (err && err.data) {
          setErrorModal({ show: true, error: err.data });
        }
      }
    } else {
      let data = {
        clientId: id,
        authorName: authorName,
        authorProfilePic: authorProfilePic.length
          ? authorProfilePic[0].id
          : null,
        authorDesignation: authorDesignation,
        authorMessage: authorMessage,
      };
      try {
        const response = await ClientService.addReview(data);
        if (response.status === 201) {
          getReviews();
          setOpenAddReview(false);
          setAuthorName("");
          setAuthorProfilePic([]);
          setAuthorDesignation("");
          setAuthorMessage("");
          setLoading(false);
          setSuccessModal({ show: true, success: { msg: "Review created" } });
        }
      } catch (err) {
        setLoading(false);
        if (err && err.data) {
          setErrorModal({ show: true, error: err.data });
        }
      }
    }
  };
  const getActivityData = async (userId) => {
    try {
      const response = await ClientService.getUserActivity(userId);
      if (response.status === 200) {
        setActivityData(response.data.result);
        //console.log("staff data",response.data.result);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err && err.data) {
        setErrorModal({ show: true, error: err.data });
      }
    }
  };
  const checkAccess = (type) => {
    return permissions[0][type];
  };

  const getClientData = async () => {
    setLoading(true);
    try {
      const response = await ClientService.getClientDetails(id);
      if (response.status === 200) {
        setClientData(response.data.result);
        const categoryList = [];
        if (response.data.result.subscriptions.length) {
          response.data.result.subscriptions[0]["categories"].forEach(
            (data, index) => {
              categoryList.push({
                name: data.category.name,
                value: data.profileAccessBalanceLeft,
                index: index,
              });
            }
          );
        }

        // response.data.result.categories.forEach((data, index) => {
        //     categoryList.push({ name: data.category.name, value: data.profileAccessBalanceLeft, index: index });
        // });
        setSubsCategoryList(categoryList);
        //console.log("client data",response.data.result);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      history.push(`/${userType}/client`);
    }
  };
  const getJobsData = async (param, url) => {
    try {
      let params = param ? { ...param } : {};
      hotJobOnly ? (params.jobType = "hot") : "";
      params.clientId = clientData.id ? clientData.id : "";
      //console.log('params',params,hotJobOnly,param)         ;
      const response = await JobsService.getJobList("", "", "", "", hotJobOnly, params.clientId);
      if (response.status === 200) {
        if (url) {
          let list = [];
          list = list.concat(jobList.data, response.data.result.data);
          response.data.result.data = list;
        }
        setJobList(response.data.result);
        setJobsPaginationObj(response.data.detail);
      }
      setLoading(false);
    } catch (e) {
      // console.log(e);
      setLoading(false);
    }
  };

  const onSearch = (e) => {
    e.persist();
    e.preventDefault();
    e.stopPropagation();
    const value = e.target.value;
    if ((value && value.length > 2) || !value) {
      getJobsData({ search: value });
    }
  };

  const closePopup = () => {
    setConfirmationObj({ show: false, msg: "" });
    setCurrentAction("");
  };

  const performAction = (e) => {
    //console.log("action",e);
    e.persist();
    e.preventDefault();
    const action = e.target.dataset.value;
    setCurrentAction({ action: action, data: [id] });
    setConfirmationObj({
      show: true,
      msg: `You want to ${action} this client account?`,
      type: "client",
    });
  };

  const doAction = async () => {
    if (confirmationObj.type === "job") {
      doJobAction();
      return;
    }
    setLoading(true);
    setConfirmationObj({ show: false, msg: "" });

    try {
      const res = await ClientService.updateClientStatus(currentAction.action, {
        clientIds: currentAction.data,
      });
      setCurrentAction({});
      setLoading(false);
      //console.log("success res", res);
      if (res.status === 200) {
        setSuccessModal({ show: true, success: { msg: res.data.result } });
        getClientData();
      }
    } catch (err) {
      //console.log(err);
      setLoading(false);
      if (err && err.data) {
        setErrorModal({ show: true, error: err.data });
      }
    }
  };

  const performJobAction = (e) => {
    //console.log("action",e);
    e.persist();
    e.preventDefault();
    const action = e.target.dataset.value;
    const id = e.target.dataset.id;
    setCurrentJobAction({ action: action, data: [id] });
    setConfirmationObj({
      show: true,
      msg: `You want to ${action} this Job?`,
      type: "job",
    });
  };

  const doJobAction = async () => {
    setLoading(true);
    setConfirmationObj({ show: false, msg: "" });

    try {
      const res = await JobsService.updateJobStatus(currentJobAction.action, {
        jobPostIds: currentJobAction.data,
      });
      setCurrentJobAction({});
      setLoading(false);
      //console.log("success res", res);
      if (res.status === 200) {
        setSuccessModal({ show: true, success: { msg: res.data.result } });
        getJobsData();
      }
    } catch (err) {
      //console.log(err);
      setLoading(false);
      if (err && err.data) {
        setErrorModal({ show: true, error: err.data });
      }
    }
  };
  const updateSubscription = () => {
    //console.log("updateSubscription called");
    setShowSubsPopup({ show: true, client: clientData });
  };

  const afterAddSubscription = (data) => {
    getClientData();
    setShowSubsPopup({ show: false, client: {} });
    setSuccessObj({ show: true, msg: "Subscription is added successfully." });
  };

  const onCancelSubscription = (data) => {
    //console.log("onCancelSubscription called",data);
    setShowSubsPopup({ show: false, client: {} });
  };

  const addJobs = () => {
    //console.log("Add job called");
    history.push({
      pathname: `/${userType}/job-post/add`,
      state: {
        id: clientData.id,
        name: clientData.name,
        logo: clientData.logo,
      },
    });
  };

  const onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  const createReview = () => {
    setOpenAddReview(true);
    setIsEditReview(false);
    setAuthorName("");
    setAuthorProfilePic([]);
    setAuthorDesignation("");
    setAuthorMessage("");
  };

  const editReview = (review) => {
    setSelectedReviewId(review.id);
    ClientService.fetchReview(review.id)
      .then((res) => {
        setOpenAddReview(true);
        setIsEditReview(true);
        setAuthorName(res["data"]["result"]["authorName"]);
        setAuthorDesignation(res["data"]["result"]["authorDesignation"]);
        setAuthorMessage(res["data"]["result"]["authorMessage"]);
        setShouldDisplay(res["data"]["result"]["shouldDisplay"]);
        setIsLocked(res["data"]["result"]["isLocked"]);
        setIsValidated(res["data"]["result"]["isValidated"]);
        let image = [];
        image.push(res["data"]["result"]["authorProfilePic"]);
        setAuthorProfilePic(image);
      })
      .catch((err) => {
        setLoading(false);
        if (err && err.data) {
          setErrorModal({ show: true, error: err.data });
        }
      });
  };

  if (loading) return <Loader />;

  return (
    <div id="staff-view">
      {userType !== "cli" ? (
        <div className="row cs-breadcrumb no-gutters">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/client`}>Client</Link>
            </li>
            <li className="breadcrumb-item">{clientData.name}</li>
          </ol>
        </div>
      ) : null}

      <div className={`content-wrapper ${userType === "cli" ? "mt-3" : null}`}>
        <div className="content-container ">
          <div className="row client-details-container no-gutters">
            <div className="col-12">
              <div className="row">
                <div className="col-2 mt-4">
                  {clientData.logo ? (
                    <img
                      className="user-image  margin-left-auto"
                      src={clientData.logo.href}
                    ></img>
                  ) : (
                    <div className="user-no-image margin-left-auto">
                      <div className="margin-auto">No Image</div>
                    </div>
                  )}
                </div>
                <div className="col-10">
                  <div className="name-section row no-gutters">
                    <p className="col-auto name m-0">{clientData.name}</p>
                    <p className="col-auto mb-0 ml-4 mt-2">
                      {clientData.isArchived ? (
                        <span className="badge badge-danger">Archived</span>
                      ) : clientData.isActive ? (
                        <span className="badge badge-success">Active</span>
                      ) : (
                        <span className="badge badge-warning">Inactive</span>
                      )}
                    </p>
                    <div className="col d-flex flex-row-reverse staff-detail-action ">
                      {checkAccess("update") ? <Link target={"_blank"} rel={"noopener noreferrer"} to={`/${userType}/client/edit/${id}`} className="btn btn-primary">Edit</Link> : ''}
                      {checkAccess("write") ? (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={updateSubscription}
                        ><b>+</b> Subscription</button>
                      ) : null}
                      {clientData.user ? <>  <Link
                        target={"_blank"} rel={"noopener noreferrer"}
                        to={`/${userType}/client/details/${id}?client=${clientData.name}&userId=${clientData.user.id}`}
                        className="btn btn-info"
                      >
                        View Logs
                      </Link></> : <>  <Link
                        target={"_blank"} rel={"noopener noreferrer"}
                        to={`/${userType}/client/details/${id}?client=${clientData.name}`}
                        className="btn btn-info"
                      >
                        View Logs
                      </Link></>}
                      {checkAccess("update") && !clientData.isActive ? <button type="button" className="btn btn-warning btn-sm" data-value="activate" onClick={performAction}>Activate</button> : ''}
                      {checkAccess("update") && clientData.isActive ? <button type="button" className="btn btn-warning btn-sm" data-value="deactivate" onClick={performAction}>Deactivate</button> : ''}
                      {checkAccess("delete") ? <button type="button" className="btn btn-danger btn-sm" data-value="delete" onClick={performAction}>Delete Permanently</button> : ''}
                    </div>
                  </div>
                  <div className="detail-section client-view-left row no-gutters">
                    {clientData.addedBy ? (
                      <div className="col-6 item-detail">
                        {" "}
                        <PersonIcon />{" "}
                        <span title="Account Creator Name">
                          {clientData.addedBy.firstName +
                            " " +
                            clientData.addedBy.lastName}
                        </span>
                      </div>
                    ) : ('')}
                    {clientData.addedBy ? (
                      <div className="col-6 item-detail">
                        {" "}
                        <MailIcon />{" "}
                        <span title="Account Creator Email">
                          {clientData.addedBy.email}
                        </span>
                      </div>
                    ) : ('')}
                    <div className="col-6 item-detail">
                      {" "}
                      <EventIcon />{" "}
                      <span title="Account Created On">
                        <Moment
                          date={clientData.createdOn}
                          format={"DD MMM YYYY"}
                        ></Moment>
                      </span>
                    </div>
                    <div className="col-6 item-detail">
                      {" "}
                      <RoomIcon />{" "}
                      <span title="Client Location">{clientData.location}</span>
                    </div>
                  </div>
                  {clientData.user ? (
                    <div className="detail-section client-view-left row no-gutters spl-dotted-border-div-css">
                      <div className="col-6 item-detail">
                        {" "}
                        <MailIcon />{" "}
                        <span title="Lead Contact Email">
                          {clientData.personContactEmail}
                        </span>
                      </div>
                      <div className="col-6 item-detail">
                        {" "}
                        <PhoneIcon />{" "}
                        <span title="Lead Contact Number">
                          {clientData.personContactNumber}
                        </span>
                      </div>
                      <div className="col-6 item-detail">
                        {" "}
                        <PersonIcon />{" "}
                        <span title="Client Login Person Name">
                          {clientData.user.firstName +
                            " " +
                            clientData.user.lastName}
                        </span>
                      </div>
                      <div className="col-6 item-detail">
                        {" "}
                        <MailIcon />
                        <span title="Client Login Email">
                          {clientData.user.email}
                        </span>
                        {clientData.user.hasEmailVerified ? (
                          <span className="pl-2">
                            <CheckCircleIcon className="verified_icon" />{" "}
                            <i className="pl-1">
                              <b>Verified</b>
                            </i>
                          </span>
                        ) : (
                          <span className="pl-2">
                            <CancelIcon className="not_verified_icon" />{" "}
                            <i className="pl-1">
                              <b>Not Verified</b>
                            </i>
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="detail-section client-view-left row no-gutters spl-dotted-border-div-css">
                      <div className="col-6 item-detail">
                        {" "}
                        <MailIcon />{" "}
                        <span title="Lead Contact Email">
                          {clientData.personContactEmail}
                        </span>
                      </div>
                      <div className="col-4 item-detail">
                        {" "}
                        <PhoneIcon />{" "}
                        <span title="Lead Contact Number">
                          {clientData.personContactNumber}
                        </span>
                      </div>
                      <div className="col-2 item-detail">
                        <button
                          type="button"
                          className="btn btn-dark btn-sm float-right"
                          data-value="deactivate"
                          onClick={() => {
                            setOpenAddUser(true);
                          }}
                        >
                          + Add User
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="detail-section client-view-left  row no-gutters">
                <label className="label col-12 py-3">Description</label>
                <div className="col-12 item-detail description">
                  {HtmlParser(clientData.description)}
                </div>
              </div>

              <div className="image-section row no-gutters">
                <label className="label col-12 py-3">Images</label>
                <div className="image-preview-section col-12 row no-gutters">
                  {clientData?.imageUploads &&
                    clientData?.imageUploads?.length > 0 ? (
                    clientData?.imageUploads.map((image) => {
                      return (
                        <div
                          className="card col-auto image-preview-card m-2 p-0"
                          key={image.id}
                        >
                          <img
                            className="card-img-top w-100 h-100"
                            src={image.media.href}
                            alt="Card image cap"
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="w-100 text-center p-4 my-2 border-light-grey">
                      No images avilable.
                    </div>
                  )}
                </div>
              </div>
              <div className="video-section row no-gutters">
                <label className="label col-12 py-3">Videos</label>
                <div className="image-preview-section col-12 row no-gutters">
                  {clientData?.videoUploads &&
                    clientData?.videoUploads.length > 0 ? (
                    clientData?.videoUploads.map((video) => {
                      return (
                        <div
                          className="card bg-dark text-white col-5 h-100 mx-3"
                          key={video.id}
                        >
                          <div className="card-body p-0">
                            <video
                              src={video.media.href}
                              width="100%"
                              height="200"
                              controls
                            ></video>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="w-100 text-center p-4 my-2 border-light-grey">
                      No videos avilable.
                    </div>
                  )}
                </div>
              </div>

              <div className="row no-gutters testimonial-section">
                <label className="label col-10 py-3">Reviews</label>
                <button
                  className="btn btn-primary write-review-btn col-2"
                  onClick={() => createReview()}
                >
                  + Write Review
                </button>
                <div className="col-12">
                  {listReviews.length === 0 ? (
                    <h4 className="text-center">No Reviews Found</h4>
                  ) : null}
                  <Slider {...settings}>
                    {listReviews.map((review) => {
                      return (
                        <div
                          className="review_bg"
                          key={review.id}
                          onClick={() => editReview(review)}
                        >
                          <div className="d-flex">
                            <div>
                              <img
                                className=""
                                src={review.authorProfilePic.href}
                              />
                            </div>
                            <div>
                              <div>
                                <p className="mb-0">
                                  <b>{review.authorName}</b>
                                </p>
                                <h6>{review.authorDesignation}</h6>
                              </div>
                            </div>
                            <div className="flex-fill text-right">
                              <h6 className="badge">{`${getLastUpdated(
                                review.createdOn
                              )} ago`}</h6>
                            </div>
                          </div>
                          <div className="desc">
                            <q title={review.authorMessage}>
                              <i>
                                {review.authorMessage.slice(0, 165)}
                                {review.authorMessage.length >= 165
                                  ? "..."
                                  : null}
                              </i>
                            </q>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SuccessToaster
        data={successObj}
        onClose={() => {
          setSuccessObj({ show: false, msg: "" });
        }}
      ></SuccessToaster>
      <AddSubscriptionModal
        data={showSubsPopup}
        onConfirmAction={afterAddSubscription}
        onCancel={onCancelSubscription}
      ></AddSubscriptionModal>
      <ConfirmModal
        data={confirmationObj}
        onConfirmAction={doAction}
        onCancel={closePopup}
      ></ConfirmModal>
      <ErrorModal
        data={errModel}
        onConfirmAction={() => setErrorModal({ show: false, error: {} })}
      ></ErrorModal>
      <SuccessModal
        data={successModel}
        onConfirmAction={() => setSuccessModal({ show: false, success: {} })}
      ></SuccessModal>

      <Dialog
        open={openAddUser}
        onClose={() => setOpenAddUser(false)}
        className="add_client_user"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add New User"}</DialogTitle>
        <DialogContent>
          <div className="form-group m-0 mb-3">
            <div className="d-flex justify-content-center">
              <TextField
                label="First Name"
                type="search"
                variant="outlined"
                onChange={(event) => setFirstName(event.target.value)}
              />
            </div>
          </div>
          <div className="form-group m-0 mb-3">
            <div className="d-flex justify-content-center">
              <TextField
                label="Last Name"
                type="search"
                variant="outlined"
                onChange={(event) => setLastName(event.target.value)}
              />
            </div>
          </div>
          <div className="form-group m-0 mb-3">
            <div className="d-flex justify-content-center">
              <TextField
                label="Email Id"
                type="search"
                variant="outlined"
                onChange={(event) => setEmailId(event.target.value)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseAddUser}
            color="primary"
            variant="contained"
            autoFocus
            className="mr-3 mb-3"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddReview}
        onClose={() => setOpenAddUser(false)}
        className="add_client_user"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">
          {isEditReview ? "Update Review" : "Write Review"}
        </DialogTitle>
        <DialogContent>
          <div className="form-group m-0 mb-3">
            <div className="d-flex justify-content-center">
              <TextField
                label="Author Name"
                type="search"
                variant="outlined"
                value={authorName}
                onChange={(event) => setAuthorName(event.target.value)}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 50);
                }}
              />
            </div>
          </div>
          <div className="form-group m-0 mb-3">
            <div className="d-flex justify-content-center">
              <TextField
                label="Author Designation"
                type="search"
                variant="outlined"
                value={authorDesignation}
                onChange={(event) => setAuthorDesignation(event.target.value)}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 50);
                }}
              />
            </div>
          </div>
          <div className="form-group m-0 mb-3">
            <div className="d-flex justify-content-center">
              <TextField
                id="outlined-multiline-static"
                label="Author Message"
                multiline
                rows={4}
                variant="outlined"
                value={authorMessage}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 500);
                }}
                onChange={(event) => setAuthorMessage(event.target.value)}
              />
            </div>
          </div>
          {isEditReview && (userType === "adm" || userType === "stf") ? (
            <div>
              <div className="form-group m-0 mb-1">
                <label className="label-sm mr-4">
                  Should Display Review ?{" "}
                </label>
                <div
                  className="switch"
                  onClick={() => setShouldDisplay(!shouldDisplay)}
                >
                  {shouldDisplay ? (
                    <input
                      type="checkbox"
                      name="shouldDisplayTrue"
                      id="shouldDisplay"
                      value={shouldDisplay}
                      checked={shouldDisplay}
                      onChange={() => { }}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      name="shouldDisplayFalse"
                      id="shouldDisplay"
                      value={shouldDisplay}
                      checked={shouldDisplay}
                      onChange={() => { }}
                    />
                  )}
                  <span className="slider round"></span>
                </div>
              </div>
              <div className="form-group m-0 mb-1">
                <label className="label-sm mr-4">Is Review Locked ? </label>
                <div className="switch" onClick={() => setIsLocked(!isLocked)}>
                  {isLocked ? (
                    <input
                      type="checkbox"
                      name="isLockedTrue"
                      id="isLocked"
                      value={isLocked}
                      checked={isLocked}
                      onChange={() => { }}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      name="isLockedFalse"
                      id="isLocked"
                      value={isLocked}
                      checked={isLocked}
                      onChange={() => { }}
                    />
                  )}
                  <span className="slider round"></span>
                </div>
              </div>
              <div className="form-group m-0 mb-1">
                <input
                  type="checkbox"
                  name="isValidatedFalse"
                  id="isValidated"
                  className="checkbox"
                  value={isValidated}
                  checked={isValidated}
                  onChange={() => setIsValidated(!isValidated)}
                />
                <label className="label-sm mr-4">Is Review Validated ? </label>
              </div>
            </div>
          ) : null}
          <div className="form-group m-0 mb-2">
            <div className="d-flex justify-content-left">
              <div className="row no-gutters">
                <div className="fileUpload btn btn-secondary cs-upload-1 m-0 col-auto">
                  <span>Upload Photo</span>
                  <input
                    type="file"
                    className="upload"
                    name="logo"
                    id="logo"
                    onChange={(event) => onFileChange(event)}
                  />
                </div>
                <div className="col file-name">
                  <span className="margin-top-auto">
                    {authorProfilePic.length
                      ? authorProfilePic[0].originalName
                      : null}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenAddReview(false)}
            color="primary"
            variant="contained"
            className="mr-3 mb-3"
          >
            Cancel
          </Button>
          <Button
            onClick={handleCloseAddReview}
            color="primary"
            variant="contained"
            autoFocus
            className="mr-3 mb-3"
            disabled={
              !authorName ||
              (authorName && !authorName.trim().length) ||
              !authorProfilePic.length ||
              !authorDesignation ||
              (authorDesignation && !authorDesignation.trim().length) ||
              !authorMessage ||
              (authorMessage && !authorMessage.trim().length)
            }
          >
            {isEditReview ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClientView;
