import Api from './Interceptor';
import LocalStorageService from './LocalstorageService';

const localStorage = LocalStorageService.getService();

const AuthService = (function () {
    let query = function (param) {
        return Api({
            method: param.method,
            url: param.url,
            data: param.data
        })
    }
    return {
        login: function (data) {
            let requestObj = { method: 'POST', data: data, url: 'auth/signin/legacy' }
            return query(requestObj);
        },
        signup: function (data) {
            let requestObj = { method: 'POST', data: data, url: 'candidate/user' }
            return query(requestObj);
        },
        forgotPassword: function (email) {
            let requestObj = { method: 'GET', url: 'auth/forget/password?email=' + email }
            return query(requestObj);
        },
        activateAccount: function (data, code) {
            let requestObj = { method: 'POST', data: data, url: 'auth/account/activate?code=' + code }
            return query(requestObj);
        },
        setNewPassword: function (data, code) {
            let requestObj = { method: 'POST', data: data, url: 'auth/forget/password?code=' + code }
            return query(requestObj);
        },
        isLoggedIn: function () {
            return localStorage.getAccessToken() ? true : false;
        },
        getAccessToken: function () {
            return localStorage.getAccessToken();
        },
        validateAccessToken: function () {
            let requestObj = { method: 'GET', url: 'auth/token/status' }
            return query(requestObj);
        },
        logout: function () {
            let requestObj = { method: 'GET', url: 'auth/signout' }
            return query(requestObj);
        }
    }
})()

export default AuthService;